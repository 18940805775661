import {FbFormObject} from '../class/fb_form_object';

export class ScCommodityInventoryLogForm extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'commodity', label: 'commodity', visible: true, noclick: false, disable_ripple: true},
            {id: 'action', label: 'action', visible: true, noclick: false, disable_ripple: true},
            {id: 'value', label: 'value', visible: true, noclick: false, disable_ripple: true},
            {id: 'user_id', label: 'user', visible: true, noclick: false, disable_ripple: true},
            {id: 'receiver', label: 'receiver', visible: true, noclick: false, disable_ripple: true},
            {id: 'insert_ts', label: 'insert_ts', visible: true, noclick: false, disable_ripple: true},
            {id: 'note', label: 'note', visible: true, noclick: false, disable_ripple: true},
            {id: 'source_type', label: 'source type', visible: true, noclick: false, disable_ripple: true},
            {id: 'source_name', label: 'source name', visible: true, noclick: false, disable_ripple: true},
            {id: 'image', label: 'Image', visible: true, type: 'template', template_row: this.templates['upload_tpl'],
                noclick: false, disable_ripple: true}
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Commodity',
                class: 'card card-1 padding single-column',
                field_class: '',
                fields: [
                    {
                        type: 'autocomplete',
                        name: 'commodity',
                        options: this.select_boxes['commodity_select'],
                        rules: [
                            {rule: 'required'}
                        ],
                        disabled: false
                    },
                    {
                        type: 'select',
                        name: 'action',
                        options: this.select_boxes['action_select'],
                        rules: [
                            {rule: 'required'}
                        ],
                        disabled: false
                    },
                    {
                        type: 'number',
                        name: 'c_id',
                        disabled: false
                    },
                    {
                        type: 'number',
                        name: 'value',
                        disabled: false,
                        number_formatted: true
                    },
                    {
                        type: 'autocomplete',
                        name: 'user_id',
                        options: this.select_boxes['ifa_member_select'],
                        rules: [
                            {rule: 'required'}
                        ],
                        disabled: false
                    },
                    {
                        type: 'autocomplete',
                        name: 'receiver',
                        options: this.select_boxes['ifa_member_select'],
                        rules: [
                            {rule: 'required'}
                        ],
                        disabled: false
                    },
                    {
                        type: 'datetime',
                        name: 'insert_ts',
                        disabled: true
                    },
                    {
                        type: 'textarea',
                        name: 'note',
                        disabled: false
                    }
                ]
            },
            {
                caption: 'Pictures',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'template',
                        template: this.templates['upload_tpl'],
                        name: 'image',
                        no_input: false
                    }
                ]
            }
        ];
        super.setEditFields();
    }

    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.stores['sc_commodity_inventory_log'] = this.storeService.createNoneGlobalStore('sc_commodity_inventory_log',
                'sc-commodity-inventory/type/' + this.custom_obj.type + '/'  + this.custom_obj.commodity + '/log', 'log_id');
            this.stores['sc_available_commodities'] = this.storeService.getStore('sc_available_commodities');
            this.stores['ifa_member'] = this.storeService.getStore('ifa_member');

            this.main_store = this.stores['sc_commodity_inventory_log'];

            const selectFilter = [];
            selectFilter.push('first=0');
            selectFilter.push('rows=1000');

            const stores = [];
            stores.push(this.stores['sc_commodity_inventory_log'].loadStore(this.filter));
            stores.push(this.stores['sc_available_commodities'].loadStore('?' + selectFilter.join('&')));
            stores.push(this.stores['ifa_member'].loadStore('?' + selectFilter.join('&')));

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
                commodity: this.custom_obj.commodity,
                action: null,
                c_id: 0,
                value: null,
                user_id: null,
                receiver: null,
                insert_ts: null,
                note: ''
            }
        };
    }

    /**
     * custom reload
     */
    public setSelectboxes() {
        this.parseCommoditySelectBox(this.custom_obj.type);
        this.parseActionSelectBox();
        this.parseScUserSelectbox();
        // call super
        super.setSelectboxes();
    }

    public parseCommoditySelectBox(type) {
        const options = [];
        const records = this.stores['sc_available_commodities'].getRecords();
        for (let i = 0; i < records.length; i++) {
            if (type === records[i].data.type) {
                options.push({
                    value: String(records[i].data.c_id),
                    name: records[i].data.c_name
                });
            }
        }
        this.select_boxes['commodity_select'] = options;
        return this.select_boxes['commodity_select'];
    }

    public parseActionSelectBox() {
        const options = [
            {
                value: 'in',
                name: 'in'
            },
            {
                value: 'out',
                name: 'out'
            }
        ];
        this.select_boxes['action_select'] = options;
        return this.select_boxes['action_select'];
    }

    public parseScUserSelectbox() {
        const options = [];
        const records = this.stores['ifa_member'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.user_id),
                name: records[i].data.sc_username
            });
        }
        this.select_boxes['ifa_member_select'] = options;
        return this.select_boxes['ifa_member_select'];
    }
}
