import { Component } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {StoreService} from '../../services/store.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent {
  public markdown = '## News';
  public records: any;

  constructor(private authService: AuthService, private storeService: StoreService, public router: Router) {
    const NewsStore = this.storeService.getStore('dc_news');
    NewsStore.loadStore('').then((records) => {
      this.records = records;
    });
  }
}
