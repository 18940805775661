<div>
    <app-content-container [content_template]="page_content"
                           [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div class="padding">
        <div class="card card-1 padding table-wrapper" *ngIf="storeObj">
            <app-fb-forms-table [storeObj]="storeObj"
                                [edit_allowed]="true"
                                (row_click)="rowSelected($event)"
                                [stateKey]="'vehicle-class'"
                                [show_add_in_view]="true"
                                [show_delete]="true"
                                [title]="'Vehicle Class'"
            >
            </app-fb-forms-table>
        </div>
    </div>
</ng-template>