import {FbFormObject} from '../class/fb_form_object';
import {AppSettings} from '../config/AppSettings';

export class ExplorePoi extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'title', label: 'Title', visible: true, noclick: false, disable_ripple: true},
            {id: 'description', label: 'Description', visible: true, noclick: false, disable_ripple: true},
            {id: 'tag', label: 'Tags', visible: true, noclick: false, disable_ripple: true},
            {id: 'coordinate_location', label: 'Location', visible: true, noclick: false, disable_ripple: true},
            {id: 'coordinate_x', label: 'x', visible: true, noclick: false, disable_ripple: true},
            {id: 'coordinate_y', label: 'y', visible: true, noclick: false, disable_ripple: true},
            {id: 'coordinate_d', label: 'distance', visible: true, noclick: false, disable_ripple: true},
            {id: 'user_id', label: 'Explorer', visible: true, noclick: false, disable_ripple: true},
            {id: 'insert_ts', label: 'reported at', visible: true, noclick: false, disable_ripple: true},
            {id: 'image', label: 'Image', visible: true, type: 'template', template_row: this.templates['upload_tpl'],
                noclick: false, disable_ripple: true}
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Track',
                class: 'card card-1 padding single-column',
                field_class: '',
                fields: [
                    {
                        type: 'text',
                        name: 'title'
                    },
                    {
                        type: 'editor',
                        name: 'description'
                    }
                ]
            },
            {
                caption: 'Tags',
                class: 'card card-1 padding single-column',
                field_class: '',
                fields: [
                    {
                        type: 'chip_autocomplete',
                        name: 'tag',
                        options: this.select_boxes['tags_select']
                    }
                ]
            },
            {
                caption: 'Location',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'text',
                        name: 'coordinate_location'
                    },
                    {
                        type: 'number',
                        name: 'coordinate_x',
                    },
                    {
                        type: 'number',
                        name: 'coordinate_y',
                    },
                    {
                        type: 'number',
                        name: 'coordinate_d',
                    },
                ]
            },
            {
                caption: 'Explorer',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'autocomplete',
                        name: 'user_id',
                        options: this.select_boxes['sc_user_select'],
                        rules: [
                            {rule: 'required'}
                        ],
                        disabled: true
                    },
                    {
                        type: 'datetime',
                        name: 'insert_ts',
                        disabled: true
                    },
                ]
            },
            {
                caption: 'Pictures',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'template',
                        template: this.templates['upload_tpl'],
                        name: 'image',
                        disabled: true,
                        no_input: false
                    }
                ]
            }
        ];
        super.setEditFields();
    }

    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.stores['explorer_poi'] = this.storeService.getStore('explorer_poi');


            this.main_store = this.stores['explorer_poi'];

            this.stores['sc_user'] = this.storeService.getStore('sc_user');

            const selectFilter = [];
            selectFilter.push('first=0');
            selectFilter.push('rows=1000');

            const tagFilter = [];
            tagFilter.push('filter[type][0][mode]=equals');
            tagFilter.push('filter[type][0][operator]=and');
            tagFilter.push('filter[type][0][value]=explore_poi');
            tagFilter.push('first=0');
            tagFilter.push('rows=100');

            this.stores['poi_tags'] = this.storeService.createNoneGlobalStore('poi_tags',
                'tags', 'tag');

            const stores = [];
            stores.push(this.stores['explorer_poi'].loadStore(this.filter));
            stores.push(this.stores['sc_user'].loadStore('?' + selectFilter.join('&')));
            stores.push(this.stores['poi_tags'].loadStore('?' + tagFilter.join('&')));

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
                title: '',
                description: '',
                coordinate_location: '',
                coordinate_x: null,
                coordinate_y: null,
                coordinate_d: null,
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        this.parseScUserSelectbox();
        this.parsTagsSelectBox();
        // call super
        super.setSelectboxes();
    }

    public parseScUserSelectbox() {
        const options = [];
        const records = this.stores['sc_user'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.user_id),
                name: records[i].data.sc_username
            });
        }
        this.select_boxes['sc_user_select'] = options;
        return this.select_boxes['sc_user_select'];
    }

    public parsTagsSelectBox() {
        const options = [];
        const records = this.stores['poi_tags'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.tag),
                name: records[i].data.tag
            });
        }
        this.select_boxes['tags_select'] = options;
        return this.select_boxes['tags_select'];
    }
}
