import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../../../../services/auth.service';
import {StoreService} from '../../../../../services/store.service';
import {Router} from '@angular/router';
import {ScVersion} from '../../../../../forms/sc-version';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {NotificationService} from '../../../../../services/notification.service';
import {AppSettings} from '../../../../../config/AppSettings';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-sc-version',
  templateUrl: './sc-version.component.html',
  styleUrls: ['./sc-version.component.css']
})
export class ScVersionComponent implements OnInit, OnDestroy, AfterViewInit {


  @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
    this._select_caption_tpl = value;
    // this.setColumns();
    this.cdr.markForCheck();
  }


  public ScVersion: ScVersion;
  public storeExtObj = {};
  private subs: Array<Subscription> = [];

  public loading_text = '';
  public is_loading = true;
  public rows: any[];


  private _select_caption_tpl: ElementRef;

  public is_admin: boolean;

  constructor(private storeService: StoreService,
              private authService: AuthService,
              private router: Router,
              private cdr: ChangeDetectorRef,
              private notificationService: NotificationService,
              public dialog: MatDialog,
              public http: HttpClient) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin);
    this.storeExtObj['http'] = this.http;
    this.storeExtObj['button_callback'] = () => {
      this.reloadStore();
    };
    this.ScVersion = new ScVersion(this.storeService, this.authService, 'edit');
    this.is_loading = false;
    this.cdr.detectChanges();
  }

  public rowSelected($event: any) {
    const route = 'admin/games/starcitizen/sc-version/' + $event.row.version_id;
    if ($event.in_new_tab) {
      const url = this.router.createUrlTree([route]);
      const serializedUrl = this.router.serializeUrl(url);

      // Insert `#` between the domain and route
      const modifiedUrl = '#/' + serializedUrl;
      window.open(modifiedUrl, '_blank');
    } else {
      this.router.navigateByUrl(route);
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  public addClicked() {
    this.router.navigateByUrl('/admin/games/starcitizen/sc-version/add');
  }

  public reloadStore() {
    this.is_loading = true;
    this.ScVersion.reload().then(line_records => {
      this.setRows();
    });
  }

  public saveAllChangedRows($event) {
    this.ScVersion.saveAllChangedRows($event).then((success) => {
      if (success) {
        this.notificationService.success('Daten',
            'Die Datensätze wurden erfolgreich gespeichert', {timeOut: 5000});
        this.reloadStore();
      } else {
        this.notificationService.error('Daten',
            'Es konnten nicht alle Datansätze gespeichert werden', {timeOut: 5000});
      }
    });
  }


  public deleteMarkedRecords($event: any) {
    this.ScVersion.deleteRecords($event).then((success) => {
      if (success) {
        this.notificationService.success('Daten',
            'Die Daten wurden erfolgreich gelöscht', {timeOut: 5000});
        this.reloadStore();
      } else {
        this.notificationService.error('Daten',
            'Es konnten nicht alle Daten gelöscht werden', {timeOut: 5000});
      }
    });
  }

  private setRows() {
    this.rows = null;

    this.is_loading = true;
    this.cdr.detectChanges();

    const records = this.ScVersion.getStore().getRecords();

    // getRecordByPropertyValue('list_type', type)

    this.is_loading = false;
    this.rows = records;
    this.cdr.detectChanges();
  }
}
