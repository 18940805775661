import {Component, OnInit, ViewChild} from '@angular/core';
import {FormInputsComponent} from '../../shared/form-inputs/form-inputs.component';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../services/notification.service';

@Component({
    selector: 'app-check-login',
    templateUrl: './check-login.component.html',
    styleUrls: ['./check-login.component.css']
})
export class CheckLoginComponent implements OnInit {

    @ViewChild('tpl_form') tpl_form: FormInputsComponent;
    public fields: any[];
    public login_obj = {
        username: '',
        password: ''
    };

    constructor(private authService: AuthService,
                private router: Router,
                private notificationService: NotificationService) {
    }

    ngOnInit() {
        this.checkLogin();
    }

    private checkLogin() {
        this.authService.login_state_changed.subscribe((state: number) => {
            if (state === 2) {
                this.notificationService.success('Login',
                    'Login success.', {timeOut: 5000});
                this.router.navigateByUrl('/main');
            } else {
                if (state === -1) {
                    this.router.navigateByUrl('/register');
                } else {
                    this.router.navigateByUrl('/login');
                }
            }
        });
        this.authService.checkLogin();
    }

}
