import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {StoreService} from '../../services/store.service';
import {Router} from '@angular/router';
import {AppSettings} from '../../config/AppSettings';

@Component({
  selector: 'app-bot',
  templateUrl: './bot.component.html',
  styleUrls: ['./bot.component.css']
})
export class BotComponent implements OnInit {

  public loading_text = '';
  public is_loading = false;
  public menu = [];
  public is_admin = false;
  public is_logged_in = false;
  public is_ifa = false;

  constructor(private authService: AuthService, private storeService: StoreService, public router: Router) {
    this.is_logged_in = this.authService.getLoginState() === 2;
    this.is_ifa = this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_ifa);
  }

  ngOnInit() {
    this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin)
        || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_event_admin);
  }
}
