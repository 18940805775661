import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {MaterialModule} from './material/material.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { MainComponent } from './pages/main/main.component';
import {AppRoutingModule} from './routing/app-routing.module';
import { MenuComponent } from './shared/menu/menu.component';
import { ExternalComponent } from './pages/external/external.component';
import { SafePipe } from './pipes/safe.pipe';
import { LoginComponent } from './pages/login/login.component';
import {AuthService} from './services/auth.service';
import {FormInputsComponent} from './shared/form-inputs/form-inputs.component';
import {LinkDirective} from './directives/link.directive';
import {CustomFocusDirective} from './directives/custom-focus.directive';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslationLoader} from './shared/translation.loader';
import {ContentContainerComponent } from './shared/content-container/content-container.component';
import {SearchService} from './services/search.service';
import {SearchComponent} from './shared/search/search.component';
import {HttpClientInMemoryWebApiModule} from 'angular-in-memory-web-api';
import {InMemoryDataService} from './services/in-memory-data.service';
import {RefreshTokenInterceptor} from './services/refresh-token-interceptor.service';
import { CheckLoginComponent } from './pages/check-login/check-login.component';
import { IconTileComponent } from './shared/icon-tile/icon-tile.component';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { ConfirmComponent } from './shared/dialoge/confirm/confirm.component';
import { EditUserComponent } from './pages/user/edit-user/edit-user.component';
import { TeamTileComponent } from './shared/team-tile/team-tile.component';
import { FileUploadComponent } from './shared/file-upload/file-upload.component';
import {fakeBackendProvider} from './helpers/fakeBackendProvider';
import {FbTableComponent} from './shared/fb-table/fb-table.component';
import {MatAnimatedIconComponent} from './shared/mat-animated-icon/mat-animated-icon.component';
import {CommentComponent} from './shared/dialoge/comment/comment.component';
import {AddFormDialogComponent} from './shared/add-form-dialog/add-form-dialog.component';
import {AddSelectListComponent} from './shared/dialoge/add-select-list/add-select-list.component';
import { RegisterComponent } from './pages/register/register.component';
import { PasswordStrengthBarComponent } from './shared/password-strength-bar/password-strength-bar.component';
import { UserComponent } from './pages/user/user.component';
import { LogoutComponent } from './pages/logout/logout.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import { AdminComponent } from './pages/admin/admin.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { TokenLoginComponent } from './pages/token-login/token-login.component';
import { DiscordAuthPointComponent } from './pages/discord-auth-point/discord-auth-point.component';
import { ScVersionComponent } from './pages/admin/games/starcitizen/sc-version/sc-version.component';
import { StarcitizenComponent } from './pages/admin/games/starcitizen/starcitizen.component';
import { GamesComponent } from './pages/admin/games/games.component';
import { EditScVersionComponent } from './pages/admin/games/starcitizen/sc-version/edit-sc-version/edit-sc-version.component';
import {NotificationService} from './services/notification.service';
import { ToastModule } from 'primeng/toast';
import {MessageService} from 'primeng/api';
import { FbFormsTableComponent } from './shared/fb-forms-table/fb-forms-table.component';
import {TableModule} from 'primeng/table';
import {TagModule} from 'primeng/tag';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import {MultiSelectModule} from 'primeng/multiselect';
import {SliderModule} from 'primeng/slider';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import { SimpleEditComponent } from './shared/simple-edit/simple-edit.component';
import { FormInputsRowComponent } from './shared/form-inputs-row/form-inputs-row.component';
import { BotEventsComponent } from './pages/bot/bot-events/bot-events.component';
import { BotEventComponent } from './pages/bot/bot-events/bot-event/bot-event.component';
import {IMqttServiceOptions, MqttModule} from 'ngx-mqtt';
import {AppSettings} from './config/AppSettings';
import { BotEventMemberComponent } from './pages/bot/bot-events/bot-event/bot-event-member/bot-event-member.component';
import {
    NgxMatDatetimePickerModule, NgxMatNativeDateModule
} from '@angular-material-components/datetime-picker';
import { BotEventDepositTilesComponent } from './pages/bot/bot-events/bot-event/bot-event-deposit-tiles/bot-event-deposit-tiles.component';
import { EventOreDepositTileComponent } from './shared/event-ore-deposit-tile/event-ore-deposit-tile.component';
import { BotEventSummaryComponent } from './pages/bot/bot-events/bot-event/bot-event-summary/bot-event-summary.component';
import { PrivacyPoliceComponent } from './pages/privacy-police/privacy-police.component';
import {MarkdownModule} from 'ngx-markdown';
import { BotComponent } from './pages/bot/bot.component';
import { BotAboutComponent } from './pages/bot/bot-about/bot-about.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { PageFooterComponent } from './shared/page-footer/page-footer.component';
import { NewsComponent } from './pages/news/news.component';
import { DiscordMessageComponent } from './shared/discord-message/discord-message.component';
import {Highlight, HIGHLIGHT_OPTIONS} from 'ngx-highlightjs';
import { MembersComponent } from './pages/members/members.component';
import { MembersPointsComponent } from './pages/members/members-points/members-points.component';
import { MembersPointsDetailsComponent } from './pages/members/members-points/members-points-details/members-points-details.component';
import { MembersPointsEditComponent } from './pages/members/members-points/members-points-details/members-points-edit/members-points-edit.component';
import { BotEventPointsComponent } from './pages/bot/bot-events/bot-event/bot-event-points/bot-event-points.component';
import {
    BotEventCommodityDeliveryComponent
} from './pages/bot/bot-events/bot-event/bot-event-commodity-delivery/bot-event-commodity-delivery.component';
import {EventCargoDepositTileComponent} from './shared/event-cargo-deposit-tile/event-cargo-deposit-tile.component';
import {ScCommodityEditComponent} from './pages/admin/games/starcitizen/sc-commodity/sc-commodity-edit/sc-commodity-edit.component';
import {ScCommodityComponent} from './pages/admin/games/starcitizen/sc-commodity/sc-commodity.component';
import { IfaComponent } from './pages/admin/ifa/ifa.component';
import { IfaBadgesComponent } from './pages/admin/ifa/ifa-badges/ifa-badges.component';
import { IfaMemberComponent } from './pages/admin/ifa/ifa-member/ifa-member.component';
import { EditIfaMemberComponent } from './pages/admin/ifa/ifa-member/edit-ifa-member/edit-ifa-member.component';
import { IfaMemberBadgesComponent } from './pages/admin/ifa/ifa-member/edit-ifa-member/ifa-member-badges/ifa-member-badges.component';
import { GuidesComponent } from './pages/guides/guides.component';
import { GuideEditComponent } from './pages/guides/guide-edit/guide-edit.component';
import {EditorModule} from 'primeng/editor';
import {NoHtmlPipe} from './pipes/no-html.pipe';
import {NgxPageScrollCoreModule} from 'ngx-page-scroll-core';
import { ScContentComponent } from './shared/sc-content/sc-content.component';
import {TooltipModule} from 'primeng/tooltip';
import {NgxEditorModule} from 'ngx-editor';
import { MediaComponent } from './pages/media/media.component';
import {FileUpload2Component} from './shared/file-upload2/file-upload2.component';
import { MediaEditComponent } from './pages/media/media-edit/media-edit.component';
import { TableItemPickerComponent } from './shared/dialoge/table-item-picker/table-item-picker.component';
import { UpcomingEventsComponent } from './pages/upcomming-events/upcoming-events.component';
import { DiscordEventComponent } from './shared/dicsord-event/discord-event.component';
import { RandomCheckboxComponent } from './shared/random-checkbox/random-checkbox.component';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { BadgeIconComponent } from './shared/badge-icon/badge-icon.component';
import { TableOfContentComponent } from './shared/table-of-content/table-of-content.component';
import { TableOfContentListRecursiveComponent } from './shared/table-of-content/table-of-content-list-recursive/table-of-content-list-recursive.component';
import { BotResourcesComponent } from './pages/bot/bot-resources/bot-resources.component';
import { MemberProfileComponent } from './pages/members/member-profile/member-profile.component';
import { WikiLinkComponent } from './pages/wiki-link/wiki-link.component';
import { RaceTracksComponent } from './pages/rally/race-tracks/race-tracks.component';
import { RaceTrackDetailsComponent } from './pages/rally/race-tracks/race-track-details/race-track-details.component';
import { ImagePreviewComponent } from './shared/image-preview/image-preview.component';
import { RaceTypesComponent } from './pages/admin/games/starcitizen/race-types/race-types.component';
import {RaceTracksAdminComponent} from './pages/admin/games/starcitizen/race-tracks/race-tracks-admin.component';
import { ManufacturerComponent } from './pages/admin/games/starcitizen/manufacturer/manufacturer.component';
import { VehicleTypesComponent } from './pages/admin/games/starcitizen/vehicle-types/vehicle-types.component';
import { VehicleClassComponent } from './pages/admin/games/starcitizen/vehicle-class/vehicle-class.component';
import { VehiclesComponent } from './pages/admin/games/starcitizen/vehicles/vehicles.component';
import { LapTimesComponent } from './pages/rally/race-tracks/lap-times/lap-times.component';
import { LapTimeEditComponent } from './pages/rally/race-tracks/lap-times/lap-time-edit/lap-time-edit.component';
import { RaceTrackTypeInfoTileComponent } from './shared/race-track-type-info-tile/race-track-type-info-tile.component';
import { RaceTypeVehicleTypeComponent } from './pages/admin/games/starcitizen/race-types/race-type-vehicle-type/race-type-vehicle-type.component';
import { RaceTypeVehicleClassComponent } from './pages/admin/games/starcitizen/race-types/race-type-vehicle-class/race-type-vehicle-class.component';
import { EditRaceTypeComponent } from './pages/admin/games/starcitizen/race-types/edit-race-type/edit-race-type.component';
import { CareersComponent } from './pages/admin/games/starcitizen/career/careers.component';
import { RaceTypeCareersComponent } from './pages/admin/games/starcitizen/race-types/race-type-careers/race-type-careers.component';
import { RallyComponent } from './pages/rally/rally.component';
import { RaceTrackInfoTileComponent } from './shared/race-track-info-tile/race-track-info-tile.component';
import { SeriesComponent } from './pages/rally/series/series.component';
import { SeriesDetailsComponent } from './pages/rally/series/series-details/series-details.component';
import { RallySeriesInfoTileComponent } from './shared/rally-series-info-tile/rally-series-info-tile.component';
import { RallySeriesTrackInfoComponent } from './shared/rally-series-track-info/rally-series-track-info.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { CookieConsentComponent } from './shared/cookie-consent/cookie-consent.component';
import { PointsOfInterestComponent } from './pages/points-of-interest/points-of-interest.component';
import { PointsOfInterestDetailsComponent } from './pages/points-of-interest/points-of-interest-details/points-of-interest-details.component';
import {PaginatorModule} from 'primeng/paginator';
import { PoiTileComponent } from './shared/poi-tile/poi-tile.component';
import { ImageViewerComponent } from './shared/dialoge/image-viewer/image-viewer.component';
import { FleetComponent } from './pages/fleet/fleet.component';
import { CaptainListComponent } from './shared/dialoge/captain-list/captain-list.component';
import { ScContractsComponent } from './pages/sc-contracts/sc-contracts.component';
import { ScAvailableCommoditiesComponent } from './pages/admin/games/starcitizen/sc-available-commodities/sc-available-commodities.component';
import { ScContractCommoditiesComponent } from './pages/sc-contracts/sc-contract-commodities/sc-contract-commodities.component';
import { ScContractEditComponent } from './pages/sc-contracts/sc-contract-edit/sc-contract-edit.component';
import { ScCommodityInventoryComponent } from './pages/sc-commodity-inventory/sc-commodity-inventory.component';
import { ScCommodityInventoryEditComponent } from './pages/sc-commodity-inventory/sc-commodity-inventory-edit/sc-commodity-inventory-edit.component';
import { ScCommodityInventoryLogComponent } from './pages/sc-commodity-inventory/sc-commodity-inventory-log/sc-commodity-inventory-log.component';
import { ScContractRewardsComponent } from './pages/sc-contracts/sc-contract-rewards/sc-contract-rewards.component';
import { ScCommodityInventoryTypeComponent } from './pages/sc-commodity-inventory/sc-commodity-inventory-type/sc-commodity-inventory-type.component';
import { ScCommoditySetsComponent } from './pages/admin/games/starcitizen/sc-available-commodities/sc-commodity-sets/sc-commodity-sets.component';
import { ScContractTilesComponent } from './pages/sc-contracts/sc-contract-tiles/sc-contract-tiles.component';
import { ContractTileComponent } from './shared/contract-tile/contract-tile.component';
import { ScWikiLinkComponent } from './shared/sc-wiki-link/sc-wiki-link.component';
import { AuecInfoComponent } from './pages/sc-commodity-inventory/auec-info/auec-info.component';
import {BaseChartDirective, provideCharts, withDefaultRegisterables} from 'ng2-charts';
import {LastActiveTabDirective} from './directives/last-active-tab.directive';
import {
    ScCommodityCategoryComponent
} from './pages/admin/games/starcitizen/sc-available-commodities/sc-commodity-category/sc-commodity-category.component';
import {CopyValueDirective} from './directives/copy-value.directive';
import {VehicleDetailsComponent} from './shared/vehicle-details/vehicle-details.component';
import {VehicleComponentDetailsComponent} from './shared/vehicle-component-details/vehicle-component-details.component';
import {MiningLoadoutCalcComponent} from './pages/mining-loadout-calc/mining-loadout-calc.component';
import {CalculatorDataComponent} from './pages/admin/games/starcitizen/calculator-data/calculator-data.component';
import {RippleModule} from 'primeng/ripple';
import {ItemLocationComponent} from './pages/admin/games/starcitizen/calculator-data/item-location/item-location.component';
import {DisplayStatComponent} from './pages/mining-loadout-calc/display-stat/display-stat.component';
import {StatRowComponent} from './pages/mining-loadout-calc/stat-row/stat-row.component';

// registerLocaleData(localeDe, 'de', localeDeExtra);
registerLocaleData(localeDe);

const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: location.origin
    },
    palette: {
        popup: {
            background: '#000'
        },
        button: {
            background: '#f1d600'
        }
    },
    theme: 'edgeless',
    type: 'opt-out'
};

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
    hostname:  AppSettings.mqtt_host,
    port: AppSettings.mqtt_port,
    protocol: 'wss',
    path: '/mqtt'
};



@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
        MainComponent,
        MenuComponent,
        ExternalComponent,
        SafePipe,
        LoginComponent,
        FormInputsComponent,
        CustomFocusDirective,
        LinkDirective,
        ContentContainerComponent,
        SearchComponent,
        CheckLoginComponent,
        IconTileComponent,
        ConfirmComponent,
        EditUserComponent,
        TeamTileComponent,
        FileUploadComponent,
        FbTableComponent,
        MatAnimatedIconComponent,
        CommentComponent,
        AddFormDialogComponent,
        AddSelectListComponent,
        RegisterComponent,
        PasswordStrengthBarComponent,
        UserComponent,
        LogoutComponent,
        AdminComponent,
        TokenLoginComponent,
        DiscordAuthPointComponent,
        ScVersionComponent,
        StarcitizenComponent,
        GamesComponent,
        EditScVersionComponent,
        ScCommodityComponent,
        ScCommodityEditComponent,
        FbFormsTableComponent,
        SimpleEditComponent,
        FormInputsRowComponent,
        BotEventsComponent,
        BotEventComponent,
        BotEventMemberComponent,
        BotEventDepositTilesComponent,
        EventOreDepositTileComponent,
        BotEventCommodityDeliveryComponent,
        BotEventSummaryComponent,
        PrivacyPoliceComponent,
        BotComponent,
        BotAboutComponent,
        ImpressumComponent,
        PageFooterComponent,
        NewsComponent,
        DiscordMessageComponent,
        MembersComponent,
        MembersPointsComponent,
        MembersPointsDetailsComponent,
        MembersPointsEditComponent,
        BotEventPointsComponent,
        EventCargoDepositTileComponent,
        IfaComponent,
        IfaBadgesComponent,
        IfaMemberComponent,
        EditIfaMemberComponent,
        IfaMemberBadgesComponent,
        GuidesComponent,
        GuideEditComponent,
        NoHtmlPipe,
        ScContentComponent,
        MediaComponent,
        FileUpload2Component,
        MediaEditComponent,
        TableItemPickerComponent,
        UpcomingEventsComponent,
        DiscordEventComponent,
        RandomCheckboxComponent,
        BadgeIconComponent,
        TableOfContentComponent,
        TableOfContentListRecursiveComponent,
        BotResourcesComponent,
        MemberProfileComponent,
        WikiLinkComponent,
        RaceTracksComponent,
        RaceTrackDetailsComponent,
        ImagePreviewComponent,
        RaceTypesComponent,
        RaceTracksAdminComponent,
        ManufacturerComponent,
        VehicleTypesComponent,
        VehicleClassComponent,
        VehiclesComponent,
        LapTimesComponent,
        LapTimeEditComponent,
        RaceTrackTypeInfoTileComponent,
        RaceTypeVehicleTypeComponent,
        RaceTypeVehicleClassComponent,
        EditRaceTypeComponent,
        CareersComponent,
        RaceTypeCareersComponent,
        RallyComponent,
        RaceTrackInfoTileComponent,
        SeriesComponent,
        SeriesDetailsComponent,
        RallySeriesInfoTileComponent,
        RallySeriesTrackInfoComponent,
        ProfileComponent,
        CookieConsentComponent,
        PointsOfInterestComponent,
        PointsOfInterestDetailsComponent,
        PoiTileComponent,
        ImageViewerComponent,
        FleetComponent,
        CaptainListComponent,
        ScContractsComponent,
        ScAvailableCommoditiesComponent,
        ScContractCommoditiesComponent,
        ScContractEditComponent,
        ScCommodityInventoryComponent,
        ScCommodityInventoryEditComponent,
        ScCommodityInventoryLogComponent,
        ScContractRewardsComponent,
        ScCommodityInventoryTypeComponent,
        ScCommoditySetsComponent,
        ScCommodityCategoryComponent,
        ScContractTilesComponent,
        ContractTileComponent,
        ScWikiLinkComponent,
        AuecInfoComponent,
        VehicleDetailsComponent,
        MiningLoadoutCalcComponent,
        CalculatorDataComponent,
        ItemLocationComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {passThruUnknownUrl: true, dataEncapsulation: false}),
        MatNativeDateModule,
        ReactiveFormsModule,
        MaterialModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader, useClass: TranslationLoader,
                deps: [HttpClient]
            }
        }),
        NgxChartsModule,
        NgxMaterialTimepickerModule,
        ToastModule,
        TableModule,
        TagModule,
        DropdownModule,
        ProgressBarModule,
        InputTextModule,
        MultiSelectModule,
        SliderModule,
        ButtonModule,
        CalendarModule,
        MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
        NgxMatDatetimePickerModule,
        NgxMatNativeDateModule,
        MarkdownModule.forRoot(),
        Highlight,
        EditorModule,
        NgxPageScrollCoreModule,
        TooltipModule,
        NgxEditorModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
        PaginatorModule,
        BaseChartDirective, LastActiveTabDirective, CopyValueDirective, VehicleComponentDetailsComponent, RippleModule, DisplayStatComponent, StatRowComponent
    ], providers: [
        provideCharts(withDefaultRegisterables()),
        AuthService,
        SearchService,
        // provider used to create fake backend
        fakeBackendProvider,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RefreshTokenInterceptor,
            multi: true
        },
        NotificationService,
        MessageService,
        {
            provide: HIGHLIGHT_OPTIONS,
            useValue: {
                fullLibraryLoader: () => import('highlight.js')
            }
        },
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule { }
