import {FbFormObject} from '../class/fb_form_object';

export class Vehicle extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'fy_id', label: 'Id', visible: true, noclick: false, disable_ripple: true},
            {id: 'vehicle_name', label: 'Name', visible: true, noclick: false, disable_ripple: true},
            {id: 'fy_slug', label: 'Slug', visible: true, noclick: false, disable_ripple: true},
            {id: 'fy_manufacturer_code', label: 'Manufacturer Code', visible: true, noclick: false, disable_ripple: true},
            {id: 'fy_erkulIdentifier', label: 'erkulIdentifier', visible: true, noclick: false, disable_ripple: true},
            {id: 'fy_focus', label: 'Focus', visible: true, noclick: false, disable_ripple: true},
            {id: 'fy_maxSpeedAcceleration', label: 'max Speed Acceleration', visible: true, noclick: false, disable_ripple: true},
            {id: 'fy_maxSpeedDecceleration', label: 'max Speed Decceleration', visible: true, noclick: false, disable_ripple: true},
            {id: 'fy_scmSpeed', label: 'SCM Speed', visible: true, noclick: false, disable_ripple: true},
            {id: 'fy_scmSpeedAcceleration', label: 'SCM Speed Acceleration', visible: true, noclick: false, disable_ripple: true},
            {id: 'fy_scmSpeedDecceleration', label: 'SCM Speed Decceleration', visible: true, noclick: false, disable_ripple: true},
            {id: 'fy_mass', label: 'Mass', visible: true, noclick: false, disable_ripple: true},
            {id: 'fy_beam', label: 'Beam', visible: true, noclick: false, disable_ripple: true},
            {id: 'fy_height', label: 'Height', visible: true, noclick: false, disable_ripple: true},
            {id: 'fy_length', label: 'Length', visible: true, noclick: false, disable_ripple: true},
            {id: 'fy_size', label: 'Size', visible: true, noclick: false, disable_ripple: true},
            {id: 'fy_isGroundVehicle', label: 'is ground Vehicle', visible: true, noclick: false, disable_ripple: true},


            {id: 'manufacturer', label: 'Manufacturer', visible: true, noclick: false, disable_ripple: true},
            {id: 'vehicle_type', label: 'Type', visible: true, noclick: false, disable_ripple: true},
            {id: 'vehicle_class', label: 'Class', visible: true, noclick: false, disable_ripple: true},
            {id: 'career_key', label: 'Career', visible: true, noclick: false, disable_ripple: true},
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Fleetyards',
                class: 'card card-1 padding single-column',
                field_class: '',
                fields: [
                    {
                        type: 'autocomplete',
                        name: 'fy_id',
                        options: this.select_boxes['fy_id_select']
                    },
                    {
                        type: 'text',
                        name: 'vehicle_name',
                        disabled: true,
                        rules: [
                            {rule: 'required'}
                        ],
                        on_other_field_changed: (self, changed_field, setFieldValueCallback, refreshFormField) => {
                            if (changed_field.name === 'fy_id') {
                                if (changed_field.model.obj.fy_id !== '') {
                                    const record = this.stores['fy_models'].getRecordById(changed_field.model.obj.fy_id);
                                    setFieldValueCallback(self, record.data.name);
                                } else {
                                    setFieldValueCallback(self, '');
                                }
                            }
                        },
                    },
                    {
                        type: 'text',
                        name: 'fy_slug',
                        disabled: true,
                        on_other_field_changed: (self, changed_field, setFieldValueCallback, refreshFormField) => {
                            if (changed_field.name === 'fy_id') {
                                if (changed_field.model.obj.fy_id !== '') {
                                    const record = this.stores['fy_models'].getRecordById(changed_field.model.obj.fy_id);
                                    setFieldValueCallback(self, record.data.slug);
                                } else {
                                    setFieldValueCallback(self, '');
                                }
                            }
                        },
                    },
                    {
                        type: 'text',
                        name: 'fy_manufacturer_code',
                        disabled: true,
                        on_other_field_changed: (self, changed_field, setFieldValueCallback, refreshFormField) => {
                            if (changed_field.name === 'fy_id') {
                                if (changed_field.model.obj.fy_id !== '') {
                                    const record = this.stores['fy_models'].getRecordById(changed_field.model.obj.fy_id);
                                    setFieldValueCallback(self, record.data.manufacturer.code);
                                } else {
                                    setFieldValueCallback(self, '');
                                }
                            }
                        },
                    },
                    {
                        type: 'text',
                        name: 'fy_erkulIdentifier',
                        disabled: true,
                        on_other_field_changed: (self, changed_field, setFieldValueCallback, refreshFormField) => {
                            if (changed_field.name === 'fy_id') {
                                if (changed_field.model.obj.fy_id !== '') {
                                    const record = this.stores['fy_models'].getRecordById(changed_field.model.obj.fy_id);
                                    setFieldValueCallback(self, record.data.erkulIdentifier);
                                } else {
                                    setFieldValueCallback(self, '');
                                }
                            }
                        },
                    },
                    {
                        type: 'text',
                        name: 'fy_focus',
                        disabled: true,
                        on_other_field_changed: (self, changed_field, setFieldValueCallback, refreshFormField) => {
                            if (changed_field.name === 'fy_id') {
                                if (changed_field.model.obj.fy_id !== '') {
                                    const record = this.stores['fy_models'].getRecordById(changed_field.model.obj.fy_id);
                                    setFieldValueCallback(self, record.data.focus);
                                } else {
                                    setFieldValueCallback(self, '');
                                }
                            }
                        },
                    },
                    {
                        type: 'number',
                        name: 'fy_maxSpeedAcceleration',
                        disabled: true,
                        on_other_field_changed: (self, changed_field, setFieldValueCallback, refreshFormField) => {
                            if (changed_field.name === 'fy_id') {
                                if (changed_field.model.obj.fy_id !== '') {
                                    const record = this.stores['fy_models'].getRecordById(changed_field.model.obj.fy_id);
                                    setFieldValueCallback(self, record.data.speeds.maxSpeedAcceleration);
                                } else {
                                    setFieldValueCallback(self, '');
                                }
                            }
                        },
                    },
                    {
                        type: 'number',
                        name: 'fy_maxSpeedDecceleration',
                        disabled: true,
                        on_other_field_changed: (self, changed_field, setFieldValueCallback, refreshFormField) => {
                            if (changed_field.name === 'fy_id') {
                                if (changed_field.model.obj.fy_id !== '') {
                                    const record = this.stores['fy_models'].getRecordById(changed_field.model.obj.fy_id);
                                    setFieldValueCallback(self, record.data.speeds.maxSpeedDecceleration);
                                } else {
                                    setFieldValueCallback(self, '');
                                }
                            }
                        },
                    },
                    {
                        type: 'number',
                        name: 'fy_scmSpeed',
                        disabled: true,
                        on_other_field_changed: (self, changed_field, setFieldValueCallback, refreshFormField) => {
                            if (changed_field.name === 'fy_id') {
                                if (changed_field.model.obj.fy_id !== '') {
                                    const record = this.stores['fy_models'].getRecordById(changed_field.model.obj.fy_id);
                                    setFieldValueCallback(self, record.data.speeds.scmSpeed);
                                } else {
                                    setFieldValueCallback(self, '');
                                }
                            }
                        },
                    },
                    {
                        type: 'number',
                        name: 'fy_scmSpeedAcceleration',
                        disabled: true,
                        on_other_field_changed: (self, changed_field, setFieldValueCallback, refreshFormField) => {
                            if (changed_field.name === 'fy_id') {
                                if (changed_field.model.obj.fy_id !== '') {
                                    const record = this.stores['fy_models'].getRecordById(changed_field.model.obj.fy_id);
                                    setFieldValueCallback(self, record.data.speeds.scmSpeedAcceleration);
                                } else {
                                    setFieldValueCallback(self, '');
                                }
                            }
                        },
                    },
                    {
                        type: 'number',
                        name: 'fy_scmSpeedDecceleration',
                        disabled: true,
                        on_other_field_changed: (self, changed_field, setFieldValueCallback, refreshFormField) => {
                            if (changed_field.name === 'fy_id') {
                                if (changed_field.model.obj.fy_id !== '') {
                                    const record = this.stores['fy_models'].getRecordById(changed_field.model.obj.fy_id);
                                    setFieldValueCallback(self, record.data.speeds.scmSpeedDecceleration);
                                } else {
                                    setFieldValueCallback(self, '');
                                }
                            }
                        },
                    },
                    {
                        type: 'number',
                        name: 'fy_mass',
                        disabled: true,
                        on_other_field_changed: (self, changed_field, setFieldValueCallback, refreshFormField) => {
                            if (changed_field.name === 'fy_id') {
                                if (changed_field.model.obj.fy_id !== '') {
                                    const record = this.stores['fy_models'].getRecordById(changed_field.model.obj.fy_id);
                                    setFieldValueCallback(self, record.data.metrics.mass);
                                } else {
                                    setFieldValueCallback(self, '');
                                }
                            }
                        },
                    },
                    {
                        type: 'number',
                        name: 'fy_beam',
                        disabled: true,
                        on_other_field_changed: (self, changed_field, setFieldValueCallback, refreshFormField) => {
                            if (changed_field.name === 'fy_id') {
                                if (changed_field.model.obj.fy_id !== '') {
                                    const record = this.stores['fy_models'].getRecordById(changed_field.model.obj.fy_id);
                                    setFieldValueCallback(self, record.data.metrics.beam);
                                } else {
                                    setFieldValueCallback(self, '');
                                }
                            }
                        },
                    },
                    {
                        type: 'number',
                        name: 'fy_height',
                        disabled: true,
                        on_other_field_changed: (self, changed_field, setFieldValueCallback, refreshFormField) => {
                            if (changed_field.name === 'fy_id') {
                                if (changed_field.model.obj.fy_id !== '') {
                                    const record = this.stores['fy_models'].getRecordById(changed_field.model.obj.fy_id);
                                    setFieldValueCallback(self, record.data.metrics.height);
                                } else {
                                    setFieldValueCallback(self, '');
                                }
                            }
                        },
                    },
                    {
                        type: 'number',
                        name: 'fy_length',
                        disabled: true,
                        on_other_field_changed: (self, changed_field, setFieldValueCallback, refreshFormField) => {
                            if (changed_field.name === 'fy_id') {
                                if (changed_field.model.obj.fy_id !== '') {
                                    const record = this.stores['fy_models'].getRecordById(changed_field.model.obj.fy_id);
                                    setFieldValueCallback(self, record.data.metrics.length);
                                } else {
                                    setFieldValueCallback(self, '');
                                }
                            }
                        },
                    },
                    {
                        type: 'text',
                        name: 'fy_size',
                        disabled: true,
                        on_other_field_changed: (self, changed_field, setFieldValueCallback, refreshFormField) => {
                            if (changed_field.name === 'fy_id') {
                                if (changed_field.model.obj.fy_id !== '') {
                                    const record = this.stores['fy_models'].getRecordById(changed_field.model.obj.fy_id);
                                    setFieldValueCallback(self, record.data.metrics.size);
                                } else {
                                    setFieldValueCallback(self, '');
                                }
                            }
                        },
                    },
                    {
                        type: 'checkbox',
                        name: 'fy_isGroundVehicle',
                        disabled: true,
                        on_other_field_changed: (self, changed_field, setFieldValueCallback, refreshFormField) => {
                            if (changed_field.name === 'fy_id') {
                                if (changed_field.model.obj.fy_id !== '') {
                                    const record = this.stores['fy_models'].getRecordById(changed_field.model.obj.fy_id);
                                    setFieldValueCallback(self, record.data.isGroundVehicle);
                                } else {
                                    setFieldValueCallback(self, '');
                                }
                            }
                        },
                    }
                ]
            },
            {
                caption: 'Custom Data',
                class: 'card card-1 padding single-column',
                field_class: '',
                fields: [
                    {
                        type: 'select',
                        name: 'manufacturer',
                        options: this.select_boxes['manufacturer_select'],
                        rules: [
                            {rule: 'required'}
                        ],
                        on_other_field_changed: (self, changed_field, setFieldValueCallback, refreshFormField) => {
                            if (changed_field.name === 'fy_id') {
                                if (changed_field.model.obj.fy_id !== '') {
                                    const record = this.stores['fy_models'].getRecordById(changed_field.model.obj.fy_id);
                                    const manufacurer = this.stores['manufacturer'].getRecordByKeyArray([
                                        {key: 'man_short', value: record.data.manufacturer.code}
                                    ]);
                                    if (manufacurer) {
                                        setFieldValueCallback(self, manufacurer.data.man_id);
                                    } else {
                                        setFieldValueCallback(self, '');
                                    }
                                } else {
                                    setFieldValueCallback(self, '');
                                }
                            }
                        },
                    },
                    {
                        type: 'select',
                        name: 'vehicle_type',
                        options: this.select_boxes['vehicle_type_select'],
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'select',
                        name: 'vehicle_class',
                        options: this.select_boxes['vehicle_class_select'],
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'select',
                        name: 'career_key',
                        options: this.select_boxes['careers_select'],
                        rules: [
                            {rule: 'required'}
                        ]
                    }
                ]
            }
        ];
        super.setEditFields();
    }

    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.stores['vehicle'] = this.storeService.getStore('vehicle');

            this.main_store = this.stores['vehicle'];

            this.stores['manufacturer'] = this.storeService.getStore('manufacturer');
            this.stores['manufacturer'] = this.storeService.getStore('manufacturer');
            this.stores['vehicle_types'] = this.storeService.getStore('vehicle_types');
            this.stores['vehicle_class'] = this.storeService.getStore('vehicle_class');
            this.stores['careers'] = this.storeService.getStore('careers');
            this.stores['fy_models'] = this.storeService.getStore('fy_models');

            const selectFilter = [];
            selectFilter.push('first=0');
            selectFilter.push('rows=1000');


            const stores = [];
            stores.push(this.stores['vehicle'].loadStore(this.filter));
            stores.push(this.stores['manufacturer'].loadStore('?' + selectFilter.join('&')));
            stores.push(this.stores['vehicle_types'].loadStore('?' + selectFilter.join('&')));
            stores.push(this.stores['vehicle_class'].loadStore('?' + selectFilter.join('&')));
            stores.push(this.stores['careers'].loadStore('?' + selectFilter.join('&')));
            stores.push(this.stores['fy_models'].loadStore('?' + selectFilter.join('&')));

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
                vehicle_name: '',
                vehicle_type: '',
                vehicle_class: '',
                manufacturer: 0,
                career_key: '',
                fy_id: '',
                fy_slug: '',
                fy_manufacturer_code: '',
                fy_erkulIdentifier: '',
                fy_focus: '',
                fy_maxSpeedAcceleration: 0,
                fy_maxSpeedDecceleration: 0,
                fy_scmSpeed: 0,
                fy_scmSpeedAcceleration: 0,
                fy_scmSpeedDecceleration: 0,
                fy_mass: 0,
                fy_beam: 0,
                fy_height: 0,
                fy_length: 0,
                fy_size: '',
                fy_isGroundVehicle: false,
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        this.parseCareersSelectBox();
        this.parseManufacturerSelectBox();
        this.parseVehicleClassSelectBox();
        this.parseVehicleTypeSelectBox();
        this.parseFyModelSelectBox();

        // call super
        super.setSelectboxes();
    }

    public parseCareersSelectBox(): any[] {
        const options = [];
        const records = this.stores['careers'].getRecords();
        for (let i = 0; i < records.length; i++) {
            const rec = records[i];
            options.push(
                {
                    name: rec.data.career_name,
                    value: rec.data.career_key
                }
            );
        }
        this.select_boxes['careers_select'] = options;
        return this.select_boxes['careers_select'];
    }

    public parseManufacturerSelectBox() {
        const options = [];
        const records = this.stores['manufacturer'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.man_id),
                name: records[i].data.man_short + ' - ' + records[i].data.man_name
            });
        }
        this.select_boxes['manufacturer_select'] = options;
        return this.select_boxes['manufacturer_select'];
    }

    public parseVehicleTypeSelectBox() {
        const options = [];
        const records = this.stores['vehicle_types'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.vehicle_type),
                name: records[i].data.type_name
            });
        }
        this.select_boxes['vehicle_type_select'] = options;
        return this.select_boxes['vehicle_type_select'];
    }

    public parseVehicleClassSelectBox() {
        const options = [];
        const records = this.stores['vehicle_class'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.vehicle_class),
                name: records[i].data.class_name
            });
        }
        this.select_boxes['vehicle_class_select'] = options;
        return this.select_boxes['vehicle_class_select'];
    }

    public parseFyModelSelectBox() {
        const options = [];
        const records = this.stores['fy_models'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.id),
                name: records[i].data.name
            });
        }
        this.select_boxes['fy_id_select'] = options;
        return this.select_boxes['fy_id_select'];
    }

}
