import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SearchService} from '../../services/search.service';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

    @Input() is_flex: boolean;
    @Input() context: string;

    get search_input(): string {
        return this._search_input;
    }

    @Input() set search_input(value: string) {
        this._search_input = value;
    }

    @Output() on_change: EventEmitter<any> = new EventEmitter();
    private _search_input = '';

    constructor(private searchService: SearchService) {
    }

    ngOnInit() {
    }

    public onKeyUp(event: any) {
        if (event.key === 'Enter') {
            this.searchService.setSearchValue(this.context, this.search_input);
        }
    }

    public doSearch() {
        this.searchService.setSearchValue(this.context, this.search_input);
    }

}
