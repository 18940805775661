import {Component, Input} from '@angular/core';
import {MarkdownService} from 'ngx-markdown';
import * as https from 'https';
import {CookieService} from '../../services/cookie.service';

@Component({
  selector: 'app-discord-message',
  templateUrl: './discord-message.component.html',
  styleUrls: ['./discord-message.component.css']
})
export class DiscordMessageComponent {
  get message(): any {
    return this._message;
  }

  @Input()
  set message(value: any) {
    if (value.content) {
      value.content_md = this.parseCodeBlock(value.content);
      value.content_md = this.parseContent(value.content_md);
    }

    if (value.description) {
      value.description_md = this.parseContent(value.description);
      value.description_md = this.parseCodeBlock(value.description_md);
    }

    if (value.embeds && value.embeds.length > 0) {
      for (let i = 0; i < value.embeds.length; i++) {
        const embed = value.embeds[i];

        if (embed.description) {
          embed.description_md = this.parseContent(embed.description);
          embed.description_md = this.parseCodeBlock(embed.description_md);
        }

        if (embed.color) {
          embed.color = embed.color.toString(16).padStart(6, '0');
        } else {
          embed.color = '0e0e10';
        }
        if (embed.fields && embed.fields.length > 0) {
          for (let f = 0; f < embed.fields.length; f++) {
            const field = embed.fields[f];
            this.parseFieldAttr(field, 'name');
            this.parseFieldAttr(field, 'value');
          }
        }
      }
    }
    this._message = value;
    console.log(this._message);
  }

  discordMarkdown = `
    **Bold Text**

    *Italic Text*

    [Link](https://example.com)

    \`Inline Code\`

    \`\`\`
    Code Block
    \`\`\`
  `;

  private _message: any;

  constructor(private markdownService: MarkdownService,
              private cookieService: CookieService) {
  }

  private parseContent(content: string) {
    let tmpContent = '';
    for (const line of content.split('\n')) {
      // console.log('-->', line.trim());
      tmpContent += this.discordMarkdownToHtml(line.trim()) + '\n\n';
    }
    return tmpContent;
  }

  private parseFieldAttr(field: any, key: string) {
    // field[key] = this.markdownLinkToHtml(field, key);
    let len = field[key].length;
    field['type_' + key] = 'text';
    this.formatCode(field, key);
    if (field[key].length !== len) {
      field['type_' + key] = 'code';
      return;
    }

    len = field.value.length;
    this.formatBlock(field, key);
    if (field[key].length !== len) {
      field['type_' + key] = 'blocks';
      return;
    }

    len = field[key].length;
    this.formatDate(field, key);
    if (field[key].length !== len) {
      field['type_' + key] = 'date';
      return;
    }

    len = field[key].length;
    this.formatTime(field, key);
    if (field[key].length !== len) {
      field['type_' + key] = 'time';
      return;
    }
  }

  private discordMarkdownToHtml(value: string): string {

    // Bold Italics
    value = value.replace(/\*\*\*([^_]+)\*\*\*/g, '<em>$1</em>');

    // Bold
    value = value.replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>');

    // Underline
    value = value.replace(/__([^_]+)__/g, '<u>$1</u>');

    // Italics
    value = value.replace(/_([^_]+)_/g, '<em>$1</em>');
    value = value.replace(/\*([^_]+)\*/g, '<em>$1</em>');

    // Strikethrough
    value = value.replace(/~~([^~]+)~~/g, '<strike>$1</strike>');

    // Inline code
    value = value.replace(/`([^`]+)`/g, '<code>$1</code>');

    // Links [Name](URL)
    value = value.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2">$1</a>');

    // User mentions
    value = value.replace(/<@!?(\d+)>/g, '<span class="mention">@User-$1</span>');

    // Channel mentions
    value = value.replace(/<#(\d+)>/g, '<span class="channel">#Channel-$1</span>');

    // countdown
    // value = value.replace(/<:countdown:(\d+)>/g, '<span class="channel">$1</span>');

    // Role mentions (assuming roles have names and not just numbers)
    // value = value.replace(/<@&(\d+)>/g, '<span class="role">@Role-$1</span>');
    // value = value.replace(/<@&([0-9]+)>/g, '<span class="role">@Role-$1</span>');
    // ToDo: replace with role name
    value = value.replace(/<@&([0-9]+)>/g, '');

    // Headline
    value = value.replace(/>###(.+?)</g, '><h3>$1</h3><');
    value = value.replace(/>##(.+?)</g, '><h2>$1</h2><');
    value = value.replace(/>#(.+?)</g, '><h1>$1</h1><');
    value = value.replace(/###(.+?)$/g, '<h3>$1</h3>');
    value = value.replace(/##(.+?)$/g, '<h2>$1</h2>');
    value = value.replace(/#(.+?)$/g, '<h1>$1</h1>');

    return value;
  }

  private parseCodeBlock(value: string) {
    // Multi-line code
    value = value.replace(/```([^`]+)```/g, '<code class="dc-code">\n$1\n</code>');
    return value;
  }

  private markdownLinkToHtml(field: any, key: string): string {
    return field[key].replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2">$1</a>');
  }

  private formatCode(field: any, key: string) {
    const regex = /```(.*?)\n([\s\S]*?)```/;
    const match = field[key].match(regex);
    if (match) {
      field[key] = match[2];
    }
  }

  private formatBlock(field: any, key: string) {
    field[key] = field[key].replace(/`([^`]+)`/g, '<span>$1</span>');
  }

  private formatDate(field: any, key: string) {
    const newValue = field[key].replace(/<t:([^`]+):D>/g, '$1');
    if (newValue !== field[key]) {
      field[key] = new Date(newValue * 1000);
    }
  }

  private formatTime(field: any, key: string) {
    const newValue = field[key].replace(/<t:([^`]+):t>/g, '$1');
    if (newValue !== field[key]) {
      field[key] = new Date(newValue * 1000);
    }
  }

  public snowFlakeToTime(snowflake: string) {
    // const snowflake = "1124530049329139772";

    // Convert snowflake to timestamp
    // Discord epoch (2015-01-01T00:00:00.000Z) in milliseconds
    const discordEpoch = 1420070400000;
    const timestamp = Number(snowflake) / 4194304 + discordEpoch;

    // Convert timestamp to Date object
    return new Date(timestamp);
  }

  public isAllowedDomain(url: string): boolean {
    for (const item of this.cookieService.getProviderList()) {
      if (url.startsWith(item.domain)) {
        return true;
      }
    }
    console.log('not supported', url);
    return false;
  }

  public getConsent(url: string) {
    for (const item of this.cookieService.getProviderList()) {
      if (url.startsWith(item.domain)) {
         return this.cookieService.getConsent(item.provider_key);
      }
    }
    return false;
  }

  public getConsentProviderName(url: string) {
    for (const item of this.cookieService.getProviderList()) {
      if (url.startsWith(item.domain)) {
        return item.name;
      }
    }
    return 'no domain found';
  }

  public getNoCookieUrl(url: string) {
    // if (!url.startsWith('https://img.youtube.com')) {
    //   url = url.replace('youtube', 'youtube-nocookie');
    //   url = url.replace('youtu.be', 'youtube-nocookie');
    //   url = url.replace('watch?v=', 'embed/');
    // }
    return url;
  }
}
