import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {
    Router
} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import * as jsonBig from 'json-bigint';
import {AppSettings} from '../config/AppSettings';
import {NotificationService} from './notification.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    // Refresh Token Subject tracks the current token, or is null if no token is currently
    // available (e.g. refresh pending).
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );

    private updateRunning = false;

    constructor(private auth: AuthService,
                private router: Router,
                private notificationService: NotificationService) {
        console.warn('create RefreshTokenInterceptor');
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.auth.getLoginToken();
        if (token && !request.url.includes('https://starcitizen.tools/api.php')) {
            request = request.clone({
                setHeaders: {
                    'JWT': token
                },
                withCredentials: true
            });
        }

        /*
        if (!request.headers.has('Content-Type')) {
            console.log('request', request);
            request = request.clone({
                setHeaders: {
                    'content-type': 'application/json'
                }
            });
        }
        */
        /*
        request = request.clone({
            headers: request.headers.set('Accept', 'application/json')
        });
        */

        return request.responseType === 'json'
            ? this.handleJsonResponses(request, next)
            : next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                /*
                if (event instanceof HttpResponse) {
                    console.log('event--->>>', event);
                }
                 */
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                return this.handleError(error);
            }));
    }
    private handleError(error: HttpErrorResponse) {
        if (error.status === 401) {
            if (error.error.error === 'Expired token') {
                // this.presentToast('Login failed');
                this.auth.logout().then(() => {
                    this.router.navigateByUrl('/login');
                });
            } else {
                // no right but token is fine
                this.auth.logout().then(() => {
                    this.router.navigateByUrl('/main');
                });
            }
        }
        return throwError(error);
    }

    private handleJsonResponses(
        httpRequest: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next
            .handle(httpRequest.clone({ responseType: 'text' }))
            .pipe(map(event => this.parseResponse(event)),
                catchError((error: HttpErrorResponse) => {
                    return this.handleError(error);
                }));
    }

    private parseResponse(event: HttpEvent<any>): HttpEvent<any> {
        if (event instanceof HttpResponse) {
            const latest_version = event.headers.get('X-App-Version');
            if (latest_version && AppSettings.version !== latest_version && this.updateRunning === false) {
                this.updateRunning = true;
                this.notificationService.info('New Version', 'Loading new version for you', 5000);
                setTimeout(() => {
                    window.location.reload();
                    this.updateRunning = false;
                }, 5000);
            }
        }
        return event instanceof HttpResponse
            ? event.clone({ body: jsonBig.parse(event.body) })
            : event;
    }
}
