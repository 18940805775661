<input
        type="file"
        #file
        style="display: none"
        (change)="onFilesAdded()"
        multiple
/>
<div class="container" fxLayout="column" fxLayoutAlign="space-evenly stretch">
  <p>Datei(en) hochladen</p>
  <div>
    <button
            [disabled]="uploading || (files && files.size > 0)"
            mat-raised-button
            color="accent"
            class="add-files-btn"
            (click)="addFiles()"
    >
      Datei hinzufügen
    </button>
  </div>

  <!-- This is the content of the dialog, containing a list of the files to upload -->
    <mat-list>
      <mat-list-item *ngFor="let file of files">
        <h4 mat-line>{{file.name}}</h4>
        <mat-progress-bar
                *ngIf="progress && progress[file.name] && progress[file.name].progress"
                mode="determinate"
                [value]="progress[file.name].progress | async"
        ></mat-progress-bar>
      </mat-list-item>
    </mat-list>

  <!-- This are the actions of the dialog, containing the primary and the cancel button-->
  <div class="actions">
    <button mat-raised-button class="button-save" (click)="startUpload()"
            [disabled]="uploading || (files && files.size === 0)">Datei speichern</button>
  </div>
</div>
