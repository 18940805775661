import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {AppSettings} from '../config/AppSettings';
import {map} from 'rxjs/operators';
import {Store} from '../class/store';
import {StoreService} from './store.service';
import {FbUtils} from '../class/fb-utils';
import {Router} from '@angular/router';
import {NotificationService} from './notification.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private login_state: number; // 0:logged out; 1:login; 2:logged_in
    public login_state_changed: BehaviorSubject<any>;
    public login_token = '';


    private store: Store;
    private subs: Array<Subscription> = [];
    public userAuth$: Observable<any>;
    private user: any;
    private is_admin = false;
    private user_loaded = false;
    public user_loaded_changed: BehaviorSubject<any>;


    constructor(private http: HttpClient,
                private notificationService: NotificationService,
                private storeService: StoreService,
                private router: Router) {
        this.login_state = this.getSavedLoginState();
        this.login_state_changed = new BehaviorSubject(this.login_state);
        this.user_loaded_changed = new BehaviorSubject(this.user_loaded);

        // console.log('# login_state is:', this.login_state);

        if (this.login_state === 2) {
            // console.log('# login_state is 2');
            const user = this.getUser();
            // console.log('# user is', user);
            if (user) {
                // console.log('# setUserObjectOnly');
                this.setUserObjectOnly(user);
            }
        }

        //
        // this.forms = this.storeService.getStore('auth_user');
        // this.userAuth$ = this.forms.getStore();
        /*
        this.subs.push(this.userAuth$.subscribe((records: any) => {
            if (records && records && records.length > 0) {
                this.setUser(records[0].data);
            }
        }));
        */
        // this.forms.loadAllRecords();
    }

    public getUserAuthStoreObservable(): Observable<any> {
        return this.userAuth$;
    }

    public getUserInfo(): any {
        return this.user;
    }

    public getUserId(): any {
        if (this.user && this.user.details && this.user.details.user_id) {
            return this.user.details.user_id;
        }
        return null;
    }

    private change_login() {
    }

    public login(login_obj: any) {
        this.setLoginState(1); // mark for login in
        // ToDo create fallback for error
        this.sendLogin(login_obj).subscribe((response) => {
                if (this.login_state === 2) {
                    // console.log('getUSerDetails from login');
                    this.getUSerDetails().subscribe((user_response) => {
                    });
                }
            },
            (error) => {
                this.notificationService.warn('Login',
                    'Login failed.', {timeOut: 5000});
                this.setLoginState(0);
            });
    }

    public loginWithToken(token: any) {
        this.setLoginState(1); // mark for login in
        // ToDo create fallback for error
        this.sendLoginWithToken(token).subscribe((response) => {
                if (this.login_state === 2) {
                    this.getUSerDetails().subscribe((user_response) => {
                    });
                }
            },
            (error) => {
                this.notificationService.warn('Login',
                    'Login failed.', {timeOut: 5000});
                this.setLoginState(0);
            });
    }

    public checkLogin(): Observable<any> {
        const obs = this.http.get(FbUtils.formatUrlForApi(AppSettings.getRealAPILink() + 'auth/check')).pipe(
            map((res: any) => {
                if (res && res.success) {
                    // this.setLoginToken(user.token);
                    this.setLoginState(2);
                    // localStorage.setItem('currentUser', JSON.stringify(user));
                } else {
                    if (res.message === 'no users found') {
                        this.setLoginState(-1);
                    } else {
                        this.setLoginState(0);
                    }
                }
                return res;
            })
        );
        obs.subscribe((response) => {
                // console.log('getUSerDetails from checkLogin');
                this.getUSerDetails().subscribe((user_response) => {
                });
            },
            (error) => {
                /*
                this.notificationService.alert('Login',
                    'Login failed.', {timeOut: 5000});
                this.setLoginState(0);
                */
                // ToDo: remove on real api
                // this.setLoginState(2);
            }
        );
        return obs;
    }

    private sendLogin(login_obj: any): Observable<any> {
        return this.http.post(FbUtils.formatUrlForApi(AppSettings.getRealAPILink() + 'auth/login'), login_obj).pipe(
            map((user: any) => {
                if (user && user.jwt) {
                    this.setLoginToken(user.jwt);
                    this.setLoginState(2);
                    // localStorage.setItem('currentUser', JSON.stringify(user));
                } else {
                    this.setLoginState(0);
                    // this.setLoginState(0);
                }
                return user;
            })
        );
    }

    private sendLoginWithToken(login_token: string): Observable<any> {
        return this.http.post(FbUtils.formatUrlForApi(AppSettings.getRealAPILink() + 'auth/login/token'), {login_token: login_token}).pipe(
            map((user: any) => {
                if (user && user.jwt) {
                    this.setLoginToken(user.jwt);
                    this.setLoginState(2);
                    // localStorage.setItem('currentUser', JSON.stringify(user));
                } else {
                    this.setLoginState(0);
                    // this.setLoginState(0);
                }
                return user;
            })
        );
    }

    public logout(): Promise<any> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.removeUser();
                this.setLoginToken('');
                this.setLoginState(0); // mark for logged out
                resolve(this.login_state);
            }, 200);
        });
    }

    public getLoginState() {
        return this.login_state;
    }

    private setLoginState(state: number) {
        this.login_state = state;
        this.login_state_changed.next(this.login_state);
        this.saveLoginState();
    }

    public getUser() {
        const user = localStorage.getItem('self');
        if (user) {
            return JSON.parse(user);
        }
        return false;
    }

    private setUser(users: any) {
        this.setUserObjectOnly(users[0]);
        this.user_loaded_changed.next(this.user_loaded);
    }

    private setUserObjectOnly(user: any) {
        this.is_admin = user.is_admin;
        this.user = user;
        localStorage.setItem('self', JSON.stringify(this.user));
        this.user_loaded = true;
    }

    private removeUser() {
        localStorage.setItem('self', JSON.stringify({}));
        this.is_admin = false;
        this.user = {};
        this.user_loaded = false;
        this.user_loaded_changed.next(this.user_loaded);
    }

    private getSavedLoginState(): number {
        return Number(localStorage.getItem('login_state'));
    }

    private saveLoginState() {
        localStorage.setItem('login_state', this.login_state.toString());
    }

    private sendRegister(register_obj: any): Observable<any> {
        return this.http.post(FbUtils.formatUrlForApi(AppSettings.getRealAPILink() + 'register'), register_obj).pipe(
            map((user: any) => {
                if (user && user.token) {
                    this.setLoginToken(user.token);
                    this.setLoginState(2);
                    // localStorage.setItem('currentUser', JSON.stringify(user));
                }
                return user;
            })
        );
    }

    public getUSerDetails(): Observable<any> {
        return this.http.get(FbUtils.formatUrlForApi(AppSettings.getRealAPILink() + 'auth/user')).pipe(
            map((user: any) => {
                if (user) {
                    this.setUser(user);
                }
                return user;
            })
        );
    }

    public register(register_obj: any) {
        // AppSettings.getRealAPILink()
        // ToDo create fallback for error
        this.sendRegister(register_obj).subscribe((response) => {
            this.sendLogin({
                username: register_obj.username,
                password: register_obj.password
            }).subscribe((ogin_resp) => {
            });
        });
    }

    private setLoginToken(token: string) {
        this.login_token = token;
        localStorage.setItem('token', token);
    }

    public getLoginToken(): string {
        this.login_token = localStorage.getItem('token');
        return this.login_token;
    }

    public hasRole(guild_id: string, role: string): boolean {
        if (this.user && this.user.guilds && this.user.guilds[guild_id]) {
            if (this.user.guilds[guild_id].roles && this.user.guilds[guild_id].roles.length > 0) {
                for (let i = 0; i < this.user.guilds[guild_id].roles.length; i++) {
                    const u_role = this.user.guilds[guild_id].roles[i];
                    if (u_role === role) {
                        return true;
                    }
                }
            }
        }
        return  false;
    }

    public isAdmin(): boolean {
        return this.is_admin;
    }

    private getDiscordLoginLink(): Observable<any> {
        return this.http.get(FbUtils.formatUrlForApi(AppSettings.getRealAPILink() + 'dc/login-link')).pipe(
            map((linkObj: any) => {
                return linkObj;
            })
        );
    }

    private getLocalJwtForDiscord(state: string, code: string): Observable<any> {
        return this.http.post(FbUtils.formatUrlForApi(AppSettings.getRealAPILink() + 'dc/local-token'), {
            state: state,
            code: code
        }).pipe(
            map((jwtObj: any) => {
                return jwtObj;
            })
        );
    }

    public startOneTimeTokenLogin(state: string, code: string) {
        this.getLocalJwtForDiscord(state, code).subscribe((jwtObj) => {
            const jwt = jwtObj.rows[0];
            if (jwt) {
                this.setLoginToken(jwt);
                this.setLoginState(2);
                this.getUSerDetails().subscribe((user_response) => {
                    this.router.navigateByUrl('/main');
                });
                // localStorage.setItem('currentUser', JSON.stringify(user));
            } else {
                this.setLoginState(0);
                // this.setLoginState(0);
            }
        });
    }

    public authWithDiscord() {
        this.setLoginState(1);
        this.getDiscordLoginLink().subscribe((linkObj) => {
            const loginLink  = linkObj.rows[0];
            location.href = loginLink;
        });
    }
}
