<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading"
                       [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div class="padding">
        <app-form-inputs *ngIf="fields && fields.length"
                         #tpl_form
                         [field_groups]="fields"
                         [edit_allowed]="true"
                         [loading]="false"
                         (on_submit)="saveStore($event)"
                         [button_class]="'button-save'">
        </app-form-inputs>
        <ng-container *ngIf="mode === 'edit'">
            <button mat-fab class="button-delete button-bottom" (click)="deleteUser()">
                <mat-icon aria-label="löschen">delete</mat-icon>
            </button>
        </ng-container>
    </div>
</ng-template>