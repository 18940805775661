<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading" [loading_text]="loading_text"
                           [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div class="padding" *ngIf="is_admin && edit || mode === 'add'">
        <app-form-inputs *ngIf="fields && fields.length && upload_tpl"
                         #tpl_form
                         [field_groups]="fields"
                         [edit_allowed]="true"
                         [loading]="false"
                         (on_submit)="saveStore($event)"
                         [button_class]="'button-save'">
        </app-form-inputs>

        <div class="card card-1 padding table-wrapper" *ngIf="storeAssignedObj">
            <app-fb-forms-table [storeObj]="storeAssignedObj"
                                [storeExtObj]="{track_id: id}"
                                [edit_allowed]="true"
                                [stateKey]="'track-assigned-types'"
                                [show_add_in_view]="true"
                                [show_delete]="true"
                                [title]="'Assigned Types'"
            >
            </app-fb-forms-table>
        </div>

    </div>
    <div class="padding" *ngIf="!edit && record">
        <app-race-track-info-tile title="{{record.data.track_name}}" icon="fas fa-calendar"
                                  [style]="{'background-image': 'url(' + media_path +  'media/' + record.data.file_id + '/show)'}"
                                  [info_data]="record.data"
                                  [customClass]="'bg-card'"></app-race-track-info-tile>

    </div>
    <div class="padding" *ngIf="edit === false && record">
        <main class="tiles padding" *ngIf="race_types">
            <ng-container *ngFor="let raceType of race_types">
                <a [routerLink]="'/rally/lap-times/' + record.data.track_id + '/' + raceType.data.type_id" class="link-clear">
                    <app-race-track-type-info-tile title="{{raceType.data.type_name}}" icon="fas fa-calendar"
                                   [style]="{'background-image': 'url(' + bgUrl + raceType.data.file_id  + '/show)'}"
                                   [info_data]="raceType.data"
                                   [customClass]="'bg-card'"></app-race-track-type-info-tile>
                </a>
            </ng-container>
        </main>
    </div>
    <ng-container *ngIf="is_admin && edit === false">
        <button mat-mini-fab class="button-edit" (click)="enterEditMode()"
                style="position: absolute; left: 8px; top: 8px;"
                matTooltip="bearbeiten">
            <mat-icon aria-label="bearbeiten">edit</mat-icon>
        </button>
    </ng-container>
</ng-template>

<ng-template #upload_tpl let-element="element" let-column="column" let-edit="edit">

    <div *ngIf="element.model && element.model.obj.file_id > 0">
        <app-image-preview [file_id]="element.model.obj.file_id"></app-image-preview>
        <button mat-mini-fab class="button-delete"
                style="margin-left: 16px;"
                (click)="deleteFile(element.model.obj.file_id)"
                matTooltip="löschen"
                type="button">
            <mat-icon aria-label="löschen">delete</mat-icon>
        </button>
    </div>

    <ng-container *ngIf="element.model && element.model.obj.track_id > 0 &&
                         (element.model.obj.file_id === 0 || element.model.obj.file_id === null)
                         && edit">
        <app-file-upload2 [file_type]="'race_track'"
                          (on_success)="onFileUpload($event, 'race_track', element)"
                          [url]="url_file_store"
                          [show_save_button]="true"
                          [parent_object_id]="element.model.obj.track_id"
                          [max_count]="1"
                          [accept]="'.jpeg,.jpg,.png,image/*'"
                          (init)="onFileuploadInit($event)"
        ></app-file-upload2>
    </ng-container>
</ng-template>
