import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CookieService {

    public open_consent_changed: BehaviorSubject<boolean>;
    private valid_domains = [
        // starcitizen.tools
        {domain: 'https://starcitizen.tools', provider_key: 'sc_wiki', name: 'starcitizen.tools'},
        {domain: 'https://www.starcitizen.tools', provider_key: 'sc_wiki', name: 'starcitizen.tools'},

        // youtube
        {domain: 'https://youtube.com', provider_key: 'youtube', name: 'youtube'},
        {domain: 'https://www.youtube.com', provider_key: 'youtube', name: 'youtube'},
        {domain: 'https://youtu.be', provider_key: 'youtube', name: 'youtube'},
        {domain: 'https://www.youtu.be', provider_key: 'youtube', name: 'youtube'},
        {domain: 'https://img.youtube.com', provider_key: 'youtube', name: 'youtube'},

        // discord
        {domain: 'https://cdn.discordapp.com', provider_key: 'discord', name: 'discord'},
        {domain: 'https://www.cdn.discordapp.com', provider_key: 'discord', name: 'discord'},

        // redit
        {domain: 'https://share.redd.it', provider_key: 'reddit', name: 'reddit'},

        // rsi
        {domain: 'https://robertsspaceindustries.com', provider_key: 'rsi', name: 'robertsspaceindustries'},
        {domain: 'https://www.robertsspaceindustries.com', provider_key: 'rsi', name: 'robertsspaceindustries'},
        {domain: 'https://cdn.robertsspaceindustries.com', provider_key: 'rsi', name: 'robertsspaceindustries'},

        // fleetyards
        {domain: 'https://cdn.fleetyards.net', provider_key: 'fleetyards', name: 'fleetyards.net'},
    ];

    constructor(private http: HttpClient) {
        this.open_consent_changed = new BehaviorSubject(this.hasConsent('consent_sc_wiki'));
    }

    setConsent(domain: string, consent: boolean) {
        if (consent) {
            localStorage.setItem(`consent_${domain}`, 'true');
        } else {
            localStorage.setItem(`consent_${domain}`, 'false');
        }
    }

    getConsent(domain: string) {
        const consent = localStorage.getItem(`consent_${domain}`);
        return consent === 'true';
    }

    hasConsent(domain: string) {
        const consent = localStorage.getItem(`consent_${domain}`);
        return consent === 'true' || consent === 'false';
    }

    openConsent() {
        this.open_consent_changed.next(true);
    }

    closeConsent() {
        this.open_consent_changed.next(false);
    }

    public getProvider(provider_key: any) {
        for (const item of this.getProviderList()) {
            if (item.provider_key === provider_key) {
                return item.name;
            }
        }
    }

    public getProviderName(provider_key: any) {
        return this.getProvider(provider_key);
    }

    public getProviderList() {
        return this.valid_domains;
    }
}
