<div>
    <app-content-container [content_template]="page_content"
                           [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div class="padding">
        <div class="card card-1 padding table-wrapper" *ngIf="storeObj">
            <app-fb-forms-table [storeObj]="storeObj"
                                [storeTemplates]="{upload_tpl: upload_tpl, man_short_select_tpl: man_short_select_tpl}"
                                [edit_allowed]="true"
                                (row_click)="rowSelected($event)"
                                [stateKey]="'manufacturer'"
                                [show_add_in_view]="true"
                                [show_delete]="true"
                                [title]="'Manufacturer'"
            >
            </app-fb-forms-table>
        </div>
    </div>
</ng-template>

<ng-template #man_short_select_tpl let-element="element">
    <div>{{element.value}}</div>
    <div>{{element.longName}}</div>
</ng-template>