<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading" [loading_text]="loading_text"
                           [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div class="padding">
        <div class="guide-side-menu" *ngIf="mode !== 'add'">
            <div class="guide-toc card padding">
                <div>
                    By: <b [innerHTML]="record.data.series_lead_name"></b>
                </div>
                <ol>
                    <li (click)="scrollTo('details')">Details</li>
                    <li (click)="scrollTo('tracks')">Tracks</li>
                    <li (click)="scrollTo('signups')">Signups</li>
                    <li (click)="scrollTo('ranking')">Ranking</li>
                </ol>
                <ng-container *ngIf="is_admin && edit === false && mode !== 'add'">
                    <button mat-mini-fab class="button-edit" (click)="enterEditMode()"
                            matTooltip="edit">
                        <mat-icon aria-label="edit">edit</mat-icon>
                    </button>
                </ng-container>
            </div>
        </div>
        <div class="guide-content-container">
            <div *ngIf="record">
                <ng-container *ngIf="(is_admin && !edit) && (mode !== 'add')">
                    <app-rally-series-info-tile title="{{record.data.series_name}}" icon="fas fa-calendar"
                                                [style]="{'background-image': 'url(' + media_path +  'media/' + record.data.file_id + '/show)'}"
                                                [info_data]="record.data"
                                                [customClass]="'bg-card'"></app-rally-series-info-tile>
                </ng-container>
                <div *ngIf="is_admin && edit || mode === 'add'">
                    <app-form-inputs *ngIf="fields && fields.length && upload_tpl"
                                     #tpl_form
                                     [field_groups]="fields"
                                     [edit_allowed]="true"
                                     [loading]="false"
                                     (on_submit)="saveStore($event)"
                                     [button_class]="'button-save'">
                    </app-form-inputs>

                </div>

                <h3 class="group-caption">Details</h3>
                <section id="details" style="text-align: left;">
                    <div class="card card-1 padding">
                        <ul>
                            <li>Name: <b class="detail-value">{{record.data.series_name}}</b></li>
                            <li>Description: <b class="detail-value">{{record.data.description}}</b></li>
                            <li>Leader: <b class="detail-value">{{record.data.series_lead_name}}</b></li>
                            <li>Entry fee: <b class="detail-value">{{record.data.entry_fee}}</b></li>
                            <li>Start: <b class="detail-value">{{record.data.series_start | date:'medium'}}</b></li>
                            <li>End: <b class="detail-value">{{record.data.series_end | date:'medium'}}</b></li>
                            <li>Participation limit: <b class="detail-value">{{record.data.signup_limit}}</b></li>
                            <li>Signup start: <b class="detail-value">{{record.data.signup_start | date:'medium'}}</b></li>
                            <li>Signup end: <b class="detail-value">{{record.data.signup_end | date:'medium'}}</b></li>
                        </ul>
                    </div>
                </section>

                <h3 class="group-caption">Tracks</h3>
                <section id="tracks">
                    <div class="card card-1 padding table-wrapper"
                         *ngIf="storeAssignedObj && is_admin && edit && mode !== 'add'">
                        <ng-container *ngIf="is_admin && edit && mode !== 'add'">
                            <app-fb-forms-table [storeObj]="storeAssignedObj"
                                                [storeExtObj]="{series_id: id}"
                                                [edit_allowed]="true"
                                                [stateKey]="'series_tracks'"
                                                [show_add_in_view]="true"
                                                [show_delete]="true"
                                                [title]="'Assigned Tracks'"
                            >
                            </app-fb-forms-table>
                        </ng-container>
                    </div>
                    <ng-container *ngIf="!edit && mode !== 'add'">
                        <main class="tiles padding">
                            <ng-container *ngFor="let track of tracks">
                                <a routerLink="/rally/lap-times/{{track.data.track_id}}/{{track.data.race_type_id}}/{{track.data.series_id}}/{{track.data.assign_id}}" class="link-clear">
                                    <app-rally-series-track-info title="{{track.data.track_name}}" icon="fas fa-calendar"
                                                                   [style]="{'background-image': 'url(' + media_path +  'media/' + track.data.track_file_id + '/show)'}"
                                                                   [info_data]="track.data"
                                                                   [customClass]="'bg-card'"></app-rally-series-track-info>
                                </a>
                            </ng-container>
                        </main>
                    </ng-container>
                </section>

                <h3 class="group-caption">Signups</h3>
                <section id="signups">
                    <div class="card card-1 padding table-wrapper"
                         *ngIf="storeSignupsObj">
                        <ng-container>
                            <app-fb-forms-table [storeObj]="storeSignupsObj"
                                                [header_tpl]="header_tpl"
                                                [storeExtObj]="{series_id: id}"
                                                [edit_allowed]="is_admin"
                                                [stateKey]="'series_signups'"
                                                [show_add_in_view]="false"
                                                [show_delete]="is_admin"
                                                [title]="'Signups'"
                            >
                            </app-fb-forms-table>
                        </ng-container>
                    </div>
                </section>

                <h3 class="group-caption">Ranking</h3>
                <section id="ranking">
                    <div class="card card-1 padding table-wrapper">
                        <ng-container>
                            <app-fb-forms-table [storeObj]="formSeriesPoints"
                                                [storeExtObj]="{series_id: id}"
                                                [edit_allowed]="false"
                                                [stateKey]="'series_ranking'"
                                                [show_add_in_view]="false"
                                                [show_delete]="false"
                                                [title]="'Ranking'"
                            >
                            </app-fb-forms-table>
                        </ng-container>
                    </div>
                </section>

                <!--    </div>-->
                <!--    <div class="padding" *ngIf="edit === false && record">-->
                <!--        <main class="tiles padding" *ngIf="race_types">-->
                <!--            <ng-container *ngFor="let raceType of race_types">-->
                <!--                <a [routerLink]="'/rally/lap-times/' + record.data.track_id + '/' + raceType.data.type_id" class="link-clear">-->
                <!--                    <app-race-track-type-info-tile title="{{raceType.data.type_name}}" icon="fas fa-calendar"-->
                <!--                                                   [style]="{'background-image': 'url(' + bgUrl + raceType.data.file_id  + '/show)'}"-->
                <!--                                                   [info_data]="raceType.data"-->
                <!--                                                   [customClass]="'bg-card'"></app-race-track-type-info-tile>-->
                <!--                </a>-->
                <!--            </ng-container>-->
                <!--        </main>-->
            </div>
        </div>
        <div style="clear: both;"></div>
    </div>
</ng-template>

<ng-template #header_tpl>

    <ng-container *ngIf="isSignupAllowed && !isSignedUp">
        <button mat-raised-button class="button-add"
                style="margin-left: 16px; min-width: 128px;"
                (click)="signup()"
                matTooltip="signup"
                type="button">
            Signup
        </button>
    </ng-container>
    <ng-container *ngIf="!isSignupAllowed && !isSignedUp">
        Open from {{record.data.signup_start | date:'medium'}} until {{record.data.signup_end | date:'medium'}}
    </ng-container>
    <ng-container *ngIf="isSignedUp">
        already signed up
    </ng-container>
</ng-template>

<ng-template #upload_tpl let-element="element" let-column="column" let-edit="edit">

    <div *ngIf="element.model && element.model.obj.file_id > 0">
        <app-image-preview [file_id]="element.model.obj.file_id"></app-image-preview>
        <button mat-mini-fab class="button-delete"
                style="margin-left: 16px;"
                (click)="deleteFile(element.model.obj.file_id)"
                matTooltip="löschen"
                type="button">
            <mat-icon aria-label="löschen">delete</mat-icon>
        </button>
    </div>

    <ng-container *ngIf="element.model && element.model.obj.series_id > 0 &&
                         (element.model.obj.file_id === 0 || element.model.obj.file_id === null)
                         && edit">
        <app-file-upload2 [file_type]="'rally_series'"
                          (on_success)="onFileUpload($event, 'rally_series', element)"
                          [url]="url_file_store"
                          [show_save_button]="true"
                          [parent_object_id]="element.model.obj.series_id"
                          [max_count]="1"
                          [accept]="'.jpeg,.jpg,.png,image/*'"
                          (init)="onFileuploadInit($event)"
        ></app-file-upload2>
    </ng-container>
</ng-template>
