import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {StoreService} from '../../../services/store.service';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ScbMembersPointsSummary} from '../../../forms/scb-members-points-summary';
import {AppSettings} from '../../../config/AppSettings';

@Component({
  selector: 'app-members-points',
  templateUrl: './members-points.component.html',
  styleUrls: ['./members-points.component.css']
})
export class MembersPointsComponent implements OnInit, OnDestroy, AfterViewInit {


  @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
    this._select_caption_tpl = value;
    // this.setColumns();
    this.cdr.markForCheck();
  }


  public ScbMembersPointsSummary: ScbMembersPointsSummary;
  private subs: Array<Subscription> = [];

  public loading_text = '';
  public is_loading = false;
  public rows: any[];


  private _select_caption_tpl: ElementRef;

  public column_definition: any[];
  public is_admin: boolean;


  constructor(private storeService: StoreService,
              private authService: AuthService,
              private router: Router,
              private cdr: ChangeDetectorRef,
              public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin)
        || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_event_admin);

    this.ScbMembersPointsSummary = new ScbMembersPointsSummary(this.storeService, this.authService, 'edit');
    this.cdr.detectChanges();
  }

  public rowSelected($event: any) {
    if (this.is_admin) {
      const route = 'members-points/' + $event.row.user_id;
      if ($event.in_new_tab) {
        const url = this.router.createUrlTree([route]);
        const serializedUrl = this.router.serializeUrl(url);

        // Insert `#` between the domain and route
        const modifiedUrl = '#/' + serializedUrl;
        window.open(modifiedUrl, '_blank');
      } else {
        this.router.navigateByUrl(route);
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
