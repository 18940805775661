<article class="tile-card-contract card card-1">
    <div class="menuCardContainer">
        <div class="inner-card" *ngIf="is_loading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="inner-card" *ngIf="!is_loading">
            <div class="ware-house-card-header" *ngIf="contract_data.is_private === 0">
            </div>
            <div class="ware-house-private-card-header" *ngIf="contract_data.is_private === 1">
            </div>
            <div class="cardContainer" *ngIf="contract_data">
                <div style="float: left">
                    <b>{{title}}
                        <mat-chip>
                        <!--
                            // Todo: branch or type icon?
                            <img matChipAvatar src="" alt=""/>
                        -->
                            {{contract_data.type}}
                        </mat-chip>
                    </b>
                </div>
                <div style="float: right">
                    <mat-chip-option>
                        <!--
                            // Todo: branch or type icon?
                            <img matChipAvatar src="" alt=""/>
                        -->
                        deadline {{contract_data.deadline_ts | date:'mediumDate':'': 'de'}}
                    </mat-chip-option>
                </div>
                <div *ngIf="contract_data.is_private === 0">
                    <table style="width: 100%; border-spacing: 0;">
                        <ng-container *ngIf="commodities">
                            <tr>
                                <th></th>
                                <th>Item</th>
                                <th>Stückpreis</th>
                                <th>Menge</th>
                                <th>Summe</th>
                            </tr>
                            <tr *ngFor="let row of commodities">
                                <td class="td-spacing align-left">
                                    Pos {{row.pos}}:
                                </td>
                                <td class="td-spacing align-left">
                                    <app-sc-wiki-link [linkObj]="{link: row.sc_wiki_url, name: row.commodity_name}"></app-sc-wiki-link>
                                </td>
                                <td class="td-spacing align-right">
                                    {{row.c_value | number : '1.0-1' : 'de-DE'}}
                                </td>
                                <td class="td-spacing align-right">
                                    {{row.min_required_value | number : '1.0-1' : 'de-DE'}}
                                </td>
                                <td class="td-spacing align-right">
                                    {{row.item_sum | number : '1.0-1' : 'de-DE'}}
                                </td>
                            </tr>
                            <tr>
                                <td class="td-spacing" colspan="5">
                                    <mat-divider></mat-divider>
                                </td>
                            </tr>
                            <tr>
                                <td class="td-spacing" colspan="4">
                                    <b>total</b>
                                </td>
                                <td class="td-spacing align-right">{{commodities_total | number : '1.0-1' : 'de-DE'}}</td>
                            </tr>
                        </ng-container>

                        <ng-container *ngIf="contract_data.description">
                            <tr>
                                <td colspan="6" style="padding-top: 32px; padding-bottom: 32px;">
                                    <pre>{{contract_data.description}}</pre>
                                </td>
                            </tr>
                        </ng-container>

                    </table>

                </div>
                <div *ngIf="contract_data.is_private === 1">
                    <table style="width: 100%; border-spacing: 0;">

                        <ng-container *ngIf="contract_data.description">
                            <tr>
                                <td colspan="5" style="padding-top: 32px; padding-bottom: 32px;">
                                    <pre>{{contract_data.description}}</pre>
                                </td>
                            </tr>
                        </ng-container>

                        <ng-container *ngIf="commodities">
                            <tr>
                                <td colspan="5">
                                    <b>wanted</b>
                                </td>
                            </tr>
                            <tr>
                                <th></th>
                                <th>Item</th>
                                <th>Stückpreis</th>
                                <th>Menge</th>
                                <th>Summe</th>
                            </tr>
                            <tr *ngFor="let row of commodities">
                                <td class="td-spacing align-left">
                                    Pos {{row.pos}}:
                                </td>
                                <td class="td-spacing align-left">
                                    <app-sc-wiki-link [linkObj]="{link: row.sc_wiki_url, name: row.commodity_name}"></app-sc-wiki-link>
                                </td>
                                <td class="td-spacing align-right">
                                    {{row.c_value | number : '1.0-1' : 'de-DE'}}
                                </td>
                                <td class="td-spacing align-right">
                                    {{row.min_required_value | number : '1.0-1' : 'de-DE'}}
                                </td>
                                <td class="td-spacing align-right">
                                    {{row.item_sum | number : '1.0-1' : 'de-DE'}}
                                </td>
                            </tr>
                            <tr>
                                <td class="td-spacing" colspan="5">
                                    <mat-divider></mat-divider>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="rewards">
                            <ng-container *ngIf="rewards.length === 1 && rewards[0].c_type === 'currency'">
                                <tr>
                                    <td class="td-spacing" colspan="4">
                                        <b>total</b>
                                    </td>
                                    <td class="td-spacing align-right">{{rewards[0].reward_value}}</td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="rewards[0].c_type !== 'currency' || rewards.length > 1">
                                <tr>
                                    <td colspan="5">
                                        <b>reward</b>
                                    </td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th>Item</th>
                                    <th>Stückpreis</th>
                                    <th>Menge</th>
                                    <th>Summe</th>
                                </tr>


                                <tr *ngFor="let row of rewards">
                                    <td class="td-spacing align-left">
                                        Pos {{row.pos}}:
                                    </td>
                                    <td class="td-spacing align-left">
                                        <app-sc-wiki-link [linkObj]="{link: row.sc_wiki_url, name: row.commodity_name}"></app-sc-wiki-link>
                                    </td>
                                    <td class="td-spacing align-right">
                                        {{row.c_value | number : '1.0-1' : 'de-DE'}}
                                    </td>
                                    <td class="td-spacing align-right">
                                        {{row.reward_value | number : '1.0-1' : 'de-DE'}}
                                    </td>
                                    <td class="td-spacing align-right">
                                        {{row.item_sum | number : '1.0-1' : 'de-DE'}}
                                    </td>
                                </tr>
<!--                            <tr *ngFor="let row of rewards">-->
<!--                                <td class="td-spacing align-right">{{row.commodity_name}}</td>-->
<!--                                <td class="td-spacing align-right">{{row.reward_value}}</td>-->
<!--                            </tr>-->
                            </ng-container>
                        </ng-container>
                    </table>
                </div>
            </div>
            <div class="card-footer">
                <div>
                    <table style="width: 100%; border-spacing: 0;">
                        <tr>
                            <td class="bottom-row" colspan="3">Client: <span class="player-name">{{clientName}}</span></td>
                            <td class="bottom-row" colspan="3">Contractor: <span class="player-name">{{contractorName}}</span></td>
                        </tr>
                    </table>
                </div>
                <div>
                    <ng-container [ngSwitch]="contract_data.status">
                        <div *ngSwitchCase="'draft'" class="action-button-container">
                            <button mat-raised-button color="primary"
                                    *ngIf="String(user_id) === String(contract_data.client)"
                                    (click)="editContract()"
                            >bearbeiten</button>
                        </div>
                        <div *ngSwitchCase="'published'" class="action-button-container">
                            <button mat-raised-button color="primary"
                                    (click)="setStatus('taken')"
                                    *ngIf="!deadlineReached()"
                            >annehmen</button>
                            <button mat-raised-button color="primary"
                                    *ngIf="String(user_id) === String(contract_data.client)"
                                    (click)="editContract()"
                            >bearbeiten</button>
                        </div>
                        <div *ngSwitchCase="'taken'" class="action-button-container">
                            <button mat-raised-button color="warn"
                                    *ngIf="String(user_id) === String(contract_data.client)
                                     || String(user_id) === String(contract_data.contractor)"
                                    (click)="setStatus('published')"
                            >abbrechen</button>
                            <button mat-raised-button color="primary"
                                    *ngIf="String(user_id) === String(contract_data.client) && !deadlineReached()"
                                    (click)="setStatus('turned-in')"
                            >abschließen</button>
                            <button mat-raised-button color="primary"
                                    *ngIf="String(user_id) === String(contract_data.client)"
                                    (click)="editContract()"
                            >bearbeiten</button>
                        </div>
                        <div *ngSwitchCase="'turned-in'" class="action-button-container">
                            <button mat-raised-button color="warn"
                                    *ngIf="String(user_id) === String(contract_data.client)
                                     || String(user_id) === String(contract_data.contractor)"
                                    (click)="setStatus('published')"
                            >abbrechen</button>
                            <button mat-raised-button color="primary"
                                    *ngIf="String(user_id) === String(contract_data.contractor)"
                                    (click)="setStatus('confirmed')"
                            >bestätigen</button>
                        </div>
                        <div *ngSwitchCase="'confirmed'">
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</article>
