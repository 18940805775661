import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {StoreService} from '../../../services/store.service';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../../services/notification.service';
import {MatDialog} from '@angular/material/dialog';
import {ScbEvent} from 'src/app/forms/scb-event';
import {IMqttMessage, MqttService} from 'ngx-mqtt';
import {Store} from '../../../class/store';

@Component({
    selector: 'app-bot-events',
    templateUrl: './bot-events.component.html',
    styleUrls: ['./bot-events.component.css']
})
export class BotEventsComponent implements OnInit, OnDestroy, AfterViewInit {


    @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
        this._select_caption_tpl = value;
        // this.setColumns();
        this.cdr.markForCheck();
    }


    public ScbEvent: ScbEvent;
    private subs: Array<Subscription> = [];

    public loading_text = '';
    public is_loading = true;
    public rows: any[];


    private _select_caption_tpl: ElementRef;

    public column_definition: any[];
    public is_admin: boolean;

    private channelStore: Store;

    constructor(private storeService: StoreService,
                private authService: AuthService,
                private router: Router,
                private cdr: ChangeDetectorRef,
                private notificationService: NotificationService,
                public dialog: MatDialog,
                private _mqttService: MqttService) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.is_admin = this.authService.isAdmin();

        this.ScbEvent = new ScbEvent(this.storeService, this.authService, 'edit');
        this.is_loading = false;
        this.cdr.detectChanges();
        this.channelStore = this.storeService.getStore('dc_channel');
        this.channelStore.loadStore('').then((records) => {
            this.subs.push(this._mqttService.observe('bot/event').subscribe((message: IMqttMessage) => {
                // refresh table on notifications for user guilds events
                const info = JSON.parse(message.payload.toString());
                const rec = this.channelStore.getRecordById(info.channel_id);
                if (this.ScbEvent.getStore() && rec) {
                    this.is_loading = true;
                    this.ScbEvent.getStore().reloadStore().then(() => {
                        this.is_loading = false;
                    });
                }
            }));
        });
        this.loadRoleStore();
    }

    private loadRoleStore() {
        const roleStore = this.storeService.createNoneGlobalStore('sc_ores',
            'me/bot/mod_role/server', 'role_id');

        this.is_admin = false;

        roleStore.loadStore('').then((records: any) => {
            if (records && records.length > 0) {
                // user is mod in at least one server
                this.is_admin = true;
            }
        });
    }

    public rowSelected($event: any) {
        const route = 'bot/events/' + $event.row.event_id;
        if ($event.in_new_tab) {
            const url = this.router.createUrlTree([route]);
            const serializedUrl = this.router.serializeUrl(url);

            // Insert `#` between the domain and route
            const modifiedUrl = '#/' + serializedUrl;
            window.open(modifiedUrl, '_blank');
        } else {
            this.router.navigateByUrl(route);
        }
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }

    public addClicked() {
        this.router.navigateByUrl('/bot/events/add');
    }
}
