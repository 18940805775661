import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {StoreService} from '../../services/store.service';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {AppSettings} from '../../config/AppSettings';
import {ScContractForm} from '../../forms/scContractForm';
import {MatChipSelectionChange} from '@angular/material/chips';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-sc-contracts',
    templateUrl: './sc-contracts.component.html',
    styleUrls: ['./sc-contracts.component.css']
})
export class ScContractsComponent implements OnInit, OnDestroy, AfterViewInit {
    public selectFilterAvailable: any[] | undefined;
    public selectFilterAccepted: any[] | undefined;
    public selectFilterConfirmed: any[] | undefined;
    public selectFilterCreated: any[] | undefined;

    @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
        this._select_caption_tpl = value;
        // this.setColumns();
        this.cdr.markForCheck();
    }


    public storeObject: ScContractForm;
    private subs: Array<Subscription> = [];

    public loading_text = '';
    public is_loading = false;
    public rows: any[];


    private _select_caption_tpl: ElementRef;

    public column_definition: any[];
    public is_admin: boolean;
    public is_ifa = false;
    public add_allowed = false;
    public selectedContractList = 'available';
    public storeExtObj = {};

    public contractLists = [
        {value: 'available', label: 'verfügbare Aufträge'},
        {value: 'accepted', label: 'angenommene Aufträge'},
        {value: 'confirmed', label: 'abgeschlossene Aufträge'},
        {value: 'created', label: 'erteilte Aufträge'}
    ];

    constructor(private storeService: StoreService,
                private authService: AuthService,
                private router: Router,
                private cdr: ChangeDetectorRef,
                public dialog: MatDialog,
                public http: HttpClient) {

        const selectedContractList = localStorage.getItem('selectedContractList');
        if (selectedContractList && selectedContractList !== '') {
            this.selectedContractList = selectedContractList;
        }
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin)
            || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_contract_admin);
        this.is_ifa = this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_ifa) || this.is_admin;
        this.storeObject = new ScContractForm(this.storeService, this.authService, 'edit');

        this.storeExtObj['is_private'] = !this.is_admin;
        this.storeExtObj['http'] = this.http;
        this.storeExtObj['button_callback'] = (c_id: string) => {
            this.reloadStore();
        };
        this.cdr.detectChanges();

        const selectFilterAvailable = [];
        selectFilterAvailable.push('filter[contractor][0][mode]=equals');
        selectFilterAvailable.push('filter[contractor][0][operator]=and');
        selectFilterAvailable.push('filter[contractor][0][value]=0');
        selectFilterAvailable.push('filter[status][0][mode]=equals');
        selectFilterAvailable.push('filter[status][0][operator]=and');
        selectFilterAvailable.push('filter[status][0][value]=published');
        this.selectFilterAvailable = selectFilterAvailable;

        const selectFilterAccepted = [];
        selectFilterAccepted.push('filter[contractor][0][mode]=equals');
        selectFilterAccepted.push('filter[contractor][0][operator]=and');
        selectFilterAccepted.push('filter[contractor][0][value]=' + this.authService.getUser().details.user_id);
        selectFilterAccepted.push('filter[status][0][mode]=in');
        selectFilterAccepted.push('filter[status][0][operator]=and');
        selectFilterAccepted.push('filter[status][0][value]=taken,turned-in');
        this.selectFilterAccepted = selectFilterAccepted;

        const selectFilterConfirmed = [];
        selectFilterConfirmed.push('filter[contractor][0][mode]=equals');
        selectFilterConfirmed.push('filter[contractor][0][operator]=and');
        selectFilterConfirmed.push('filter[contractor][0][value]=' + this.authService.getUser().details.user_id);
        selectFilterConfirmed.push('filter[status][0][mode]=equals');
        selectFilterConfirmed.push('filter[status][0][operator]=and');
        selectFilterConfirmed.push('filter[status][0][value]=confirmed');
        this.selectFilterConfirmed = selectFilterConfirmed;

        const selectFilterCreated = [];
        selectFilterCreated.push('filter[client][0][mode]=equals');
        selectFilterCreated.push('filter[client][0][operator]=and');
        selectFilterCreated.push('filter[client][0][value]=' + this.authService.getUser().details.user_id);
        this.selectFilterCreated = selectFilterCreated;

        this.cdr.detectChanges();

    }

    public contractListChanged($event: MatChipSelectionChange, contractList: any) {
        if ($event.selected) {
            if (this.selectedContractList !== contractList.value) {
                this.selectedContractList = contractList.value;
                // this.loadFleet(classification.name);
            }
        } else {
            this.selectedContractList = 'available';
            // this.loadFleet('');
        }
        localStorage.setItem('selectedContractList', this.selectedContractList);
    }

    public rowSelected($event: any) {
        const route = 'sc-contracts/' + $event.row.c_id;
        if ($event.in_new_tab) {
            const url = this.router.createUrlTree([route]);
            const serializedUrl = this.router.serializeUrl(url);

            // Insert `#` between the domain and route
            const modifiedUrl = '#/' + serializedUrl;
            window.open(modifiedUrl, '_blank');
        } else {
            this.router.navigateByUrl(route);
        }
    }

    public reloadStore() {
        this.is_loading = true;
        this.storeObject.reload().then(line_records => {
            this.setRows();
        });
    }

    private setRows() {
        this.rows = null;

        this.is_loading = true;
        this.cdr.detectChanges();

        const records = this.storeObject.getStore().getRecords();

        // getRecordByPropertyValue('list_type', type)

        this.is_loading = false;
        this.rows = records;
        this.cdr.detectChanges();
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }
}
