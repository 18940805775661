import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {StoreService} from '../../../services/store.service';
import {Router} from '@angular/router';
import {AppSettings} from '../../../config/AppSettings';

@Component({
  selector: 'app-ifa',
  templateUrl: './ifa.component.html',
  styleUrls: ['./ifa.component.css']
})
export class IfaComponent implements OnInit {

  public loading_text = '';
  public is_loading = false;
  public menu = [];
  public is_admin = false;

  constructor(private authService: AuthService, private storeService: StoreService, public router: Router) {
    this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin);
  }

  ngOnInit() {
  }

}
