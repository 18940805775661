import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ScVersion} from '../../../../../../forms/sc-version';
import {StoreService} from '../../../../../../services/store.service';
import {AuthService} from '../../../../../../services/auth.service';
import {NotificationService} from '../../../../../../services/notification.service';
import {AppSettings} from '../../../../../../config/AppSettings';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-edit-sc-version',
  templateUrl: './edit-sc-version.component.html',
  styleUrls: ['./edit-sc-version.component.css']
})
export class EditScVersionComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
    this._select_caption_tpl = value;
    this.cdr.markForCheck();
  }

  public loading_text = '';
  public is_loading = true;
  public fields: any[];
  public mode = 'edit';
  public edit = false;
  public rows = [];
  public count = 0;

  private subs: Array<Subscription> = [];
  private record: any;
  private id: string|number = '';
  private ScVersion: ScVersion;

  private _select_caption_tpl: ElementRef;

  constructor(private storeService: StoreService,
              private authService: AuthService,
              private notificationService: NotificationService,
              private route: ActivatedRoute,
              public router: Router,
              private cdr: ChangeDetectorRef,
              public dialog: MatDialog,
              public http: HttpClient) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.subs.push(this.route.params.subscribe((params: any) => {
      this.edit = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin);

      if (params.id && params.id !== 'add') {
        this.mode = 'edit';
        this.id = params.id;
      } else {
        this.mode = 'add';
        // this.edit = true;
      }

      const filter = [];
      filter.push('filter[version_id][0][mode]=equals');
      filter.push('filter[version_id][0][operator]=and');
      filter.push('filter[version_id][0][value]=' + this.id);
      filter.push('first=0');
      filter.push('rows=1');

      this.ScVersion = new ScVersion(this.storeService, this.authService, this.mode);
      this.ScVersion.init({select_caption_tpl: this._select_caption_tpl}, {http: this.http, button_callback: () => {
        this.router.navigateByUrl('/admin/games/starcitizen/sc-version');
            }},
          '?' + filter.join('&')).then((success) => {
        this.fields = this.ScVersion.getEditFields();
        if (this.id && this.id !== '' && this.mode === 'edit') {
          const rec = this.ScVersion.getStore().getRecordById(this.id);
          if (rec) {
            this.record = rec;
          }
        } else {
          this.resetModel();
        }
        this.rebuildForm();
        this.is_loading = false;
      });

    }));
  }

  public rebuildForm() {
    this.fields = [];
    this.cdr.detectChanges();
    this.fields = this.ScVersion.getForm(this.record.data);
    this.cdr.detectChanges();
  }

  private resetModel() {
    this.record = this.ScVersion.getDefaultModel();
  }

  public saveStore(event: any) {


    if (this.mode === 'edit') {
      this.ScVersion.getStore().patchRecord(this.record, '').then((res) => {
        if (res && res.success) {
          this.notificationService.success('Data',
              'Speichern erfolgreich.', {timeOut: 5000});
          this.rebuildForm();
        } else {
          this.notificationService.error('Data',
              'Speichern fehlgeschlagen.', {timeOut: 5000});
        }
      });
    } else {
      this.ScVersion.getStore().addRecord(this.record.data, '').then((res) => {
        if (res && res.success) {
          this.notificationService.success('Data',
              'Speichern erfolgreich.', {timeOut: 5000});
          this.resetModel();
          this.rebuildForm();
        } else {
          this.notificationService.error('Data',
              'Speichern fehlgeschlagen.', {timeOut: 5000});
        }
      });
    }
  }


  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
    if (this.mode === 'edit') {
      this.ScVersion.getStore().revertRecord(this.record);
    }
  }
}
