import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {StoreService} from '../../../services/store.service';
import {AuthService} from '../../../services/auth.service';
import {NotificationService} from '../../../services/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import {AppSettings} from '../../../config/AppSettings';
import {ScCommodityInventoryForm} from '../../../forms/scCommodityInventoryForm';

@Component({
    selector: 'app-sc-commodity-inventory-edit',
    templateUrl: './sc-commodity-inventory-edit.component.html',
    styleUrls: ['./sc-commodity-inventory-edit.component.css']
})
export class ScCommodityInventoryEditComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
        this._select_caption_tpl = value;
        this.cdr.markForCheck();
    }

    public loading_text = '';
    public is_loading = true;
    public fields: any[];
    public mode = 'edit';
    public edit = false;
    public rows = [];
    public count = 0;

    private subs: Array<Subscription> = [];
    public record: any;
    public id: string|number = '';
    private ScCommodityInventory: ScCommodityInventoryForm;

    private _select_caption_tpl: ElementRef;

    constructor(private storeService: StoreService,
                private authService: AuthService,
                private notificationService: NotificationService,
                private route: ActivatedRoute,
                public router: Router,
                private cdr: ChangeDetectorRef,
                public dialog: MatDialog,
                public http: HttpClient) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.subs.push(this.route.params.subscribe((params: any) => {
            this.edit = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin)
                || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_rally_admin);

            if (params.id && params.id !== 'add') {
                this.mode = 'edit';
                this.id = params.id;
            } else {
                this.mode = 'add';
                // this.edit = true;
            }

            const filter = [];
            filter.push('filter[commodity][0][mode]=equals');
            filter.push('filter[commodity][0][operator]=and');
            filter.push('filter[commodity][0][value]=' + this.id);
            filter.push('first=0');
            filter.push('rows=1');

            this.ScCommodityInventory = new ScCommodityInventoryForm(this.storeService, this.authService, this.mode);
            this.ScCommodityInventory.init({select_caption_tpl: this._select_caption_tpl}, {type: params.type, http: this.http},
                '?' + filter.join('&')).then((success) => {
                this.fields = this.ScCommodityInventory.getEditFields();
                if (this.id && this.id !== '' && this.mode === 'edit') {
                    const rec = this.ScCommodityInventory.getStore().getRecordById(this.id);
                    if (rec) {
                        this.record = rec;
                    }
                } else {
                    this.resetModel();
                }
                this.rebuildForm();
                this.is_loading = false;
            });

        }));
    }

    public rebuildForm() {
        this.fields = [];
        this.cdr.detectChanges();
        this.fields = this.ScCommodityInventory.getForm(this.record.data);
        this.cdr.detectChanges();
    }

    private resetModel() {
        this.record = this.ScCommodityInventory.getDefaultModel();
    }

    public saveStore(event: any) {


        if (this.mode === 'edit') {
            this.ScCommodityInventory.getStore().patchRecord(this.record, '').then((res) => {
                if (res && res.success) {
                    this.notificationService.success('Data',
                        'Speichern erfolgreich.', {timeOut: 5000});
                    this.rebuildForm();
                } else {
                    this.notificationService.error('Data',
                        'Speichern fehlgeschlagen.', {timeOut: 5000});
                }
            });
        } else {
            this.ScCommodityInventory.getStore().addRecord(this.record.data, '').then((res) => {
                if (res && res.success) {
                    this.notificationService.success('Data',
                        'Speichern erfolgreich.', {timeOut: 5000});
                    this.resetModel();
                    this.rebuildForm();
                } else {
                    this.notificationService.error('Data',
                        'Speichern fehlgeschlagen.', {timeOut: 5000});
                }
            });
        }
    }


    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
        if (this.mode === 'edit') {
            this.ScCommodityInventory.getStore().revertRecord(this.record);
        }
    }
}
