import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FbFormObject} from '../../../../class/fb_form_object';
import {Subscription} from 'rxjs';
import {StoreService} from '../../../../services/store.service';
import {AuthService} from '../../../../services/auth.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../../../services/notification.service';
import {MatDialog} from '@angular/material/dialog';
import {AppSettings} from '../../../../config/AppSettings';
import {IfaBadge} from '../../../../forms/ifa-badge';
import {IfaMember} from '../../../../forms/ifa-member';

@Component({
  selector: 'app-ifa-member',
  templateUrl: './ifa-member.component.html',
  styleUrls: ['./ifa-member.component.css']
})
export class IfaMemberComponent implements OnInit, OnDestroy, AfterViewInit {


  @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
    this._select_caption_tpl = value;
    // this.setColumns();
    this.cdr.markForCheck();
  }


  public storeObj: FbFormObject;
  private subs: Array<Subscription> = [];

  public loading_text = '';
  public is_loading = true;
  public rows: any[];


  private _select_caption_tpl: ElementRef;

  public is_admin: boolean;

  constructor(private storeService: StoreService,
              private authService: AuthService,
              private router: Router,
              private cdr: ChangeDetectorRef,
              private notificationService: NotificationService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin);

    this.storeObj = new IfaMember(this.storeService, this.authService, 'edit');
    this.is_loading = false;
    this.cdr.detectChanges();
  }

  public rowSelected($event: any) {
    const route = 'admin/ifa/member/' + $event.row.user_id;
    if ($event.in_new_tab) {
      const url = this.router.createUrlTree([route]);
      const serializedUrl = this.router.serializeUrl(url);

      // Insert `#` between the domain and route
      const modifiedUrl = '#/' + serializedUrl;
      window.open(modifiedUrl, '_blank');
    } else {
      this.router.navigateByUrl(route);
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
