<div class="full-width"
     [ngClass]="class"
     [matTooltip]="tooltip"
>
    <div class="stat-caption">
        {{caption}}
    </div>
    <div class="stat-value">
        {{display_value| number: null}}{{unit}}
    </div>
</div>
