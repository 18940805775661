<div>
    <app-content-container [content_template]="page_content" [is_loading]="false" [loading_text]="''"
                           [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div class="padding">
        <markdown class="variable-binding" [data]="markdown"></markdown>
        <table *ngIf="records" style="width: 100%;">
            <ng-container *ngFor="let branch of branches">
                <tr>
                    <th colspan="2" [attr.rowspan]="2" style="vertical-align: top">{{branch.name}}</th>

                    <td class="member" *ngIf="branch.leader[0]">
                        <a [routerLink]="'/members/profile/' + branch.leader[0].data.user_id" class="link-clear member">
                            <span class="member-badge" *ngIf="branch.leader[0].data['file_id_' + getBadgeForBranchLeader(branch, branch.leader[0])] > 0"
                                  [matTooltip]="branch.leader[0].data['max_rank_name_' + getBadgeForBranchLeader(branch, branch.leader[0])]">
                                        <app-badge-icon *ngIf="branch.leader[0].data['file_id_' + getBadgeForBranchLeader(branch, branch.leader[0])] > 0"
                                                        [file_id]="branch.leader[0].data['file_id_' + getBadgeForBranchLeader(branch, branch.leader[0])]"></app-badge-icon>
                                    </span>
                            <span class="member-badge" *ngIf="branch.leader[0].data['file_id_' + getBadgeForBranchLeader(branch, branch.leader[0])] === 0"></span>
                            <span>{{branch.leader[0] ? (branch.leader[0].data.sc_username != null ? branch.leader[0].data.sc_username : 'no sc name for ' + branch.leader[0].data.global_name) : 'no global_name assigned'}}</span>
                        </a>
                    </td>
                    <td *ngIf="!branch.leader[0]"></td>
                </tr>
                <tr>
                    <td *ngFor="let squad of branch.squads" style="vertical-align: top;">
                        <table style="width: 100%;">
                            <tr>
                                <th colspan="2">{{squad.name}}</th>
                            </tr>
                            <tr>
                                <td class="member" [attr.rowspan]="squad.leader.length + 1" style="vertical-align: top;">
                                    Leader:
                                </td>
                            </tr>
                            <tr *ngFor="let leader of squad.leader">
                                <td class="member">
                                    <a [routerLink]="'/members/profile/' + leader.data.user_id" class="link-clear member">
                                         <span class="member-badge" *ngIf="leader.data['file_id_' + squad.badge_key] > 0"
                                               [matTooltip]="leader.data['max_rank_name_' + squad.badge_key]">
                                            <app-badge-icon *ngIf="leader.data['file_id_' + squad.badge_key] > 0"
                                                            [file_id]="leader.data['file_id_' + squad.badge_key]"></app-badge-icon>
                                        </span>
                                        <span class="member-badge" *ngIf="leader.data['file_id_' + squad.badge_key] === 0"></span>
                                        <span>{{leader ? (leader.data.sc_username != null ? leader.data.sc_username : 'no sc name for ' + leader.data.global_name) : 'no global_name assigned'}}</span>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td class="member" [attr.rowspan]="squad.coLeader.length + 1" style="vertical-align: top;">
                                    Co. Leader:
                                </td>
                            </tr>
                            <tr *ngFor="let coLeader of squad.coLeader">
                                <td class="member">
                                    <a [routerLink]="'/members/profile/' + coLeader.data.user_id" class="link-clear member">
                                        <span class="member-badge" *ngIf="coLeader.data['file_id_' + squad.badge_key] > 0"
                                              [matTooltip]="coLeader.data['max_rank_name_' + squad.badge_key]">
                                            <app-badge-icon *ngIf="coLeader.data['file_id_' + squad.badge_key] > 0"
                                                            [file_id]="coLeader.data['file_id_' + squad.badge_key]"></app-badge-icon>
                                        </span>
                                        <span class="member-badge" *ngIf="coLeader.data['file_id_' + squad.badge_key] === 0"></span>
                                        <span>{{coLeader ? (coLeader.data.sc_username != null ? coLeader.data.sc_username : 'no sc name for ' + coLeader.data.global_name) : 'no global_name assigned'}}</span>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td class="member" [attr.rowspan]="squad.members.length + 1" style="vertical-align: top;">
                                   Members:
                                </td>
                            </tr>
                            <tr *ngFor="let member of squad.members">
                                <td class="member">
                                    <a [routerLink]="'/members/profile/' + member.data.user_id" class="link-clear member">
                                        <span class="member-badge" *ngIf="member.data['file_id_' + squad.badge_key] > 0"
                                              [matTooltip]="member.data['max_rank_name_' + squad.badge_key]">
                                            <app-badge-icon *ngIf="member.data['file_id_' + squad.badge_key] > 0"
                                                            [file_id]="member.data['file_id_' + squad.badge_key]"></app-badge-icon>
                                        </span>
                                        <span class="member-badge" *ngIf="member.data['file_id_' + squad.badge_key] === 0"></span>
                                        <span>{{member ? (member.data.sc_username != null && member.data.sc_username != '' ? member.data.sc_username : 'no sc name for ' + member.data.global_name): 'no global_name assigned'}}</span>
                                    </a>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td colspan="9">
                        <mat-divider></mat-divider>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>
</ng-template>


