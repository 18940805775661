<h3 class="group-caption">Currencies</h3>
<div class="card card-1 padding">
    <div *ngIf="currencies">
        <div *ngFor="let currency of currencies" style="float: left">
            {{currency.data.in_stock}}<span style="padding-left: 16px;">{{currency.data.c_name}}</span>
        </div>
        <div style="float: right;">
            <mat-form-field class="example-form-field">
                <mat-label>Range</mat-label>
                <mat-date-range-input
                        [formGroup]="range"
                        [rangePicker]="rangePicker"
                >
                    <input matStartDate placeholder="Start date" formControlName="start">
                    <input matEndDate placeholder="End date" formControlName="end">
                </mat-date-range-input>
                <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #rangePicker></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="lineChartData && lineChartData.datasets.length > 0">
        <canvas
                baseChart
                [data]="lineChartData"
                [options]="lineChartOptions"
                [type]="lineChartType"
                (chartHover)="chartHovered($event)"
                (chartClick)="chartClicked($event)"
        ></canvas>
    </div>
</div>