import {FbFormObject} from '../class/fb_form_object';

export class MiningModule extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'module', label: 'module', visible: true, noclick: false, disable_ripple: true},
            {id: 'type', label: 'type', visible: true, noclick: false, disable_ripple: true},
            {id: 'price', label: 'price', visible: true, noclick: false, disable_ripple: true},

            {id: 'laser_power_mod', label: 'laser_power_mod', visible: true, noclick: false, disable_ripple: true},
            {id: 'resistance', label: 'resistance', visible: true, noclick: false, disable_ripple: true},
            {id: 'instability', label: 'instability', visible: true, noclick: false, disable_ripple: true},
            {id: 'optimal_charge_rate', label: 'optimal_charge_rate', visible: true, noclick: false, disable_ripple: true},
            {id: 'optimal_charge_window', label: 'optimal_charge_window', visible: true, noclick: false, disable_ripple: true},
            {id: 'inert_materials', label: 'inert_materials', visible: true, noclick: false, disable_ripple: true},

            {id: 'overcharge_rate', label: 'overcharge_rate', visible: true, noclick: false, disable_ripple: true},
            {id: 'clustering', label: 'clustering', visible: true, noclick: false, disable_ripple: true},
            {id: 'shatter_damage', label: 'shatter_damage', visible: true, noclick: false, disable_ripple: true},
            {id: 'extract_power_mod', label: 'extract_power_mod', visible: true, noclick: false, disable_ripple: true}
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Module',
                class: 'card card-1 padding single-column',
                field_class: '',
                fields: [
                    {
                        type: 'text',
                        name: 'module',
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'select',
                        name: 'type',
                        options: this.select_boxes['type_select'],
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'number',
                        name: 'price',
                    },
                    {
                        type: 'number',
                        name: 'laser_power_mod',
                    },
                    {
                        type: 'number',
                        name: 'resistance',
                    },
                    {
                        type: 'number',
                        name: 'instability',
                    },
                    {
                        type: 'number',
                        name: 'optimal_charge_rate',
                    },
                    {
                        type: 'number',
                        name: 'optimal_charge_window',
                    },
                    {
                        type: 'number',
                        name: 'inert_materials',
                    },
                    {
                        type: 'number',
                        name: 'overcharge_rate',
                    },
                    {
                        type: 'number',
                        name: 'clustering',
                    },
                    {
                        type: 'number',
                        name: 'shatter_damage',
                    },
                    {
                        type: 'number',
                        name: 'extract_power_mod',
                    }
                ]
            }
        ];
        super.setEditFields();
    }

    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.stores['mining_module'] = this.storeService.getStore('mining_module');

            this.main_store = this.stores['mining_module'];

            const stores = [];
            stores.push(this.stores['mining_module'].loadStore(this.filter));

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
                module: '',
                type: '',
                price: null,
                resistance: null,
                instability: null,
                optimal_charge_rate: null,
                optimal_charge_window: null,
                inert_materials: null
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        this.setTypeSelectBox();
        // call super
        super.setSelectboxes();
    }

    public setTypeSelectBox() {
        this.select_boxes['type_select'] = [
            {
                value: 'active',
                name: 'active'
            },
            {
                value: 'passive',
                name: 'passive'
            }
        ];
        return this.select_boxes['vehicle_select'];
    }
}
