<div>
    <app-content-container [content_template]="page_content"
                           [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>

        <mat-tab-group mat-align-tabs="start" color="accent" *ngIf="user_id"
                       LastActiveTabDirective="member-points-details">
            <mat-tab label="Mining Points">
                <div class="padding">
                    <div class="card card-1 padding table-wrapper" *ngIf="ScbMembersPointsMining">
                        <app-fb-forms-table [storeObj]="ScbMembersPointsMining"
                                            [edit_allowed]="editAllowed"
                                            [storeExtObj]="storeExtObjMining"
                                            (add_click)="addRow('mining')"
                                            [require_filter]="require_filter"
                                            [stateKey]="'members-points-details-mining'"
                                            [show_add]="editAllowed"
                                            [show_delete]="editAllowed"
                                            [show_select]="editAllowed"
                                            [title]="'Member Mining'"
                        >
                        </app-fb-forms-table>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Salvage Points">
                <div class="padding">
                    <div class="card card-1 padding table-wrapper" *ngIf="ScbMembersPointsSalvage">
                        <app-fb-forms-table [storeObj]="ScbMembersPointsSalvage"
                                            [edit_allowed]="editAllowed"
                                            [storeExtObj]="storeExtObjSalvage"
                                            [require_filter]="require_filter"
                                            (add_click)="addRow('salvage')"
                                            [stateKey]="'members-points-details-mining'"
                                            [show_add]="editAllowed"
                                            [show_delete]="editAllowed"
                                            [show_select]="editAllowed"
                                            [title]="'Member Salvage'"
                        >
                        </app-fb-forms-table>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Trading Points">
                <div class="padding">
                    <div class="card card-1 padding table-wrapper" *ngIf="ScbMembersPointsTrading">
                        <app-fb-forms-table [storeObj]="ScbMembersPointsTrading"
                                            [edit_allowed]="editAllowed"
                                            [storeExtObj]="storeExtObjTrading"
                                            [require_filter]="require_filter"
                                            (add_click)="addRow('trading')"
                                            [stateKey]="'members-points-details-mining'"
                                            [show_add]="editAllowed"
                                            [show_delete]="editAllowed"
                                            [show_select]="editAllowed"
                                            [title]="'Member Trading'"
                        >
                        </app-fb-forms-table>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
</ng-template>
<ng-template #select_caption_tpl let-element="element" let-column="column">
    {{element.tmp.caption[column.id]}}
</ng-template>
