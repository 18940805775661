import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import $ from 'jquery';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';


@Component({
    selector: 'app-external',
    templateUrl: './external.component.html',
    styleUrls: ['./external.component.css']
})
export class ExternalComponent implements OnInit, OnDestroy {

    private subs: Array<Subscription> = [];
    private extternal_sites = [
        {key: 'faq', url: 'http://xxx/wiki/doku.php?id=faq:start', name: 'FAQ'}
    ];
    /*
     */

    public external_src: string;
    public height: number;
    public width: number;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.setIframeDimension();
    }

    constructor(private router: Router, private route: ActivatedRoute, private cdRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.setIframeDimension();

        this.subs.push(this.route.params.subscribe((params: any) => {
            const selected_sites = this.extternal_sites.filter((site) => {
                return site.key === params.keyId;
            });

            if (selected_sites.length > 0) {
                this.route.data['value'].breadcrumb = selected_sites[0].name;
                this.external_src = selected_sites[0].url;
                this.cdRef.detectChanges();
                // override breadcrumb
                /*
                this.breadcrumbsService.forms([{
                    label: selected_sites[0].name,
                    params: {keyId: params.keyId},
                    url: '/ext/faq'
                }]);
                */
            } else {
                this.router.navigateByUrl('');
            }
        }));

        this.checkElement('body') // use whichever selector you want
            .then((element) => {
                this.setIframeDimension();
                // Do whatever you want now the element is there
                if (this.external_src) {
                    // console.log('external_src', this.external_src);
                    // Do whatever you want now the element is there
                    $('.loader-container').show();
                    $('#iframe1').hide();
                    // setReportHelp();
                    document.getElementById('iframe1').onload = function () {
                        $('.loader-container').hide();
                        $('#iframe1').show();
                    };
                } else {
                    // console.log('no iframe');
                    $('.loader-container').hide();
                }
            });
    }

    private setIframeDimension() {
        this.height = $('.scroll-content').height() - 4;
        this.width = $('.custom-sidenav-container').width();
        this.cdRef.detectChanges();
    }

    private rafAsync() {
        return new Promise(resolve => {
            requestAnimationFrame(resolve); // faster than set time out
        });
    }

    private checkElement(selector) {
        if (document.querySelector(selector) === null) {
            return this.rafAsync().then(() => this.checkElement(selector));
        } else {
            return Promise.resolve(true);
        }
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }

}
