import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DialogData {
    title: string;
    text: string;
    storeClass: any;
    name_atr: string;
    second_name_atr: string;
    records: any;
    single_line: boolean;
    default: string;
    obj: any;
    storeObj: any;
    add_url: string;
    type: string;
    show_add: boolean;
    show_add_in_table: boolean;
    edit_allowed: boolean;
    show_delete: boolean;
}

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<ConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

    ngOnInit() {
    }
}
