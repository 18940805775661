<div class="padding">
    <div class="card card-1 padding table-wrapper" *ngIf="storeObj">
        <app-fb-forms-table [storeObj]="storeObj"
                            [edit_allowed]="true"
                            [stateKey]="'ifa-badges'"
                            [storeExtObj] = "storeExtObj"
                            [show_add_in_view]="true"
                            [show_delete]="true"
                            [title]="'Badges'"
        >
        </app-fb-forms-table>
    </div>
</div>
