<div class="confirm-container dialog-comment">
    <mat-toolbar class="shadow-bottom" color="warn">
        <h1 mat-dialog-title>{{data.title}}</h1>
    </mat-toolbar>
    <div class="confirm-content padding">
        <app-form-inputs *ngIf="fields && fields.length"
                         #tpl_form
                         [field_groups]="fields"
                         [edit_allowed]="true"
                         [loading]="false"
                         [show_save_button]="false">
        </app-form-inputs>
    </div>
    <div mat-dialog-actions class="dialog-footer-bar" *ngIf="form_set && fields && fields.length">
        <button mat-raised-button [mat-dialog-close]="{result: false, comment: ''}"
                cdkFocusInitial class="button-cancel" style="float: left; margin-left: 16px;">abbrechen</button>
        <button mat-raised-button [mat-dialog-close]="{result: true, comment: model.comment}"
                class="button-confirm" style="float: right; margin-right: 16px;"
                [disabled]="!_tpl_form.form.isValid() || model.comment.length === 0">bestätigen</button>
    </div>
</div>
