import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {Subscription} from 'rxjs';
import {Store} from '../../../../../class/store';
import {StoreService} from '../../../../../services/store.service';
import {AuthService} from '../../../../../services/auth.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../../../../services/notification.service';
import {MatDialog} from '@angular/material/dialog';
import {IMqttMessage, MqttService} from 'ngx-mqtt';
import {ScbEventOreDelivery} from '../../../../../forms/scb-event-ore-delivery';
import {FbFormObject} from '../../../../../class/fb_form_object';
import {ScbEventCargoDelivery} from '../../../../../forms/scb-event-cargo-delivery';
import {ScbEventExtraPayment} from '../../../../../forms/scb-event-extra-payment';
import {ScbEventOreDeliveryBv2} from '../../../../../forms/scb-event-ore-delivery-bv2';
import {ScbEventRefineryFeeBv2} from '../../../../../forms/scb-event-refinery-fee-bv2';

@Component({
    selector: 'app-bot-event-commodity-delivery',
    templateUrl: './bot-event-commodity-delivery.component.html',
    styleUrls: ['./bot-event-commodity-delivery.component.css']
})
export class BotEventCommodityDeliveryComponent implements OnInit, OnDestroy, AfterViewInit {


    @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
        this._select_caption_tpl = value;
        this.cdr.markForCheck();
    }

    @ViewChild('k_rounder_tpl', {static: true}) set k_rounder_tpl(value: ElementRef) {
        this._k_rounder_tpl = value;
        this.cdr.markForCheck();
    }


    public ScbCommodity: FbFormObject;
    private subs: Array<Subscription> = [];

    public loading_text = '';
    public is_loading = true;
    public rows: any[];

    @Input()
    public event_id = 0;
    @Input()
    public editAllowed = false;
    @Input()
    public com_type = 'ore';
    @Input()
    public version_id = 0;
    @Input()
    public event_base_version = 1;

    private _select_caption_tpl: ElementRef;
    private _k_rounder_tpl: ElementRef;

    public column_definition: any[];
    public is_admin: boolean;

    private channelStore: Store;
    public ext_obj = {};
    public storeTemplates: {};
    public require_filter = {index: 0, filter: []};

    public com_type_name = 'com_type';

    @Output() public dataLoaded: EventEmitter<any> = new EventEmitter();

    constructor(private storeService: StoreService,
                private authService: AuthService,
                private router: Router,
                private cdr: ChangeDetectorRef,
                private notificationService: NotificationService,
                public dialog: MatDialog,
                private _mqttService: MqttService) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.is_admin = this.authService.isAdmin();
        this.storeTemplates = {select_caption_tpl: this._select_caption_tpl, k_rounder_tpl: this._k_rounder_tpl};

        this.require_filter.filter = [];
        this.require_filter.filter.push('filter[event_id][0][mode]=equals');
        this.require_filter.filter.push('filter[event_id][0][operator]=and');
        this.require_filter.filter.push('filter[event_id][0][value]=' + this.event_id);
        this.require_filter.filter.push('filter[deposit_type][0][mode]=equals');
        this.require_filter.filter.push('filter[deposit_type][0][operator]=and');
        this.require_filter.filter.push('filter[deposit_type][0][value]=' + this.com_type);
        this.require_filter.filter.push('first=0');
        this.require_filter.filter.push('rows=1');
        this.require_filter.index = 2;

        this.com_type_name = this.capitalizeFirstLetter(this.com_type);

        const com_filter = [];
        com_filter.push('filter[version_id][0][mode]=equals');
        com_filter.push('filter[version_id][0][operator]=and');
        com_filter.push('filter[version_id][0][value]=' + this.version_id);
        com_filter.push('filter[com_type][0][mode]=equals');
        com_filter.push('filter[com_type][0][operator]=and');
        com_filter.push('filter[com_type][0][value]=' + this.com_type);
        com_filter.push('first=0');
        com_filter.push('rows=1');

        switch (this.com_type) {
            case 'ore':
                switch (this.event_base_version) {
                    default:
                        this.ScbCommodity = new ScbEventOreDelivery(this.storeService, this.authService, 'edit');
                        break;
                    case 2:
                        this.ScbCommodity = new ScbEventOreDeliveryBv2(this.storeService, this.authService, 'edit');
                        break;
                }
                break;
            case 'refinery_fee':
                this.ScbCommodity = new ScbEventRefineryFeeBv2(this.storeService, this.authService, 'edit');
                break;
            case 'cargo':
                this.ScbCommodity = new ScbEventCargoDelivery(this.storeService, this.authService, 'edit');
                break;
            case 'extra':
                this.ScbCommodity = new ScbEventExtraPayment(this.storeService, this.authService, 'edit');
                break;
        }


        this.ext_obj = {event_id: this.event_id, com_filter: com_filter};
        this.is_loading = false;
        this.cdr.detectChanges();
        this.channelStore = this.storeService.getStore('dc_channel');
        this.channelStore.loadStore('').then((records) => {
            this.subs.push(this._mqttService.observe('bot/event').subscribe((message: IMqttMessage) => {
                const info = JSON.parse(message.payload.toString());
                if (Number(info.event_id) === Number(this.event_id)) {
                    this.is_loading = true;
                    this.ScbCommodity.getStore().reloadStore().then(() => {
                        this.is_loading = false;
                    });
                }
            }));
        });
    }

    public rowSelected($event: any) {
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }

    public addClicked() {
    }

    public dataLoadedDone(event) {
        this.dataLoaded.emit(event);
    }

    private capitalizeFirstLetter(val) {
        return String(val).charAt(0).toUpperCase() + String(val).slice(1);
    }
}
