<div *ngIf="form">
    <form name="form" [formGroup]="form.getForm()" (ngSubmit)="form.isValid()" (submit)="onSubmit($event)">
        <div *ngFor="let group of field_groups">
            <div class="field-group">
                <h3 class="group-caption" *ngIf="group.caption && group.caption.length > 0">{{group.caption}}</h3>
                <div class="{{group.class}}">
                    <div *ngFor="let field of group.fields" class="{{group.field_class}}">
                        <div [ngSwitch]="field.type" class="field-container" *ngIf="!field.hidden"
                             [ngClass]="{'field-important' : field.important}"
                             matTooltip="{{field.description}}" matTooltipClass="field-info-tip">
                            <div class="inner-field-container">

                                <ng-container
                                        *ngIf="edit_allowed && field.reset_allowed && field.model.obj[field.model.key] !== ''
                                        && field.model.obj[field.model.key] !== null && field.model.obj[field.model.key] !== undefined && !field.disabled">
                                    <span (click)="setFieldValue(field, '')"
                                          class="show-reset-field-btn"
                                          title="{{'FORMS.BTN_RESET_FIELD'| translate }} {{field.model.obj[field.model.key]}}">
                                        <mat-icon>remove_circle</mat-icon>
                                    </span>
                                </ng-container>

                                <!-- Radio -->
                                <div class="full-width fb-radio-container" *ngSwitchCase="'radio'">
                                    <label>{{field.placeholder}}</label>
                                    <br/>
                                    <mat-radio-group
                                            formControlName="{{field.name}}"
                                            class="form-control"
                                            [required]="field.required ? field.required : false">
                                        <mat-radio-button *ngFor="let option of field.options"
                                                          [value]="option.value">
                                            {{option.name}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <mat-hint align="end" *ngIf="field.important">
                                        wichtiges Feld
                                    </mat-hint>
                                    <mat-error *ngIf="form.hasError(field.name)">
                                        <strong>{{form.getError(field.name)}}</strong>
                                    </mat-error>
                                </div>

                                <!-- Chip Autocomplete -->
                                <mat-form-field class="full-width" *ngSwitchCase="'chip_autocomplete'">
                                    <mat-chip-grid #chipList aria-label="''" [disabled]="field.disabled">
                                        <mat-chip-row *ngFor="let value of field.values"
                                                      [removable]="true" (removed)="removeChipValueObject(value, field)">
                                            {{value.name}}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip-row>
                                        <input placeholder="{{field.placeholder}}" #chip_auto_input
                                               [matChipInputFor]="chipList"
                                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                               [matChipInputAddOnBlur]="true"
                                               [required]="field.required ? field.required : false"
                                               [matAutocomplete]="auto"
                                               formControlName="{{field.name}}">
                                        <!--
                                        <input matInput placeholder="{{field.placeholder}}"
                                               [matAutocomplete]="auto"
                                               [required]="field.required ? field.required : false"
                                               formControlName="{{field.name}}">
                                               -->
                                    </mat-chip-grid>
                                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="field.displayFn"
                                                      (optionSelected)="ChipAutocompleteSelected($event, field, chip_auto_input)"
                                                      (closed)="chipAutocompleteClosed(field, chip_auto_input)">
                                        <ng-container *ngFor="let option of field.filteredOptions | async">
                                            <mat-option *ngIf="field.template"
                                                        [value]="option" class="{{field.option_class}}">
                                                <ng-container
                                                        *ngTemplateOutlet="field.template;context:{element: option}">
                                                </ng-container>
                                            </mat-option>
                                            <mat-option *ngIf="!field.template"
                                                        [value]="option">
                                                {{option.name}}
                                            </mat-option>
                                        </ng-container>

                                    </mat-autocomplete>
                                    <mat-hint align="end" *ngIf="field.important">
                                        wichtiges Feld
                                    </mat-hint>
                                    <mat-error *ngIf="form.hasError(field.name)">
                                        <strong>{{form.getError(field.name)}}</strong>
                                    </mat-error>
                                </mat-form-field>

                                <!-- Select -->
                                <mat-form-field class="full-width" *ngSwitchCase="'select'">
                                    <mat-label>{{field.placeholder}}</mat-label>
                                    <mat-select formControlName="{{field.name}}"
                                                class="form-control"
                                                [required]="field.required ? field.required : false"
                                                [multiple]="field.multiple ? field.multiple : false">
                                        <mat-option *ngFor="let option of field.options"
                                                    [value]="option.value">
                                            {{option.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-hint align="end" *ngIf="field.important">
                                        wichtiges Feld
                                    </mat-hint>
                                    <mat-error *ngIf="form.hasError(field.name)">
                                        <strong>{{form.getError(field.name)}}</strong>
                                    </mat-error>
                                </mat-form-field>

                                <!-- select_group -->
                                <mat-form-field class="full-width" *ngSwitchCase="'select_group'">
                                    <mat-label>{{field.placeholder}}</mat-label>
                                    <mat-select formControlName="{{field.name}}"
                                                class="form-control"
                                                [required]="field.required ? field.required : false"
                                                [multiple]="field.multiple ? field.multiple : false">
                                        <mat-optgroup *ngFor="let group of field.select_groups" [label]="group.label"
                                                      [disabled]="group.disabled">
                                            <mat-option *ngFor="let option of group.items"
                                                        [value]="option.value">
                                                {{option.label}}
                                            </mat-option>
                                        </mat-optgroup>
                                    </mat-select>
                                    <mat-hint align="end" *ngIf="field.important">
                                        wichtiges Feld
                                    </mat-hint>
                                    <mat-error *ngIf="form.hasError(field.name)">
                                        <strong>{{form.getError(field.name)}}</strong>
                                    </mat-error>
                                </mat-form-field>


                                <!-- slide toggle -->
                                <div *ngSwitchCase="'slide_toggle'">
                                    <section class="slider-section">
                                        <mat-slide-toggle
                                                formControlName="{{field.name}}">
                                            {{field.placeholder}}
                                        </mat-slide-toggle>
                                        <mat-hint align="end" class="mat-warn" *ngIf="field.important">
                                            wichtiges Feld
                                        </mat-hint>
                                    </section>
                                    <mat-hint class="hint-warning" *ngIf="field.warning">
                                        {{field.warning}}
                                    </mat-hint>
                                    <mat-error *ngIf="form.hasError(field.name)">
                                        <strong>{{form.getError(field.name)}}</strong>
                                    </mat-error>
                                </div>


                                <!-- checkbox -->
                                <section class="slider-section" *ngSwitchCase="'checkbox'">
                                    <mat-checkbox formControlName="{{field.name}}">{{field.placeholder}}</mat-checkbox>
                                    <mat-hint align="end" *ngIf="field.important">
                                        wichtiges Feld
                                    </mat-hint>
                                    <mat-error *ngIf="form.hasError(field.name)">
                                        <strong>{{form.getError(field.name)}}</strong>
                                    </mat-error>
                                </section>

                                <!-- mask -->
                                <div class="full-width" *ngSwitchCase="'mask'">
                                    <app-fb-mask-input [value]="field.model.obj[field.model.key]"
                                                       [caption]="field.placeholder"
                                                       [mask]="field.mask"
                                                       [showMaskTyped]="field.showMaskTyped"
                                                       (valueChanged)="onMaskInputChanged($event, field)"></app-fb-mask-input>
                                </div>

                                <!-- default input (multi types) -->
                                <mat-form-field class="full-width" *ngSwitchDefault>
                                    <mat-label>{{field.placeholder}}</mat-label>
                                    <input matInput name="{{field.name}}"
                                           value="{{field.model.obj[field.model.key]}}"
                                           [required]="field.required ? field.required : false"
                                           class="form-control"
                                           formControlName="{{field.name}}"
                                           type="{{field.type}}"
                                           [focus]="field.focus"
                                           (keydown)="onKeyDown($event, field)"
                                           (keyup)="onKeyUp($event, field)"
                                           min="{{field.min}}"
                                           max="{{field.max}}"
                                           step="{{field.step}}">
                                    <mat-hint align="end" *ngIf="field.hint && !field.max">
                                        {{field.hint}}
                                    </mat-hint>
                                    <mat-hint align="end" *ngIf="field.hint
                                     && field.hint
                                     && field.hint.type === 'length'">
                                        {{field.model.obj[field.model.key].length}} / {{field.max}}
                                    </mat-hint>
                                    <mat-hint align="end" *ngIf="field.important">
                                        wichtiges Feld
                                    </mat-hint>
                                    <mat-error *ngIf="form.hasError(field.name)">
                                        <strong>{{form.getError(field.name)}}</strong>
                                    </mat-error>
                                </mat-form-field>

                                <!-- autocomplete -->
                                <div *ngSwitchCase="'autocomplete'">
                                    <mat-form-field class="full-width" [ngClass]="{'data-loading': field.loading}">
                                        <mat-label>{{field.placeholder}}</mat-label>
                                        <input matInput
                                               [matAutocomplete]="auto"
                                               [required]="field.required ? field.required : false"
                                               formControlName="{{field.name}}">
                                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="field.displayFn"
                                                          (closed)="autocompleteClosed(field)">
                                            <ng-container *ngFor="let option of field.filteredOptions | async">
                                                <mat-option *ngIf="field.template"
                                                            [value]="option" class="{{field.option_class}}">
                                                    <ng-container
                                                            *ngTemplateOutlet="field.template;context:{element: option}">
                                                    </ng-container>
                                                </mat-option>
                                                <mat-option *ngIf="!field.template"
                                                            [value]="option">
                                                    {{option.name}}
                                                </mat-option>
                                            </ng-container>

                                        </mat-autocomplete>
                                        <mat-hint align="end" *ngIf="field.important">
                                            wichtiges Feld
                                        </mat-hint>
                                    </mat-form-field>
                                    <mat-progress-bar mode="indeterminate" class="field-loading" *ngIf="field.loading"></mat-progress-bar>
                                    <mat-error *ngIf="form.hasError(field.name)">
                                        <strong>{{form.getError(field.name)}}</strong>
                                    </mat-error>
                                </div>

                                <!-- textarea -->
                                <mat-form-field class="full-width" *ngSwitchCase="'textarea'">
                                    <mat-label>{{field.placeholder}}</mat-label>
                                    <textarea matInput
                                              name="{{field.name}}"
                                              value="{{field.model.obj[field.model.key]}}"
                                              [required]="field.required ? field.required : false"
                                              class="form-control"
                                              rows="{{field.rows ? field.rows : 2}}"
                                              cols="{{field.cols ? field.cols : 40}}"
                                              formControlName="{{field.name}}"></textarea>
                                    <mat-hint align="end" *ngIf="field.important">
                                        wichtiges Feld
                                    </mat-hint>
                                    <mat-error *ngIf="form.hasError(field.name)">
                                        <strong>{{form.getError(field.name)}}</strong>
                                    </mat-error>
                                </mat-form-field>

                                <!-- Time -->
                                <mat-form-field class="full-width" *ngSwitchCase="'time'">
                                    <mat-label>{{field.placeholder}}</mat-label>
                                    <input matInput
                                           formControlName="{{field.name}}"
                                           type="time"
                                           step="{{field.step}}"
                                           [required]="field.required ? field.required : false"/>
<!--                                    <ngx-material-timepicker #picker ></ngx-material-timepicker>-->
                                    <mat-error *ngIf="form.hasError(field.name)">
                                        <strong>{{form.getError(field.name)}}</strong>
                                    </mat-error>
                                </mat-form-field>

                                <!-- Date picker -->
                                <mat-form-field class="full-width" *ngSwitchCase="'date'">
                                    <mat-label>{{field.placeholder}}</mat-label>
                                    <input matInput [matDatepicker]="picker"
                                           name="{{field.name}}"
                                           formControlName="{{field.name}}"
                                           (dateInput)="onDateInputChanged($event, field)"
                                           [min]="field.min"
                                           [max]="field.max"
                                           readonly>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                    <mat-hint align="end" *ngIf="field.hint">
                                        {{field.hint}}
                                    </mat-hint>
                                    <mat-hint align="end" *ngIf="field.important">
                                        wichtiges Feld
                                    </mat-hint>
                                    <mat-error *ngIf="form.hasError(field.name)">
                                        <strong>{{form.getError(field.name)}}</strong>
                                    </mat-error>
                                </mat-form-field>

                                <!-- Password -->
                                <div *ngSwitchCase="'password'">
                                    <mat-form-field class="full-width pw-padding">
                                        <mat-label>{{field.placeholder}}</mat-label>
                                        <input matInput name="{{field.name}}"
                                               value="{{field.model.obj[field.model.key]}}"
                                               [required]="field.required ? field.required : false"
                                               class="form-control"
                                               formControlName="{{field.name}}"
                                               type="{{field.show_password ? 'text' : 'password'}}"
                                               [focus]="field.focus"
                                               (keydown)="onKeyDown($event, field)"
                                               (keyup)="onKeyUp($event, field)">
                                        <div *ngIf="field.show_password" (click)="togglePasswordVisiblity(field)"
                                             class="pwd_showhide_btn" title="{{'FORMS.BTN_HIDE_PASSWORD'| translate }}">
                                            <mat-icon>visibility_off</mat-icon>
                                        </div>
                                        <div *ngIf="!field.show_password" (click)="togglePasswordVisiblity(field)"
                                             class="pwd_showhide_btn" title="{{'FORMS.BTN_SHOW_PASSWORD'| translate }}">
                                            <mat-icon>visibility</mat-icon>
                                        </div>
                                        <mat-error *ngIf="form.hasError(field.name)">
                                            <strong>{{form.getError(field.name)}}</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div *ngSwitchCase="'password_strength'">
                                    <mat-form-field class="full-width pw-padding">
                                        <mat-label>{{field.placeholder}}</mat-label>
                                        <input matInput name="{{field.name}}"
                                               value="{{field.model.obj[field.model.key]}}"
                                               [required]="field.required ? field.required : false"
                                               class="form-control"
                                               formControlName="{{field.name}}"
                                               type="{{field.show_password ? 'text' : 'password'}}"
                                               [focus]="field.focus"
                                               (keydown)="onKeyDown($event, field)"
                                               (keyup)="onKeyUp($event, field)">
                                        <div *ngIf="field.show_password" (click)="togglePasswordVisiblity(field)"
                                             class="pwd_showhide_btn" title="{{'FORMS.BTN_HIDE_PASSWORD'| translate }}">
                                            <mat-icon>visibility_off</mat-icon>
                                        </div>
                                        <div *ngIf="!field.show_password" (click)="togglePasswordVisiblity(field)"
                                             class="pwd_showhide_btn" title="{{'FORMS.BTN_SHOW_PASSWORD'| translate }}">
                                            <mat-icon>visibility</mat-icon>
                                        </div>
                                        <mat-hint align="end" *ngIf="field.important">
                                            wichtiges Feld
                                        </mat-hint>
                                        <mat-error *ngIf="form.hasError(field.name)">
                                            <strong>{{form.getError(field.name)}}</strong>
                                        </mat-error>
                                    </mat-form-field>
                                    <app-password-strength-bar [passwordToCheck]="field.model.obj[field.model.key]"
                                                               (passwordStrengthChanged)="field.passwordStrengthChanged($event)"></app-password-strength-bar>

                                </div>

                                <!-- chip list -->
                                <div *ngSwitchCase="'chip'">
                                    <mat-form-field class="example-chip-list">
                                        <mat-chip-grid #chipList aria-label="''">
                                            <mat-chip-row *ngFor="let value of field.values"
                                                          [removable]="true" (removed)="removeChipValue(value, field)">
                                                {{value}}
                                                <mat-icon matChipRemove>cancel</mat-icon>
                                            </mat-chip-row>
                                            <input placeholder="{{field.placeholder}}"
                                                   [matChipInputFor]="chipList"
                                                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                   [matChipInputAddOnBlur]="true"
                                                   (matChipInputTokenEnd)="addChip($event, field)">
                                        </mat-chip-grid>
                                        <mat-hint align="end" *ngIf="field.important">
                                            wichtiges Feld
                                        </mat-hint>
                                        <mat-error *ngIf="form.hasError(field.name)">
                                            <strong>{{form.getError(field.name)}}</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <!-- ToDo: build datetime picker or reference here -->
                                <div *ngSwitchCase="'datetime'" style="width: 180px;">
                                    <mat-form-field class="full-width">
                                        <mat-label>{{field.placeholder}}</mat-label>
                                        <input matInput [ngxMatDatetimePicker]="picker"
                                               name="{{field.name}}"
                                               formControlName="{{field.name}}"
                                               (dateInput)="onDateInputChanged($event, field)"
                                               [min]="field.min"
                                               [max]="field.max"
                                               readonly>
                                        <ngx-mat-datepicker-toggle matSuffix [for]="picker"></ngx-mat-datepicker-toggle>
                                        <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="true"
                                                                 [enableMeridian]="false"
                                                                 [disableMinute]="false" [hideTime]="false">
                                        </ngx-mat-datetime-picker>
                                        <mat-hint align="end" *ngIf="field.hint">
                                            {{field.hint}}
                                        </mat-hint>
                                        <mat-hint align="end" *ngIf="field.important">
                                            wichtiges Feld
                                        </mat-hint>
                                        <mat-error *ngIf="form.hasError(field.name)">
                                            <strong>{{form.getError(field.name)}}</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <!-- ToDo: build autocomplete or reference here -->

                                <!-- Template -->
                                <div *ngSwitchCase="'template'">
                                    <ng-container *ngIf="!field.no_input">
                                        <ng-container
                                                *ngTemplateOutlet="field.template;context:{element: field, edit: edit_allowed}">
                                        </ng-container>
                                    </ng-container>
                                </div>

                                <div *ngSwitchCase="'editor'">
                                    <ngx-editor-menu
                                            [editor]="field.editor"
                                            [toolbar]="toolbar"
                                            [customMenuRef]="customMenu"
                                    ></ngx-editor-menu>
                                    <ngx-editor [editor]="field.editor"
                                                [formControlName]="field.name"
                                    ></ngx-editor>
                                    <ng-template #customMenu>
                                        <app-custom-menu [editor]="field.editor">
                                            <div class="NgxEditor__Seperator"></div>
                                            <div class="NgxEditor__MenuItem NgxEditor__MenuItem--Icon"
                                                 style="float: left"
                                                 (click)="openImagePicker($event, field)"
                                                 matTooltip="side media">
                                                <i class="fa fa-image"></i>
                                            </div>
                                            <div class="NgxEditor__MenuItem NgxEditor__MenuItem--Icon"
                                                 style="float: left"
                                                 (click)="addCheckbox($event, field)"
                                                 matTooltip="checkbox">
                                                <i class="fas fa-check-square"></i>
                                            </div>
                                            <div class="NgxEditor__MenuItem NgxEditor__MenuItem--Icon"
                                                 style="float: left"
                                                 (click)="openWikiLinkPicker($event, field)"
                                                 matTooltip="side wiki links">
                                                <i class="fab fa-wikipedia-w"></i>
                                            </div>
                                        </app-custom-menu>
                                    </ng-template>

                                    <mat-accordion>
                                        <mat-expansion-panel hideToggle>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    preview
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div class="ql-container ql-snow card padding" style="border-width: 0;">
                                                <app-sc-content [content]="field.model.obj[field.model.key]"></app-sc-content>
                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </div>

                                <div *ngSwitchCase="'button'">
                                    <button mat-raised-button [color]="field.color" type="button"
                                            [ngClass]="field.class" (click)="field.onClick($event, field.model.obj)">{{field.placeholder}}</button>
                                </div>


                                <!-- field description -->
                                <!--
                                <div *ngIf="field.description" class="description">
                                    {{field.description}}
                                </div>
                                -->
                            </div>
                            <!-- error display div -->
                            <!--                            <div *ngIf="form.hasError(field.name)" class="alert alert-danger">-->
                            <!--                                {{form.getError(field.name)}}-->
                            <!--                            </div>-->

                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="form-loading" *ngIf="loading">
            <mat-spinner></mat-spinner>
        </div>

        <!-- submit button -->
        <div *ngIf="!loading && edit_allowed && show_save_button">
            <button mat-raised-button [color]="button_color"
                    class="full-width"
                    [ngClass]="button_class"
                    [disabled]="!is_valid || isDirty"
                    type="submit">{{button_text}}</button>
        </div>
        <div *ngIf="!loading && edit_allowed && show_filter_button">
            <button mat-raised-button [color]="button_filter_color"
                    class="full-width"
                    [ngClass]="button_class"
                    [disabled]="!is_valid || isDirty"
                    (click)="sendFilterOnchangeByButton()">{{button_filter_text}}</button>
        </div>
    </form>
</div>
