import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {StoreService} from '../../../../../services/store.service';
import {AuthService} from '../../../../../services/auth.service';
import {NotificationService} from '../../../../../services/notification.service';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '../../../../../class/store';
import {Subscription} from 'rxjs';
import {IMqttMessage, MqttService} from 'ngx-mqtt';

@Component({
  selector: 'app-bot-event-deposit-tiles',
  templateUrl: './bot-event-deposit-tiles.component.html',
  styleUrls: ['./bot-event-deposit-tiles.component.css']
})
export class BotEventDepositTilesComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  public event_id = 0;
  public user_id = 0;
  public records: any[];
  private DepositStore: Store;

  private subs: Array<Subscription> = [];

  @Output() public dataLoaded: EventEmitter<any> = new EventEmitter();

  constructor(private storeService: StoreService,
              private authService: AuthService,
              private notificationService: NotificationService,
              private route: ActivatedRoute,
              private cdr: ChangeDetectorRef,
              public dialog: MatDialog,
              private _mqttService: MqttService) {
  }

  ngOnInit() {
    this.user_id = this.authService.getUserId();
  }

  ngAfterViewInit(): void {
      this.DepositStore = this.storeService.createNoneGlobalStore('event-ore-deposits',
          '/games/starcitizen/events/' + String(this.event_id) + '/deposit', 'deposit_id');
      this.DepositStore.loadStore('').then((records: any[]) => {
        this.records = records;
        if (this.dataLoaded) {
          this.dataLoaded.emit({totalRecords: this.records.length});
        }
      });

    this.subs.push(this._mqttService.observe('bot/event/' + String(this.event_id) + '/deposit').subscribe((message: IMqttMessage) => {
      // refresh table on notifications for user guilds events
      if (this.DepositStore.getStore()) {
        this.DepositStore.reloadStore().then((records: any[]) => {
          this.records = records;
          if (this.dataLoaded) {
            this.dataLoaded.emit({totalRecords: this.records.length});
          }
        });
      }
    }));
  }

    ngOnDestroy(): void {
      this.subs.forEach(s => s.unsubscribe());
    }
}
