<div class="message-embed card">
    <!--    <img class="avatar" src="user_avatar.jpg" alt="User Avatar">-->
    <span class="username">{{message.author.username}}</span>
    <div class="message-details">
        <div class="message-content" *ngIf="message.content_md && (message.embeds.length === 0 || message.embeds[0].type !== 'video'); else videoHeader">
            <markdown [data]="message.content_md"></markdown>
        </div>
        <!-- embed link -->
        <!--        <div *ngIf="embed.type === 'link'" class="embed-description-container">-->
        <!--            <div class="embed-link">-->
        <!--                <a [href]="message.embeds[0].url">{{message.embeds[0].title}}</a>-->
        <!--            </div>-->
        <!--        </div>-->
        <ng-container *ngIf="message.attachments && message.attachments.length > 0 ">
            <div class="attachment-grid">
                <mat-grid-list cols="2" rowHeight="2:1" gutterSize="2px">
                    <mat-grid-tile *ngFor="let attachment of message.attachments">
                        <div [ngSwitch]="attachment.content_type">

                            <div class="attachment-cell" *ngSwitchDefault>
                                <a [href]="message.embeds[0].url"
                                   *ngIf="message.embeds.length > 0 && message.embeds[0].type === 'link'">
                                    <img [src]="attachment.url" class="discord-attachment"/>
                                </a>
                                <img [src]="attachment.url"
                                     *ngIf="message.embeds.length === 0 || (message.embeds.length > 0 && message.embeds[0].type !== 'link')"
                                     class="discord-attachment"
                                />
                            </div>
                            <div class="attachment-cell" *ngSwitchCase="'video/mp4'">
                                <video playsinline="" height="309" controls
                                       preload="metadata" width="550"
                                       style="width: 100%; height: 100%; max-height: inherit; object-fit: cover;">
                                    <source [src]="attachment.url | safe: 'ResourceURL'" [type]="attachment.content_type">
                                </video>
                            </div>
                        </div>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
        </ng-container>
        <ng-container *ngIf="message.embeds && message.embeds.length > 0 ">
            <div *ngFor="let embed of message.embeds"
                 class="discord-embed"
                 [attr.style]="'border-left: 5px solid #' + embed.color + ';'">
                <!-- description -->
                <div *ngIf="embed.description_md || embed.icon" class="embed-description-container">
                    <div>
                        <markdown *ngIf="embed.description_md && embed.type !== 'video'" [data]="embed.description_md"></markdown>
                    </div>
                    <div *ngIf="embed.thumbnail && embed.type !== 'video'"><img [src]="embed.thumbnail.url" height="42px"></div>
                    <div *ngIf="embed.thumbnail && embed.type === 'video'">
                        <iframe class="embedIframe-1UpXja" [src]="embed.video.url | safe: 'ResourceURL'"
                                width="400" height="225"
                                frameborder="0"
                                scrolling="no"
                                allowfullscreen=""
                                sandbox="allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-presentation"
                                style="max-width: 400px; max-height: 225px;">
                        </iframe>
                    </div>
                </div>
                <!-- embed link -->
                <div *ngIf="embed.type === 'link'" class="embed-description-container">
                    <div class="embed-link">
                        <a [href]="message.embeds[0].url" class="text-link">{{message.embeds[0].title}}</a>
                    </div>
                </div>
                <ng-container *ngIf="embed.fields && embed.fields.length > 0 ">
                    <!-- Fields -->
                    <ng-container *ngFor="let field of embed.fields">
                        <div class="field" [ngClass]="{'field-inline': field.inline, 'field-block': !field.inline}">
                            <span class="field-name">
                                <ng-container [ngSwitch]="field.type_name">
                                    <div class="field-value" *ngSwitchDefault><markdown [data]="field.name"></markdown></div>
                                    <div class="field-value" *ngSwitchCase="'text'"><pre>{{field.name}}</pre></div>
                                    <div class="field-value" *ngSwitchCase="'code'"><pre><code [highlight]="field.name"></code></pre></div>
                                    <div class="field-value dc-block" *ngSwitchCase="'blocks'" [innerHTML]="field.name"></div>
                                    <div class="field-value" *ngSwitchCase="'date'">{{field.name | date:'mediumDate':'': 'de'}}</div>
                                    <div class="field-value" *ngSwitchCase="'time'">{{field.name | date:'mediumDate':'': 'de'}}</div>
                                </ng-container>
                            </span>
                            <ng-container [ngSwitch]="field.type_value">
                                <div class="field-value" *ngSwitchDefault><pre [innerHTML]="field.value"></pre></div>
                                <div class="field-value" *ngSwitchCase="'text'"><pre [innerHTML]="field.value"></pre></div>
                                <div class="field-value" *ngSwitchCase="'code'"><pre><code [highlight]="field.value"></code></pre></div>
                                <div class="field-value dc-block" *ngSwitchCase="'blocks'" [innerHTML]="field.value"></div>
                                <div class="field-value" *ngSwitchCase="'date'">{{field.value | date:'mediumDate':'': 'de'}}</div>
                                <div class="field-value" *ngSwitchCase="'time'">{{field.value | date:'shortTime':'': 'de'}}</div>
                            </ng-container>
                            <!--                            <div class="field-value"> <markdown emoji>{{field.value}}</markdown></div>-->
                            <!--                            <div [markdown]="discordMarkdown"></div>-->
                        </div>
                    </ng-container>
                </ng-container>
                <div *ngIf="embed.image" >
                    <img [src]="embed.image.url" class="embed-footer-image"/>
                </div>
            </div>

        </ng-container>
    </div>
</div>

<ng-template #videoHeader>
    <div *ngIf="message.embeds.length > 0 && message.embeds[0].type === 'video'">
        <div class="video-author">
            <a [href]="message.embeds[0].author.url">{{message.embeds[0].author.name}}</a>
        </div>
        <div class="video-title">
            <a [href]="message.embeds[0].video.url">{{message.embeds[0].title}}</a>
        </div>
    </div>
</ng-template>
