<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div class="padding" *ngIf="mode === 'add'">
        <app-form-inputs *ngIf="fields && fields.length"
                         #tpl_form
                         [field_groups]="fields"
                         [edit_allowed]="can_add"
                         [loading]="false"
                         (on_submit)="saveStore($event)"
                         [button_class]="'button-save'">
        </app-form-inputs>
    </div>
    <div class="padding" *ngIf="mode === 'edit'">
        <app-form-inputs *ngIf="fields && fields.length"
                         #tpl_form
                         [field_groups]="fields"
                         [edit_allowed]="can_edit"
                         [loading]="false"
                         (on_submit)="saveStore($event)"
                         [button_class]="'button-save'">
        </app-form-inputs>
    </div>
</ng-template>

<ng-template #upload_tpl let-element="element" let-column="column" let-edit="edit">

    <div *ngIf="element.model && element.model.obj.file_id > 0">
        <app-image-preview [file_id]="element.model.obj.file_id"></app-image-preview>
        <button mat-mini-fab class="button-delete" *ngIf="this.can_edit"
                style="margin-left: 16px;"
                (click)="deleteFile(element.model.obj.file_id)"
                matTooltip="löschen"
                type="button">
            <mat-icon aria-label="löschen">delete</mat-icon>
        </button>
    </div>

    <ng-container *ngIf="element.model && element.model.obj.rt_id > 0 &&
                         (element.model.obj.file_id === 0 || element.model.obj.file_id === null)
                         && edit">
        <app-file-upload2 [file_type]="'race_time'"
                          (on_success)="onFileUpload($event, 'race_time', element)"
                          [url]="url_file_store"
                          [show_save_button]="true"
                          [parent_object_id]="element.model.obj.rt_id"
                          [max_count]="1"
                          [reduce]="true"
                          [accept]="'.jpeg,.jpg,.png,image/*'"
                          (init)="onFileuploadInit($event)"
        ></app-file-upload2>
    </ng-container>
</ng-template>
