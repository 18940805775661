import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from '../services/auth.service';
import {AppSettings} from '../config/AppSettings';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {

    constructor(private authService: AuthService, private router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        return new Promise((resolve, reject) => {
            if (this.authService.getLoginState() === 2) {
                const me = this;
                const user = this.authService.getUser();
                if (user) {
                    if (user.guilds) {
                        if (me.authService.isAdmin()) {
                            // console.log('url is admin but ok');
                            resolve(true);
                        }
                        if (next.routeConfig.data.groups && next.routeConfig.data.groups.length > 0) {
                            for (let g = 0; g < next.routeConfig.data.groups.length; g++) {
                                const g_route = next.routeConfig.data.groups[g];
                                if (this.authService.hasRole(g_route.guild_id, g_route.role)) {
                                    resolve(true);
                                }
                            }
                            resolve(false);
                        }
                        resolve(true);
                    } else {
                        // console.log('url resolve false');
                        resolve(false);
                    }
                }
            } else {
                // console.log('url musst checklogin resolve false');
                this.router.navigateByUrl('/checklogin');
                resolve(false);
            }
        });
    }
}

