<div>
    <app-content-container [content_template]="page_content"
                           [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>

    <mat-tab-group mat-align-tabs="start" color="accent" LastActiveTabDirective="available-commodities">
        <mat-tab label="Commodities">
            <div class="padding">
                <div class="card card-1 padding table-wrapper" *ngIf="storeObj">
                    <app-fb-forms-table [storeObj]="storeObj"
                                        [edit_allowed]="is_admin"
                                        (row_click)="rowSelected($event)"
                                        [stateKey]="'available_commodities'"
                                        [show_add_in_view]="is_admin"
                                        [show_delete]="is_admin"
                                        [title]="'Available Commodities'"
                    >
                    </app-fb-forms-table>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Categories">
            <app-sc-commodity-category></app-sc-commodity-category>
        </mat-tab>
        <mat-tab label="Sets">
            <app-sc-commodity-sets></app-sc-commodity-sets>
        </mat-tab>
    </mat-tab-group>
</ng-template>