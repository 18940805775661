import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {FormInputsComponent} from '../../shared/form-inputs/form-inputs.component';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../services/notification.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

    @ViewChild('tpl_form') tpl_form: FormInputsComponent;
    public fields: any[];
    public register_obj = {
        username: '',
        firstname: '',
        lastname: '',
        password: '',
        password_repeat: ''
    };

    public password_strength = 0;

    constructor(private authService: AuthService,
                private router: Router,
                private cdr: ChangeDetectorRef,
                private notificationService: NotificationService) {
    }

    ngOnInit() {
        if (this.authService.getLoginState() === 2) {
            this.router.navigateByUrl('/main');
        } else {
            this.buildForm();
        }
    }

    public doRegister(form: any) {
        if (form.isValid()) {
            this.authService.login_state_changed.subscribe((state: number) => {
                if (state === 2) {
                    this.notificationService.success('Register',
                        'Register success.', {timeOut: 5000});
                    this.router.navigateByUrl('/main');
                }
            });
            this.authService.register(this.register_obj);
        }
    }


    private buildForm() {
        const me = this;
        this.fields = [
            {
                class: '',
                fields: [
                    {
                        model: {obj: this.register_obj, key: 'username'},
                        placeholder: 'Loginname',
                        type: 'text',
                        name: 'username',
                        rules: [
                            {rule: 'required'}
                        ],
                        hint: {
                            type: 'length'
                        },
                        max: 20
                    },
                    {
                        model: {obj: this.register_obj, key: 'firstname'},
                        placeholder: 'Vorname',
                        type: 'text',
                        name: 'firstname',
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        model: {obj: this.register_obj, key: 'lastname'},
                        placeholder: 'Nachname',
                        type: 'text',
                        name: 'lastname',
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        model: {obj: this.register_obj, key: 'password'},
                        placeholder: 'password',
                        type: 'password_strength',
                        name: 'password',
                        rules: [
                            {rule: 'required'},
                            {rule: 'minLength:4'}
                        ],
                        password_strength: this.password_strength,
                        passwordStrengthChanged: function ($event) {
                            me.password_strength = $event;
                            me.cdr.detectChanges();
                        }
                    },
                    {
                        model: {obj: this.register_obj, key: 'password_repeat'},
                        placeholder: 'Passwort wiederholen',
                        type: 'password',
                        name: 'password_repeat',
                        rules: [
                            {rule: 'required'},
                            {rule: 'equalTo:password'}
                        ],
                        on_other_field_changed: (self, changed_field, setFieldValueCallback, refreshFormField) => {
                            setFieldValueCallback(self, self.model.obj.password_repeat);
                        }
                    }
                ]
            }
        ];
    }
}
