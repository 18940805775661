<div>
    <app-content-container [content_template]="page_content"
                           [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <mat-tab-group mat-align-tabs="start" color="accent" LastActiveTabDirective="sc-commodity-inventory">
        <mat-tab label="Currencies">
            <div class="padding">
                <app-auec-info></app-auec-info>
            </div>
            <app-sc-commodity-inventory-type [type]="'currency'"></app-sc-commodity-inventory-type>
        </mat-tab>
        <mat-tab label="Items">
            <app-sc-commodity-inventory-type [type]="'item'"></app-sc-commodity-inventory-type>
        </mat-tab>
        <mat-tab label="Cargo">
            <app-sc-commodity-inventory-type [type]="'cargo'"></app-sc-commodity-inventory-type>
        </mat-tab>
        <mat-tab label="Ore">
            <app-sc-commodity-inventory-type [type]="'ore'"></app-sc-commodity-inventory-type>
        </mat-tab>
    </mat-tab-group>
</ng-template>