import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {ScContractRewardForm} from '../../../forms/scContractRewardForm';
import {StoreService} from '../../../services/store.service';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-sc-contract-rewards',
  templateUrl: './sc-contract-rewards.component.html',
  styleUrls: ['./sc-contract-rewards.component.css']
})
export class ScContractRewardsComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  public c_id = 0;

  @Input()
  public is_owner: boolean;

  @Input()
  public contract_type: string;

  public storeObject: ScContractRewardForm;
  private subs: Array<Subscription> = [];

  public loading_text = '';
  public is_loading = false;
  public rows: any[];

  public column_definition: any[];


  constructor(private storeService: StoreService,
              private authService: AuthService,
              private cdr: ChangeDetectorRef,
              public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.storeObject = new ScContractRewardForm(this.storeService, this.authService, 'edit');
    this.cdr.detectChanges();
  }

  public rowSelected($event: any) {
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
