import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SearchService {

    private search_value: string;
    public search_changed: BehaviorSubject<any>;

    constructor() {
        this.search_changed = new BehaviorSubject({
            context: '',
            search_value: ''
        });
    }

    public getSearchValue() {
        return this.search_value;
    }

    public setSearchValue(context: string, search_value: string) {
        this.search_value = search_value;
        this.search_changed.next({
            context: context,
            search_value: this.search_value
        });
        this.saveSearchValue(context);
    }

    private getSavedSearchValue(context: string): string {
        const search_value = localStorage.getItem('search_value_' + context);
        if (search_value) {
            return search_value;
        }
        return '';
    }
    private saveSearchValue(context: string) {
        localStorage.setItem('search_value_' + context, this.search_value);
    }
}
