import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {StoreService} from '../../../services/store.service';
import {AuthService} from '../../../services/auth.service';
import {NotificationService} from '../../../services/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import {AppSettings} from '../../../config/AppSettings';
import {ScContractForm} from '../../../forms/scContractForm';

@Component({
  selector: 'app-sc-contract-edit',
  templateUrl: './sc-contract-edit.component.html',
  styleUrls: ['./sc-contract-edit.component.css']
})
export class ScContractEditComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
    this._select_caption_tpl = value;
    this.cdr.markForCheck();
  }

  public loading_text = '';
  public is_loading = true;
  public fields: any[];
  public mode = 'edit';
  public edit = false;
  public rows = [];
  public count = 0;

  private subs: Array<Subscription> = [];
  public record: any;
  public id: string|number = '';

  public is_admin: boolean;
  public is_owner: boolean;
  public reward_loading = false;

  private ScOrder: ScContractForm;

  private _select_caption_tpl: ElementRef;

  constructor(private storeService: StoreService,
              private authService: AuthService,
              private notificationService: NotificationService,
              private route: ActivatedRoute,
              public router: Router,
              private cdr: ChangeDetectorRef,
              public dialog: MatDialog,
              public http: HttpClient) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {

    this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin)
        || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_contract_admin);

    this.subs.push(this.route.params.subscribe((params: any) => {
      this.edit = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin)
          || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_contract_admin);

      if (params.id && params.id !== 'add') {
        this.mode = 'edit';
        this.id = params.id;
      } else {
        this.mode = 'add';
        this.is_owner = true;
        // this.edit = true;
      }

      const filter = [];
      filter.push('filter[c_id][0][mode]=equals');
      filter.push('filter[c_id][0][operator]=and');
      filter.push('filter[c_id][0][value]=' + this.id);
      filter.push('first=0');
      filter.push('rows=1');

      this.ScOrder = new ScContractForm(this.storeService, this.authService, this.mode);
      this.ScOrder.init({select_caption_tpl: this._select_caption_tpl}, {is_private: !this.is_admin, http: this.http,
            button_callback: (c_id: string) => {
              this.notificationService.success('Data',
                  'Speichern erfolgreich.', {timeOut: 5000});
              this.router.navigateByUrl('/sc-contracts/' + c_id);
            }},
          '?' + filter.join('&')).then((success) => {
        this.fields = this.ScOrder.getEditFields();
        if (this.id && this.id !== '' && this.mode === 'edit') {
          const rec = this.ScOrder.getStore().getRecordById(this.id);
          if (rec) {
            this.record = rec;
            this.is_owner = this.is_admin || String(this.record.data.client) === String(this.authService.getUserId());
          }
        } else {
          this.resetModel();
        }
        this.rebuildForm();
        this.is_loading = false;
      });

    }));
  }

  public rebuildForm() {
    this.fields = [];
    this.cdr.detectChanges();
    this.fields = this.ScOrder.getForm(this.record.data);
    this.cdr.detectChanges();
  }

  private resetModel() {
    this.record = this.ScOrder.getDefaultModel();
  }

  public saveStore(event: any) {


    if (this.mode === 'edit') {
      this.ScOrder.getStore().patchRecord(this.record, '').then((res) => {
        if (res && res.success) {
          this.notificationService.success('Data',
              'Speichern erfolgreich.', {timeOut: 5000});
          this.rebuildForm();
          this.afterSaved();
        } else {
          this.notificationService.error('Data',
              'Speichern fehlgeschlagen.', {timeOut: 5000});
        }
      });
    } else {
      this.ScOrder.getStore().addRecord(this.record.data, '').then((res) => {
        if (res && res.success) {
          this.notificationService.success('Data',
              'Speichern erfolgreich.', {timeOut: 5000});
          this.afterSaved();
          this.router.navigateByUrl('/sc-contracts/' + res.record.data.c_id);
        } else {
          this.notificationService.error('Data',
              'Speichern fehlgeschlagen.', {timeOut: 5000});
        }
      });
    }
  }


  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
    if (this.mode === 'edit') {
      this.ScOrder.getStore().revertRecord(this.record);
    }
  }

  afterSaved() {
    this.is_loading = true;
    this.cdr.detectChanges();
    setTimeout(() => {
      this.is_loading = false;
      this.cdr.detectChanges();
    }, 200);
  }
}
