import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private messageService: MessageService) { }


  public success(title, body, timeout) {
    this.messageService.add({ key: 'br', severity: 'success', summary: title, detail: body });
  }

  public error(title, body, timeout) {
    this.messageService.add({ key: 'br', severity: 'error', summary: title, detail: body });
  }
  public warn(title, body, timeout) {
    this.messageService.add({ key: 'br', severity: 'warn', summary: title, detail: body });
  }

  public info(title, body, timeout) {
    this.messageService.add({ key: 'br', severity: 'info', summary: title, detail: body });
  }
}
