import {FbFormObject} from '../class/fb_form_object';

export class ScUser extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'sc_username', label: 'SC Username', visible: true, noclick: false, disable_ripple: true}
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Profile',
                class: 'card card-1 padding single-column',
                field_class: '',
                fields: [
                    {
                        type: 'text',
                        name: 'sc_username',
                        rules: [
                            {rule: 'required'}
                        ]
                    }
                ]
            }
        ];
        super.setEditFields();
    }

    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.stores['my_sc_user'] = this.storeService.getStore('my_sc_user');

            this.main_store = this.stores['my_sc_user'];

            const stores = [];
            stores.push(this.stores['my_sc_user'].loadStore(this.filter));

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
                sc_username: ''
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        // call super
        super.setSelectboxes();
    }

}