import {AuthService} from '../services/auth.service';
import {AppSettings} from '../config/AppSettings';

export class MenuEntries {
    public is_admin = false;
    public is_logged_in = false;
    public is_ifa = false;

    constructor(private authService: AuthService) {
        this.is_admin = this.authService.isAdmin();
        this.is_logged_in = this.authService.getLoginState() === 2;
        this.is_ifa = this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_ifa);
    }

    public getEntries (active_route = '', show_on_side = true, show_on_main = true) {

        const menu = [
            {
                group_id: 2, name: 'Portal', entries: [
                    // {
                    //     name: 'News',
                    //     type: 'internal',
                    //     route: '/news',
                    //     icon: 'far fa-newspaper', side: true, main: true,
                    //     open_new: false,
                    //     must_logged_in: false,
                    //     must_ifa: false,
                    //     must_admin: false
                    // },
                    {
                        name: 'Bot-Events',
                        type: 'internal',
                        route: '/bot/events',
                        icon: 'fab fas fa-calendar', side: true, main: true,
                        open_new: false,
                        must_logged_in: true,
                        must_ifa: false,
                        must_admin: false
                    },
                    {
                        name: 'Rally',
                        type: 'internal',
                        route: '/rally',
                        icon: 'fas fa-space-shuttle', side: true, main: true,
                        open_new: false,
                        must_logged_in: true,
                        must_ifa: false,
                        must_admin: false
                    },
                    {
                        name: 'Guides',
                        type: 'internal',
                        route: '/guides',
                        icon: 'far fa-chalkboard-teacher', side: true, main: true,
                        open_new: false,
                        must_logged_in: false,
                        must_ifa: false,
                        must_admin: false
                    },
                    {
                        name: 'Members',
                        type: 'internal',
                        route: '/members',
                        icon: 'fab fas fa-users', side: true, main: true,
                        open_new: false,
                        must_logged_in: false,
                        must_ifa: false,
                        must_admin: false
                    },
                    {
                        name: 'Admin',
                        type: 'internal',
                        route: '/admin',
                        icon: 'fab fas fa-users-cog', side: true, main: true,
                        open_new: false,
                        must_logged_in: true,
                        must_ifa: false,
                        must_admin: true
                    }
                ],
                description: '',
                is_link: false, link: ''
            },
            {
                group_id: 2, name: 'Benutzer', entries: [
                    {
                        name: 'Abmelden',
                        type: 'internal',
                        route: '/logout',
                        icon: 'fab fa-sign-out-alt', side: true, main: true,
                        open_new: false,
                        must_logged_in: true,
                        must_ifa: false,
                        must_admin: false
                    }
                ],
                description: '',
                is_link: false, link: ''
            },
            {
                group_id: 5, name: 'Public', entries: [
                    {
                        name: 'About Bot',
                        type: 'internal',
                        route: '/bot/about',
                        icon: 'fas fa-robot', side: true, main: true,
                        open_new: false,
                        must_logged_in: false,
                        must_ifa: false,
                        must_admin: false
                    },
                    {
                        name: 'Privacy Police',
                        type: 'internal',
                        route: '/privacy-police',
                        icon: 'fas fa-file-contract', side: true, main: true,
                        open_new: false,
                        must_logged_in: false,
                        must_ifa: false,
                        must_admin: false
                    },
                    {
                        name: 'Impressum',
                        type: 'internal',
                        route: '/impressum',
                        icon: 'fas fa-address-card', side: true, main: true,
                        open_new: false,
                        must_logged_in: false,
                        must_ifa: false,
                        must_admin: false
                    }
                ],
                description: '',
                is_link: false, link: ''
            }
        ];

        const real_menu = [];
        for (let i = 0; i < menu.length; i++) {
            const group = menu[i];
            if (group.entries && group.entries.length) {
                const entries = group.entries.filter((entry) => {
                    return entry.route !== active_route && (show_on_side === entry.side || show_on_main === entry.main)
                        && (entry.must_logged_in === this.is_logged_in || entry.must_logged_in === false)
                        && (entry.must_ifa === this.is_ifa || entry.must_ifa === false)
                        && (entry.must_admin === this.is_admin || entry.must_admin === false);
                });
                group.entries = entries;
                real_menu.push(group);
            } else {
                real_menu.push(group);
            }
        }

        return real_menu;
    }
}
