import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FbFormObject} from '../../../../../../class/fb_form_object';
import {Subscription} from 'rxjs';
import {StoreService} from '../../../../../../services/store.service';
import {AuthService} from '../../../../../../services/auth.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../../../../../services/notification.service';
import {MatDialog} from '@angular/material/dialog';
import {AppSettings} from '../../../../../../config/AppSettings';
import {IfaMemberBadge} from '../../../../../../forms/ifa-member-badge';

@Component({
  selector: 'app-ifa-member-badges',
  templateUrl: './ifa-member-badges.component.html',
  styleUrls: ['./ifa-member-badges.component.css']
})
export class IfaMemberBadgesComponent implements OnInit, OnDestroy, AfterViewInit {


  @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
    this._select_caption_tpl = value;
    // this.setColumns();
    this.cdr.markForCheck();
  }

  @Input()
  public user_id = 0;

  public storeObj: FbFormObject;
  public storeExtObj = {};
  private subs: Array<Subscription> = [];

  public loading_text = '';
  public is_loading = true;
  public rows: any[];


  private _select_caption_tpl: ElementRef;

  public is_admin: boolean;

  constructor(private storeService: StoreService,
              private authService: AuthService,
              private router: Router,
              private cdr: ChangeDetectorRef,
              private notificationService: NotificationService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin);

    this.storeObj = new IfaMemberBadge(this.storeService, this.authService, 'edit');
    this.storeExtObj['user_id'] = this.user_id;
    this.is_loading = false;
    this.cdr.detectChanges();
  }

  public rowSelected($event: any) {
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
