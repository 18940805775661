import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {StoreService} from '../../services/store.service';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {AppSettings} from '../../config/AppSettings';
import {FbFormObject} from '../../class/fb_form_object';
import {Store} from '../../class/store';
import {debounceTime, map, startWith} from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipEditedEvent, MatChipInputEvent} from '@angular/material/chips';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';


export interface Fruit {
    name: string;
}

@Component({
    selector: 'app-points-of-interest',
    templateUrl: './points-of-interest.component.html',
    styleUrls: ['./points-of-interest.component.css']
})
export class PointsOfInterestComponent implements OnInit, OnDestroy, AfterViewInit {


    @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
        this._select_caption_tpl = value;
        // this.setColumns();
        this.cdr.markForCheck();
    }

    public storeObject: FbFormObject;
    private subs: Array<Subscription> = [];

    public loading_text = '';
    public is_loading = false;
    public rows: any[];


    private _select_caption_tpl: ElementRef;

    public column_definition: any[];
    public is_admin: boolean;
    public is_ifa = false;

    public first = 0;

    public totalRecords = 10;
    public pageSize = 10;
    public store: Store;
    public media_path: string = AppSettings.getRealAPILink();
    public searchText = '';
    public searchTags = [];

    tags = new FormControl('');
    tagsList: string[] = [];

    private selectFilter = [];
    private searchSubject = new Subject<string>();
    private tagSubject = new Subject<any[]>();

    constructor(private storeService: StoreService,
                private authService: AuthService,
                private router: Router,
                private cdr: ChangeDetectorRef,
                public dialog: MatDialog) {
    }

    ngOnInit() {
        this.searchSubject.pipe(debounceTime(500)).subscribe((searchValue) => {
            this.doSearch();
        });
        this.tagSubject.pipe(debounceTime(500)).subscribe((searchValue) => {
            this.doSearch();
        });
    }

    ngAfterViewInit(): void {
        this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin)
            || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_event_admin);
        this.is_ifa = this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_ifa);
        // this.storeObject = new ExplorePoi(this.storeService, this.authService, 'edit');
        this.store = this.storeService.getStore('explorer_poi');

        const tagFilter = [];
        tagFilter.push('filter[type][0][mode]=equals');
        tagFilter.push('filter[type][0][operator]=and');
        tagFilter.push('filter[type][0][value]=explore_poi');
        tagFilter.push('first=0');
        tagFilter.push('rows=100');

        const tagStore = this.storeService.createNoneGlobalStore('poi_tags',
            'tags', 'tag');
        tagStore.loadStore('?' + tagFilter.join('&')).then((records: any[]) => {
            const tags = [];
            for (let i = 0; i < records.length; i++) {
                tags.push(records[i].data.tag);
            }
            this.tagsList = tags;
        });

        this.selectFilter = [];
        this.loadPage();
        this.cdr.detectChanges();
    }

    private doSearch() {
        // this.performSearch(searchValue);
        this.selectFilter = [];
        this.selectFilter.push('first=0');
        this.selectFilter.push('rows=' + this.pageSize);
        if (this.searchText && this.searchText !== '') {
            this.selectFilter.push('filter[title][0][mode]=contains');
            this.selectFilter.push('filter[title][0][operator]=and');
            this.selectFilter.push('filter[title][0][value]=' + this.searchText);

        }

        if (this.searchTags && this.searchTags.length) {
            for (let i = 0; i < this.searchTags.length; i++) {
                this.selectFilter.push('filter[tag][0][mode]=contains');
                this.selectFilter.push('filter[tag][0][operator]=and');
                this.selectFilter.push('filter[tag][0][value]=' + this.searchTags[i]);
            }
        }
        this.loadPage();
    }

    public addClicked() {
        this.router.navigateByUrl('/points-of-interest/add');
    }

    public rowSelected($event: any) {
        const route = 'points-of-interest/' + $event.row.poi_id;
        if ($event.in_new_tab) {
            const url = this.router.createUrlTree([route]);
            const serializedUrl = this.router.serializeUrl(url);

            // Insert `#` between the domain and route
            const modifiedUrl = '#/' + serializedUrl;
            window.open(modifiedUrl, '_blank');
        } else {
            this.router.navigateByUrl(route);
        }
    }

    onPageChange(event: any) {
        this.first = event.first;
        this.pageSize = event.rows;
        this.selectFilter = [];
        this.loadPage();
    }

    private loadPage() {
        this.is_loading = true;
        this.selectFilter.push('first=' + this.first);
        this.selectFilter.push('rows=' + this.pageSize);
        this.store.loadStore('?' + this.selectFilter.join('&')).then((records: any[]) => {
            this.totalRecords = this.store.totalRecords;
            this.rows = records;
            this.is_loading = false;
        });
    }

    onSearch() {
        this.searchSubject.next(this.searchText);
    }

    onTagChanged($event: any) {
        this.searchTags = $event.value;
        this.tagSubject.next(this.searchTags);
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
        this.searchSubject.complete();
    }
}
