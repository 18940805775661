import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {StoreService} from '../../../services/store.service';
import {AuthService} from '../../../services/auth.service';
import {NotificationService} from '../../../services/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {UserStore} from '../../../forms/user';
import {ConfirmComponent} from '../../../shared/dialoge/confirm/confirm.component';

@Component({
    selector: 'app-edit-team-user',
    templateUrl: './edit-user.component.html',
    styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
        this._select_caption_tpl = value;
        this.cdr.markForCheck();
    }

    public loading_text = '';
    public is_loading = true;
    public fields: any[];
    public mode = 'edit';
    public edit = false;
    public rows = [];
    public count = 0;

    private subs: Array<Subscription> = [];
    private record: any;
    private id: string|number = '';
    private UserStore: UserStore;

    private _select_caption_tpl: ElementRef;

    constructor(private storeService: StoreService,
                private authService: AuthService,
                private notificationService: NotificationService,
                private route: ActivatedRoute,
                private router: Router,
                private cdr: ChangeDetectorRef,
                public dialog: MatDialog) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.subs.push(this.route.params.subscribe((params: any) => {
            this.edit = this.authService.isAdmin();

            if (params.id && params.id !== 'add') {
                this.mode = 'edit';
                this.id = params.id;
            } else {
                this.mode = 'add';
                // this.edit = true;
            }

            const filter = [];
            filter.push('filter[user_id][0][mode]=equals');
            filter.push('filter[user_id][0][operator]=and');
            filter.push('filter[user_id][0][value]=' + this.id);
            filter.push('first=0');
            filter.push('rows=1');

            this.UserStore = new UserStore(this.storeService, this.authService, this.mode);
            this.UserStore.init({select_caption_tpl: this._select_caption_tpl}, {},
                '?' + filter.join('&')
                ).then((success) => {
                this.fields = this.UserStore.getEditFields();
                if (this.id && this.id !== '' && this.mode === 'edit') {
                    const rec = this.UserStore.getStore().getRecordById(this.id);
                    if (rec) {
                        this.record = rec;
                    }
                } else {
                    this.resetModel();
                }
                this.rebuildForm();
                this.is_loading = false;
            });

        }));
    }

    public rebuildForm() {
        this.fields = [];
        this.cdr.detectChanges();
        this.fields = this.UserStore.getForm(this.record.data);
        this.cdr.detectChanges();
    }

    private resetModel() {
        this.record = this.UserStore.getDefaultModel();
    }

    public saveStore(event: any) {


        if (this.mode === 'edit') {
            this.UserStore.getStore().patchRecord(this.record, '').then((res) => {
                if (res && res.success) {
                    this.notificationService.success('Data',
                        'Speichern erfolgreich.', {timeOut: 5000});
                    this.rebuildForm();
                } else {
                    this.notificationService.error('Data',
                        'Speichern fehlgeschlagen.', {timeOut: 5000});
                }
            });
        } else {
            this.UserStore.getStore().addRecord(this.record.data, '').then((res) => {
                if (res && res.success) {
                    this.notificationService.success('Data',
                        'Speichern erfolgreich.', {timeOut: 5000});
                    this.resetModel();
                    this.rebuildForm();
                } else {
                    this.notificationService.error('Data',
                        'Speichern fehlgeschlagen.', {timeOut: 5000});
                }
            });
        }
    }

    public deleteUser() {
        const dialogRef = this.dialog.open(ConfirmComponent, {
            width: '280px',
            height: '300px',
            data: {title: 'Löschen bestätigen', text: 'Benutzer wirklich löschen?'}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const rec = this.UserStore.getStore().getRecordById(Number(this.id));
                this.UserStore.getStore().deleteRecord(rec).then((res) => {
                    if (res && res.success) {
                        this.notificationService.success('Benutzer',
                            rec.firstname + ' ' + rec.lastname + ' wurde erfolgreich gelöscht', {timeOut: 5000});
                        this.router.navigateByUrl('/user');
                    } else {
                        this.notificationService.error('Benutzer',
                            rec.firstname + ' ' + rec.lastname + ' konnte nicht gelöscht werden', {timeOut: 5000});
                    }
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
        if (this.mode === 'edit') {
            this.UserStore.getStore().revertRecord(this.record);
        }
    }
}
