<div>
    <app-content-container [content_template]="page_content" [is_loading]="false" [loading_text]="''"
                           [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div class="padding">
        <markdown class="variable-binding" [data]="markdown"></markdown>
        <div *ngIf="records" style="text-align: left">
            <ng-container *ngFor="let event of records">
                <app-discord-message [message]="event.data.message"></app-discord-message>
<!--                <div>-->
<!--                    {{event.data.name}} at {{event.data.scheduled_start_time | date:'short':'': 'en'}}-->
<!--                </div>-->
            </ng-container>
        </div>
    </div>
</ng-template>
