import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {FbFormObject} from '../../../class/fb_form_object';
import {AppSettings} from '../../../config/AppSettings';
import {FileUpload2Component} from '../../../shared/file-upload2/file-upload2.component';
import {StoreService} from '../../../services/store.service';
import {AuthService} from '../../../services/auth.service';
import {NotificationService} from '../../../services/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import {ViewportScroller} from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';
import {RaceTrackType} from '../../../forms/race-track-type';
import {FbUtils} from '../../../class/fb-utils';
import {map} from 'rxjs/operators';
import {RallySeries} from '../../../forms/rally-series';

@Component({
    selector: 'app-series',
    templateUrl: './series.component.html',
    styleUrls: ['./series.component.css']
})
export class SeriesComponent implements OnInit {

    public loading_text = '';
    public is_loading = false;
    public menu = [];
    public is_admin = false;
    public is_logged_in = false;
    public is_ifa = false;
    public records: any[] = [];
    public media_path: string = AppSettings.getRealAPILink();
    constructor(private authService: AuthService, private storeService: StoreService, public router: Router) {
        this.is_logged_in = this.authService.getLoginState() === 2;
        this.is_ifa = this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_ifa);
    }

    ngOnInit() {
        this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin)
            || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_rally_admin);

        const seriesStore = this.storeService.getStore('rally_series');
        seriesStore.unloadStore();
        seriesStore.loadStore('').then((records) => {
            this.records = records;
        });
    }

    openAddSeries() {
        this.router.navigateByUrl('/rally/series/add');
    }
}
