import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {StoreService} from '../../services/store.service';
import {Router} from '@angular/router';
import {AppSettings} from '../../config/AppSettings';
import SceneController from '../../class/SceneController';


@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit, AfterViewInit {

    public loading_text = '';
    public is_loading = false;
    public menu = [];
    public is_admin = false;
    public is_contract_admin = false;
    public is_logged_in = false;
    public is_ifa = false;

    constructor(private authService: AuthService, private storeService: StoreService, public router: Router) {
        this.is_logged_in = this.authService.getLoginState() === 2;
    }

    ngOnInit() {
        this.is_ifa = this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_ifa);
        this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin);
        this.is_contract_admin = this.is_admin || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_contract_admin);
    }



    ngAfterViewInit(): void {
        // this.initThree();

        // const sceneController = new SceneController('viewer_container');
    }
}
