import {FbFormObject} from '../class/fb_form_object';

export class MiningLaser extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'laser', label: 'laser', visible: true, noclick: false, disable_ripple: true},
            {id: 'size', label: 'size', visible: true, noclick: false, disable_ripple: true},
            {id: 'price', label: 'price', visible: true, noclick: false, disable_ripple: true},
            {id: 'slots', label: 'slots', visible: true, noclick: false, disable_ripple: true},
            {id: 'optimum_range', label: 'optimum_range', visible: true, noclick: false, disable_ripple: true},
            {id: 'max_range', label: 'max_range', visible: true, noclick: false, disable_ripple: true},
            {id: 'min_power_percent', label: 'min_power_percent', visible: true, noclick: false, disable_ripple: true},
            {id: 'min_power', label: 'min_power', visible: true, noclick: false, disable_ripple: true},
            {id: 'max_power', label: 'max_power', visible: true, noclick: false, disable_ripple: true},
            {id: 'extract_power', label: 'extract_power', visible: true, noclick: false, disable_ripple: true},
            {id: 'resistance', label: 'resistance', visible: true, noclick: false, disable_ripple: true},
            {id: 'instability', label: 'instability', visible: true, noclick: false, disable_ripple: true},
            {id: 'optimal_charge_rate', label: 'optimal_charge_rate', visible: true, noclick: false, disable_ripple: true},
            {id: 'optimal_charge_window', label: 'optimal_charge_window', visible: true, noclick: false, disable_ripple: true},
            {id: 'inert_materials', label: 'inert_materials', visible: true, noclick: false, disable_ripple: true}
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Laser',
                class: 'card card-1 padding single-column',
                field_class: '',
                fields: [
                    {
                        type: 'text',
                        name: 'laser',
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'number',
                        name: 'size',
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'number',
                        name: 'price',
                    },
                    {
                        type: 'number',
                        name: 'slots',
                    },
                    {
                        type: 'number',
                        name: 'optimum_range',
                    },
                    {
                        type: 'number',
                        name: 'max_range',
                    },
                    {
                        type: 'number',
                        name: 'min_power_percent',
                    },
                    {
                        type: 'number',
                        name: 'min_power',
                    },
                    {
                        type: 'number',
                        name: 'max_power',
                    },
                    {
                        type: 'number',
                        name: 'extract_power',
                    },
                    {
                        type: 'number',
                        name: 'resistance',
                    },
                    {
                        type: 'number',
                        name: 'instability',
                    },
                    {
                        type: 'number',
                        name: 'optimal_charge_rate',
                    },
                    {
                        type: 'number',
                        name: 'optimal_charge_window',
                    },
                    {
                        type: 'number',
                        name: 'inert_materials',
                    }
                ]
            }
        ];
        super.setEditFields();
    }

    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.stores['mining_laser'] = this.storeService.getStore('mining_laser');

            this.main_store = this.stores['mining_laser'];

            const stores = [];
            stores.push(this.stores['mining_laser'].loadStore(this.filter));

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
                laser: '',
                size: 1,
                price: null,
                slots: null,
                optimum_range: null,
                max_range: null,
                min_power_percent: null,
                min_power: null,
                max_power: null,
                extract_power: null,
                resistance: null,
                instability: null,
                optimal_charge_rate: null,
                optimal_charge_window: null,
                inert_materials: null
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        // call super
        super.setSelectboxes();
    }
}
