<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading" [loading_text]="loading_text"
                           [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div *ngIf="is_loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <main class="tiles padding">
        <a routerLink="/admin/ifa/member" class="link-clear" *ngIf="is_admin">
            <app-icon-tile title="Member" icon="fas fa-user-cog"></app-icon-tile>
        </a>
        <a routerLink="/admin/ifa/badges" class="link-clear" *ngIf="is_admin">
            <app-icon-tile title="Badges" icon="fas fa-user-cog"></app-icon-tile>
        </a>
    </main>

</ng-template>

