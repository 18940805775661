<div *ngIf="component_group" class="component-info-tile card card-1 padding" style="float: left; margin-left: 16px;">
    <h3>{{convertToTitleCase(component_group.key)}}</h3>
    <div>
        <ng-container *ngFor="let comp of component_group.value | keyvalue">
            <h4>{{convertToTitleCase(comp.key)}}</h4>
            <ng-container *ngIf="comp['value']">
                <ng-container cols="1" rowHeight="56px" *ngFor="let key of comp['value'] | keyvalue">
                    <mat-grid-list cols="1" rowHeight="56px" *ngFor="let size of key.value | keyvalue">
                        <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                            <div class="total-value" style="display: block; width: 100%;"
                                 *ngIf="size.value[0].categoryLabel && size.value[0].component">
                                <div style="float: left;">
                                    {{size.value.length}} x Size {{size.value[0].sizeLabel}} <b>{{size.value[0].categoryLabel}}</b>
                                </div>
                                <div *ngIf="size.value[0].component.manufacturer">
                                    <div  class="manufacturer-label">{{size.value[0].component.manufacturer.name}}</div>
                                </div>
                            </div>
                            <div class="total-value" style="display: block; width: 100%;"
                                 *ngIf="!size.value[0].categoryLabel && size.value[0].component">
                                <div style="float: left;">
                                    {{size.value.length}} x Size {{size.value[0].sizeLabel}} <b>{{size.value[0].component.name}}</b>
                                </div>
                                <div  class="manufacturer-label">{{size.value[0].component.manufacturer.name}}</div>
                            </div>
                            <div class="total-value" style="display: block; width: 100%;"
                                 *ngIf="size.value[0].categoryLabel && !size.value[0].component">
                                <div style="float: left;">
                                    {{size.value.length}} x Size {{size.value[0].sizeLabel}} <b>{{size.value[0].categoryLabel}}</b>
                                </div>
                            </div>
                            <div class="total-value" style="display: block; width: 100%;"
                                 *ngIf="!size.value[0].categoryLabel && !size.value[0].component">
                                <div style="float: left;">
                                    {{size.value.length}} x Size {{size.value[0].sizeLabel}}
                                </div>
                            </div>
                        </mat-grid-tile>
                        <ng-container *ngIf="size.value[0].loadouts && size.value[0].loadouts.length > 0">
                            <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                <div class="total-value slot-sub-level" style="display: block; width: 100%;"
                                     *ngIf="size.value[0].loadouts[0] && size.value[0].loadouts[0].loadoutIdentifier">
<!--                                    {{loadout.component.size}} <b>{{loadout.component.name}}</b>-->
                                    <div style="float: left;">
                                        {{size.value[0].loadouts.length}} x Size {{size.value[0].loadouts[0].size}}
                                        <b>{{size.value[0].loadouts[0].loadoutIdentifier}}</b>
                                    </div>
                                    <div class="manufacturer-label" *ngIf="size.value[0].loadouts[0].component">
                                        {{size.value[0].loadouts[0].component.manufacturer.name}}
                                    </div>
                                </div>
                            </mat-grid-tile>
                        </ng-container>
                    </mat-grid-list>
                    <mat-divider></mat-divider>
                </ng-container>
            </ng-container>
        </ng-container>

    </div>
</div>
