<div>
    <app-content-container [content_template]="page_content"
                           [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div class="padding">
        <div class="card card-1 padding table-wrapper" *ngIf="ScVersion">
            <app-fb-forms-table [storeObj]="ScVersion"
                                [edit_allowed]="true"
                                (row_click)="rowSelected($event)"
                                (add_click)="addClicked()"
                                [stateKey]="'sc-versions'"
                                [show_add]="true"
                                [show_delete]="true"
                                [storeExtObj]="storeExtObj"
                                [title]="'Version'"
            >
            </app-fb-forms-table>
        </div>
    </div>
</ng-template>
<ng-template #select_caption_tpl let-element="element" let-column="column">
    {{element.tmp.caption[column.id]}}
</ng-template>
