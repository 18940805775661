<div>
    <app-content-container [content_template]="page_content"
                           [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div class="padding">
        <div class="card card-1 padding table-wrapper" *ngIf="ScbMembersPointsSummary">
            <app-fb-forms-table [storeObj]="ScbMembersPointsSummary"
                                [edit_allowed]="false"
                                (row_click)="rowSelected($event)"
                                [stateKey]="'members-points-summary'"
                                [show_add]="false"
                                [show_delete]="false"
                                [title]="'Member Points Summary'"
            >
            </app-fb-forms-table>
        </div>
    </div>
</ng-template>
<ng-template #select_caption_tpl let-element="element" let-column="column">
    {{element.tmp.caption[column.id]}}
</ng-template>
