import {DynamicFormObject} from './dynamic_form_object';

export class FbFormObject extends DynamicFormObject {

    /**
     * default function to delete marked records
     **/
    public deleteRecords($event: any) {

        const index_field = this.getStore()['index_field'];
        return new Promise<any>((resolve, reject) => {
            // create promise array
            const save_array = [];
            const selection = $event._selected;
            if (selection.length > 0) {
                for (let i = 0; i < selection.length; i++) {
                    const rec = selection[i];
                    if (rec) {
                        const real_record = this.getStore().getRecordById(rec.data[index_field]);
                        if (real_record) {
                            save_array.push(this.getStore().deleteRecord(real_record));
                        }
                    }
                }
            }
            Promise.all(save_array).then((result) => {
                let success = true;
                for (let i = 0; i < result.length; i++) {
                    if (!result[i]) {
                        success = false;
                    }
                }
                resolve(success);
            });
        });
    }


    public getNameForSelectBoxEntryByValueSimple(list_type: string, key: any) {
        const entry = this.select_boxes[list_type].find(x => {
            return String(x.value) === String(key) || Number(x.value) === Number(key);
        });
        if (entry) {
            return entry.name;
        }
        return key;
    }

    public getNameForSelectBoxGroupEntryByValue(list_type: string, key: any) {

        for (let i = 0; i < this.select_boxes[list_type].length; i++) {
            const options = this.select_boxes[list_type][i].options;
            const entry = options.find(x => {
                return x.value === key || Number(x.value) === Number(key);
            });
            if (entry) {
                return entry.name;
            }
        }
        return key;
    }

    public setSelectboxes() {
    }


    public CheckFieldConditionChanged(field) {
        const disable_result = this.checkFieldDisable(field);
        const enable_result = this.checkFieldEnable(field);
        if (!field.disabled) {
            const rules_result = this.checkFieldRules(field);
            if (rules_result) {
                return true;
            }
        }
        if (disable_result) {
            return true;
        }
        if (enable_result) {
            return true;
        }
        return false;
    }
    private checkFieldDisable(field) {
        if (field.conditions
            && field.conditions.disable
            && this.checkConditions(field, field.conditions.disable.conditions, false, 'disable')) {
            field.hide_possible = true;
            if (!field.disabled) {
                field.disabled = true;
                field.rules = [];
                return true;
            } else {
                return false;
            }
        }
        return false;
    }
    private checkFieldEnable(field) {
        if (field.conditions
            && field.conditions.enable
            && this.checkConditions(field, field.conditions.enable.conditions, false, 'enable')) {
            field.hide_possible = false;
            if (field.disabled) {
                field.disabled = false;
                return true;
            } else {
                return false;
            }
        }
        return false;
    }
    private checkFieldRules(field) {
        if (field.conditions
            && field.conditions.rules
            && this.checkConditions(field, field.conditions.rules.conditions, true, 'rule')) {
            return true;
        }
        return false;
    }
    private applyRulesForCondition(field, condition) {
        if (!field.rules) {
            field.rules = [];
        }
        if (field.rules && field.rules.length > 0 && condition.rules.length === 0) {
            field.rules = condition.rules;
            field.required = false;
            return true;
        }

        if (field.rules
            && field.rules.length === 0
            && condition.rules.length > 0) {
            field.rules = condition.rules;
            field.required = true;
            return true;
        }
        return false;
    }

    private checkCondition(field, condition, apply_rules, context: string, condition_matched: boolean) {
        const data = field.model.obj;
        let compare_obj = data[condition.key];

        if (condition.hasOwnProperty('obj')) {
            compare_obj = condition.obj[condition.key];
        }
        switch (condition.operator) {
            default:
                if (compare_obj === condition.value) {
                    condition_matched = true;
                } else {
                    condition_matched = false;
                }
                condition_matched = this.handleConditionChains(field, condition_matched, condition, apply_rules, context);
                break;
            case '===':
                if (compare_obj === condition.value) {
                    condition_matched = true;
                } else {
                    condition_matched = false;
                }
                condition_matched = this.handleConditionChains(field, condition_matched, condition, apply_rules, context);
                break;
            case '!==':
                if (compare_obj !== condition.value) {
                    condition_matched = true;
                } else {
                    condition_matched = false;
                }
                condition_matched = this.handleConditionChains(field, condition_matched, condition, apply_rules, context);
                break;
            case '>':
                if (Number(compare_obj) > Number(condition.value)) {
                    condition_matched = true;
                } else {
                    condition_matched = false;
                }
                condition_matched = this.handleConditionChains(field, condition_matched, condition, apply_rules, context);
                break;
            case '<':
                if (Number(compare_obj) === Number(condition.value)) {
                    condition_matched = true;
                } else {
                    condition_matched = false;
                }
                condition_matched = this.handleConditionChains(field, condition_matched, condition, apply_rules, context);
                break;
        }
        return condition_matched;
    }

    private handleConditionChains(field, condition_matched, condition, apply_rules, context) {
        if (condition.hasOwnProperty('and')) {
            for (let i = 0; i < condition.and.length; i++) {
                const condi_and = condition.and[i];
                if (this.checkCondition(field, condi_and, apply_rules, context, condition_matched) && condition_matched) {
                    condition_matched = true;
                } else {
                    condition_matched = false;
                }
            }
        }
        if (condition.hasOwnProperty('or')) {
            for (let i = 0; i < condition.or.length; i++) {
                const condi_or = condition.or[i];
                if (this.checkCondition(field, condi_or, apply_rules, context, condition_matched) || condition_matched) {
                    condition_matched = true;
                } else {
                    condition_matched = false;
                }
            }
        }
        return condition_matched;
    }

    private checkConditions(field: any, conditions: any[], apply_rules: boolean, context: string) {
        if (conditions && conditions.length > 0) {
            let condition_matched = false;
            for (let i = 0; i < conditions.length; i++) {
                const condition = conditions[i];
                condition_matched = this.checkCondition(field, condition, apply_rules, context, condition_matched);
                if (condition_matched) {
                    // stop on first possible match
                    if (apply_rules) {
                        return this.applyRulesForCondition(field, condition);
                    }
                    return true;
                }
            }
        }
        return false;
    }
}
