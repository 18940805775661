import {FbFormObject} from '../class/fb_form_object';

export class ScAvailableCommodityForm extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'type', label: 'Type', visible: true, noclick: false, disable_ripple: true},
            {id: 'c_name', label: 'Name', visible: true, noclick: false, disable_ripple: true},
            {id: 'cat_id', label: 'Category', visible: true, noclick: false, disable_ripple: true},
            {id: 'set_id', label: 'Set', visible: true, noclick: false, disable_ripple: true},
            {id: 'sc_wiki_url', label: 'WIKI URL', visible: true, noclick: false, disable_ripple: true,
                is_wikiLink: true, wiki_link_name: 'c_name'},
            {id: 'description', label: 'Description', visible: true, noclick: false, disable_ripple: true},
            {id: 'value', label: 'value', visible: true, noclick: false, disable_ripple: true}
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Commodity',
                class: 'card card-1 padding single-column',
                field_class: '',
                fields: [
                    {
                        type: 'select',
                        name: 'type',
                        options: this.select_boxes['type_select'],
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'text',
                        name: 'c_name',
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'autocomplete',
                        name: 'cat_id',
                        options: this.select_boxes['cat_select'],
                    },
                    {
                        type: 'autocomplete',
                        name: 'set_id',
                        options: this.select_boxes['set_select']
                    },
                    {
                        type: 'text',
                        name: 'sc_wiki_url',
                    },
                    {
                        type: 'textarea',
                        name: 'description'
                    },
                    {
                        type: 'number',
                        name: 'value'
                    }
                ]
            }
        ];
        super.setEditFields();
    }

    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.stores['sc_available_commodities'] = this.storeService.getStore('sc_available_commodities');
            this.stores['commodity_sets'] = this.storeService.getStore('commodity_sets');
            this.stores['commodity_categories'] = this.storeService.getStore('commodity_categories');
            this.main_store = this.stores['sc_available_commodities'];

            const selectFilter = [];
            selectFilter.push('first=0');
            selectFilter.push('rows=1000');

            const stores = [];
            stores.push(this.stores['sc_available_commodities'].loadStore(this.filter));
            stores.push(this.stores['commodity_sets'].loadStore('?' + selectFilter.join('&')));
            stores.push(this.stores['commodity_categories'].loadStore('?' + selectFilter.join('&')));
            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
                type: '',
                c_name: '',
                set_id: 0,
                sc_wiki_url: '',
                description: '',
                value: 0
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        this.parseTypeSelectBox();
        this.parseSetSelectBox();
        this.parseCategorySelectBox();
        // call super
        super.setSelectboxes();
    }

    public parseTypeSelectBox(): any[] {
        const options = [
            {
                value: 'ore',
                name: 'Ore'
            },
            {
                value: 'cargo',
                name: 'Cargo'
            },
            {
                value: 'currency',
                name: 'Currency'
            },
            {
                value: 'item',
                name: 'Item'
            }
        ];
        this.select_boxes['type_select'] = options;
        return this.select_boxes['type_select'];
    }

    public parseSetSelectBox() {
        const options = [];
        const records = this.stores['commodity_sets'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.set_id),
                name: records[i].data.set_name
            });
        }
        this.select_boxes['set_select'] = options;
        return this.select_boxes['set_select'];
    }

    public parseCategorySelectBox() {
        const options = [];
        const records = this.stores['commodity_categories'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.cat_id),
                name: records[i].data.cat_name
            });
        }
        this.select_boxes['cat_select'] = options;
        return this.select_boxes['cat_select'];
    }

}
