import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {StoreService} from '../../services/store.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {NotificationService} from '../../services/notification.service';
import {MatDialog} from '@angular/material/dialog';
import {IMqttMessage, MqttService} from 'ngx-mqtt';
import {MatChipListboxChange} from '@angular/material/chips';
import {MiningLoadoutService} from '../../services/mining-loadout.service';
import {MatSelectChange} from '@angular/material/select';
import {Gadget, Laser, Ship} from '../../interfaces/mining-loadout';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';

@Component({
    selector: 'app-mining-loadout-calc',
    templateUrl: './mining-loadout-calc.component.html',
    styleUrl: './mining-loadout-calc.component.css'
})
export class MiningLoadoutCalcComponent implements OnInit, OnDestroy, AfterViewInit {

    private subs: Array<Subscription> = [];

    public loading_text = '';
    public is_loading = true;

    public mining_ships: any[];
    public mining_laser: any[];
    public mining_module: any[];
    public mining_gadgets: any[];
    public mining_modules_grouped = {active: [], passive: []};
    public mining_laser_grouped: any = {};

    public mining_ships_configs: any = {};
    public mining_laser_configs: any = {};
    public mining_module_with_key: any = {};
    public all_stats: any = {};
    public all_bonus_stats: any = {};
    public gadget_bonus: any = {};
    public selected_ship: Ship = {
        id: 'prospector'
    };

    public tmp_gadget: Gadget | null = null;

    public shopping_list = {laser: [], modules: [], gadgets: []};
    public shop_list = {};
    public item_count = 0;

    public stat_translation = {
        price: {
            caption: 'price',
            unit: 'aUEC',
            negative_class: '',
            positive_class: '',
        },
        optimum_range: {
            caption: 'opt range',
            unit: '',
            negative_class: '',
            positive_class: '',
        },
        max_range: {
            caption: 'max range',
            unit: '',
            negative_class: '',
            positive_class: '',
        },
        min_power_percent: {
            caption: 'min pwr per',
            unit: '%',
            negative_class: '',
            positive_class: '',
        },
        min_power: {
            caption: 'min power',
            unit: '',
            negative_class: '',
            positive_class: '',
        },
        max_power: {
            caption: 'max power',
            unit: '',
            negative_class: '',
            positive_class: '',
        },
        extract_power: {
            caption: 'ext power',
            unit: '',
            negative_class: 'bad',
            positive_class: 'good',
        },
        resistance: {
            caption: 'resistance',
            unit: '%',
            negative_class: 'good',
            positive_class: 'bad-positive-percent',
        },
        instability: {
            caption: 'instability',
            unit: '%',
            negative_class: 'good',
            positive_class: 'bad-positive-percent',
        },
        optimal_charge_rate: {
            caption: 'opt chrg rt',
            unit: '%',
            negative_class: 'bad',
            positive_class: 'good-positive-percent',
        },
        optimal_charge_window: {
            caption: 'opt chrg wnd',
            unit: '%',
            negative_class: 'bad',
            positive_class: 'good-positive-percent',
        },
        inert_materials: {
            caption: 'inert',
            unit: '%',
            negative_class: 'good',
            positive_class: 'bad-positive-percent',
        },
        shatter_damage: {
            caption: 'shatter',
            unit: '%',
            negative_class: 'good',
            positive_class: 'bad-positive-percent',
        },
        overcharge_rate: {
            caption: 'overcharge',
            unit: '%',
            negative_class: 'good',
            positive_class: 'bad-positive-percent',
        },
        clustering: {
            caption: 'clustering',
            unit: '%',
            negative_class: 'bad',
            positive_class: 'good-positive-percent',
        },
        laser_power_mod: {
            caption: 'laser power',
            unit: '%',
            negative_class: 'bad',
            positive_class: 'good-positive-percent',
        },
        extract_power_mod: {
            caption: 'ext power',
            unit: '%',
            negative_class: 'bad',
            positive_class: 'good-positive-percent',
        },
    };

    private config: any;
    private last_selected_ship = {
        id: 'prospector'
    };


    constructor(private storeService: StoreService,
                private authService: AuthService,
                private router: Router,
                private cdr: ChangeDetectorRef,
                private notificationService: NotificationService,
                public dialog: MatDialog,
                private _mqttService: MqttService,
                private route: ActivatedRoute,
                public configService: MiningLoadoutService) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.route.queryParams.subscribe(params => {
            const encodedConfig = params['config'];
            if (encodedConfig) {
                const configString = atob(encodedConfig);
                const config = JSON.parse(configString);
                this.loadConfiguration(config);
            }
            this.loadCalcStore();
        });
    }

    loadConfiguration(config) {
        this.config = config;
    }

    private loadCalcStore() {
        const selectFilter = [];
        selectFilter.push('first=0');
        selectFilter.push('rows=1000');

        const stores = [];
        const load_stores = [];
        stores['mining_laser'] = this.storeService.getStore('mining_laser');
        stores['mining_modules'] = this.storeService.getStore('mining_module');
        stores['mining_gadgets'] = this.storeService.getStore('mining_gadgets');
        stores['miningStore'] = this.storeService.createNoneGlobalStore('mining_data',
            'uex/mining-data', 'type');

        load_stores.push(stores['mining_laser'].loadStore('?' + selectFilter.join('&')));
        load_stores.push(stores['mining_modules'].loadStore('?' + selectFilter.join('&')));
        load_stores.push(stores['mining_gadgets'].loadStore('?' + selectFilter.join('&')));
        load_stores.push(stores['miningStore'].loadStore('?' + selectFilter.join('&')));

        Promise.all(load_stores).then((result) => {
            // handle mining ships
            const mining_ship_records = stores['miningStore'].getRecords();
            if (mining_ship_records && mining_ship_records.length > 0) {
                this.mining_ships = stores['miningStore'].getRecordById('mining_ships').data.rows;
                for (const record of this.mining_ships) {
                    this.mining_ships_configs[record.slug] = record;
                }
            }

            // handle mining laser
            this.mining_laser = [];
            const mining_laser = stores['mining_laser'].getRecords();
            if (mining_laser && mining_laser.length > 0) {
                for (const record of mining_laser) {
                    record['module_slots'] = [];
                    for (let i = 1; i <= record.data.slots; i++) {
                        record['module_slots'].push({
                            slot_id: i,
                            slot_value: null
                        });
                    }
                    this.mining_laser_configs[record.data.laser] = record.data;
                    this.mining_laser.push(record.data);
                    const size = record.data.size;
                    if (!this.mining_laser_grouped[size]) {
                        this.mining_laser_grouped[size] = [];
                    }
                    this.mining_laser_grouped[size].push(record.data);
                }
            }

            // handle mining module
            this.mining_module = [];
            const active_modules = [];
            const passive_modules = [];
            const mining_modules = stores['mining_modules'].getRecords();
            if (mining_modules && mining_modules.length > 0) {
                for (const module of mining_modules) {
                    this.mining_module_with_key[module.data.module] = module.data;
                    this.mining_module.push(module.data);
                    if (module.data.type === 'active') {
                        active_modules.push(module.data);
                    } else {
                        passive_modules.push(module.data);
                    }
                }
                this.mining_modules_grouped.active = active_modules;
                this.mining_modules_grouped.passive = passive_modules;
            }

            // handle mining gadget
            this.mining_gadgets = [];
            const mining_gadgets = stores['mining_gadgets'].getRecords();
            if (mining_gadgets && mining_gadgets.length > 0) {
                for (const gadget of mining_gadgets) {
                    this.mining_gadgets.push(gadget.data);
                }
            }

            // only if we do not have a given config
            if (!this.config) {
                console.log('new config');
                this.setNewShipConfig();
            } else {
                console.log('from config');
                this.updateShipWithConfig();
            }

            this.calcStats();

            this.is_loading = false;
        });

    }

    private initLaser(from_config: boolean) {
        this.configService.lasers = [];

        console.log('from_config', from_config);
        console.log(this.config);

        for (let l = 0; l < this.mining_ships_configs[this.selected_ship.id].mining_laser.length; l++) {

            let laser = null;
            const default_laser = this.mining_ships_configs[this.selected_ship.id].mining_laser[l];
            if (from_config) {
                laser = this.config.lasers[l];
            } else {
                laser = this.mining_ships_configs[this.selected_ship.id].mining_laser[l];
            }

            const new_laser = {
                laser: laser.laser,
                laser_hover: null,
                position: laser.position,
                position_name: default_laser.position_name,
                sort: laser.sort,
                slots:  this.mining_laser_configs[laser.laser].slots,
                moduleIds:  from_config ? this.config.lasers[l].moduleIds : [],
                is_active: from_config ? laser.is_active : default_laser.is_active,
                stats: {},
                bonus_stats: {},
                moduleStates: from_config ? this.config.lasers[l].moduleStates : [],
                modulePrices: [],
                moduleIds_hover: [],
                size: laser.size,
                price: this.mining_laser_configs[laser.laser].price
            };

            for (let i = 0; i < new_laser.slots; i++) {
                if (!from_config) {
                    console.log('## bad', from_config);
                    new_laser.moduleIds.push('');
                    new_laser.moduleStates.push(false);
                    new_laser.moduleStates.push(0);
                    new_laser.moduleIds_hover.push(null);
                }
            }
            this.configService.lasers.push(
                new_laser
            );

            // set empty module slots
        }
    }

    private setNewShipConfig() {
        this.configService.selectedShip = this.selected_ship;
        this.configService.gadget = {gadget: '', price: 0};

        this.initLaser(false);

        console.log(' this.configService.lasers',  this.configService.lasers);

        // this.configService.lasers = null;
    }

    private updateShipWithConfig() {
        // Fetch ship, lasers, modules, and gadget by IDs
        // Update the ConfigurationService with loaded data
        this.selected_ship.id = this.config.shipId;

        this.configService.gadget = this.mining_gadgets.find((g) => {
            return g.gadget === this.config.gadgetId;
        });

        if (!this.configService.gadget) {
            this.configService.gadget = {gadget: '', price: 0};
        }

        console.log('this.configService.gadget', this.configService.gadget);

        this.configService.selectedShip = this.selected_ship;
        this.configService.lasers = this.config.lasers;

        // const ship: any = this.mining_ships_configs[this.config.shipId];
        // for (let i = 0; i < this.configService.lasers.length; i++) {
        //     this.configService.lasers[i].position_name = ship.mining_laser[i].position_name;
        // }

        this.initLaser(true);

        this.configService.gadget_enabled = this.config.gadget_enabled;

    }

    shipSelected($event: MatChipListboxChange) {
        if ($event.value) {
            this.selected_ship.id = $event.value;
            this.last_selected_ship.id = $event.value;
            this.setNewShipConfig();
            this.calcStats();
        } else {
            this.configService.selectedShip.id = '';
            this.cdr.detectChanges();
            this.configService.selectedShip.id = this.last_selected_ship.id;
            this.cdr.detectChanges();
        }
    }

    shareConfiguration() {
        const link = this.configService.generateShareableLink();
        navigator.clipboard.writeText(link).then(() => {
            this.notificationService.success('link copied', '', 5000);
        });
    }

    laserChanged($event: MatSelectChange, laser: Laser) {
        const cLaser = this.configService.lasers.find((l => {
            return l.position === laser.position;
        }));
        if (cLaser) {
            cLaser.laser = $event.value;
            cLaser.moduleIds = [];
            cLaser.slots = this.mining_laser_configs[cLaser.laser].slots;

            for (let i = 0; i < cLaser.slots; i++) {
                cLaser.moduleIds.push(null);
            }
        }
        this.calcStats();
    }

    laserActiveChanged($event: MatSlideToggleChange, laser: Laser) {
        this.calcStats();
    }


    laserModuleChanged($event: MatSelectChange, position: string, m_id: string, index: number) {

        const cLaser = this.configService.lasers.find((l => {
            return l.position === position;
        }));
        if (cLaser) {
            cLaser.moduleIds[index] = $event.value;
        }
        this.calcStats();
    }

    laserModuleActiveChanged($event: MatSlideToggleChange, position: string, m_id: string, index: number) {
        const cLaser = this.configService.lasers.find((l => {
            return l.position === position;
        }));
        if (cLaser) {
            cLaser.moduleStates[index] = $event.checked;
        }
        this.calcStats();
    }

    gadgetChanged($event: MatSelectChange) {
        this.calcStats();
    }

    gadgetActiveChanged($event: MatSlideToggleChange) {
        this.calcStats();
    }

    calcStats() {

        this.shopping_list.laser = [];
        this.shopping_list.modules = [];
        this.shopping_list.gadgets = [];

        this.all_stats = {
            min_power: 0,
            max_power: 0,
            resistance: 1,
            instability: 1,
            optimal_charge_rate: 1,
            optimal_charge_window: 1,
            shatter_damage: 1,
            overcharge_rate: 1,
            clustering: 1,

            resistance_display: 0,
            instability_display: 0,
            optimal_charge_rate_display: 0,
            optimal_charge_window_display: 0,
            shatter_damage_display: 0,
            overcharge_rate_display: 0,
            clustering_display: 0,

            active_laser_count: 0,
            price: 0
        };

        this.all_bonus_stats = {
            min_power: 0,
            max_power: 0
        };

        this.gadget_bonus = {
            resistance: 1,
            instability: 1,
            optimal_charge_rate: 1,
            optimal_charge_window: 1,
            inert_materials: 1,
            shatter_damage: 1,
            overcharge_rate: 1,
            clustering: 1,
            extract_power: 1,

            resistance_display: 0,
            instability_display: 0,
            optimal_charge_rate_display: 0,
            optimal_charge_window_display: 0,
            shatter_damage_display: 0,
            overcharge_rate_display: 0,
            clustering_display: 0,
            extract_power_display: 0,
            inert_materials_display: 0,

            price: 0
        };

        const multiplicative_stats = [
            'resistance',
            'instability',
            'optimal_charge_rate',
            'optimal_charge_window',
            'shatter_damage',
            'overcharge_rate',
            'clustering',
            'inert_materials'
        ];

        // get gadget
        let gadget = this.mining_gadgets.find((g) => {
            return g.gadget === this.configService?.gadget?.gadget;
        });

        if (this.tmp_gadget) {
            gadget = this.mining_gadgets.find((g) => {
                return g.gadget === this.tmp_gadget?.gadget;
            });
        }




        // for each laser
        for (const laser of this.configService.lasers) {
            const laser_for_calc = laser.laser_hover ? laser.laser_hover : laser.laser;


            this.shopping_list.laser.push(this.mining_laser_configs[laser_for_calc]);

            laser.stats = {
                optimum_range: this.mining_laser_configs[laser_for_calc].optimum_range,
                max_range: this.mining_laser_configs[laser_for_calc].max_range,
                min_power_percent: this.mining_laser_configs[laser_for_calc].min_power_percent,
                min_power: this.mining_laser_configs[laser_for_calc].min_power,
                max_power: this.mining_laser_configs[laser_for_calc].max_power,
                extract_power: this.mining_laser_configs[laser_for_calc].extract_power,
                resistance: 1,
                instability: 1,
                optimal_charge_rate: 1,
                optimal_charge_window: 1,
                inert_materials: 1,
                shatter_damage: 1,
                overcharge_rate: 1,
                clustering: 1,

                resistance_display: 0,
                instability_display: 0,
                optimal_charge_rate_display: 0,
                optimal_charge_window_display: 0,
                shatter_damage_display: 0,
                overcharge_rate_display: 0,
                clustering_display: 0,

                price: this.mining_laser_configs[laser_for_calc].price
            };

            if (this.mining_laser_configs[laser_for_calc].resistance) {
                for (const state of multiplicative_stats) {
                    if (this.mining_laser_configs[laser_for_calc][state]) {
                        laser.stats[state] = (this.mining_laser_configs[laser_for_calc][state] / 100) + 1;
                    }
                }
            }
            if (laser.is_active) {
                for (const stat of multiplicative_stats) {
                    this.all_stats[stat] = this.stackPercents(this.all_stats[stat],
                        (this.mining_laser_configs[laser_for_calc][stat] / 100));
                }

            }

            laser.bonus_stats = {
                min_power: 0,
                max_power: 0,
                extract_power: 0
            };

            let index = 0;
            for (const module_id of laser.moduleIds) {
                let module = this.mining_module_with_key[module_id];
                if (laser.moduleIds_hover && laser.moduleIds_hover[index]) {
                    module = this.mining_module_with_key[laser.moduleIds_hover[index]];
                }

                if (module) {
                    laser.stats.price += module.price;

                    if (module.type === 'passive' || laser.moduleStates[index] || (laser.moduleIds_hover && laser.moduleIds_hover[index])) {
                        laser.bonus_stats.min_power = this.stackPercents(laser.bonus_stats.min_power,
                            (module.laser_power_mod / 100));
                        laser.bonus_stats.max_power = this.stackPercents(laser.bonus_stats.max_power,
                            (module.laser_power_mod / 100));
                        laser.bonus_stats.extract_power = this.stackPercents(laser.bonus_stats.extract_power,
                            (module.extract_power_mod / 100));

                        if (laser.is_active) {
                            for (const stat of multiplicative_stats) {
                                laser.stats[stat] = this.stackPercents(laser.stats[stat], (module[stat] / 100));
                                this.all_stats[stat] = this.stackPercents(this.all_stats[stat], (module[stat] / 100));
                            }
                        }
                    }

                    this.shopping_list.modules.push(module);
                } else {

                }
                index++;
            }

            if (laser.bonus_stats.extract_power === 0) {
                laser.bonus_stats.extract_power = 1;
            }
            if (laser.bonus_stats.min_power === 0) {
                laser.bonus_stats.min_power = 1;
            }
            if (laser.bonus_stats.max_power === 0) {
                laser.bonus_stats.max_power = 1;
            }

            this.all_stats.price += laser.stats.price;

            laser.stats.min_power = Math.round(laser.stats.min_power * (laser.bonus_stats.min_power)); // done
            laser.stats.max_power = Math.round(laser.stats.max_power * (laser.bonus_stats.max_power)); // done
            laser.stats.extract_power = Math.round(laser.stats.extract_power * (laser.bonus_stats.extract_power)); // done

            if (laser.is_active) {

                this.all_bonus_stats.min_power += laser.bonus_stats.min_power;
                this.all_bonus_stats.max_power += laser.bonus_stats.max_power;

                this.all_stats.min_power += laser.stats.min_power;
                this.all_stats.max_power += laser.stats.max_power;

                this.all_stats.clustering += laser.stats.clustering;

                this.all_stats.active_laser_count++;
            }

            // normalize laser values
            for (const stat of multiplicative_stats) {
                if (laser.stats[stat]) {
                    laser.stats[stat + '_display'] = Math.round((laser.stats[stat]) * 100) - 100;
                    if (laser.stats[stat + '_display'] > 0) {
                        laser.stats[stat + '_display'] = '+' + laser.stats[stat + '_display'];
                    }
                }
            }
        }


        if (gadget) {
            for (const stat of multiplicative_stats) {
                if (this.configService.gadget_enabled) {
                    this.all_stats[stat] = this.stackPercents(this.all_stats[stat], (gadget[stat] / 100));
                }
                this.gadget_bonus[stat] = this.stackPercents(this.gadget_bonus[stat], (gadget[stat] / 100));
            }
            // this.configService.gadget = this.mining_gadgets.find((g) => {
            //     return g.gadget === gadget.gadget;
            // });
            this.all_stats.price += this.configService.gadget.price;

            this.shopping_list.gadgets.push(gadget);
        }

        // normalize sum values
        for (const stat of multiplicative_stats) {
            if (this.all_stats[stat]) {
                this.all_stats[stat + '_display'] = Math.round((this.all_stats[stat]) * 100) - 100;
                if (this.all_stats[stat + '_display'] > 0) {
                    this.all_stats[stat + '_display'] = '+' + this.all_stats[stat + '_display'];
                }
            }
            if (this.gadget_bonus[stat]) {
                this.gadget_bonus[stat + '_display'] = Math.round((this.gadget_bonus[stat]) * 100) - 100;
                if (this.gadget_bonus[stat + '_display'] > 0) {
                    this.gadget_bonus[stat + '_display'] = '+' + this.gadget_bonus[stat + '_display'];
                }
            }
        }

        console.log('shopping_list', this.shopping_list);
        this.transformShoppingList();
    }

    private stackPercents(current: number, to_add: number) {
        if (to_add && to_add !== 0) {
            if (current && current !== 0) {
                current = current * (to_add + 1);
            } else {
                current = to_add + 1;
            }
        }
        return current;
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }

    onLaserOptionMouseOver(availableLaser: any, laser: Laser) {
        laser.laser_hover = availableLaser.laser;
        this.calcStats();
    }

    resetHoverLaser(laser: Laser) {
        laser.laser_hover = null;
        this.calcStats();
    }

    onModuleOptionMouseOver(module: any, laser: Laser, index: any) {
        laser.moduleIds_hover[index] = module;
        this.calcStats();
    }

    resetHoverModule(laser: Laser, index: any) {
        laser.moduleIds_hover[index] = null;
        this.calcStats();
    }

    onGadgetOptionMouseOver(gadget: any) {
        this.tmp_gadget = gadget;
        this.calcStats();
    }

    resetHoverGadget() {
        this.tmp_gadget = null;
        this.calcStats();
    }

    transformShoppingList() {
        this.shop_list = {};
        this.item_count = 0;
        for (const laser of this.shopping_list.laser) {
            if (laser.locations) {
                for (const shop of laser.locations) {
                    if (!this.shop_list[shop.location]) {
                        this.shop_list[shop.location] = {};
                    }
                    if (!this.shop_list[shop.location][laser.laser]) {
                        this.shop_list[shop.location][laser.laser] = {
                            item: laser,
                            type: 'laser',
                            wanted: 1
                        };
                    } else {
                        this.shop_list[shop.location][laser.laser].wanted++;
                    }
                }
            }
            this.item_count++;
        }

        for (const module of this.shopping_list.modules) {
            if (module.locations) {
                for (const shop of module?.locations) {
                    if (!this.shop_list[shop.location]) {
                        this.shop_list[shop.location] = {};
                    }
                    if (!this.shop_list[shop.location][module.module]) {
                        this.shop_list[shop.location][module.module] = {
                            item: module,
                            type: 'module',
                            wanted: 1
                        };
                    } else {
                        this.shop_list[shop.location][module.module].wanted++;
                    }
                }
            }
            this.item_count++;
        }

        for (const gadget of this.shopping_list.gadgets) {
            if (gadget.locations) {
                for (const shop of gadget?.locations) {
                    if (!this.shop_list[shop.location]) {
                        this.shop_list[shop.location] = {};
                    }
                    if (!this.shop_list[shop.location][gadget.gadget]) {
                        this.shop_list[shop.location][gadget.gadget] = {
                            item: gadget,
                            type: 'gadget',
                            wanted: 1
                        };
                    } else {
                        this.shop_list[shop.location][gadget.gadget].wanted++;
                    }
                }
            }
            this.item_count++;
        }

        console.log('shop_list', this.shop_list);
    }

    getObjectLength(obj: object): number {
        return Object.keys(obj).length;
    }
}
