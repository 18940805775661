<div class="padding">
    <div class="card card-1 padding table-wrapper" *ngIf="storeObject">
        <app-fb-forms-table [storeObj]="storeObject"
                            [edit_allowed]="data.edit_allowed"
                            (row_click)="rowSelected($event)"
                            (add_click)="addClicked()"
                            [stateKey]="data.name_atr + '_picker'"
                            [show_add]="data.show_add"
                            [show_add_in_view]="data.show_add_in_table"
                            [show_delete]="data.show_delete"
                            [title]="data.name_atr"
                            [rowsPerPageOptions]="[10, 20]"
        >
        </app-fb-forms-table>
    </div>
</div>
