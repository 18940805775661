<div>
    <app-content-container [content_template]="page_content"
                           [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <mat-tab-group mat-align-tabs="start" color="accent" LastActiveTabDirective="ifa-badges">
        <mat-tab label="Badges">
            <div class="padding">
                <div class="card card-1 padding table-wrapper" *ngIf="storeObj && upload_tpl">
                    <app-fb-forms-table [storeObj]="storeObj"
                                        [storeTemplates]="{upload_tpl: upload_tpl}"
                                        [edit_allowed]="true"
                                        (row_click)="rowSelected($event)"
                                        [stateKey]="'ifa-badges'"
                                        [show_add_in_view]="true"
                                        [show_delete]="true"
                                        [title]="'Badges'"
                    >
                    </app-fb-forms-table>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Assigned Badges" >
            <div class="padding">
                <div class="card card-1 padding table-wrapper" *ngIf="storeAssignedObj && upload_tpl">
                    <app-fb-forms-table [storeObj]="storeAssignedObj"
                                        [edit_allowed]="false"
                                        (row_click)="rowSelected($event)"
                                        [stateKey]="'ifa-assigned-badges'"
                                        [show_add_in_view]="false"
                                        [show_delete]="false"
                                        [title]="'Assigned Badges'"
                    >
                    </app-fb-forms-table>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</ng-template>
<ng-template #select_caption_tpl let-element="element" let-column="column">
    {{element.tmp.caption[column.id]}}
</ng-template>

<ng-template #upload_tpl let-element="element" let-column="column" let-edit="edit">
    <div *ngIf="element.data && element.data.file_id > 0">
        <app-badge-icon [file_id]="element.data.file_id"></app-badge-icon>
        <button mat-mini-fab class="button-delete"
                (click)="deleteFile(element.data.file_id, element.data)"
                style="margin-left: 16px;"
                matTooltip="löschen"
                type="button">
            <mat-icon aria-label="löschen">delete</mat-icon>
        </button>
    </div>
    <div *ngIf="element.model && element.model.obj.file_id > 0">
        <app-badge-icon [file_id]="element.model.obj.file_id"></app-badge-icon>
        <button mat-mini-fab class="button-delete"
                (click)="deleteFile(element.model.obj.file_id, element.model.obj)"
                style="margin-left: 16px;"
                matTooltip="löschen"
                type="button">
            <mat-icon aria-label="löschen">delete</mat-icon>
        </button>
    </div>
    <ng-container *ngIf="element.model && element.model.obj.badge_id > 0 &&
                         (element.model.obj.file_id === 0 || element.model.obj.file_id === null)
                         && edit">
        <app-file-upload2 [file_type]="'badge'"
                          (on_success)="onFileUpload($event, 'badge', element)"
                          [url]="url_file_store"
                          [show_save_button]="true"
                          [parent_object_id]="element.model.obj.badge_id"
                          [max_count]="1"
                          [accept]="'.jpeg,.jpg,.png,image/*'"
                          (init)="onFileuploadInit($event)"
        ></app-file-upload2>
    </ng-container>
</ng-template>
