import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AppSettings} from '../../../config/AppSettings';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-captain-list',
    templateUrl: './captain-list.component.html',
    styleUrls: ['./captain-list.component.css']
})
export class CaptainListComponent {

    public captains: any[] = [];
    public is_loading = true;

    constructor(@Inject(MAT_DIALOG_DATA) public data: { model: string }, private http: HttpClient) {
        this.captains = [];
        this.http.get<any[]>(AppSettings.getRealAPILink() + 'fleetyards/fleet/vehicles/models/' + data.model, {withCredentials: true})
            .subscribe((result: any) => {
                let records = [];
                if (result.success) {
                    records = result.rows;
                } else {
                    // old api
                    records = result;
                }
                this.captains = records;
                this.is_loading = false;
            });
    }
}
