import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {StoreService} from '../../../services/store.service';
import {AuthService} from '../../../services/auth.service';
import {NotificationService} from '../../../services/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {FileUpload2Component} from '../../../shared/file-upload2/file-upload2.component';
import {AppSettings} from '../../../config/AppSettings';

@Component({
  selector: 'app-media-edit',
  templateUrl: './media-edit.component.html',
  styleUrls: ['./media-edit.component.css']
})
export class MediaEditComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
    this._select_caption_tpl = value;
    this.cdr.markForCheck();
  }

  public loading_text = '';
  public is_loading = false;
  public fields: any[];
  public mode = 'edit';
  public edit = false;
  public rows = [];
  public count = 0;

  public files = [];
  public max_file_count = 5;
  public url_file_store = AppSettings.getRealAPILink() + 'media';
  private file_upload: FileUpload2Component;

  private subs: Array<Subscription> = [];
  private record: any;
  private id: string|number = '';

  private _select_caption_tpl: ElementRef;

  constructor(private storeService: StoreService,
              private authService: AuthService,
              private notificationService: NotificationService,
              private route: ActivatedRoute,
              private router: Router,
              private cdr: ChangeDetectorRef,
              public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.subs.push(this.route.params.subscribe((params: any) => {
      this.edit = this.authService.isAdmin();

      if (params.id && params.id !== 'add') {
        this.mode = 'edit';
        this.id = params.id;
      } else {
        this.mode = 'add';
        // this.edit = true;
      }
    }));
  }

  public onFileuploadInit(file_upload: FileUpload2Component) {
    this.file_upload = file_upload;
  }

  public onFileUpload($event, type, element) {
    if ($event.ok) {
      // this.FileStore.reloadStore('').then(records => {
      this.notificationService.success('Daten',
          'Speichern erfolgreich.', {timeOut: 5000});
      this.router.navigateByUrl('/media');
      // });
    } else {
      this.notificationService.error('Daten',
          'Speichern fehlgeschlagen.', {timeOut: 5000});
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
