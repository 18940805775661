import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FbFormObject} from '../../../../../class/fb_form_object';
import {Subscription} from 'rxjs';
import {AppSettings} from '../../../../../config/AppSettings';
import {FileUpload2Component} from '../../../../../shared/file-upload2/file-upload2.component';
import {StoreService} from '../../../../../services/store.service';
import {AuthService} from '../../../../../services/auth.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../../../../services/notification.service';
import {MatDialog} from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import {Manufacturer} from '../../../../../forms/manufacturer';

@Component({
    selector: 'app-manufacturer',
    templateUrl: './manufacturer.component.html',
    styleUrls: ['./manufacturer.component.css']
})
export class ManufacturerComponent implements OnInit, OnDestroy, AfterViewInit {


    @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
        this._select_caption_tpl = value;
        // this.setColumns();
        this.cdr.markForCheck();
    }

    @ViewChild('upload_tpl', {static: true}) public upload_tpl: ElementRef;
    @ViewChild('man_short_select_tpl', {static: true}) public man_short_select_tpl: ElementRef;

    public storeObj: FbFormObject;
    // public storeAssignedObj: FbFormObject;
    private subs: Array<Subscription> = [];

    public loading_text = '';
    public is_loading = true;
    public rows: any[];

    public files = [];
    public max_file_count = 1;
    public url_file_store = AppSettings.getRealAPILink() + 'media';
    private file_upload: FileUpload2Component;

    private _select_caption_tpl: ElementRef;

    public is_admin: boolean;

    constructor(private storeService: StoreService,
                private authService: AuthService,
                private router: Router,
                private cdr: ChangeDetectorRef,
                private notificationService: NotificationService,
                public dialog: MatDialog,
                private http: HttpClient) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin);

        this.storeObj = new Manufacturer(this.storeService, this.authService, 'edit');
        this.storeObj.setTemplates({upload_tpl: this.upload_tpl, man_short_select_tpl: this.man_short_select_tpl});

        // this.storeAssignedObj = new IfaMemberBadgeOverview(this.storeService, this.authService, 'edit');

        this.is_loading = false;
        this.cdr.detectChanges();
    }

    public rowSelected($event: any) {
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }
}
