import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import {AppSettings} from '../../config/AppSettings';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {

    @Input() file_type = 'mis_team_user';
    @Output() on_success: EventEmitter<any> = new EventEmitter();
    @ViewChild('file', { static: true }) file;
    public files: Set<File> = new Set();
    progress: any;
    showCancelButton = true;
    uploading = false;
    uploadSuccessful = false;

    constructor(private http: HttpClient) { }

    ngOnInit() {
    }

    addFiles() {
        this.file.nativeElement.click();
    }

    onFilesAdded() {
        const files: { [key: string]: File } = this.file.nativeElement.files;
        for (const key in files) {
            if (!isNaN(Number(key))) {
                this.files.add(files[key]);
            }
        }
    }

    public startUpload() {
        // set the component state to "uploading"
        this.uploading = true;

        // start the upload and save the progress map
        this.progress = this.upload(this.files);

        // convert the progress map into an array
        const allProgressObservables = [];
        // tslint:disable-next-line:forin
        for (const key in this.progress) {
            allProgressObservables.push(this.progress[key].progress);
        }


        // Hide the cancel-button
        this.showCancelButton = false;

        // When all progress-observables are completed...
        forkJoin(allProgressObservables).subscribe(end => {

            // ... the upload was successful...
            this.uploadSuccessful = true;

            // ... and the component is no longer uploading
            this.uploading = false;
        });
    }

    public upload(files: Set<File>): { [key: string]: { progress: Observable<number> } } {

        // this will be the our resulting map
        const status: { [key: string]: { progress: Observable<number> } } = {};

        files.forEach(file => {
            // create a new multipart-form for every file
            const formData: FormData = new FormData();
            formData.append('file', file, file.name);
            formData.append('file_type', this.file_type);

            // create a http-post request and pass the form
            // tell it to report the upload progress
            const req = new HttpRequest('POST', AppSettings.getRealAPILink() + '/mis/files', formData, {
                reportProgress: true,
                withCredentials: true
            });

            // create a new progress-subject for every file
            const progress = new Subject<number>();

            // send the http-request and subscribe for progress-updates
            this.http.request(req).subscribe(event => {
                if (event.type === HttpEventType.UploadProgress) {

                    // calculate the progress percentage
                    const percentDone = Math.round(100 * event.loaded / event.total);

                    // pass the percentage into the progress-stream
                    progress.next(percentDone);
                } else if (event instanceof HttpResponse) {

                    // Close the progress-stream if we get an answer form the API
                    // The upload is complete
                    progress.complete();
                    this.files = new Set();

                    if (this.on_success) {
                        this.on_success.emit({
                            file_id: event.body['rows'][0].file_id
                        });
                    }
                }
            });

            // Save every progress-observable in a map of all observables
            status[file.name] = {
                progress: progress.asObservable()
            };
        });

        // return the map of progress.observables
        return status;
    }
}
