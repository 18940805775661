<!--The content below is only a placeholder and can be replaced.-->
<p-toast position="bottom-right" key="br"></p-toast>
<app-cookie-consent *ngIf="showConsent"></app-cookie-consent>
<div>
    <mat-sidenav-container class="custom-sidenav-container"
                           [ngClass]="{'pinned-inner':pinned_menu && is_open && login_state === 2,'slide-inner':!pinned_menu && is_open && login_state === 2}">
        <mat-sidenav *ngIf="login_state === 2"
                     mode="side"
                     class="side-nav-content left-side-nav"
                     #sidenav
                     (openedChange)="openedChanged($event)"
                     [ngClass]="{'md-pinned-menu':pinned_menu,'md-slide-menu':!pinned_menu}">
            <div class="side-nav-inner mat-drawer-container">
                <!-- ngStyle height for better hover effect -->
                <div class="side-nav-menu-container theme-based-override-side-menu" [ngClass]="{'pinned-menu':pinned_menu,'slide-menu':!pinned_menu}"
                     [ngStyle]="{'height': height + 'px'}">
                    <div style="padding-bottom: 8px; padding-left: 8px;">
                        <button mat-icon-button *ngIf="login_state === 2 && sidenav" (click)="toggleSidenav()">
                            <mat-icon aria-label="Menu">menu</mat-icon>
                        </button>
                        <div class="only-hover-and-pinned" *ngIf="!is_small_device">
                            <button mat-icon-button (click)="togglePinnedMenu()" style="float: right;">
                                <!--<mat-icon aria-label="">lock</mat-icon>-->
                                <mat-animated-icon start="lock_open" end="lock" colorStart="none" colorEnd="none" [animate]="pinned_menu"></mat-animated-icon>
                            </button>
                        </div>
                    </div>
                    <div style="clear: both;">
                        <!--<app-search [is_flex]="true"
                                    [context]="search_context"
                                    *ngIf="show_search && is_small_device"></app-search>-->
                        <app-menu></app-menu>
                        <div style="min-height: 16px;"></div>
                        <div class="copy-right">
                            <span>©2024 IFA Team</span>
                        </div>
                    </div>
                </div>
            </div>
        </mat-sidenav>
        <mat-sidenav mode="side" position="end" class="side-nav-content">
        </mat-sidenav>
        <div class="inner-container"
             [ngClass]="{'pinned-inner-container':pinned_menu && is_open,'slide-inner-container':!pinned_menu && is_open}">
            <div class="top-bar" [ngClass]="{'mat-elevation-z6': !has_tabs}">
                <mat-toolbar class="" color="primary">
                    <button mat-icon-button *ngIf="login_state === 2 && !is_open && sidenav" (click)="toggleSidenav()">
                        <mat-icon aria-label="Menu">menu</mat-icon>
                    </button>

                    <a [routerLink]="'/main'" role="link">
                        <button mat-icon-button><mat-icon aria-label="Menu">home</mat-icon></button>
                    </a>

                    <button mat-icon-button *ngIf="show_back" (click)="goBack()">
                        <mat-icon aria-label="Menu">arrow_back</mat-icon>
                    </button>

                    <div>
                        <div class="top-menu-buttons">
                            <a routerLink="/bot"
                                    role="link">
                                <button mat-button color="primary">Bot</button>
                            </a>
                            <a routerLink="/rally"
                                    role="link" *ngIf="login_state === 2">
                                <button mat-button color="primary">Rally</button>
                            </a>
                            <a routerLink="/guides"
                                    role="link" *ngIf="login_state === 2">
                                <button mat-button color="primary">Guides</button>
                            </a>
<!--                            <button mat-buttoncolor="primary" routerLink="/upcoming-events" routerLinkActive="active">Upcoming Events</button>-->
<!--                            <button mat-button color="primary" routerLink="/news" routerLinkActive="active">News</button>-->
                            <a routerLink="/bot/about"
                                    >
                                <button mat-button color="primary">About Bot</button>
                            </a>
                        </div>
                    </div>

                    <div>
                        {{page_title}}
                    </div>
                    <!--                    <div class="top-search-container">-->
                    <!--                        <mat-form-field appearance="legacy" class="full-width">-->
                    <!--                            <mat-label>Suche</mat-label>-->
                    <!--                            <input matInput placeholder="" class="search"/>-->
                    <!--                            <mat-icon matSuffix>search</mat-icon>-->
                    <!--                        </mat-form-field>-->
                    <!--                    </div>-->


                    <!-- logged in toolbar -->
                    <div class="logged-in toolbar-right" *ngIf="login_state === 2">
                        <!--                        <div style="float: left;" *ngIf="show_search && !is_small_device">-->
                        <!--                            <div>-->
                        <!--                                <app-search [context]="search_context"></app-search>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <div style="float: left;">
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>face</mat-icon>
                            </button>

                            <!-- user menu -->
                            <mat-menu #menu="matMenu" class="theme-based-override-user-menu">
                                <div *ngIf="user" style="padding: 16px;">
                                    <div *ngIf="user.details && user.details.username" style="padding: 16px;">
                                        Hallo {{user.details.username}}
                                    </div>
                                    <div *ngIf="!user.details || !user.details.username" style="padding: 16px;">
                                        Hallo {{user.user}}
                                    </div>
                                </div>
                                <div *ngIf="user" style="margin-bottom: 8px;">
                                    <button mat-menu-item (click)="router.navigateByUrl('/profile')">
                                        <mat-icon>user</mat-icon>
                                        <span>Profile</span>
                                    </button>
                                </div>
                                <!--
                                <div mat-menu-item disabled>
                                    Firstname Lastname
                                </div>
                                <button mat-menu-item disabled>
                                    <mat-icon>voicemail</mat-icon>
                                    <span>Check voicemail</span>
                                </button>
                                <button mat-menu-item>
                                    <mat-icon>notifications_off</mat-icon>
                                    <span>Disable alerts</span>
                                </button>
                                -->

<!--                                <div style="padding-left: 12px; padding-right: 12px;">-->
<!--                                    <mat-form-field>-->
<!--                                        <mat-select placeholder="Theme" [(value)]="selected_theme" (selectionChange)="themeSelected($event)">-->
<!--                                            <mat-option *ngFor="let theme of themes" [value]="theme.value">-->
<!--                                                {{theme.name}}-->
<!--                                            </mat-option>-->
<!--                                        </mat-select>-->
<!--                                    </mat-form-field>-->
<!--                                </div>-->
                                <div style="padding-left: 12px; padding-right: 12px;">
                                    <button mat-raised-button [routerLink]="'/logout'" class="logout-button full-width shorten-menu-text" (click)="hideSideBar()">
                                        Logout
                                    </button>
                                </div>
                            </mat-menu>
                        </div>
                    </div>
                    <div class="toolbar-right" *ngIf="login_state !== 2">
                        <div>
                            <button mat-raised-button class="button-login-discord" (click)="doLogin()">
                                Login with Discord
                            </button>
                        </div>
                    </div>
                </mat-toolbar>
            </div>
            <div class="scroll-content" style="text-align:center;" [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
                <router-outlet #o="outlet"></router-outlet>
            </div>
        </div>
    </mat-sidenav-container>
</div>
