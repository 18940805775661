import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize } from 'rxjs/operators';



// @ts-ignore
import demoBuslines from './../../assets/data/buslines.json';
// @ts-ignore
import demoReports from './../../assets/data/reports.json';
// @ts-ignore
import demoMisTeamUser from './../../assets/data/mis-team-user.json';
// @ts-ignore
import demoAuthUser from './../../assets/data/auth-user.json';
// @ts-ignore
import demoMisJobs from './../../assets/data/mis-jobs.json';
// @ts-ignore
import demoBahnstellen from './../../assets/data/bahnstellen.json';
// @ts-ignore
import demoMisJobMails from './../../assets/data/mis-job-mails.json';
// @ts-ignore
import demoMisJobMailReceiver from './../../assets/data/mis-job-mails-receiver.json';
// @ts-ignore
import demoMisJobValidation from './../../assets/data/mis-job-validation.json';
// @ts-ignore
import demoMisRegionen from './../../assets/data/regionen.json';
// @ts-ignore
import demoGesellschaften from './../../assets/data/gesellschaften.json';
// @ts-ignore
import demoAmdGesellschaften from './../../assets/data/amd-gesellschaften.json';
// @ts-ignore
import demoBuendel from './../../assets/data/buendel.json';
// @ts-ignore
import demoSelectentries from './../../assets/data/selectentries.json';

import {AppSettings} from '../config/AppSettings';

// array in local storage for registered users
const users = JSON.parse(localStorage.getItem('users')) || [];

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { url, method, headers, body } = request;

        // wrap in delayed observable to simulate server api call
        return of(null)
            .pipe(mergeMap(handleRoute))
            .pipe(materialize()) // call materialize and dematerialize to ensure delay even if an error is thrown
            // (https://github.com/Reactive-Extensions/RxJS/issues/648)
            .pipe(delay(500))
            .pipe(dematerialize());

        function handleRoute() {
            switch (true) {
                case url.endsWith('/mis/auth/user') && method === 'GET' && AppSettings.demo_mode:
                    return getAuthUser();
                case url.endsWith('/mis/reports') && method === 'GET' && AppSettings.demo_mode:
                    return getReports();
                case url.endsWith('/bus/lines') && method === 'GET' && AppSettings.demo_mode:
                    return getBusLines();
                case url.endsWith('/mis/team/user') && method === 'GET' && AppSettings.demo_mode:
                    return getMisTeamUser();
                case url.indexOf('mis/jobs?') > 0 && method === 'GET' && AppSettings.demo_mode:
                    return getMisJobs();
                case url.endsWith('/mis/bus/bahnstellen') && method === 'GET' && AppSettings.demo_mode:
                    return getBahnstellen();
                case url.endsWith('/mails') && method === 'GET' && AppSettings.demo_mode:
                    return getMisJobsMails();
                case url.endsWith('/mails/receiver') && method === 'GET' && AppSettings.demo_mode:
                    return getMisJobsMailReceiver();
                case url.endsWith('/validation') && method === 'GET' && AppSettings.demo_mode:
                    return getMisJobsValidation();
                case url.endsWith('/mis/bus/regionen') && method === 'GET' && AppSettings.demo_mode:
                    return getRegionen();
                case url.endsWith('/mis/bus/buendel') && method === 'GET' && AppSettings.demo_mode:
                    return getBundel();
                case url.endsWith('/mis/bus/gesellschaften') && method === 'GET' && AppSettings.demo_mode:
                    return getGesellschaften();
                case url.endsWith('/mis/bus/amd/gesellschaften') && method === 'GET' && AppSettings.demo_mode:
                    return getAmdGesellschaften();
                case url.endsWith('/mis/bus/selectentries') && method === 'GET' && AppSettings.demo_mode:
                    return getSelectentries();
                default:
                    // pass through any requests not handled above
                    return next.handle(request);
                /*
                case url.endsWith('/users/register') && method === 'POST':
                    return register();
                case url.endsWith('/users/authenticate') && method === 'POST':
                    return authenticate();
                case url.endsWith('/users') && method === 'GET':
                    return getUsers();
                case url.match(/\/users\/\d+$/) && method === 'GET':
                    return getUserById();
                case url.match(/\/users\/\d+$/) && method === 'DELETE':
                    return deleteUser();
                default:
                    // pass through any requests not handled above
                    return next.handle(request);
            */
            }
        }

        // route functions

        function getAuthUser() {
            return ok(demoAuthUser);
        }

        function getReports() {
            return ok(demoReports);
        }

        function getBundel() {
            return ok(demoBuendel);
        }
        function getGesellschaften() {
            return ok(demoGesellschaften);
        }
        function getAmdGesellschaften() {
            return ok(demoAmdGesellschaften);
        }
        function getSelectentries() {
            return ok(demoSelectentries);
        }
        function getRegionen() {
            return ok(demoMisRegionen);
        }

        function getBusLines() {
            return ok(demoBuslines);
        }

        function getMisTeamUser() {
            return ok(demoMisTeamUser);
        }

        function getMisJobs() {
            return ok(demoMisJobs);
        }

        function getBahnstellen() {
            return ok(demoBahnstellen);
        }

        function getMisJobsMails() {
            return ok(demoMisJobMails);
        }

        function getMisJobsMailReceiver() {
            return ok(demoMisJobMailReceiver);
        }

        function getMisJobsValidation() {
            return ok(demoMisJobValidation);
        }
        /*
        function register() {
            const user = body;

            if (users.find(x => x.username === user.username)) {
                return error('Username "' + user.username + '" is already taken');
            }

            user.id = users.length ? Math.max(...users.map(x => x.id)) + 1 : 1;
            users.push(user);
            localStorage.setItem('users', JSON.stringify(users));

            return ok();
        }

        function authenticate() {
            const { username, password } = body;
            const user = users.find(x => x.username === username && x.password === password);
            if (!user) {
                return error('Username or password is incorrect');
            }
            return ok({
                id: user.id,
                username: user.username,
                firstName: user.firstName,
                lastName: user.lastName,
                token: 'fake-jwt-token'
            });
        }
        function getUsers() {
            if (!isLoggedIn()) {
                return unauthorized();
            }
            return ok(users);
        }

        function getUserById() {
            if (!isLoggedIn()) {
                return unauthorized();
            }

            const user = users.find(x => x.id === idFromUrl());
            return ok(user);
        }

        function deleteUser() {
            if (!isLoggedIn()) {
                return unauthorized();
            }

            users = users.filter(x => x.id !== idFromUrl());
            localStorage.setItem('users', JSON.stringify(users));
            return ok();
        }
        */

        // helper functions
        // tslint:disable-next-line:no-shadowed-variable
        function ok(body?: any) {
            return of(new HttpResponse({ status: 200, body }));
        }

        function unauthorized() {
            return throwError({ status: 401, error: { message: 'Unauthorised' } });
        }

        function error(message) {
            return throwError({ error: { message } });
        }

        function isLoggedIn() {
            return headers.get('Authorization') === 'Bearer fake-jwt-token';
        }

        function idFromUrl() {
            const urlParts = url.split('/');
            return Number(urlParts[urlParts.length - 1]);
        }
    }
}

export const fakeBackendProvider = {
    // use fake backend in place of Http service for backend-less development
    provide: HTTP_INTERCEPTORS,
    useClass: FakeBackendInterceptor,
    multi: true
};
