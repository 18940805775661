import { Component } from '@angular/core';

@Component({
  selector: 'app-bot-about',
  templateUrl: './bot-about.component.html',
  styleUrls: ['./bot-about.component.css']
})
export class BotAboutComponent {
  public markdown = '# About Star-Citizen-Atlantis-Bot\n' +
      '' +
      '## What is Star-Citizen-Atlantis-Bot\n' +
      'A Discord bot to organize events such as mining or salvaging for Star Citizen.  ' +
      'It calculates the credits to be received or paid for all participants.  ' +
      'Statistics are created, and events can be displayed on our website xxxx.de by registering with Discord.  ' +
      'Also, it allows cross-server reporting of, for example, ore deposits with mass, materials, and value.  ' +
      '\n\n\n' +
      '## Setup\n' +
      '' +
      '### Invite\n' +
      'Use the [invite link](https://discord.com/api/oauth2/authorize?client_id=1033082685855371376&permissions=2147568704&scope=bot) to invite **Star-Citizen-Atlantis-Bot** to your server and authorize your account, selecting your server.\n' +
      '### Config\n' +
      '1. add role(s) that can setup and edit events\n\n' +
      '```json\n' +
      '/scb add mod_role **role**\n' +
      '```\n' +
      '3. add role(s) that can see and join events\n\n' +
      '```json\n' +
      '/scb add member_role **role**\n' +
      '```\n' +
      '4. set channel for broadcast \n\n' +
      '```json\n' +
      '/scb set mining-broadcast-channel **channel**\n' +
      '```\n' +
      '## Other Commands\n' +
      '```json\n' +
      '/scb add event type:\n' +
      '```\n' +
      '```json\n' +
      '/scb refresh event event_id:\n' +
      '```\n' +
      '```json\n' +
      '/scb edit type:(event description|event name|event tax|event ime) event_id:\n' +
      '```\n' +
      '```json\n' +
      '/scb details event event_id:\n' +
      '```\n' +
      '```json\n' +
      '/scb broadcast type type:\n' +
      '```\n' +
      '```json\n' +
      '/scb log ore-delivery\n' +
      '```\n' +
      '```json\n' +
      '/scb details mod_role\n' +
      '```\n';
}
