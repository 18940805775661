import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {Observable, of, switchMap} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle} from '@angular/material/dialog';
import {map, startWith} from 'rxjs/operators';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from '@angular/material/autocomplete';
import {MatButton} from '@angular/material/button';
import {StoreService} from '../../../services/store.service';
import {Store} from '../../../class/store';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'app-contract-set-picker',
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormField,
        MatInput,
        MatAutocompleteTrigger,
        MatAutocomplete,
        MatOption,
        MatDialogActions,
        MatButton,
        MatLabel,
        CommonModule
    ],
    templateUrl: './contract-set-picker.component.html',
    styleUrl: './contract-set-picker.component.css'
})
export class ContractSetPickerComponent implements OnInit {
    public form: FormGroup;
    public inputSetControl = new FormControl();
    public filteredOptions: Observable<any[]>;
    public isLoading = true;
    public store: Store;

    constructor(
        private fb: FormBuilder,
        private http: HttpClient,
        public dialogRef: MatDialogRef<ContractSetPickerComponent>,
        private storeService: StoreService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.form = this.fb.group({
            selected_set: this.inputSetControl,
            selected_count: ''
        });
    }

    ngOnInit() {

        this.filteredOptions = this.inputSetControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value))
        );

        this.store = this.storeService.getStore('commodity_sets');
        this.store.loadStore('').then((records: any[]) => {
            this.isLoading = false;


        });
    }

    private _filter(value: any): any[] {
        let filterValue = '';
        if (typeof value === 'string') {
            filterValue = value.toLowerCase();
        } else if (value && value.data) {
            filterValue = value.data.set_name.toLowerCase();
        }
        return this.store.getRecords().filter(option => option.data.set_name.toLowerCase().includes(filterValue));
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
        this.dialogRef.close(this.form.value);
    }

    public displayFn(option?: any): string | undefined {
        return option ? option.data.set_name : undefined;
    }
}
