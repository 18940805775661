<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading" [loading_text]="loading_text"></app-content-container>
</div>
<ng-template #page_content>
    <div class="padding">
        <div class="card card-1 padding table-wrapper">
            <app-fb-forms-table [storeObj]="UserStore"
                                [edit_allowed]="true"
                                (row_click)="rowSelected($event)"
                                (add_click)="addClicked()"
                                [show_add]="true"
                                [show_delete]="true"
                                [title]="'User'"
            >
            </app-fb-forms-table>
        </div>
    </div>
<!--    <button mat-fab class="button-add button-bottom" (click)="openNewUser()" *ngIf="is_admin">-->
<!--        <mat-icon aria-label="neu">add</mat-icon>-->
<!--    </button>-->
</ng-template>
