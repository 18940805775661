import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {FbFormObject} from '../../../class/fb_form_object';
import {StoreService} from '../../../services/store.service';
import {AuthService} from '../../../services/auth.service';
import {NotificationService} from '../../../services/notification.service';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ViewportScroller} from '@angular/common';
import {ExplorePoi} from '../../../forms/explore-poi';
import {AppSettings} from '../../../config/AppSettings';
import {FileUpload2Component} from '../../../shared/file-upload2/file-upload2.component';
import {FbUtils} from '../../../class/fb-utils';
import {map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-points-of-interest-details',
    templateUrl: './points-of-interest-details.component.html',
    styleUrls: ['./points-of-interest-details.component.css']
})
export class PointsOfInterestDetailsComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
        this._select_caption_tpl = value;
        this.cdr.markForCheck();
    }

    @ViewChild('upload_tpl', {static: true})  public upload_tpl: ElementRef;

    public loading_text = '';
    public is_loading = true;
    public fields: any[];
    public mode = 'edit';
    public edit = false;
    public owner = false;
    public rows = [];
    public count = 0;
    public toc_summary: any[];
    public toc_content: any[];

    private subs: Array<Subscription> = [];
    public record: any;
    public id: string|number = '';
    private storeObj: FbFormObject;

    public files = [];
    public max_file_count = 1;
    public url_file_store = AppSettings.getRealAPILink() + 'media';
    private file_upload: FileUpload2Component;

    public can_add = false;
    public can_edit = false;
    public is_admin: boolean;

    private _select_caption_tpl: ElementRef;

    constructor(private storeService: StoreService,
                private authService: AuthService,
                private notificationService: NotificationService,
                private route: ActivatedRoute,
                private cdr: ChangeDetectorRef,
                public dialog: MatDialog,
                private http: HttpClient) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {

        this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin);


        this.subs.push(this.route.params.subscribe((params: any) => {
            if (params.id && params.id !== 'add') {
                this.mode = 'edit';
                this.id = params.id;
            } else {
                this.mode = 'add';
                // this.edit = true;
            }

            const filter = [];
            filter.push('filter[poi_id][0][mode]=equals');
            filter.push('filter[poi_id][0][operator]=and');
            filter.push('filter[poi_id][0][value]=' + this.id);
            filter.push('first=0');
            filter.push('rows=1');

            this.storeObj = new ExplorePoi(this.storeService, this.authService, this.mode);
            this.storeObj.init({select_caption_tpl: this._select_caption_tpl, upload_tpl: this.upload_tpl}, {},
                '?' + filter.join('&')).then((success) => {
                this.fields = this.storeObj.getEditFields();
                if (this.id && this.id !== '' && this.mode === 'edit') {
                    const rec = this.storeObj.getStore().getRecordById(this.id);
                    if (rec) {
                        this.record = rec;
                        this.owner = String(this.record.data.user_id) === String(this.authService.getUserId());
                        this.can_edit = this.owner || this.is_admin;
                    }
                } else {
                    this.resetModel();
                }
                this.rebuildForm();
                this.is_loading = false;
            });

        }));
    }

    public rebuildForm() {
        this.fields = [];
        this.cdr.detectChanges();
        this.fields = this.storeObj.getForm(this.record.data);
        this.cdr.detectChanges();
    }

    private resetModel() {
        this.record = this.storeObj.getDefaultModel();
        this.record.data.user_id = this.authService.getUserId();
    }

    public saveStore(event: any) {
        if (this.mode === 'edit') {
            this.storeObj.getStore().patchRecord(this.record, '').then((res) => {
                if (res && res.success) {
                    this.notificationService.success('Data',
                        'Speichern erfolgreich.', {timeOut: 5000});
                    this.edit = false;
                } else {
                    this.notificationService.error('Data',
                        'Speichern fehlgeschlagen.', {timeOut: 5000});
                }
            });
        } else {
            this.storeObj.getStore().addRecord(this.record.data, '').then((res) => {
                if (res && res.success) {
                    this.notificationService.success('Data',
                        'Speichern erfolgreich.', {timeOut: 5000});
                    this.resetModel();
                    this.rebuildForm();
                } else {
                    this.notificationService.error('Data',
                        'Speichern fehlgeschlagen.', {timeOut: 5000});
                }
            });
        }
    }


    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
        if (this.mode === 'edit') {
            this.storeObj.getStore().revertRecord(this.record);
        }
    }

    public scrollTo(target: string) {
        document.getElementById(target).scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
        });
    }

    public tocCreated($event, type) {
        console.log($event, type);
        switch (type) {
            case 'toc_summary':
                this.toc_summary = $event;
                break;
            case 'toc_content':
                this.toc_content = $event;
                break;
        }
        this.cdr.detectChanges();
    }

    public onFileuploadInit(file_upload: FileUpload2Component) {
        this.file_upload = file_upload;
    }

    public onFileUpload($event, type, element) {
        this.record.data.file_id = $event.body.rows[0].file_id;
        if ($event.ok) {
            // this.FileStore.reloadStore('').then(records => {
            this.notificationService.success('Daten',
                'Speichern erfolgreich.', {timeOut: 5000});
            // });
        } else {
            this.notificationService.error('Daten',
                'Speichern fehlgeschlagen.', {timeOut: 5000});
            this.cdr.detectChanges();
        }
    }

    enterEditMode() {
        this.edit = true;
        this.cdr.detectChanges();
    }

    deleteFile(file_id: number) {

        return this.http.delete(FbUtils.formatUrlForApi(AppSettings.getRealAPILink() + 'media/' + file_id)).pipe(
            map((user: any) => {
            })
        ).subscribe((result) => {
            this.record.data.file_id = 0;
        });
    }
}
