import {FbFormObject} from '../class/fb_form_object';

export class Guide extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'caption', label: 'Caption', visible: true, noclick: false, disable_ripple: true},
            {id: 'tag', label: 'Tags', visible: true, noclick: false, disable_ripple: true},
            {id: 'summary', label: 'Summary', visible: true, noclick: false, disable_ripple: true, max_width: '300px'},
            {id: 'content', label: 'Content', visible: true, noclick: false, disable_ripple: true, max_width: '300px'},
            {id: 'game_version', label: 'Game Version', visible: true, noclick: false, disable_ripple: true},
            {id: 'ga_user_id', label: 'Author', visible: true, noclick: false, disable_ripple: true},
            {id: 'public', label: 'public', visible: false, noclick: false, disable_ripple: true, not_in_table: true},
            {id: 'published', label: 'published', visible: false, noclick: false, disable_ripple: true, not_in_table: true}
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'caption',
                class: 'card card-1 padding single-column',
                field_class: '',
                fields: [
                    {
                        type: 'text',
                        name: 'caption'
                    },
                    {
                        type: 'chip_autocomplete',
                        name: 'tag',
                        options: this.select_boxes['tags_select']
                    },
                    {
                        type: 'chip_autocomplete',
                        name: 'ga_user_id',
                        options: this.select_boxes['co_author_select']
                    }
                ]
            },
            {
                caption: 'Summary',
                class: 'card card-1 padding single-column',
                field_class: '',
                fields: [
                    {
                        type: 'editor',
                        name: 'summary'
                    }
                ]
            },
            {
                caption: 'Content',
                class: 'card card-1 padding single-column',
                field_class: '',
                fields: [
                    {
                        type: 'editor',
                        name: 'content'
                    }
                ]
            },
            {
                caption: 'Game Version',
                class: 'card card-1 padding single-column',
                field_class: '',
                fields: [
                    {
                        type: 'select',
                        name: 'game_version',
                        options: this.select_boxes['sc_version_select']
                    },
                    {
                        type: 'checkbox',
                        name: 'public'
                    },
                    {
                        type: 'checkbox',
                        name: 'published'
                    }
                ]
            },
        ];
        super.setEditFields();
    }

    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.stores['guides'] = this.storeService.getStore('guides');
            this.stores['sc_version'] = this.storeService.getStore('sc_version');
            this.stores['ifa_member'] = this.storeService.getStore('ifa_member');

            const tagFilter = [];
            tagFilter.push('filter[type][0][mode]=equals');
            tagFilter.push('filter[type][0][operator]=and');
            tagFilter.push('filter[type][0][value]=guide');
            tagFilter.push('first=0');
            tagFilter.push('rows=100');

            this.stores['guide_tags'] = this.storeService.createNoneGlobalStore('guide_tags',
                'tags', 'tag');

            this.main_store = this.stores['guides'];

            const stores = [];
            stores.push(this.stores['guides'].loadStore(this.filter));
            stores.push(this.stores['sc_version'].loadStore(''));
            stores.push(this.stores['guide_tags'].loadStore('?' + tagFilter.join('&')));
            stores.push(this.stores['ifa_member'].loadStore('?rows=1000'));

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
                caption: '',
                tag: '',
                content: '',
                summary: '',
                game_version: 0,
                published: 0
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        this.parseVersionSelectBox();
        this.parsCoAuthorSelectBox();
        this.parsTagsSelectBox();
        // call super
        super.setSelectboxes();
    }

    public parseVersionSelectBox() {
        const options = [];
        const records = this.stores['sc_version'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.version_id),
                name: records[i].data.version_name
            });
        }
        this.select_boxes['sc_version_select'] = options;
        return this.select_boxes['sc_version_select'];
    }

    public parsTagsSelectBox() {
        const options = [];
        const records = this.stores['guide_tags'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.tag),
                name: records[i].data.tag
            });
        }
        this.select_boxes['tags_select'] = options;
        return this.select_boxes['tags_select'];
    }

    public parsCoAuthorSelectBox() {
        const options = [];
        const records = this.stores['ifa_member'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.user_id),
                name: records[i].data.sc_username
            });
        }
        this.select_boxes['co_author_select'] = options;
        return this.select_boxes['co_author_select'];
    }
}
