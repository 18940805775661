<div class="padding">
    <div class="card card-1 padding table-wrapper" *ngIf="storeObject">
        <app-fb-forms-table [storeObj]="storeObject"
                            [edit_allowed]="is_owner"
                            [storeExtObj]="{c_id: c_id, contract_type: contract_type}"
                            (row_click)="rowSelected($event)"
                            [stateKey]="'sc-contract-rewards'"
                            [show_add_in_view]="is_owner"
                            [show_delete]="is_owner"
                            [title]="'Rewards'"
        >
        </app-fb-forms-table>
    </div>
</div>
