<ng-container *ngIf="is_mobile">
    <article class="tile-card card card-1 sc-box" [ngClass]="customClass" [ngStyle]="style" matRipple>
        <div class="menuCardContainer">
            <div class="inner-card-bg-with-border">
                <h4 class="truncate-header">{{title}}</h4>
                <i class="{{icon}} card-icon" *ngIf="icon !== ''"></i>
            </div>
        </div>
    </article>
</ng-container>
<ng-container *ngIf="!is_mobile">
    <article class="tile-card card card-1 sc-box" [ngClass]="customClass" [ngStyle]="style" matTooltip="{{title}}" matRipple>
        <div class="menuCardContainer">
            <div class="inner-card-bg-with-border">
                <h4 class="truncate-header">{{title}}</h4>
                <i class="{{icon}} card-icon" *ngIf="icon !== ''"></i>
            </div>
        </div>
    </article>
</ng-container>
