<div class="padding">
    <div class="card card-1 padding table-wrapper" *ngIf="storeObj">
        <app-fb-forms-table [storeObj]="storeObj"
                            [edit_allowed]="is_admin"
                            (row_click)="rowSelected($event)"
                            [stateKey]="'commodity-categories'"
                            [show_add_in_view]="is_admin"
                            [show_delete]="is_admin"
                            [title]="'Categories'"
        >
        </app-fb-forms-table>
    </div>
</div>
