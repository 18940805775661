<div class="card card-1 padding table-wrapper" *ngIf="storeObj && parent_row">
    <app-fb-forms-table [storeObj]="storeObj"
                        [edit_allowed]="true"
                        [storeExtObj]="{parent_row: parent_row.data}"
                        (row_click)="rowSelected($event)"
                        [stateKey]="'location-data-' + type"
                        [show_add_in_view]="true"
                        [show_delete]="true"
                        [title]="title"
    >
    </app-fb-forms-table>
</div>