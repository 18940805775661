import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {FbFormObject} from '../../../class/fb_form_object';
import {StoreService} from '../../../services/store.service';
import {AuthService} from '../../../services/auth.service';
import {NotificationService} from '../../../services/notification.service';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {Guide} from '../../../forms/guide';
import {DOCUMENT, ViewportScroller} from '@angular/common';
import {forEach} from 'lodash';
import {marked} from 'marked';
import use = marked.use;

@Component({
  selector: 'app-guide-edit',
  templateUrl: './guide-edit.component.html',
  styleUrls: ['./guide-edit.component.css']
})
export class GuideEditComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
    this._select_caption_tpl = value;
    this.cdr.markForCheck();
  }

  public loading_text = '';
  public is_loading = true;
  public fields: any[];
  public mode = 'edit';
  public edit = false;
  public owner = false;
  public rows = [];
  public count = 0;
  public toc_summary: any[];
  public toc_content: any[];

  private subs: Array<Subscription> = [];
  public record: any;
  public id: string|number = '';
  private storeObj: FbFormObject;

  private _select_caption_tpl: ElementRef;

  constructor(private storeService: StoreService,
              private authService: AuthService,
              private notificationService: NotificationService,
              private route: ActivatedRoute,
              private cdr: ChangeDetectorRef,
              public dialog: MatDialog,
              private scroller: ViewportScroller) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.subs.push(this.route.params.subscribe((params: any) => {
      if (params.id && params.id !== 'add') {
        this.mode = 'edit';
        this.id = params.id;
      } else {
        this.mode = 'add';
        // this.edit = true;
      }

      const filter = [];
      filter.push('filter[guide_id][0][mode]=equals');
      filter.push('filter[guide_id][0][operator]=and');
      filter.push('filter[guide_id][0][value]=' + this.id);
      filter.push('first=0');
      filter.push('rows=1');

      this.storeObj = new Guide(this.storeService, this.authService, this.mode);
      this.storeObj.init({select_caption_tpl: this._select_caption_tpl}, {},
          '?' + filter.join('&')).then((success) => {
        this.fields = this.storeObj.getEditFields();
        if (this.id && this.id !== '' && this.mode === 'edit') {
          const rec = this.storeObj.getStore().getRecordById(this.id);
          if (rec) {
            console.log('rec', rec);
            this.record = rec;
            this.owner = String(this.record.data.user_id) === String(this.authService.getUserId());
            if (rec.data.ga_user_id) {
              const user_owen = rec.data.ga_user_id.split(',');
              for (let i = 0; i < user_owen.length; i++) {
                if (String(this.authService.getUserId()) === user_owen[i]) {
                  this.owner = true;
                }
              }
            }
          }
        } else {
          this.resetModel();
        }
        this.rebuildForm();
        this.is_loading = false;
      });

    }));
  }

  public rebuildForm() {
    this.fields = [];
    this.cdr.detectChanges();
    this.fields = this.storeObj.getForm(this.record.data);
    this.cdr.detectChanges();
  }

  private resetModel() {
    this.record = this.storeObj.getDefaultModel();
    this.record.data.user_id = this.authService.getUserId();
  }

  public saveStore(event: any) {
    if (this.mode === 'edit') {
      this.storeObj.getStore().patchRecord(this.record, '').then((res) => {
        if (res && res.success) {
          this.notificationService.success('Data',
              'Speichern erfolgreich.', {timeOut: 5000});
          this.edit = false;
        } else {
          this.notificationService.error('Data',
              'Speichern fehlgeschlagen.', {timeOut: 5000});
        }
      });
    } else {
      this.storeObj.getStore().addRecord(this.record.data, '').then((res) => {
        if (res && res.success) {
          this.notificationService.success('Data',
              'Speichern erfolgreich.', {timeOut: 5000});
          this.resetModel();
          this.rebuildForm();
        } else {
          this.notificationService.error('Data',
              'Speichern fehlgeschlagen.', {timeOut: 5000});
        }
      });
    }
  }


  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
    if (this.mode === 'edit') {
      this.storeObj.getStore().revertRecord(this.record);
    }
  }

  public scrollTo(target: string) {
    document.getElementById(target).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }

  public tocCreated($event, type) {
    console.log($event, type);
    switch (type) {
      case 'toc_summary':
        this.toc_summary = $event;
        break;
      case 'toc_content':
        this.toc_content = $event;
        break;
    }
    this.cdr.detectChanges();
  }

  public authorsWithLinebreak(authors: string) {
    if (authors !== '') {
      const arAuthors = authors.split(',');
      return arAuthors.join(',<br>');
    }
    return '';
  }
}
