import {FbFormObject} from '../class/fb_form_object';

export class MediaShort extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'file_date', label: 'Date', visible: true, noclick: false, disable_ripple: true},
            {id: 'original_filename', label: 'Org Filename', visible: true, noclick: false, disable_ripple: true},
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Media',
                class: 'card card-1 padding single-column',
                field_class: '',
                fields: [
                    {
                        type: 'text',
                        name: 'file_date'
                    },
                    {
                        type: 'text',
                        name: 'original_filename'
                    }
                ]
            }
        ];
        super.setEditFields();
    }

    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.stores['media'] = this.storeService.getStore('media');

            this.main_store = this.stores['media'];
            const stores = [];
            stores.push(this.stores['media'].loadStore(this.filter));
            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        // call super
        super.setSelectboxes();
    }

}
