<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div class="padding">
        {{max_file_count - files.length > 0}}
        <ng-container *ngIf="max_file_count - files.length > 0">
            <app-file-upload2 [file_type]="'media'"
                              (on_success)="onFileUpload($event, 'media', element)"
                              [url]="url_file_store"
                              [show_save_button]="true"
                              [parent_object_id]="'0'"
                              [max_count]="max_file_count - files.length"
                              [accept]="'.jpeg,.jpg,.png,image/*'"
                              (init)="onFileuploadInit($event)"
            ></app-file-upload2>
        </ng-container>
    </div>
</ng-template>
