import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {AppSettings} from '../../config/AppSettings';
import {MatDialog} from '@angular/material/dialog';
import {CaptainListComponent} from '../../shared/dialoge/captain-list/captain-list.component';
import {MatChipSelectionChange} from '@angular/material/chips';
import {CookieService} from '../../services/cookie.service';
import {FormControl} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'app-fleet',
    templateUrl: './fleet.component.html',
    styleUrls: ['./fleet.component.css']
})
export class FleetComponent implements AfterViewInit, OnDestroy {

    public is_loading = true;
    public loading_text = '';
    public vehicles = [];
    public modelCount = [];
    public captains = [];
    public quickStats: any = {};
    public lastClassification = '';
    public showDetails = false;
    public searchText = '';
    public searchTags = [];

    public tags = new FormControl('');
    public tagsList: string[] = [];

    public pageSize = 1000;

    private selectFilter = [];
    private searchSubject = new Subject<string>();
    private tagSubject = new Subject<any[]>();
    private subs: Array<Subscription> = [];

    constructor(private http: HttpClient,
                public dialog: MatDialog,
                public cookieService: CookieService) {
    }

    ngAfterViewInit(): void {
        this.loadFleet('');
        this.searchSubject.pipe(debounceTime(500)).subscribe((searchValue) => {
            this.doSearch();
        });
        this.tagSubject.pipe(debounceTime(500)).subscribe((searchValue) => {
            this.doSearch();
        });
    }

    public loadFleet(classification: string) {

        const params = classification ? '?q[classification][]=' + classification : '';

        this.http.get<any[]>(AppSettings.getRealAPILink() + 'fleetyards/fleet', {withCredentials: true})
            .subscribe((result: any) => {
                this.is_loading = false;
                let records = [];
                if (result.success) {
                    records = result.rows;
                } else {
                    // old api
                    records = result;
                }
                this.is_loading = false;
            });
        this.http.get<any[]>(AppSettings.getRealAPILink() + 'fleetyards/fleet/vehicles' + params, {withCredentials: true})
            .subscribe((result: any) => {
                this.is_loading = false;
                let records = [];
                if (result.success) {
                    records = result.rows;
                } else {
                    // old api
                    records = result;
                }
                if (this.searchText) {
                    records = this.filterVehicle(records, this.searchText);
                }
                this.vehicles = records;
                this.is_loading = false;
            });
        this.http.get<any[]>(AppSettings.getRealAPILink() + 'fleetyards/fleet/members', {withCredentials: true})
            .subscribe((result: any) => {
                this.is_loading = false;
                let records = [];
                if (result.success) {
                    records = result.rows;
                } else {
                    // old api
                    records = result;
                }
                this.is_loading = false;
            });
        this.http.get<any[]>(AppSettings.getRealAPILink() + 'fleetyards/fleet/stats/model-count' + params, {withCredentials: true})
            .subscribe((result: any) => {
                this.is_loading = false;
                let records = [];
                if (result.success) {
                    records = result.rows;
                } else {
                    // old api
                    records = result;
                }
                this.modelCount = records[0].modelCounts;
                this.is_loading = false;
            });
        this.http.get<any[]>(AppSettings.getRealAPILink() + 'fleetyards/fleet/quick-stats' + params, {withCredentials: true})
            .subscribe((result: any) => {
                this.is_loading = false;
                let records = [];
                if (result.success) {
                    records = result.rows;
                } else {
                    // old api
                    records = result;
                }
                this.quickStats = records[0];
                this.is_loading = false;
            });
    }

    public getCaptains($event: any, model: string) {
        $event.stopPropagation();
        this.dialog.open(CaptainListComponent, {
            data: {model: model}
        });
    }

    public classificationChanged($event: MatChipSelectionChange, classification) {
        if ($event.selected) {
            if (this.lastClassification !== classification.name) {
                this.lastClassification = classification.name;
                this.loadFleet(classification.name);
            }
        } else {
            this.lastClassification = '';
            this.loadFleet('');
        }
    }

    public onTagChanged($event: any) {
        this.searchTags = $event.value;
        this.tagSubject.next(this.searchTags);
    }

    private doSearch() {
        // this.performSearch(searchValue);
        this.selectFilter = [];
        this.selectFilter.push('first=0');
        this.selectFilter.push('rows=' + this.pageSize);
        // if (this.searchText && this.searchText !== '') {
        //     this.selectFilter.push('filter[title][0][mode]=contains');
        //     this.selectFilter.push('filter[title][0][operator]=and');
        //     this.selectFilter.push('filter[title][0][value]=' + this.searchText);
        // }
        //
        // if (this.searchTags && this.searchTags.length) {
        //     for (let i = 0; i < this.searchTags.length; i++) {
        //         this.selectFilter.push('filter[tag][0][mode]=contains');
        //         this.selectFilter.push('filter[tag][0][operator]=and');
        //         this.selectFilter.push('filter[tag][0][value]=' + this.searchTags[i]);
        //     }
        // }

        this.loadFleet(this.lastClassification);
    }

    public onSearch() {
        this.searchSubject.next(this.searchText);
    }

    private filterVehicle(vehicles: any[], name: string) {
        const filtered = vehicles.filter((vehicle: any) => {
            if (vehicle.name.toLowerCase().includes(name.toLowerCase())) {
                return true;
            }
            return false;
        });
        return filtered;
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
        this.searchSubject.complete();
    }
}
