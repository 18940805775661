import {FbFormObject} from '../class/fb_form_object';

export class ScbEventExtraPayment extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'extra_amount_paid', label: 'extra paymend', visible: true, noclick: false, disable_ripple: true},
            {id: 'is_tax_free', label: 'tax free', visible: true, noclick: false, disable_ripple: true}
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Bonus',
                class: 'card card-1 padding single-column',
                field_class: '',
                fields: [
                    {
                        type: 'number',
                        name: 'extra_amount_paid',
                        disabled: false,
                        number_formatted: true
                    },
                    {
                        type: 'checkbox',
                        name: 'is_tax_free',
                        disabled: false
                    }
                ]
            }
        ];
        super.setEditFields();
    }

    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.stores['event_extra_delivery'] = this.storeService.createNoneGlobalStore('event-extra-delivery',
                '/games/starcitizen/events/' + String(ext_obj.event_id) + '/commodities/extra',
                'id');
            this.main_store = this.stores['event_extra_delivery'];

            const stores = [];
            if (!this.stores['event_extra_delivery'].isLoaded()) {
                stores.push(this.stores['event_extra_delivery'].loadStore(this.filter));
            }
            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
                event_id: this.custom_obj.event_id,
                extra_amount_paid: 0,
                ore_id: 0,
                user_id: 0,
                is_tax_free: 1
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        // call super
        super.setSelectboxes();
    }
}
