import {Component} from '@angular/core';
import {StoreService} from '../../../services/store.service';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-bot-resources',
  templateUrl: './bot-resources.component.html',
  styleUrls: ['./bot-resources.component.css']
})
export class BotResourcesComponent {

  public loading_text = '';
  public is_loading = true;
  public fields: any[];
  public mode = 'edit';
  public edit = false;
  public rows = [];
  public count = 0;

  public record: any;
  public cargoRows: any[];
  public oreRows: any[];
  public activeVersion: string;

  constructor(private storeService: StoreService,
              private authService: AuthService) {
    this.loadStores();
  }

  private loadStores() {
    const oreStore = this.storeService.createNoneGlobalStore('ore',
        'games/starcitizen/possible-commodities/ore', 'com_id');

    const cargoStore = this.storeService.createNoneGlobalStore('cargo',
        'games/starcitizen/possible-commodities/cargo', 'com_id');

    const activeVersionStore = this.storeService.createNoneGlobalStore('version',
        'games/starcitizen/active-version', 'com_id');

    const stores = [];
    // if (!this.stores['sc_events'].isLoaded()) {
    stores.push(oreStore.loadStore());
    stores.push(cargoStore.loadStore());
    stores.push(activeVersionStore.loadStore());

    Promise.all(stores).then((result) => {

      const cargoRecords = cargoStore.getRecords();
      for (let i = 0; i < cargoRecords.length; i++) {
        const rec = cargoRecords[i];
        rec.data.price = Number(rec.data.price);
      }
      this.cargoRows = cargoRecords;

      const oreRecords = oreStore.getRecords();
      for (let i = 0; i < oreRecords.length; i++) {
        const rec = oreRecords[i];
        rec.data.price = Number(rec.data.price);
      }
      this.oreRows = oreRecords;
      this.activeVersion = activeVersionStore.getRecords()[0].data.version_name;
      this.is_loading = false;
    });
  }

  public scrollTo(target: string) {
    document.getElementById(target).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }
}
