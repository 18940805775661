<div class="padding">
    <div class="card card-1 padding table-wrapper" *ngIf="!is_loading && k_rounder_tpl">
            <app-fb-forms-table [storeObj]="ScbCommodity"
                                [edit_allowed]="editAllowed"
                                [require_filter]="require_filter"
                                (row_click)="rowSelected($event)"
                                [storeExtObj]="ext_obj"
                                [stateKey]="'bot-event-' + com_type + '-delivery-' + event_base_version"
                                [show_add]="false"
                                [show_add_in_view]="true"
                                [show_delete]="editAllowed"
                                [storeTemplates]="storeTemplates"
                                (dataLoaded)="dataLoadedDone($event)"
                                [title]="'Event ' + com_type_name + ' Delivery ' + event_base_version"
            >
            </app-fb-forms-table>
    </div>
</div>
<ng-template #select_caption_tpl let-element="element" let-column="column">
    {{element.tmp.caption[column.id]}}
</ng-template>

<ng-template #k_rounder_tpl let-element="element" let-column="column">
    <div *ngIf="element.name">
        {{(element.model.obj[element.name] / 1000) | number:'1.0-1'}}k
    </div>
    <div *ngIf="!element.name">
        {{(element.data[column.id] / 1000) | number:'1.0-1'}}k
    </div>
</ng-template>
