import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {StoreService} from '../../services/store.service';
import {Store} from '../../class/store';
import {Observable, Subscription} from 'rxjs';
import {MenuEntries} from '../../class/menu-entries';
import {DomSanitizer} from '@angular/platform-browser';
import {MenuService} from '../../services/menu.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, OnDestroy {

    private store: Store;
    private subs: Array<Subscription> = [];

    public menu = [];
    public allReports$: Observable<any[]>;
    public loading_text = '';
    public is_loading = false;
    public last_tour_id = '';

    public is_admin = false;

    constructor(private authService: AuthService,
                private storeService: StoreService,
                private sanitizer: DomSanitizer,
                private menuService: MenuService,
                private router: Router) {
        this.is_admin = this.authService.isAdmin();
    }

    ngOnInit(): void {

        this.setupMenu();
        // this.authService.getUserAuthStoreObservable().subscribe((auth_records: any) => {
        //     if (auth_records && auth_records.length > 0) {
        //         this.allReports$.subscribe((records: any) => {
        //             if (records.length > 0) {
        //                 this.setupMenu();
        //             }
        //         });
        //         this.forms.loadAllRecords();
        //     }
        // });
    }

    private setupMenu() {
        const menuEntries = new MenuEntries(this.authService);
        this.menu = menuEntries.getEntries('', true, false);
    }


    private waitForNavElements(): Promise<any> {
        return new Promise((resolve, reject) => {
            let count = 0;
            const timer_id = setInterval(() => {
                count++;
                const acc = document.getElementsByClassName('sidenavAccordeon');
                if (acc) {
                    clearInterval(timer_id);
                    resolve(true);
                }
                if (count === 20) {
                    resolve(false);
                }
            });
        });
    }

    public logout() {
        this.closeMenu();
        this.router.navigateByUrl('/logout');
    }

    public closeMenu() {
        const sidenav = this.menuService.getSidenav();
        sidenav.toggle();
    }

    public closeMenuAfterClick($event) {
        if (!$event.ctrlKey) {
            this.closeMenu();
        }
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }
}
