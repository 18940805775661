import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[appCopyValue]',
    standalone: true
})
export class CopyValueDirective {
    @Input('appCopyValue') value: number | string;

    constructor(private el: ElementRef) {}

    @HostListener('dblclick') async onDoubleClick() {
        await this.copyToClipboard(this.value.toString());
    }

    private async copyToClipboard(value: string) {
        try {
            await navigator.clipboard.writeText(value);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    }
}
