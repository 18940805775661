<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div class="padding" *ngIf="owner && edit || mode === 'add'">
        <app-form-inputs *ngIf="fields && fields.length"
                         #tpl_form
                         [field_groups]="fields"
                         [edit_allowed]="true"
                         [loading]="false"
                         (on_submit)="saveStore($event)"
                         [button_class]="'button-save'">
        </app-form-inputs>
    </div>
    <div *ngIf="!edit && mode !== 'add'" class="padding">
        <div class="guide-side-menu">
            <div class="guide-toc card padding">
                <div>
                    By: <b [innerHTML]="record.data.sc_username"></b>
                </div>
                <ol>
                    <li (click)="scrollTo('information')">Information</li>
                    <app-table-of-content
                            *ngIf="toc_summary"
                            [toc]="toc_summary"></app-table-of-content>
                    <li (click)="scrollTo('location')">Location</li>
                    <app-table-of-content
                            *ngIf="toc_content"
                            [toc]="toc_content"></app-table-of-content>
                    <li *ngIf="record.data.file_id > 0"
                            (click)="scrollTo('picture')">Picture</li>
                </ol>
                <ng-container *ngIf="!edit && owner">
                    <button mat-mini-fab class="button-edit" (click)="edit = !edit"
                            matTooltip="bearbeiten">
                        <mat-icon aria-label="bearbeiten">edit</mat-icon>
                    </button>
                </ng-container>
            </div>
        </div>
        <div class="guide-content-container">
            <h2>{{record.data.title}}</h2>
            <section id="information">
                <h3 class="group-caption">Information</h3>
                <div class="card padding">
                    <app-sc-content [content]="record.data.description"
                                    [id]="'poi_info'"
                                    (tocChange)="tocCreated($event, 'toc_summary')"
                                    [checkboxContainerKey]="'checkboxes_poi_' + record.data.poi_id">
                    </app-sc-content>
                </div>
            </section>
            <section id="location">
                <h3 class="group-caption">Location</h3>
                <div class="card padding">
                    <tr *ngIf="record.data.coordinate_location">
                        <td class="bottom-row" colspan="6">{{record.data.coordinate_location}}, {{record.data.coordinate_x}},
                            {{record.data.coordinate_y}},{{record.data.coordinate_d}}°</td>
                    </tr>
                </div>
            </section>
            <section id="picture" *ngIf="record.data.file_id > 0">
                <h3 class="group-caption">Picture</h3>
                <div class="card padding">
                    <app-image-preview [file_id]="record.data.file_id"></app-image-preview>
                </div>
            </section>
        </div>
        <div style="clear: both;"></div>
    </div>
</ng-template>
<ng-template #upload_tpl let-element="element" let-column="column" let-edit="edit">

    <div *ngIf="element.model && element.model.obj.file_id > 0">
        <app-image-preview [file_id]="element.model.obj.file_id"></app-image-preview>
        <button mat-mini-fab class="button-delete" *ngIf="can_edit"
                style="margin-left: 16px;"
                (click)="deleteFile(element.model.obj.file_id)"
                matTooltip="löschen"
                type="button">
            <mat-icon aria-label="löschen">delete</mat-icon>
        </button>
    </div>

    <ng-container *ngIf="element.model && element.model.obj.poi_id > 0 &&
                         (element.model.obj.file_id === 0 || element.model.obj.file_id === null)
                         && edit">
        <app-file-upload2 [file_type]="'explore_poi'"
                          (on_success)="onFileUpload($event, 'explore_poi', element)"
                          [url]="url_file_store"
                          [show_save_button]="true"
                          [parent_object_id]="element.model.obj.poi_id"
                          [max_count]="1"
                          [reduce]="true"
                          [accept]="'.jpeg,.jpg,.png,image/*'"
                          (init)="onFileuploadInit($event)"
        ></app-file-upload2>
    </ng-container>
</ng-template>
