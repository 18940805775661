 <div class="content-container-no-cls">
    <div [ngClass]="(!custom_class)?'content-container':custom_class" class="content-container-wrapper">
        <mat-sidenav-container>
            <mat-sidenav #sidenav position="end" class="sidenav-right"
                         (openedChange)="rightSideNavStateChanged($event)">
                <div *ngIf="side_template">
                    <ng-container *ngTemplateOutlet="side_template"></ng-container>
                </div>
            </mat-sidenav>
            <mat-sidenav-content>
                <ng-container *ngIf="is_loading; else content">
                    <div class="centered-spinner">
                        <div style="max-width: 400px; min-width: 400px;">
                            {{loading_text}}
                            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                        </div>
                    </div>
                </ng-container>
                <ng-template #content>
                    <div class="content-filled">
                        <ng-container *ngTemplateOutlet="content_template"></ng-container>
                    </div>
                    <div class="page-footer-container">
                        <app-page-footer></app-page-footer>
                    </div>
                </ng-template>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
</div>
