<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'none'"></app-content-container>
</div>
<ng-template #page_content>
    <div *ngIf="mode === 'add'" class="padding">
        <app-form-inputs *ngIf="fields && fields.length"
                         #tpl_form
                         [field_groups]="fields"
                         [edit_allowed]="edit"
                         [loading]="false"
                         (on_submit)="saveStore($event)"
                         [button_class]="'button-save'">
        </app-form-inputs>
    </div>
    <ng-container *ngIf="mode === 'edit'">
        <div>
            <div class="padding">
                <app-form-inputs *ngIf="fields && fields.length"
                                 #tpl_form
                                 [field_groups]="fields"
                                 [edit_allowed]="edit"
                                 [loading]="false"
                                 (on_submit)="saveStore($event)"
                                 [button_class]="'button-save'">
                </app-form-inputs>
                <!--
                <div *ngIf="!edit && mode !== 'add'">
                    <button mat-raised-button
                            class="full-width button-edit" (click)="edit = !edit; rebuildForm()">bearbeiten</button>
                </div>
                -->
            </div>
        </div>
    </ng-container>
</ng-template>
<ng-template #select_caption_tpl let-element="element" let-column="column">
    {{element.tmp.caption[column.id]}}
</ng-template>
