<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading" [loading_text]="loading_text"
                           [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div *ngIf="is_loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div class="tiles padding">
        <a routerLink="/admin/games/starcitizen/sc-version" class="link-clear" *ngIf="is_admin">
            <app-icon-tile title="Version" icon="fas fa-code-branch"></app-icon-tile>
        </a>
        <a routerLink="/admin/games/starcitizen/race-types" class="link-clear" *ngIf="is_admin">
            <app-icon-tile title="Race Types" icon="fas fa-flag-checkered"></app-icon-tile>
        </a>
        <a routerLink="/admin/games/starcitizen/race-tracks" class="link-clear" *ngIf="is_admin">
            <app-icon-tile title="Race Tracks" icon="fas fa-truck-monster"></app-icon-tile>
        </a>

        <a routerLink="/admin/games/starcitizen/manufacturer" class="link-clear" *ngIf="is_admin">
            <app-icon-tile title="Manufacturer" icon="fas fa-industry"></app-icon-tile>
        </a>
        <a routerLink="/admin/games/starcitizen/vehicle-types" class="link-clear" *ngIf="is_admin">
            <app-icon-tile title="Vehicle Types" icon="fab fa-old-republic"></app-icon-tile>
        </a>
        <a routerLink="/admin/games/starcitizen/vehicle-class" class="link-clear" *ngIf="is_admin">
            <app-icon-tile title="Vehicle Class" icon="fab fa-mandalorian"></app-icon-tile>
        </a>
        <a routerLink="/admin/games/starcitizen/vehicle" class="link-clear" *ngIf="is_admin">
            <app-icon-tile title="Vehicle" icon="fas fa-space-shuttle"></app-icon-tile>
        </a>
        <a routerLink="/admin/games/starcitizen/careers" class="link-clear" *ngIf="is_admin">
            <app-icon-tile title="Careers" icon="fas fa-user-astronaut"></app-icon-tile>
        </a>
        <a routerLink="/admin/games/starcitizen/available-commodities" class="link-clear" *ngIf="is_contract_admin">
            <app-icon-tile title="Available Commodities" icon="fab fa-sith"></app-icon-tile>
        </a>

    </div>
    <mat-divider></mat-divider>
    <div class="tiles padding">
        <a routerLink="/admin/games/starcitizen/calculator-data/mining_laser" class="link-clear" *ngIf="is_admin">
            <app-icon-tile title="Mining Laser" icon="fas fa-database"></app-icon-tile>
        </a>
        <a routerLink="/admin/games/starcitizen/calculator-data/mining_module" class="link-clear" *ngIf="is_admin">
            <app-icon-tile title="Mining Module" icon="fas fa-database"></app-icon-tile>
        </a>
        <a routerLink="/admin/games/starcitizen/calculator-data/mining_gadgets" class="link-clear" *ngIf="is_admin">
            <app-icon-tile title="Mining Gadgets" icon="fas fa-database"></app-icon-tile>
        </a>
        <a routerLink="/admin/games/starcitizen/calculator-data/store_locations" class="link-clear" *ngIf="is_admin">
            <app-icon-tile title="Store Locations" icon="fas fa-database"></app-icon-tile>
        </a>
        <a routerLink="/admin/games/starcitizen/calculator-data/sc_systems" class="link-clear" *ngIf="is_admin">
            <app-icon-tile title="Systems" icon="fas fa-database"></app-icon-tile>
        </a>
    </div>
</ng-template>
