import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewChildren} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {debounceTime, map, startWith} from 'rxjs/operators';
import {DateAdapter} from '@angular/material/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FbValidationManager} from '../fb-validator/validation-manager';
import {BehaviorSubject} from 'rxjs';
import {MatChipInputEvent} from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import {Editor, Toolbar} from 'ngx-editor';
import {MatDialog} from '@angular/material/dialog';
import {StoreService} from '../../services/store.service';
import {AuthService} from '../../services/auth.service';
import {TableItemPickerComponent} from '../dialoge/table-item-picker/table-item-picker.component';
import {MediaShort} from '../../forms/media-short';
import {AppSettings} from '../../config/AppSettings';
import {WikiLink} from '../../forms/wiki-link';

@Component({
    selector: 'app-form-inputs',
    templateUrl: './form-inputs.component.html',
    styleUrls: ['./form-inputs.component.css']
})
export class FormInputsComponent implements OnInit, OnDestroy {

    public validation_changed: BehaviorSubject<any>;
    private subs: any[] = [];
    public isDirty = false;
    private isDirtyLastField = '';

    get edit_allowed(): boolean {
        return this._edit_allowed;
    }

    @Input()
    set edit_allowed(value: boolean) {
        if (value !== this._edit_allowed) {
            this._edit_allowed = value;
            this.setForm();
        }
    }

    public form: any;
    private translation: any;

    get field_groups(): any {
        return this._field_groups;
    }

    @Input()
    set field_groups(value: any) {
        const me = this;
        me._field_groups = value;
        const sub = me.translate.get('FORMS').subscribe((res: any) => {
            me.translation = res;
            me.setForm();
        });
        // @ts-ignore
        this.subs.push({key: 'field_groups', sub: sub, inter: true});
    }

    @Input()
    set ref_holder(value: any) {
        this._ref_holder = this;
        this.ref_holderChange.emit(this);
    }

    get ref_holder(): any {
        return this._field_groups;
    }

    @Output() ref_holderChange = new EventEmitter();

    @Input() public loading: boolean | undefined;
    @Input() public button_color = 'primary';
    @Input() public button_filter_color = 'primary';
    @Input() public button_class = 'dummy-class';
    @Input() public button_text = 'übernehmen';
    @Input() public button_filter_text = 'Suchen';
    @Input() public show_save_button = true;
    @Input() public show_filter_button = false;

    private _ref_holder: any;
    public _field_groups: any;
    private _edit_allowed = true;
    private form_obj: any;
    private fieldNameLookUp: any = {};

    public is_valid = false;

    // tslint:disable-next-line:no-output-on-prefix
    @Output() on_change: EventEmitter<any> = new EventEmitter();
    private _last_changed_for_event: any = null;
    // tslint:disable-next-line:no-output-on-prefix
    @Output() on_submit: EventEmitter<any> = new EventEmitter();
    @Output() after_init: EventEmitter<any> = new EventEmitter();
    @Output() on_DirtyChanged: EventEmitter<any> = new EventEmitter();

    readonly separatorKeysCodes: number[] = [ENTER, COMMA];


    toolbar: Toolbar = [
        ['bold', 'italic'],
        ['underline', 'strike'],
        ['code', 'blockquote'],
        ['ordered_list', 'bullet_list'],
        [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
        ['link', 'image'],
        ['text_color', 'background_color'],
        ['align_left', 'align_center', 'align_right', 'align_justify'],
    ];

    constructor(public translate: TranslateService,
                private dateAdapter: DateAdapter<any>,
                public cdr: ChangeDetectorRef,
                public dialog: MatDialog,
                public storeService: StoreService,
                public authService: AuthService
    ) {
        this.validation_changed = new BehaviorSubject(this.is_valid);
        this.dateAdapter.setLocale('de');
            // tslint:disable-next-line:no-unused-expression
    }

    ngOnInit() {
        // form validator
    }

    public checkValid() {
        if (this.form) {
            this.is_valid = this.form.isValid();
            this.validation_changed.next(this.is_valid);
            this.cdr.detectChanges();
        }
    }

    /**
     * called on date input changes in view
     * @author René Pellenz
     */
    onDateInputChanged(event: any, field: any) {

        const new_value = this.dateToString(event.target.value);
        this.sendNewValueFromCustomEvent(new_value, field);
    }

    onTimeInputChanged(value: any, field: any) {
        this.setFieldValue(field, value);
    }

    public revalidate() {
        this.form.revalidate();
    }

    private appendMinDateRuleIfNeeded(field: any) {
        if (field.hasOwnProperty('min') && field.min) {
            if (!field.hasOwnProperty('rules')) {
                field.rules = [];
            }
            const dt = new Date(field.min);
            dt.setDate(dt.getDate() - 1);
            const value = dt.getFullYear() + '-' +
                this.pad(dt.getMonth() + 1, 2) + '-' + this.pad(dt.getDate(), 2);
            field.rules.push({rule: 'minDate:' + value});
        }
    }


    private appendMaxDateRuleIfNeeded(field: any) {
        if (field.hasOwnProperty('max') && field.max) {
            if (!field.hasOwnProperty('rules')) {
                field.rules = [];
            }
            const dt = new Date(field.max);
            const value = dt.getFullYear() + '-' +
                this.pad(dt.getMonth() + 1, 2) + '-' + this.pad(dt.getDate(), 2);
            field.rules.push({rule: 'maxDate:' + value});
        }
    }

    public updateFormValidationObject(field: any) {
        if (this.form_obj.hasOwnProperty(field.name)) {

            if (field.type === 'date') {
                this.appendMinDateRuleIfNeeded(field);
                this.appendMaxDateRuleIfNeeded(field);
            }
            const org_field = this.getField(field.name);
            org_field.rules = field.rules;
            org_field.required = field.required;
            org_field.disabled = field.disabled;
            delete this.form_obj[org_field.name];
            // this.removeSubByKey(org_field.name);
            this.setRuleErrors(org_field);
            this.buildFieldRules(org_field, false);
            this.form.updateRule(org_field.name, this.form_obj[org_field.name]);
            // this.cdr.detectChanges();
            this.initFieldAndCallbacks(org_field, [], false);
        }

        this.checkValid();
        // this.setForm();
    }

    private buildFieldRules(field: any, do_on_init: boolean) {
        const tmp_field = field;
        if (!tmp_field.hidden) {
            // show only visible fields
            if (tmp_field.hasOwnProperty('on_before_init') && do_on_init) {
                tmp_field.on_before_init(tmp_field);
            }
            if (tmp_field.rules && tmp_field.rules.length > 0 && !field.in_filter) {
                // merge rules
                for (let r = 0; r < tmp_field.rules.length; r++) {
                    if (r === 0) {
                        this.form_obj[tmp_field.name] = tmp_field.rules[r].rule;
                    } else {
                        this.form_obj[tmp_field.name] += '|' + tmp_field.rules[r].rule;
                    }
                }
            } else {
                this.form_obj[tmp_field.name] = '';
            }
        }
    }

    private setRuleErrors(field: any) {
        const rules = field.rules;
        field['required'] = false;
        if (field.rules && field.rules.length && !field.in_filter) {
            for (let i = 0; i < rules.length; i++) {
                // use only first part of rule
                // for example use equalTo of equalTo:password
                const rule_parts = rules[i].rule.split(':');
                const rule = rule_parts[0];
                if (rule === 'required') {
                    field['required'] = true;
                }
            }
        }
    }


    private initFieldAndCallbacks(field: any, after_fields_set_array: any, first_init: boolean) {
        const me = this;

        if (first_init) {
            if (field.hasOwnProperty('on_init')) {
                field.on_init(field, this);
            }
            if (field.hasOwnProperty('after_fields_set')) {
                after_fields_set_array.push(field.name);
            }
        }

        // show only visible fields
        if (!field.hidden) {
            const model = field.model;
            // add each rule for field
            this.setRuleErrors(field);

            const val = model.obj[model.key];
            field['last_value'] = val;

            if (field.type === 'datetime-local' && val) {
                // 2020-12-14T10:00
                // field.model.obj[field.model.key]  = new Date(field.model.obj[field.model.key]);
                const dt = new Date(model.obj[model.key]);
                model.obj[model.key] = dt.getFullYear() + '-' + this.pad((dt.getMonth() + 1), 2) + '-'
                    + this.pad(dt.getDate(), 2) + 'T' + this.pad(dt.getHours(), 2)
                    + ':' + this.pad(dt.getMinutes(), 2);
            }

            if (field.type === 'datetime' && val) {
                // 2020-12-14T10:00
                // field.model.obj[field.model.key]  = new Date(field.model.obj[field.model.key]);
                const dt = new Date(model.obj[model.key]);
                model.obj[model.key] = val;
                // model.obj[model.key] = dt.getFullYear() + '-' + this.pad((dt.getMonth() + 1), 2) + '-'
                //     + this.pad(dt.getDate(), 2);
            }

            if (field.type === 'time') {
                if (model.obj[model.key] && field.step > 1) {
                    model.obj[model.key] = model.obj[model.key].substr(0, 5);
                }
            }

            // set value
            this.setFieldValue(field, model.obj[model.key]);

            if (field.type !== 'template') {
                // check for errors
                /*
                if (model.obj.hasOwnProperty(model.key) === false) {
                    console.warn('model for ' + model.key + ' not found');
                }
                 */

                // check for disabled
                if (!this.edit_allowed || field.disabled) {
                    this.form.controls[field.name].control.disable();
                } else {
                    this.form.controls[field.name].control.enable();
                }

                // check fr dynamic disabled fields
                if (field.disableCheck) {
                    if (field.disableCheck(model)) {
                        this.form.controls[field.name].control.disable();
                    } else {
                        this.form.controls[field.name].control.enable();
                    }
                }

                if (field.type === 'chip_autocomplete') {
                    this.CommaValuesToArrayForChip(field);
                }

                // modify controller for autocomplete
                if (field.type === 'autocomplete' || field.type === 'chip_autocomplete') {
                    // function that we use to filter options
                    field['filterOptions'] = function (selected_option: any): any {
                        const options = field.options.filter((option: any) => {
                            let selected_option_name = selected_option;
                            if (selected_option.hasOwnProperty('value')) {
                                if (selected_option.value) {
                                    selected_option_name = selected_option.value;
                                }
                            }
                            if (selected_option.hasOwnProperty('name')) {
                                if (selected_option.name) {
                                    selected_option_name = selected_option.name;
                                }
                            }
                            return me.isInAutocomplete(option, selected_option_name);
                            // return option.name && option.name.toLowerCase()
                            // .indexOf(selected_option_name.toLowerCase()) === 0;
                        });
                        return options.slice(0, 200);
                    };

                    // observable option which we filter on typing in input field
                    field['filteredOptions'] = this.form.controls[field.name].control.valueChanges
                        .pipe(
                            startWith(''),
                            map(option => option ? field.filterOptions(option) : field.options.slice(0, 200))
                        );
                    // function to decide if we want to see key or value in input field
                    field['displayFn'] = function (option?: any): string | undefined {
                        return option ? option.name : undefined;
                    };
                }
                if (field.type === 'editor') {
                    field['editor'] = new Editor();
                }
                const subDirty = this.form.controls[field.name].control.valueChanges.subscribe((new_value: any) => {
                    this.isDirty = true;
                    this.isDirtyLastField = field.name;
                    if (this.on_DirtyChanged) {
                        this.on_DirtyChanged.emit(this.isDirty);
                    }
                });
                // @ts-ignore
                this.subs.push({key: field.name, sub: subDirty, intern: false, first_init: first_init});


                if (field.on_apiFilter) {
                    // start search only after 300ms pause for Input changes
                    const subApiFilter = this.form.controls[field.name].control.valueChanges.pipe(debounceTime(300))
                        .subscribe((new_value: any) => {
                            if (typeof  new_value === 'string' && new_value.length !== 3) {
                                field.on_apiFilter(new_value, field);
                            }
                    });
                    // @ts-ignore
                    this.subs.push({key: field.name, sub: subApiFilter, intern: false, first_init: first_init});
                    // start search on 3 chars for better user exp
                    const subApiFilterShort = this.form.controls[field.name].control.valueChanges.pipe()
                        .subscribe((new_value: any) => {
                            if (typeof new_value === 'string' && new_value.length === 3) {
                                field.on_apiFilter(new_value, field);
                            }
                        });
                    // @ts-ignore
                    this.subs.push({key: field.name, sub: subApiFilterShort, intern: false, first_init: first_init});
                }

                // @ts-ignore
                const sub = this.form.controls[field.name].control.valueChanges.pipe(debounceTime(500))
                    // @ts-ignore
                    .subscribe((new_value: any) => {
                        if (field.last_value !== new_value) {
                            let org_new_value = new_value;
                            switch (field.type) {
                                default:
                                    // do nothing with the value
                                    break;
                                case 'date':
                                    if (new_value === '') {
                                        new_value = null;
                                    } else {
                                        new_value = this.dateToString(new_value);
                                        org_new_value = new_value;
                                    }
                                    break;
                                case 'slide_toggle':
                                    if (new_value) {
                                        new_value = 1;
                                    } else {
                                        new_value = 0;
                                    }
                                    break;
                                case 'chip_autocomplete':
                                    // this.addAutocompleteChip(field, new_value);
                                    if (new_value && new_value.hasOwnProperty('value')) {
                                        new_value = new_value.value;
                                    }
                                    if (new_value !== '') {
                                        const filtered_options = field.options.filter((option: any) => {
                                            return me.isInAutocompleteExact(option, new_value);
                                        });

                                        if (filtered_options.length === 1 &&  new_value !== '') {
                                            new_value = filtered_options[0].value;
                                        } else {
                                            if (new_value === null) {
                                                new_value = '';
                                            } else {
                                                const ar_values = new_value.split(',');
                                                if (ar_values && ar_values.length > 0) {
                                                    let found = true;
                                                    for (let i = 0; i < ar_values.length; i++) {
                                                        const filtered_options_multi = field.options.filter((option: any) => {
                                                            return me.isInAutocompleteExact(option, ar_values[i]);
                                                        });
                                                        if (filtered_options_multi.length === 0) {
                                                            found = false;
                                                        }
                                                    }
                                                    if (found === false) {
                                                        if (field.last_value) {
                                                            // set last value, we did not get an correct match on typing
                                                            // this.setFieldValue(field, field.last_value);
                                                            return false;
                                                        } else {
                                                            return false;
                                                        }
                                                    }
                                                } else {
                                                    if (field.last_value) {
                                                        // set last value, we did not get an correct match on typing
                                                        // this.setFieldValue(field, field.last_value);
                                                        return false;
                                                    }
                                                    new_value = '';
                                                }
                                                // new_value = '';
                                            }
                                        }
                                    }
                                    if (new_value === '' && field.model.obj[field.model.key].length  > 0) {
                                        new_value = field.model.obj[field.model.key].length;
                                    }
                                    break;
                                case 'autocomplete':
                                    if (field.options.length === 0) {
                                        return false;
                                    }
                                    // send value instead of the object
                                    if (new_value && new_value.hasOwnProperty('value')) {
                                        new_value = new_value.value;
                                    } else {
                                        const filtered_options = field.options.filter((option: any) => {
                                            return me.isInAutocomplete(option, new_value);
                                        });
                                        const filtered_options_ex = field.options.filter((option: any) => {
                                            return me.isInAutocompleteExact(option, new_value);
                                        });

                                        if (new_value !== '') {
                                            if (filtered_options.length > 1) {
                                                return false;
                                            } else {

                                                if (filtered_options.length === 1 && filtered_options_ex.length === 1) {
                                                    new_value = filtered_options_ex[0].value;
                                                } else {
                                                    return false;
                                                }
                                            }
                                        }
                                    }
                                    break;
                                case 'datetime':
                                    break;
                            }


                            // event for component if set
                            if (this.on_change && (field.last_value !== undefined || new_value !== '')
                                && String(field.last_value) !== String(new_value)) {
                                // update model
                                model.obj[model.key] = new_value;
                                // emit change event
                                this.sendOnChangeEvent({
                                    name: field.name,
                                    value: new_value,
                                    type: field.type,
                                    model: field.model.obj,
                                    setFieldValue: this.setFieldValue.bind(this),
                                    refreshFormField: this.refreshFormField.bind(this),
                                    form_ele: this,
                                    field: field
                                });
                            }

                            // callback for field if set
                            if (field.hasOwnProperty('on_change')) {
                                // update model
                                model.obj[model.key] = new_value;
                                setTimeout(() => {
                                    // need this to force angular change detection in component
                                    switch (field.type) {
                                        case 'date':
                                            if (new_value === null) {
                                                field.model.obj[field.name] = '';
                                            } else {
                                                const dt = new Date(new_value);
                                                new_value = dt.getFullYear() + '-' +
                                                    this.pad(dt.getMonth() + 1, 2) + '-' + this.pad(dt.getDate(), 2);
                                                field.model.obj[field.name] = new_value;
                                            }
                                            break;
                                    }

                                    field.on_change(
                                        {
                                            name: field.name,
                                            value: new_value,
                                            org_new_value: org_new_value,
                                            type: field.type,
                                            model: field.model.obj,
                                            setFieldValue: this.setFieldValue.bind(this),
                                            refreshFormField: this.refreshFormField.bind(this),
                                            form_ele: this,
                                            field: field
                                        }
                                    );
                                }, 10);
                            }


                            this.checkForFieldsOnOtherFieldChanged(field);
                            field.last_value = new_value;
                            this.checkValid();
                        }
                        if (this.isDirtyLastField === field.name) {
                            this.isDirty = false;
                            if (this.on_DirtyChanged) {
                                this.on_DirtyChanged.emit(this.isDirty);
                            }
                        }
                    });
                // @ts-ignore
                this.subs.push({key: field.name, sub: sub, intern: false, first_init: first_init});
            }
        }
    }


    private setupFields(after_fields_set_array: any[]) {
        if (this.field_groups && this.field_groups.length > 0) {
            this.form_obj = {};
            // prepare the form object
            for (let g = 0; g < this.field_groups.length; g++) {
                for (let f = 0; f < this.field_groups[g].fields.length; f++) {
                    const field = this.field_groups[g].fields[f];
                    this.fieldNameLookUp[field.name] = field.placeholder;
                }
            }
            for (let g = 0; g < this.field_groups.length; g++) {
                for (let f = 0; f < this.field_groups[g].fields.length; f++) {
                    this.buildFieldRules(this.field_groups[g].fields[f], true);
                }
            }

            // assign the form object
            this.form = new FbValidationManager(this.form_obj,
                ['invalid', 'dirty', 'submitted'],
                this.fieldNameLookUp,
                this.translation.RULES);

            // set fields and assign change events
            for (let g = 0; g < this.field_groups.length; g++) {
                for (let f = 0; f < this.field_groups[g].fields.length; f++) {
                    const field = this.field_groups[g].fields[f];
                    this.initFieldAndCallbacks(field, after_fields_set_array, true);
                }
            }
        }
    }

    private setForm() {
        const after_fields_set_array: any[] = [];
        this.setupFields(after_fields_set_array);

        if (after_fields_set_array.length > 0) {
            // set fields and assign change events
            for (let g = 0; g < this.field_groups.length; g++) {
                for (let f = 0; f < this.field_groups[g].fields.length; f++) {
                    const field = this.field_groups[g].fields[f];
                    if (field.hasOwnProperty('after_fields_set')) {
                        // dirty hack to change fields after init
                        field.after_fields_set(field.model, this);
                    }
                }
            }
        }

        this.checkValid();

        if (this.after_init) {
            this.after_init.emit({init: true, that: this, form: this.form});
        }
    }

    /**
     * toggle password input stlye
     * @author René Pellenz
     */
    togglePasswordVisiblity(field: any) {
        if (!field.hasOwnProperty('show_password')) {
            field['show_password'] = false;
        }
        field['show_password'] = !field['show_password'];
    }

    /**
     * called on inputs for key down event
     * @author René Pellenz
     */
    public onKeyDown(event: any, field: any) {
        if (field.hasOwnProperty('on_key_down')) {
            field.on_key_down(event);
        }
    }

    /**
     * called on inputs for key up event
     * @author René Pellenz
     */
    public onKeyUp(event: any, field: any) {
        if (field.hasOwnProperty('on_key_up')) {
            field.on_key_up(event);
        }
    }

    public getForm() {
        return this.form;
    }

    public onSubmit(event: any) {
        if (this.form.isValid()) {
            if (this.on_submit) {
                this.on_submit.emit(this.getForm());
            }
        }
    }

    public removeChipValue(value: {}, field: any) {
        const index = field.values.indexOf(value);
        if (index >= 0) {
            field.values.splice(index, 1);
        }
        this.updateChipField(field);
    }

    public removeChipValueObject(value: {}, field: any) {
        const index = field.values.indexOf(value);
        if (index >= 0) {
            field.values.splice(index, 1);
        }

        field.model.obj[field.model.key] = this.ArrayToCommaValue(field.values);
        this.form.controls[field.name].control.setValue(field.model.obj[field.model.key]);

        this.updateChipFieldObject(field);
    }

    public addChip(event: MatChipInputEvent, field: any): void {
        const input = event.chipInput;
        const value = event.value;

        // Add our fruit
        if ((value || '').trim()) {
            field.values.push(value.trim());
        }

        // Reset the input value
        if (input) {
            input.clear();
        }

        this.updateChipField(field);
    }


    private updateChipFieldObject(field: any) {

        field.model.obj[field.model.key] = this.ArrayToCommaValue(field.values);
        if (field.hasOwnProperty('on_change')) {
            setTimeout(() => {
                // need this to force angular change detection in component
                field.on_change(
                    {
                        name: field.name,
                        value: field.model.obj[field.model.key],
                        type: field.type,
                        model: field.model.obj,
                        setFieldValue: this.setFieldValue.bind(this),
                        refreshFormField: this.refreshFormField.bind(this),
                        form_ele: this,
                        field: field
                    }
                );
            }, 10);
        }
        if (this.on_change && (field.last_value !== undefined || field.model.obj[field.model.key] !== '')
            && String(field.last_value) !== String(field.model.obj[field.model.key])) {
            // update model
            // emit change event
            this.sendOnChangeEvent({
                name: field.name,
                value: field.model.obj[field.model.key],
                type: field.type,
                model: field.model.obj,
                setFieldValue: this.setFieldValue.bind(this),
                refreshFormField: this.refreshFormField.bind(this),
                form_ele: this,
                field: field
            });
        }
    }

    private updateChipField(field: any) {
        if (field.values && field.values.length > 0) {
            field.model.obj[field.model.key] = field.values.join();
        } else {
            field.model.obj[field.model.key] = '';
        }

        if (field.hasOwnProperty('on_change')) {
            setTimeout(() => {
                // need this to force angular change detection in component
                field.on_change(
                    {
                        name: field.name,
                        value: field.model.obj[field.model.key],
                        type: field.type,
                        model: field.model.obj,
                        setFieldValue: this.setFieldValue.bind(this),
                        refreshFormField: this.refreshFormField.bind(this),
                        form_ele: this,
                        field: field
                    }
                );
            }, 10);
        }
    }

    private checkForFieldsOnOtherFieldChanged(changed_field: any) {
        for (let g = 0; g < this.field_groups.length; g++) {
            for (let f = 0; f < this.field_groups[g].fields.length; f++) {
                const field = this.field_groups[g].fields[f];
                if (field.name !== changed_field.name) {
                    if (field.hasOwnProperty('on_other_field_changed')) {
                        field.on_other_field_changed(field, changed_field, this.setFieldValue.bind(this), this.refreshFormField.bind(this),
                            this);
                        this.cdr.detectChanges();
                    }
                }
            }
        }
    }

    public refreshFormField(field: any) {
        const me = this;
        if (field.type === 'autocomplete' || field.type === 'chip_autocomplete') {
            // function that we use to filter options
            field['filterOptions'] = function (selected_option: any): any {
                const options = field.options.filter((option: any) => {
                    let selected_option_name = selected_option;
                    if (selected_option.hasOwnProperty('value')) {
                        if (selected_option.value) {
                            selected_option_name = selected_option.value;
                        }
                    }
                    if (selected_option.hasOwnProperty('name')) {
                        if (selected_option.name) {
                            selected_option_name = selected_option.name;
                        }
                    }
                    return me.isInAutocomplete(option, selected_option_name);
                });
                return options.slice(0, 200);
            };

            // observable option which we filter on typing in input field
            field['filteredOptions'] = this.form.controls[field.name].control.valueChanges
                .pipe(
                    startWith(''),
                    map(option => option ? field.filterOptions(option) : field.options.slice(0, 200))
                );
            // function to decide if we want to see key or value in input field
            field['displayFn'] = function (option?: any): string | undefined {
                return option ? option.name : undefined;
            };
        }
        this.cdr.detectChanges();
    }

    private isInAutocomplete(option: any, search_str: string) {
        if (option === undefined || search_str === undefined || option === null || search_str === null) {
            return false;
        }
        return JSON.stringify(option).toLowerCase()
            .indexOf(String(search_str).toLowerCase()) >= 0;
    }
    private isInAutocompleteExact(option: any, search_str: string) {
        if (option === undefined || search_str === undefined || option === null || search_str === null) {
            return false;
        }
        if (option && option.name === null) {
            option.name = '';
        }
        if (option && option.value === null) {
            option.value = '';
        }
        return option.name.toLowerCase() === String(search_str).toLowerCase()
            || String(option.value).toLowerCase() === String(search_str).toLowerCase();
    }

    public getField(key: string) {
        for (let g = 0; g < this.field_groups.length; g++) {
            for (let f = 0; f < this.field_groups[g].fields.length; f++) {
                const field = this.field_groups[g].fields[f];
                if (field.name === key) {
                    return field;
                }
            }
        }
        return null;
    }

    public setFieldValue(field: any, value: any) {
        switch (field.type) {
            default:
                this.form.controls[field.name].control.setValue(value);
                break;
            case 'datetime':
                let dt: Date | null = new Date(value);
                if (value === '0000-00-00 00:00:00' || value === null) {
                    dt = null;
                }
                this.form.controls[field.name].control.setValue(dt);
                break;
            case 'checkbox':
            case 'slide_toggle':
                if (value === true || value === '1' || value === 1) {
                    this.form.controls[field.name].control.setValue(true);
                } else {
                    this.form.controls[field.name].control.setValue(false);
                }
                break;
            case 'template':
                // do nothing here until we need it
                break;
            case 'select':
                // this.myControl.setValue( {name: 'Mary'});
                if (value === null) {
                    this.form.controls[field.name].control.setValue('');
                } else {
                    if (!field.multiple) {
                        const filtered_sel_options = field.options.filter((option: any) => {
                            return String(option.value) === String(value);
                        });

                        if (filtered_sel_options.length > 0) {
                            if (typeof filtered_sel_options[0].value === 'number') {
                                this.form.controls[field.name].control.setValue(Number(value));
                            } else {
                                this.form.controls[field.name].control.setValue(String(value));
                            }
                        } else {
                            this.form.controls[field.name].control.setValue('');
                        }
                    } else {
                        this.form.controls[field.name].control.setValue(value);
                    }
                }
                break;
            case 'autocomplete':
                // this.myControl.setValue( {name: 'Mary'});
                if (value === null) {
                    this.form.controls[field.name].control.setValue('');
                } else {
                    const filtered_options = field.options.filter((option: any) => {
                        return String(option.value) === String(value);
                    });
                    if (filtered_options.length > 0) {
                        this.form.controls[field.name].control.setValue(filtered_options[0]);
                    } else {
                        this.form.controls[field.name].control.setValue('');
                    }
                }
                break;
            case 'chip':
                const ar_values = value.split(',');
                if (ar_values && ar_values.length > 0 && ar_values[0] !== '') {
                    field['values'] = ar_values;
                } else {
                    field['values'] = [];
                }
                break;
            case 'chip_autocomplete':
                field.values = [];
                if (value === '') {
                    this.updateChipFieldObject(field);
                    this.form.controls[field.name].control.setValue('');
                } else {
                    field.model.obj[field.model.key] = value;
                    this.CommaValuesToArrayForChip(field);
                    field.model.obj[field.model.key] = this.ArrayToCommaValue(field.values);
                    this.form.controls[field.name].control.setValue(field.model.obj[field.model.key]);
                }
                break;
        }
    }

    private pad(num: number, size: number) {
        let s = num + '';
        while (s.length < size) {
            s = '0' + s;
        }
        return s;
    }

    // @ts-ignore
    public autocompleteClosed(field: any) {
        const me = this;
        const new_value = this.form.controls[field.name].control.value.value;
        let filtered_options = field.options.filter((option: any) => {
            if (option.value === new_value) {
                return true;
            }
            return String(new_value) !== '' && me.isInAutocomplete(option, new_value);
        });

        if (filtered_options.length > 1) {
            filtered_options = field.options.filter((option: any) => {
                return new_value !== '' && option.value === new_value;
            });
        }
        if (filtered_options.length === 1) {
            return true;
        } else {
            this.form.controls[field.name].control.setValue('');
        }
    }

    public chipAutocompleteClosed(field: any, input: any) {
        const me = this;
        const new_value = this.form.controls[field.name].control.value.value;
        let filtered_options = field.options.filter((option: any) => {
            if (option.value === new_value) {
                return true;
            }
            return String(new_value) !== '' && me.isInAutocomplete(option, new_value);
        });

        if (filtered_options.length > 1) {
            filtered_options = field.options.filter((option: any) => {
                return new_value !== '' && option.value === new_value;
            });
        }

        field.model.obj[field.model.key] = this.ArrayToCommaValue(field.values);
        input.value = '';
        /*
        if (filtered_options.length === 1) {
            // field.values.push(new_value);
            input.value = '';
            // field.model.obj[field.model.key] = this.ArrayToCommaValue(field.values);
        } else {
            input.value = '';
        }
         */
    }

    public ChipAutocompleteSelected(event: MatAutocompleteSelectedEvent, field: any, input: any): void {
        this.addAutocompleteChip(field, event.option.value);
        input.value = '';
    }

    private addAutocompleteChip(field: any, value: any) {
        if (!field.values) {
            field.values = [];
        }
        field.values.push(value);
        field.model.obj[field.model.key] = this.ArrayToCommaValue(field.values);
        this.form.controls[field.name].control.setValue(field.model.obj[field.model.key]);
    }

    public getFieldGroups() {
        return this._field_groups;
    }

    private removeSubs() {
        this.subs.forEach((s) => {
            // @ts-ignore
            const sub = s.sub;
            sub.unsubscribe();
        });
    }

    private removeSubByKey(key: string) {
        if (this.subs.length > 0) {
            for (let i = this.subs.length - 1; i >= 0; i--) {
                const s = this.subs[i];
                // @ts-ignore
                if (s.key === key && s.intern === false) {
                    // @ts-ignore
                    const sub = s.sub;
                    sub.unsubscribe();
                    this.subs.splice(i, 1);
                }
            }
        }
    }

    private ArrayToCommaValue(ar_val: any[]) {
        let value = '';
        if (ar_val.length > 0) {
            for (let i = 0; i < ar_val.length; i++) {
                value += ar_val[i].value + ',';
            }
        }
        value = value.slice(0, -1);
        return value;
    }

    private CommaValuesToArrayForChip(field: any) {
        if (!field.model.obj[field.model.key]) {
            field.model.obj[field.model.key] = '';
        }
        const chip_value =  field.model.obj[field.model.key].replaceAll(', ', ',');
        const ar_values = chip_value.split(',');
        field['values'] = [];
        if (ar_values && ar_values.length > 0 && ar_values[0] !== '') {
            let value = '';
            if (ar_values.length > 0) {
                for (let i = 0; i < ar_values.length; i++) {
                    value = ar_values[i];
                    for (let o = 0; o < field.options.length; o++) {
                        if (value === field.options[o].value) {
                            field['values'].push(field.options[o]);
                            continue;
                        }
                    }
                }
            }
        }
    }

    private dateToString(date: Date) {
        const dt = new Date(date);
        return dt.getFullYear() + '-' +
            this.pad(dt.getMonth() + 1, 2) + '-' + this.pad(dt.getDate(), 2);
    }


    public sendFilterOnchangeByButton() {
        if (this.on_change) {
            this.on_change.emit(this._last_changed_for_event);
        }
    }

    private sendOnChangeEvent(object: any) {
        if (!this.show_filter_button) {
            this.on_change.emit(object);
        }
        this._last_changed_for_event = object;
    }

    public openImagePicker($event, field: any) {
        const dialogRef = this.dialog.open(TableItemPickerComponent, {
            width: '800px',
            data: {
                title: 'chose Image',
                name_atr: 'Media',
                storeObj: new MediaShort(this.storeService, this.authService, 'edit'),
                add_url: window.location.origin + AppSettings.sub_path + '/#/media',
                type: 'media',
                show_add: true,
                edit_allowed: true,
                show_delete: true
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.result) {
                if (result.entry) {
                    field.editor.commands
                        .insertHTML('<img src="' + AppSettings.getRealAPILink() + 'media/'
                            + result.entry.file_id + '/show" alt="" title="">').exec();
                }
            }
        });
    }

    public openWikiLinkPicker($event, field: any) {
        const dialogRef = this.dialog.open(TableItemPickerComponent, {
            width: '800px',
            data: {
                title: 'chose link',
                name_atr: 'Wiki Links',
                storeObj: new WikiLink(this.storeService, this.authService, 'edit'),
                type: 'wiki_link',
                show_add_in_table: true,
                edit_allowed: true,
                show_delete: true
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.result) {
                if (result.entry) {
                    field.editor.commands
                        .insertHTML('<a href="' + result.entry.swl_url + '"' +
                            ' title="' + result.entry.swl_name + '">' + result.entry.swl_name + '</a>').exec();
                }
            }
        });
    }

    public addCheckbox($event: any, field: any) {
        const id = prompt('set id', '1');
        field.editor.commands.insertHTML('[bb:checkbox:{id:' + id + '}]').exec();
    }

    private sendNewValueFromCustomEvent(new_value: string, field: any) {
        if (this.on_change && (field.last_value !== undefined || new_value !== '')
            && String(field.last_value) !== String(new_value)) {
            this.sendOnChangeEvent({
                name: field.name,
                value: new_value,
                type: field.type,
                model: field.model.obj,
                field: field,
                form_ele: this
            });
        }
        if (field.on_change) {
            switch (field.type) {
                case 'date':
                    const dt = new Date(new_value);
                    new_value = dt.getFullYear() + '-' +
                        this.pad(dt.getMonth() + 1, 2) + '-' + this.pad(dt.getDate(), 2);
                    field.model.obj[field.name] = new_value;
                    break;
            }
            field.on_change(
                {
                    name: field.name,
                    value: new_value,
                    type: field.type,
                    model: field.model.obj,
                    setFieldValue: this.setFieldValue.bind(this),
                    refreshFormField: this.refreshFormField.bind(this),
                    form_ele: this,
                    field: field
                }
            );
        }
        this.checkForFieldsOnOtherFieldChanged(field);
        this.checkValid();
    }
    public onMaskInputChanged(new_value: string | number | undefined, field: any) {
        this.setFieldValue(field, new_value);
        this.sendNewValueFromCustomEvent(String(new_value), field);
    }

    ngOnDestroy(): void {
        this.removeSubs();
        this.field_groups = [];
        this._edit_allowed = false;
        this.form = null;
        this.form_obj = null;
    }
}
