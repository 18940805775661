import {FbFormObject} from '../class/fb_form_object';
import {AppSettings} from '../config/AppSettings';

export class ScbEventMember extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'user_id', label: 'SC Username', visible: true, noclick: false, disable_ripple: true},
            {id: 'user_role', label: 'Role', visible: true, noclick: false, disable_ripple: true},
            {id: 'start_ts', label: 'Start', visible: true, noclick: false, disable_ripple: true},
            {id: 'end_ts', label: 'End', visible: true, noclick: false, disable_ripple: true},
            {id: 'paid', label: 'Paid', visible: true, noclick: false, disable_ripple: true},
            {id: 'paid_out', label: 'Paid out', visible: true, noclick: false, disable_ripple: true},
            {id: 'no_payout_wanted', label: 'no Payment wanted', visible: true, noclick: false, disable_ripple: true},
            {id: 'attend_order', label: 'attend order', visible: true, noclick: false, disable_ripple: true},
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'User',
                class: 'card card-1 padding single-column',
                field_class: '',
                fields: [
                    {
                        type: 'autocomplete',
                        name: 'user_id',
                        options: this.select_boxes['sc_user_select'],
                        rules: [
                            {rule: 'required'}
                        ],
                        disabled: false
                    },
                    {
                        type: 'select',
                        name: 'user_role',
                        options: this.select_boxes['role_select'],
                        disabled: false
                    },
                    {
                        type: 'datetime',
                        name: 'start_ts',
                        disabled: false
                    },
                    {
                        type: 'datetime',
                        name: 'end_ts',
                        disabled: false
                    },
                    {
                        type: 'checkbox',
                        name: 'paid',
                        disabled: false
                    },
                    {
                        type: 'checkbox',
                        name: 'paid_out',
                        disabled: false
                    },
                    {
                        type: 'checkbox',
                        name: 'no_payout_wanted',
                        disabled: false
                    },
                    {
                        type: 'number',
                        name: 'attend_order',
                        disabled: false
                    }
                ]
            }
        ];
        super.setEditFields();
    }

    public loadStores(ext_obj?: any): Promise<any> {
        console.log('--- ext_obj', ext_obj);
        return new Promise<any>((resolve, reject) => {
            const path = 'games/starcitizen/events/' + String(ext_obj.event_id) + '/member';
            this.stores['event_member'] = this.storeService.createNoneGlobalStore('event-member',
                path,
                'id');
            this.stores['event_member'].setPatchUrl(AppSettings.getRealAPILink() + path + '/{user_id}');
            this.stores['event_member'].setDeleteUrl(AppSettings.getRealAPILink() + path + '/{user_id}');
            this.main_store = this.stores['event_member'];

            this.stores['sc_user'] = this.storeService.getStore('sc_user');

            const stores = [];
            if (!this.stores['event_member'].isLoaded()) {
                stores.push(this.stores['event_member'].loadStore(this.filter));
            }

            const selectFilter = [];
            selectFilter.push('first=0');
            selectFilter.push('rows=1000');
            stores.push(this.stores['sc_user'].loadStore('?' + selectFilter.join('&')));

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
                event_id: this.custom_obj.event_id,
                user_id: 0,
                user_name: '',
                user_role: '',
                start_ts: null,
                end_ts: null,
                paid: false,
                paid_out: false,
                no_payout_wanted: false
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        this.setRoleSelectBox();
        this.parseScUserSelectbox();
        // call super
        super.setSelectboxes();
    }

    public setRoleSelectBox() {
        this.select_boxes['role_select'] = [
            {
                value: 'Defence',
                name: 'Defence'
            },
            {
                value: 'Trader',
                name: 'Trader'
            },
            {
                value: 'Miner',
                name: 'Miner'
            },
            {
                value: 'Scout',
                name: 'Scout'
            },
            {
                value: 'Scraper',
                name: 'Scraper'
            },
            {
                value: 'Support',
                name: 'Support'
            }
        ];
    }

    public parseScUserSelectbox() {
        const options = [];
        const records = this.stores['sc_user'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.user_id),
                name: records[i].data.sc_username
            });
        }
        this.select_boxes['sc_user_select'] = options;
        return this.select_boxes['sc_user_select'];
    }
}
