import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {NotificationService} from '../../services/notification.service';

@Component({
    selector: 'app-token-login',
    templateUrl: './token-login.component.html',
    styleUrls: ['./token-login.component.css']
})
export class TokenLoginComponent implements OnInit, OnDestroy {

    private subs: Array<Subscription> = [];

    constructor(private router: Router,
                private route: ActivatedRoute,
                private cdRef: ChangeDetectorRef,
                private authService: AuthService,
                private notificationService: NotificationService) {
    }

    ngOnInit(): void {
        this.subs.push(this.route.params.subscribe((params: any) => {
            const login_token = params.token;
            this.doLogin(login_token);
        }));
    }

    public doLogin(login_token: string) {
        this.authService.login_state_changed.subscribe((state: number) => {
            if (state === 2) {
                this.authService.user_loaded_changed.subscribe((user: any) => {
                    this.notificationService.success('Login',
                        'Login success.', {timeOut: 5000});
                    this.router.navigateByUrl('/main');
                });
            }
        });
        this.authService.loginWithToken(login_token);
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }

}
