<div class="padding">
    <div class="card card-1 padding table-wrapper" *ngIf="Store">
        <app-fb-forms-table [storeObj]="Store"
                            [edit_allowed]="editAllowed"
                            [require_filter]="require_filter"
                            [storeExtObj]="{}"
                            [stateKey]="'members-points-details-mining'"
                            [show_add]="editAllowed"
                            [show_delete]="false"
                            [title]="'Member Mining'"
        >
        </app-fb-forms-table>
    </div>
</div>
