<div>
    <app-content-container [content_template]="page_content"
                           [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div class="padding">
        <div [ngSwitch]="selectedContractList">
            <ng-container *ngSwitchCase="'available'">
                <ng-container *ngIf="selectFilterAvailable">
                    <app-sc-contract-tiles [filter]="selectFilterAvailable" [filter_type]="'available'">
                        <ng-container topLeftNgContent>
                            <ng-template [ngTemplateOutlet]="topLeftNgContent"></ng-template>
                        </ng-container>
                    </app-sc-contract-tiles>
                </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'accepted'">
                <ng-container *ngIf="selectFilterAccepted">
                    <app-sc-contract-tiles [filter]="selectFilterAccepted" [filter_type]="'accepted'">
                        <ng-container topLeftNgContent>
                            <ng-template [ngTemplateOutlet]="topLeftNgContent"></ng-template>
                        </ng-container>
                    </app-sc-contract-tiles>
                </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'confirmed'">
                <ng-container *ngIf="selectFilterConfirmed">
                    <app-sc-contract-tiles [filter]="selectFilterConfirmed" [filter_type]="'confirmed'">
                        <ng-container topLeftNgContent>
                            <ng-template [ngTemplateOutlet]="topLeftNgContent"></ng-template>
                        </ng-container>
                    </app-sc-contract-tiles>
                </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'created'">
                <ng-container *ngIf="is_admin">
                    <div class="card padding">
                        <div style="padding-right: 16px;">
                            <ng-template [ngTemplateOutlet]="topLeftNgContent"></ng-template>
                        </div>
                    </div>
                    <div class="card card-1 padding table-wrapper" *ngIf="storeObject">
                        <app-fb-forms-table [storeObj]="storeObject"
                                            [edit_allowed]="is_ifa"
                                            [storeExtObj]="storeExtObj"
                                            (row_click)="rowSelected($event)"
                                            [stateKey]="'sc-contract'"
                                            [show_add_in_view]="is_ifa"
                                            [show_delete]="is_ifa"
                                            [title]="'Contracts'"
                        >
                        </app-fb-forms-table>
                    </div>
                </ng-container>
                <ng-container *ngIf="!is_admin">
                    <ng-container *ngIf="selectFilterCreated">
                        <app-sc-contract-tiles [filter]="selectFilterCreated" [filter_type]="'created'">
                            <ng-container topLeftNgContent>
                                <ng-template [ngTemplateOutlet]="topLeftNgContent"></ng-template>
                            </ng-container>
                        </app-sc-contract-tiles>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-template>
<ng-template #select_caption_tpl let-element="element" let-column="column">
    {{element.tmp.caption[column.id]}}
</ng-template>

<ng-template #topLeftNgContent>
    <div style="padding-right: 16px; min-height: 48px; margin-top: 4px;">
        <div>
            <mat-chip-listbox aria-label="Contract list selection" [multiple]="false">
                <ng-container *ngFor="let contractList of contractLists">
                    <mat-chip-option [selected]="contractList.value === selectedContractList"
                                     (selectionChange)="contractListChanged($event, contractList)">
                        {{contractList.label}}
                    </mat-chip-option>
                </ng-container>
            </mat-chip-listbox>
        </div>
    </div>
</ng-template>