import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    private sidenav: any;
    constructor() {
    }

    public setSidenav(sidenav: any) {
        this.sidenav = sidenav;
    }

    public getSidenav(): any {
        return this.sidenav;
    }

}
