import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {FormInputsComponent} from '../../shared/form-inputs/form-inputs.component';
import {NotificationService} from '../../services/notification.service';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    @ViewChild('tpl_form') tpl_form: FormInputsComponent;
    public fields: any[];
    public login_obj = {
        username: '',
        password: ''
    };

    private loginLink = '';

    constructor(private authService: AuthService,
                private router: Router,
                private notificationsService: NotificationService) {
    }

    ngOnInit() {
        if (this.authService.getLoginState() === 2) {
            this.router.navigateByUrl('/main');
        } else {
            this.buildForm();
        }
    }

    public doLogin(form: any) {
        // if (form.isValid()) {
        //     this.authService.login_state_changed.subscribe((state: number) => {
        //         if (state === 2) {
        //             this.authService.user_loaded_changed.subscribe((user: any) => {
        //                 this.notificationService.success('Login',
        //                     'Login success.', {timeOut: 5000});
        //                 this.router.navigateByUrl('/main');
        //             });
        //         }
        //     });
        //     this.authService.login(this.login_obj);
        // }
        this.authService.authWithDiscord();
    }

    private buildForm() {
        const me = this;
        this.fields = [
            {
                class: '',
                fields: [
                    // {
                    //     model: {obj: this.login_obj, key: 'username'},
                    //     placeholder: 'loginname',
                    //     type: 'text',
                    //     name: 'username',
                    //     rules: [
                    //         {rule: 'required'}
                    //     ],
                    //     hint: {
                    //         type: 'length'
                    //     },
                    //     max: 20
                    // },
                    // {
                    //     model: {obj: this.login_obj, key: 'password'},
                    //     placeholder: 'password',
                    //     type: 'password',
                    //     name: 'password',
                    //     rules: [
                    //         {rule: 'required'}
                    //     ],
                    //     on_key_up: function (event: any) {
                    //         // call login from input on enter
                    //         if (event.key === 'Enter') {
                    //             // me.tpl_form.getForm().submit();
                    //         }
                    //     }
                    // }
                ]
            }
        ];
    }

    public openRegister() {
        this.router.navigateByUrl('/register');
    }
}
