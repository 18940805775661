import {FbFormObject} from '../class/fb_form_object';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {AppSettings} from '../config/AppSettings';

export class ScVersion extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'version_id', label: 'Id', visible: true, noclick: false, disable_ripple: true},
            {id: 'version_name', label: 'Name', visible: true, noclick: false, disable_ripple: true},
            {id: 'version_description', label: 'Description', visible: true, noclick: false, disable_ripple: true},
            {id: 'is_active', label: 'Active', visible: true, noclick: false, disable_ripple: true},
            {id: 'copy', label: 'copy', visible: true, noclick: true, disable_ripple: true},
            {id: 'update', label: 'update prices', visible: true, noclick: true, disable_ripple: true},
            // {id: 'ftime', label: 'ftime', visible: true, noclick: false, disable_ripple: true},
            // {id: 'fselect', label: 'fselect', visible: true, noclick: false, disable_ripple: true},
            // {id: 'fautocomplete', label: 'fautocomplete', visible: true, noclick: false, disable_ripple: true},
            // {id: 'fdatetime', label: 'fdatetime', visible: true, noclick: false, disable_ripple: true}
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'SC Version',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'number',
                        name: 'version_id',
                        disabled: true
                    },
                    {
                        type: 'text',
                        name: 'version_name',
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'textarea',
                        name: 'version_description'
                    },
                    {
                        type: 'checkbox',
                        name: 'is_active',
                    },
                    {
                        type: 'button',
                        name: 'copy',
                        color: 'secondary',
                        class: 'button-add',
                        onClick: ($event, model) => {
                            this.cloneVersion(model.version_id).then((result) => {
                                this.custom_obj.button_callback();
                            });
                        },
                    },
                    {
                        type: 'button',
                        name: 'update',
                        color: 'secondary',
                        class: 'button-edit',
                        onClick: ($event, model) => {
                            console.log('$event', $event);
                            console.log('model', model);
                            this.updateVersion(model.version_id).then((result) => {
                                this.custom_obj.button_callback();
                            });
                        },
                    },
                    // {
                    //     type: 'time',
                    //     name: 'ftime',
                    // },
                    // {
                    //     type: 'select',
                    //     name: 'fselect',
                    // },
                    // {
                    //     type: 'autocomplete',
                    //     name: 'fautocomplete',
                    // },
                    // {
                    //     type: 'datetime',
                    //     name: 'fdatetime',
                    // }
                ]
            }
        ];


        super.setEditFields();
    }

    /**
     * override / load all stores we need
     */
    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.stores['sc_version'] = this.storeService.getStore('sc_version');

            this.stores['sc_version'] = this.storeService.createNoneGlobalStore('sc_version',
                'games/starcitizen/version', 'version_id');

            this.main_store = this.stores['sc_version'];

            const stores = [];
            // if (!this.stores['sc_version'].isLoaded()) {
            stores.push(this.stores['sc_version'].loadStore(this.filter));
            // }

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
                version_id: 0,
                version_name: '',
                version_description: '',
                is_active: false
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        // call super
        super.setSelectboxes();
    }


    public cloneVersion(version_id: string): Promise<any> {
        const httpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json');
        const options = {
            headers: httpHeaders,
            withCredentials: true
        };

        return new Promise<any>((resolve, reject) =>  {
            this.getStore().showSaveScreen();
            const url = AppSettings.getRealAPILink() + 'games/starcitizen/clone/version/' + version_id;
            const httpClient: HttpClient = this.custom_obj.http;
            const postObs = httpClient.post<any>(url, {}, options);
            postObs.subscribe(
                response => {
                    let records = [];
                    if (response.success) {
                        records = response.rows;
                    } else {
                        // old api
                        records = response;
                    }
                    this.getStore().hideSaveScreen();
                    resolve({success: true});
                },
                err => {
                    console.log(err);
                    this.getStore().hideSaveScreen();
                    resolve(false);
                }
            );
        });
    }

    public updateVersion(version_id: string): Promise<any> {
        const httpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json');
        const options = {
            headers: httpHeaders,
            withCredentials: true
        };

        this.getStore().showSaveScreen();
        return new Promise<any>((resolve, reject) =>  {
            this.getStore().showSaveScreen();
            const url = AppSettings.getRealAPILink() + 'games/starcitizen/update/version/' + version_id;
            const httpClient: HttpClient = this.custom_obj.http;
            const postObs = httpClient.post<any>(url, {}, options);
            postObs.subscribe(
                response => {
                    let records = [];
                    if (response.success) {
                        records = response.rows;
                    } else {
                        // old api
                        records = response;
                    }
                    this.getStore().hideSaveScreen();
                    resolve({success: true});
                },
                err => {
                    console.log(err);
                    this.getStore().hideSaveScreen();
                    resolve(false);
                }
            );
        });
    }
}
