import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'noHtml'
})
export class NoHtmlPipe implements PipeTransform {

    constructor() {}
    transform(value) {
        return value.replace(/<[^>]*>/g, '');
    }

}
