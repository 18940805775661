import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {

    private theme: string;
    public theme_changed: BehaviorSubject<any>;

    constructor() {
        this.theme = this.getSavedTheme();
        this.theme_changed = new BehaviorSubject(this.theme);
    }

    public getTheme() {
        return this.theme;
    }

    public setTheme(theme: string) {
        this.theme = theme;
        this.theme_changed.next(this.theme);
        this.saveTheme();
    }

    private getSavedTheme(): string {
        const theme = localStorage.getItem('theme');
        if (theme) {
            return theme;
        }
        return 'default-theme';
    }
    private saveTheme() {
        localStorage.setItem('theme', this.theme);
    }
}
