<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading" [loading_text]="loading_text"
                           [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div>
        <main class="tiles padding">

            <a routerLink="/rally/series" class="link-clear">
                <app-icon-tile title="Series" icon="fas fa-calendar"
                               [customClass]="'bg-card bg-rally-series'"></app-icon-tile>
            </a>


            <a routerLink="/rally/race-tracks" class="link-clear">
                <app-icon-tile title="Tracks" icon="fas fa-calendar"
                               [customClass]="'bg-card bg-race'"></app-icon-tile>
            </a>

        </main>
    </div>
</ng-template>

