<div class="content-container">
    <div class="card card-1 padding">
        <div *ngIf="fields && fields.length > 0">
            <app-form-inputs *ngIf="fields && fields.length"
                             #tpl_form
                             [field_groups]="fields"
                             [edit_allowed]="true"
                             [loading]="false"
                             [button_text]="'Registrieren'"
                             (on_submit)="doRegister($event)"
                             [button_class]="'button-save'">
            </app-form-inputs>
        </div>
    </div>
</div>
