<div class="message-embed card">
<!--    <img class="avatar" src="user_avatar.jpg" alt="User Avatar">-->
    <span class="username">{{message.author.username}}</span>
    <div class="message-details">
        <div class="message-description" *ngIf="message.description_md && (message.embeds[0].type !== 'video')">
            <markdown [data]="message.description"></markdown>
        </div>
        <div class="message-content" *ngIf="message.content_md && (message.embeds.length === 0 || message.embeds[0].type !== 'video');">
            <markdown [data]="message.content_md"></markdown>
        </div>
        <!-- embed link -->
<!--        <div *ngIf="embed.type === 'link'" class="embed-description-container">-->
<!--            <div class="embed-link">-->
<!--                <a [href]="message.embeds[0].url">{{message.embeds[0].title}}</a>-->
<!--            </div>-->
<!--        </div>-->
        <ng-container *ngIf="message.attachments && message.attachments.length > 0 ">
            <div class="attachment-grid">
                <mat-grid-list cols="2" rowHeight="2:1" gutterSize="2px">
                    <mat-grid-tile *ngFor="let attachment of message.attachments">
                        <div [ngSwitch]="attachment.content_type">
                            <div *ngIf="isAllowedDomain(attachment.url)">
                                <div class="attachment-cell" *ngSwitchDefault>
                                    <a [href]="message.embeds[0].url"
                                       *ngIf="message.embeds.length > 0 && message.embeds[0].type === 'link'
                                              && getConsent(attachment.url)">
                                        <img [src]="getNoCookieUrl(attachment.url)" class="discord-attachment"/>
                                    </a>
                                    <span *ngIf="message.embeds.length > 0 && message.embeds[0].type === 'link'
                                              && !getConsent(attachment.url)" class="allow-external-cookie">
                                        Erlaube Cookies für {{getConsentProviderName(attachment.url)}}, um das Bild zu laden.
                                    </span>

                                    <img [src]="getNoCookieUrl(attachment.url)"
                                         *ngIf="(message.embeds.length === 0 || (message.embeds.length > 0
                                                 && message.embeds[0].type !== 'link')) && getConsent(attachment.url)"
                                         class="discord-attachment">

                                    <span *ngIf="(message.embeds.length === 0 || (message.embeds.length > 0
                                                 && message.embeds[0].type !== 'link')) && !getConsent(attachment.url)"
                                         class="discord-attachment allow-external-cookie">
                                        Erlaube Cookies für {{getConsentProviderName(attachment.url)}}, um das Bild zu laden.
                                    </span>
                                </div>

                                <div class="attachment-cell" *ngSwitchCase="'video/mp4'">
                                    <div *ngIf="isAllowedDomain(attachment.url)">
                                        <div *ngIf="getConsent(attachment.url)">
                                            <video playsinline="" height="309" controls
                                                   preload="metadata" width="550"
                                                   style="width: 100%; height: 100%; max-height: inherit; object-fit: cover;">
                                                <source [src]="getNoCookieUrl(attachment.url) | safe: 'ResourceURL'" [type]="attachment.content_type">
                                            </video>
                                        </div>
                                        <div *ngIf="!getConsent(attachment.url)" class="allow-external-cookie">
                                            Erlaube Cookies für {{getConsentProviderName(attachment.url)}}, um das Video zu laden.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!isAllowedDomain(attachment.url)">
                                content provider not supported
                            </div>
                        </div>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
        </ng-container>
        <ng-container *ngIf="message.embeds && message.embeds.length > 0 ">
            <div *ngFor="let embed of message.embeds"
                 class="discord-embed"
                 [attr.style]="'border-left: 5px solid #' + embed.color + ';'">
                <!-- description -->
                <div *ngIf="embed.description_md || embed.icon" class="embed-description-container">
                    <div>
                        <markdown *ngIf="embed.description_md && embed.type !== 'video'" [data]="embed.description_md"></markdown>
                    </div>
                    <div *ngIf="embed.thumbnail && embed.type !== 'video'">
                        <div *ngIf="isAllowedDomain(embed.thumbnail.url)">
                            <div *ngIf="getConsent(embed.thumbnail.url)">
                                <img [src]="getNoCookieUrl(embed.thumbnail.url)" height="42px"/>
                            </div>
                            <div *ngIf="!getConsent(embed.thumbnail.url)" class="allow-external-cookie">
                                Erlaube Cookies für {{getConsentProviderName(embed.thumbnail.url)}}, um das Bild zu laden.
                            </div>
                        </div>
                        <div *ngIf="!isAllowedDomain(embed.thumbnail.url)">
                            content provider not supported
                        </div>
                    </div>
                    <div *ngIf="embed.thumbnail && embed.type === 'video'">
                        <div *ngIf="isAllowedDomain(embed.video.url)">
                            <div *ngIf="getConsent(embed.video.url)">
                                <iframe class="embedIframe-1UpXja" [src]="getNoCookieUrl(embed.video.url) | safe: 'resourceUrl'"
                                        width="400" height="225"
                                        frameborder="0"
                                        scrolling="no"
                                        allowfullscreen=""
                                        sandbox="allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-presentation"
                                        style="max-width: 400px; max-height: 225px;">
                                </iframe>
                            </div>
                            <div *ngIf="!getConsent(embed.video.url)" class="allow-external-cookie">
                                Erlaube Cookies für {{getConsentProviderName(embed.video.url)}}, um das Video zu laden.
                            </div>
                        </div>
                        <div *ngIf="!isAllowedDomain(embed.video.url)">
                            content provider not supported
                        </div>
                    </div>
                </div>
                <!-- embed link -->
                <div *ngIf="embed.type === 'link'" class="embed-description-container">
                    <div class="embed-link">
                        <a [href]="message.embeds[0].url" class="text-link">{{message.embeds[0].title}}</a>
                    </div>
                </div>
                <!-- embed video -->
                <div *ngIf="embed.type === 'video'" class="embed-description-container">
                    <div class="embed-link">
                        <div *ngIf="isAllowedDomain(embed.url)">
                            <div *ngIf="embed.type === 'video'">
                                <div class="video-author" *ngIf="embed.author">
                                    <a [href]="embed.url">{{embed.author.name}}</a>
                                </div>
                                <div class="video-title">
                                    <a [href]="embed.url">{{embed.title}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="embed.fields && embed.fields.length > 0 ">
                    <!-- Fields -->
                    <ng-container *ngFor="let field of embed.fields">
                        <div class="field" [ngClass]="{'field-inline': field.inline, 'field-block': !field.inline}">
                            <span class="field-name">
                                <ng-container [ngSwitch]="field.type_name">
                                    <div class="field-value" *ngSwitchDefault><markdown [data]="field.name"></markdown></div>
                                    <div class="field-value" *ngSwitchCase="'text'"><pre>{{field.name}}</pre></div>
                                    <div class="field-value" *ngSwitchCase="'code'"><pre><code [highlight]="field.name"></code></pre></div>
                                    <div class="field-value dc-block" *ngSwitchCase="'blocks'" [innerHTML]="field.name"></div>
                                    <div class="field-value" *ngSwitchCase="'date'">{{field.name}}</div>
                                    <div class="field-value" *ngSwitchCase="'time'">{{field.name}}</div>
                                </ng-container>
                            </span>
                            <ng-container [ngSwitch]="field.type_value">
                                <div class="field-value" *ngSwitchDefault><pre [innerHTML]="field.value"></pre></div>
                                <div class="field-value" *ngSwitchCase="'text'"><pre [innerHTML]="field.value"></pre></div>
                                <div class="field-value" *ngSwitchCase="'code'"><pre><code [highlight]="field.value"></code></pre></div>
                                <div class="field-value dc-block" *ngSwitchCase="'blocks'" [innerHTML]="field.value"></div>
                                <div class="field-value" *ngSwitchCase="'date'">{{field.value | date:'mediumDate':'': 'de'}}</div>
                                <div class="field-value" *ngSwitchCase="'time'">{{field.value | date:'shortTime':'': 'de'}}</div>
                            </ng-container>
<!--                            <div class="field-value"> <markdown emoji>{{field.value}}</markdown></div>-->
<!--                            <div [markdown]="discordMarkdown"></div>-->
                        </div>
                    </ng-container>
                </ng-container>
                <div *ngIf="embed.image" >
                    <div *ngIf="isAllowedDomain(embed.image.url)">
                        <div *ngIf="getConsent(embed.image.url)">
                            <img [src]="getNoCookieUrl(embed.image.url)" class="embed-footer-image"/>
                        </div>
                        <div *ngIf="!getConsent(embed.image.url)" class="allow-external-cookie">
                            Erlaube Cookies für {{getConsentProviderName(embed.image.url)}} um das Bild zu laden.
                        </div>
                    </div>
                    <div *ngIf="!isAllowedDomain(embed.image.url)">
                        content provider not supported
                    </div>
                </div>
            </div>

        </ng-container>
    </div>
</div>

<ng-template #videoHeader>
    <div *ngIf="message.embeds.length > 0 && message.embeds[0].type === 'video'">
        <div class="video-author" *ngIf="message.embeds[0].author">
            <a [href]="message.embeds[0].author.url">{{message.embeds[0].author.name}}</a>
        </div>
        <div class="video-title">
            <a [href]="message.embeds[0].video.url">{{message.embeds[0].title}}</a>
        </div>
    </div>
</ng-template>
