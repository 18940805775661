import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {StoreService} from '../../services/store.service';
import {AuthService} from '../../services/auth.service';
import {NotificationService} from '../../services/notification.service';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '../../class/store';

@Component({
  selector: 'app-event-ore-deposit-tile',
  templateUrl: './event-ore-deposit-tile.component.html',
  styleUrls: ['./event-ore-deposit-tile.component.css']
})
export class EventOreDepositTileComponent implements OnInit, AfterViewInit {

  @Input()
  public deposit: any;
  @Input()
  public event_id = 0;
  @Input()
  public scout_id = 0;
  @Input()
  public miner_id = 0;
  @Input()
  public ores: any[];

  public records: any[];
  public oreStore: Store;

  constructor(private storeService: StoreService,
              private authService: AuthService,
              private notificationService: NotificationService,
              private route: ActivatedRoute,
              private cdr: ChangeDetectorRef,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.oreStore = this.storeService.createNoneGlobalStore('event-deposit-ores',
        '/games/starcitizen/events/' + String(this.event_id) + '/deposit/ore/' + this.deposit.data.deposit_id + '/commodities/ore',
        'deposit_id');
    this.oreStore.loadStore('').then((records: any[]) => {
      this.records = records;
    });
  }

  ngAfterViewInit(): void {
  }

  public sum(records: any, key: string) {
    let sum = 0;
    records.forEach((row: any) => {
      sum += Number(row.data[key]);
    });
    return sum;
  }

  public getRowColor(row: any) {
    if (row.data.percent >= 45) {
      return '#9716ea';
    }
    if (row.data.percent >= 35) {
      return '#00c853';
    }
    if (row.data.percent >= 25) {
      return '#aeea00';
    }
    if (row.data.percent >= 15) {
      return '#ffd600';
    }
    if (row.data.percent >= 10) {
      return '#ffab00';
    }
    return '#d50000';
  }
}
