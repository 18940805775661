<div>
    <div class="card padding">
        <div style="padding-right: 16px;">
            <ng-container>
                <div class="">
                    <div style="float: left;">
                        <ng-content select="[topLeftNgContent]"></ng-content>
                    </div>
                    <mat-form-field style="float: right;">
                        <mat-label>Search</mat-label>
                        <input matInput
                               placeholder="search"
                               [(ngModel)]="searchText"
                               (input)="onSearch()"
                               class="search"/>
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                    <mat-form-field style="margin-right: 16px; float: right;">
                        <mat-select [formControl]="tags"
                                    multiple (selectionChange)="onContractTypeChanged($event)"
                                    placeholder="Contract type">
                            <mat-select-trigger>
                                {{tags.value?.[0] || ''}}
                                <span *ngIf="(tags.value?.length || 0) > 1" class="example-additional-selection">
                                    (+{{(tags.value?.length || 0) - 1}} {{tags.value?.length === 2 ? 'other' : 'others'}})
                                </span>
                            </mat-select-trigger>
                            <mat-option *ngFor="let tag of tagsList" [value]="tag">{{tag}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div style="clear: both"></div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="" *ngIf="store">
        <div style="padding-bottom: 32px; margin-top: 24px;">
            <div *ngIf="is_loading">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <main class="big-tiles" *ngIf="rows && !is_loading">
                <ng-container *ngFor="let contract of rows">
                    <app-contract-tile title="{{contract.data.title}}" icon="fas fa-calendar"
                        [contract_data]="contract.data"
                        [user_id]="user_id"
                        (changed)="contractChanged($event)"
                        [customClass]="'bg-card'"></app-contract-tile>
                </ng-container>
            </main>
            <div class="" style="padding-left: 16px; text-align: left; padding-top: 32px;">
                <ng-container *ngIf="is_ifa">
                    <button mat-mini-fab class="button-add"
                            matTooltip="neu" (click)="addClicked()">
                        <mat-icon aria-label="neu">add</mat-icon>
                    </button>
                    <span class="button-spacer"></span>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="card flex gap-3 align-items-center justify-content-space-between">
<!--        <div class="" style="padding-left: 16px;">-->
<!--            <ng-container *ngIf="is_ifa">-->
<!--                <button mat-mini-fab class="button-add"-->
<!--                        matTooltip="neu" (click)="addClicked()">-->
<!--                    <mat-icon aria-label="neu">add</mat-icon>-->
<!--                </button>-->
<!--                <span class="button-spacer"></span>-->
<!--            </ng-container>-->
<!--        </div>-->
        <p-paginator [first]="first"
                     [rows]="pageSize"
                     [totalRecords]="totalRecords"
                     [rowsPerPageOptions]="[10, 25, 50]"
                     [showCurrentPageReport]="false"
                     currentPageReportTemplate="{first} - {last} of {totalRecords}"
                     (onPageChange)="onPageChange($event)"
                     [showJumpToPageDropdown]="true"
                     [showPageLinks]="false">
        </p-paginator>
    </div>
</div>