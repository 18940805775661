import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-random-checkbox',
  templateUrl: './random-checkbox.component.html',
  styleUrls: ['./random-checkbox.component.css']
})
export class RandomCheckboxComponent {
  checkboxId: string;
  model = false;
  modelChanged = ($event, id) => {};
}
