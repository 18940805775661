export class FbUtils {


    public static formatUrlForApi(url: string) {

        let prot = 'https://';
        if (location.protocol !== 'https:') {
            prot = 'http://';
        }
        // return prot + url;
        // return 'https://' + url;
        return url;
    }

    public static pad(num, size) {
        let s = num + '';
        while (s.length < size) {
            s = '0' + s;
        }
        return s;
    }
}
