import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FbFormObject} from '../../../class/fb_form_object';
import {Subject, Subscription} from 'rxjs';
import {Store} from '../../../class/store';
import {AppSettings} from '../../../config/AppSettings';
import {FormControl} from '@angular/forms';
import {StoreService} from '../../../services/store.service';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'app-sc-contract-tiles',
    templateUrl: './sc-contract-tiles.component.html',
    styleUrls: ['./sc-contract-tiles.component.css']
})
export class ScContractTilesComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input()
    public filter: any[] = [];

    @Input()
    public filter_type = '';

    public storeObject: FbFormObject;
    private subs: Array<Subscription> = [];

    public loading_text = '';
    public is_loading = false;
    public rows: any[];

    public user_id = '';

    public column_definition: any[];
    public is_admin: boolean;
    public is_ifa = false;

    public first = 0;

    public totalRecords = 10;
    public pageSize = 10;
    public store: Store;
    public media_path: string = AppSettings.getRealAPILink();
    public searchText = '';
    public searchContractTypes = [];

    tags = new FormControl('');
    tagsList: string[] = [
        'acquisition',
        'escort',
        'trade',
        'transportation',
    ];

    private selectFilter = [];
    private searchSubject = new Subject<string>();
    private contractTypeSubject = new Subject<any[]>();

    constructor(private storeService: StoreService,
                private authService: AuthService,
                private router: Router,
                private cdr: ChangeDetectorRef,
                public dialog: MatDialog) {
    }

    ngOnInit() {
        this.searchSubject.pipe(debounceTime(500)).subscribe((searchValue) => {
            this.doSearch();
        });
        this.contractTypeSubject.pipe(debounceTime(500)).subscribe((searchValue) => {
            this.doSearch();
        });
    }

    ngAfterViewInit(): void {
        this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin)
            || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_event_admin);
        this.is_ifa = this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_ifa);
        // this.storeObject = new ExplorePoi(this.storeService, this.authService, 'edit');
        this.store = this.storeService.createNoneGlobalStore('sc_contracts_' + this.filter_type, 'sc-contracts', 'c_id');

        this.user_id = String(this.authService.getUserId());

        this.resetFilter();
        this.loadPage();
        this.cdr.detectChanges();
    }

    private resetFilter() {
        this.selectFilter = Object.assign([], this.filter);
    }

    private doSearch() {
        // this.performSearch(searchValue);
        this.resetFilter();
        this.selectFilter.push('first=0');
        this.selectFilter.push('rows=' + this.pageSize);
        if (this.searchText && this.searchText !== '') {
            this.selectFilter.push('filter[title][0][mode]=contains');
            this.selectFilter.push('filter[title][0][operator]=and');
            this.selectFilter.push('filter[title][0][value]=' + this.searchText);

        }

        if (this.searchContractTypes && this.searchContractTypes.length) {
            this.selectFilter.push('filter[type][0][mode]=in');
            this.selectFilter.push('filter[type][0][operator]=and');
            this.selectFilter.push('filter[type][0][value]=' + this.searchContractTypes.join(','));
        }
        this.loadPage();
    }

    public addClicked() {
        this.router.navigateByUrl('/sc-contracts/add');
    }


    onPageChange(event: any) {
        this.first = event.first;
        this.pageSize = event.rows;
        this.resetFilter();
        this.loadPage();
    }

    private loadPage() {
        this.is_loading = true;
        this.selectFilter.push('first=' + this.first);
        this.selectFilter.push('rows=' + this.pageSize);
        this.store.loadStore('?' + this.selectFilter.join('&')).then((records: any[]) => {
            this.totalRecords = this.store.totalRecords;
            this.rows = records;
            this.is_loading = false;
        });
    }


    contractChanged($event: any) {
        this.doSearch();
    }

    onSearch() {
        this.searchSubject.next(this.searchText);
    }

    onContractTypeChanged($event: any) {
        this.searchContractTypes = $event.value;
        this.contractTypeSubject.next(this.searchContractTypes);
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
        this.searchSubject.complete();
    }
}
