import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {StoreService} from '../../services/store.service';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {AppSettings} from '../../config/AppSettings';
import {ScCommodityInventoryForm} from '../../forms/scCommodityInventoryForm';

@Component({
  selector: 'app-sc-commodity-inventory',
  templateUrl: './sc-commodity-inventory.component.html',
  styleUrls: ['./sc-commodity-inventory.component.css']
})
export class ScCommodityInventoryComponent implements OnInit, OnDestroy, AfterViewInit {

  public storeObject: ScCommodityInventoryForm;
  private subs: Array<Subscription> = [];

  public loading_text = '';
  public is_loading = false;
  public rows: any[];


  private _select_caption_tpl: ElementRef;

  public column_definition: any[];
  public is_admin: boolean;
  public is_ifa = false;


  constructor(private storeService: StoreService,
              private authService: AuthService,
              private router: Router,
              private cdr: ChangeDetectorRef,
              public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin)
        || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_contract_admin);
    this.is_ifa = this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_ifa);
    this.storeObject = new ScCommodityInventoryForm(this.storeService, this.authService, 'edit');
    this.cdr.detectChanges();
  }

  public rowSelected($event: any) {
    const route = 'sc-commodity-inventory/' + $event.row.commodity;
    if ($event.in_new_tab) {
      const url = this.router.createUrlTree([route]);
      const serializedUrl = this.router.serializeUrl(url);

      // Insert `#` between the domain and route
      const modifiedUrl = '#/' + serializedUrl;
      window.open(modifiedUrl, '_blank');
    } else {
      this.router.navigateByUrl(route);
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
