<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <mat-tab-group mat-align-tabs="start" color="accent" LastActiveTabDirective="sc-commodity-inventory-edit">
        <mat-tab label="Inventory">
            <div class="padding">
                <app-form-inputs *ngIf="fields && fields.length"
                                 #tpl_form
                                 [field_groups]="fields"
                                 [edit_allowed]="true"
                                 [loading]="false"
                                 (on_submit)="saveStore($event)"
                                 [button_class]="'button-save'">
                </app-form-inputs>
            </div>
        </mat-tab>
        <mat-tab label="Log" *ngIf="id && mode === 'edit' && record && record.data && record.data.commodity > 0">
            <app-sc-commodity-inventory-log [commodity]="record.data.commodity" [type]="record.data.c_type"></app-sc-commodity-inventory-log>
        </mat-tab>
    </mat-tab-group>
</ng-template>
