import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Store} from '../class/store';
import {CachedStore} from '../class/cached-store';
import {AppSettings} from '../config/AppSettings';


@Injectable({
    providedIn: 'root'
})
export class StoreService {

    private stores = {};
    public is_saving = false;

    constructor(private http: HttpClient) {
        this.createGlobalStore('auth_user', 'auth/user', 'user_id');
        this.createGlobalStore('user', 'user', 'user_id');
        this.createGlobalStore('sc_version', 'games/starcitizen/version', 'version_id');


        this.createGlobalStore('guilds', 'guilds', 'id');
        this.createGlobalStore('sc_user', 'games/starcitizen/user', 'user_id');
        this.createGlobalStore('sc_user_ifa', 'games/starcitizen/ifa/user', 'user_id');
        this.createGlobalStore('sc_events', 'games/starcitizen/events', 'event_id');
        this.createGlobalStore('dc_channel', 'user/discord/channel', 'channel_id');
        this.createGlobalStore('dc_news', 'news', 'id');
        this.createGlobalStore('dc_members', 'members', 'id');
        this.createGlobalStore('ifa_member', 'ifa/member', 'user_id');
        this.createGlobalStore('ifa_badges', 'ifa/badges', 'badge_id');
        this.createGlobalStore('guides', 'guides', 'guide_id');
        this.createGlobalStore('media', 'media', 'file_id');
        this.createGlobalStore('wiki_link', 'wiki_link', 'swl_id');
        this.createGlobalStore('upcoming_events', 'upcoming-events', 'id');
        this.createGlobalStore('tracks-overview', 'race/tracks-overview', 'track_id');
        this.createGlobalStore('race_tracks', 'race/tracks', 'track_id');
        this.createGlobalStore('race_types', 'race/types', 'type_id');
        this.createGlobalStore('manufacturer', 'games/starcitizen/manufacturer', 'man_id');
        this.createGlobalStore('vehicle_types', 'games/starcitizen/vehicle-types', 'vehicle_type');
        this.createGlobalStore('vehicle_class', 'games/starcitizen/vehicle-class', 'vehicle_class');
        this.createGlobalStore('careers', 'games/starcitizen/careers', 'careers');
        this.createGlobalStore('vehicle', 'games/starcitizen/vehicle', 'vehicle_id');
        this.createGlobalStore('lap_times', 'race/lap-times', 'rt_id');
        this.createGlobalStore('rally_series', 'race/series', 'series_id');
        this.createGlobalStore('my_sc_user', 'games/starcitizen/my-user', 'user_id');
        this.createGlobalStore('explorer_poi', 'explore/poi', 'poi_id');
        this.createGlobalStore('fy_models', 'fleetyards/models', 'id');
        this.createGlobalStore('fy_manufacturers', 'fleetyards/manufacturers', 'code');
        this.createGlobalStore('sc_available_commodities', 'games/starcitizen/available-commodities', 'c_id');
        this.createGlobalStore('sc_contracts', 'sc-contracts', 'c_id');
        this.createGlobalStore('commodity_sets', 'games/starcitizen/commodity-sets', 'set_id');
        this.createGlobalStore('commodity_categories', 'games/starcitizen/commodity-categories', 'cat_id');
        this.createGlobalStore('uex_commodities', 'uex/commodities', 'id');
        this.createGlobalStore('mining_laser', 'games/starcitizen/mining_laser', 'laser');
        this.createGlobalStore('mining_module', 'games/starcitizen/mining_module', 'module');
        this.createGlobalStore('mining_gadgets', 'games/starcitizen/mining_gadgets', 'gadget');
        this.createGlobalStore('store_locations', 'games/starcitizen/store_locations', 'location');
        this.createGlobalStore('sc_systems', 'games/starcitizen/sc_systems', 'sc_system');



        // this.createGlobalStore('Quellen_Datenobjekte', '/mis/tables/Quellen_Datenobjekte', 'auto_id');


        // const mis_selectentries_store = this.createGlobalStoreNoneSingeId('mis_selectentries', '/mis/bus/selectentries', [
        //     'list_type',
        //     'object_key'
        // ]);
        // mis_selectentries_store.setPatchUrl(AppSettings.getRealAPILink() + '/mis/bus/selectentries/type/{list_type}/key/{object_key}');
        // mis_selectentries_store.setDeleteUrl(AppSettings.getRealAPILink() + '/mis/bus/selectentries/type/{list_type}/key/{object_key}');

        /* unused for db
        this.stores['features'] = new Store(this.http, '/api/features', FeatureModel, 'feature', 'feature');
        this.stores['activityStates'] = new CachedStore(this.http, AppSettings.getRealAPILink() + 'activity/state', ActivityStateModel,
            'activityStates', 'id');
         */
    }

    private createGlobalStore(store_key: string, path: string, index_field: string): Store {
        this.stores[store_key] = new Store(this.http, AppSettings.getRealAPILink() + path, store_key, index_field);
        return this.stores[store_key];
    }

    public createNoneGlobalStore(store_key: string, path: string, index_field: string): Store {
        return new Store(this.http, AppSettings.getRealAPILink() + path, store_key, index_field);
    }

    public createNoneGlobalSingleStoreFromStore(globalStore: Store, store_key: string, id: string|number): Store {
        // let url = globalStore.getUrl() + '/' + id;
        // if (!id || id === 'all') {
        //     url = globalStore.getUrl();
        // }
        return new Store(this.http, globalStore.getUrl(), store_key, globalStore.getIndexField());
    }

    private createGlobalStoreNoneSingeId(store_key: string, path: string, index_field_array: any): Store {
        this.stores[store_key] = new Store(this.http, AppSettings.getRealAPILink() + path, store_key, '');
        this.stores[store_key].setStoreKeyArray(index_field_array);
        return this.stores[store_key];
    }


    public getStore(store_name: string): Store {
        if (this.stores.hasOwnProperty(store_name)) {
            return this.stores[store_name];
        }
        return null;
    }

    public getCachedStore(store_name: string): CachedStore {
        if (this.stores.hasOwnProperty(store_name)) {
            return this.stores[store_name];
        }
        return null;
    }

    public getHttpClient(): HttpClient {
        return this.http;
    }

    public unloadStores() {
        this.getStore('auth_user').unloadStore();
        this.getStore('user').unloadStore();
    }
}
