<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <mat-tab-group mat-align-tabs="start" color="accent" LastActiveTabDirective="edit-race-type">
        <mat-tab label="Race Type">
            <div class="padding">
                <app-form-inputs *ngIf="fields && fields.length"
                                 #tpl_form
                                 [field_groups]="fields"
                                 [edit_allowed]="is_admin"
                                 [loading]="false"
                                 (on_submit)="saveStore($event)"
                                 [button_class]="'button-save'">
                </app-form-inputs>
            </div>
        </mat-tab>
        <mat-tab label="Vehicle Types" *ngIf="is_admin && id && mode === 'edit'">
            <app-race-type-vehicle-type [type_id]="id"></app-race-type-vehicle-type>
        </mat-tab>
        <mat-tab label="Vehicle Class" *ngIf="is_admin && id && mode === 'edit'">
            <app-race-type-vehicle-class [type_id]="id"></app-race-type-vehicle-class>
        </mat-tab>
        <mat-tab label="Careers" *ngIf="is_admin && id && mode === 'edit'">
            <app-race-type-careers [type_id]="id"></app-race-type-careers>
        </mat-tab>
    </mat-tab-group>
</ng-template>


<ng-template #upload_tpl let-element="element" let-column="column" let-edit="edit">

    <div *ngIf="element.model && element.model.obj.file_id > 0">
        <app-image-preview [file_id]="element.model.obj.file_id"></app-image-preview>
        <button mat-mini-fab class="button-delete"
                style="margin-left: 16px;"
                (click)="deleteFile(element.model.obj.file_id)"
                matTooltip="löschen"
                type="button">
            <mat-icon aria-label="löschen">delete</mat-icon>
        </button>
    </div>

    <ng-container *ngIf="element.model && element.model.obj.type_id > 0 &&
                         (element.model.obj.file_id === 0 || element.model.obj.file_id === null)
                         && edit">
        <app-file-upload2 [file_type]="'race_type'"
                          (on_success)="onFileUpload($event, 'race_type', element)"
                          [url]="url_file_store"
                          [show_save_button]="true"
                          [parent_object_id]="element.model.obj.type_id"
                          [max_count]="1"
                          [accept]="'.jpeg,.jpg,.png,image/*'"
                          (init)="onFileuploadInit($event)"
        ></app-file-upload2>
    </ng-container>
</ng-template>
