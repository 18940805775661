import {FbFormObject} from '../class/fb_form_object';

export class IfaBadge extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'badge_type', label: 'Type', visible: true, noclick: false, disable_ripple: true},
            {id: 'badge_rank', label: 'Rank', visible: true, noclick: false, disable_ripple: true},
            {id: 'badge_name', label: 'Name', visible: true, noclick: false, disable_ripple: true},
            {id: 'badge_description', label: 'Description', visible: true, noclick: false, disable_ripple: true},
            {id: 'image', label: 'Image', visible: true, type: 'template', template_row: this.templates['upload_tpl'],
                noclick: false, disable_ripple: true}
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'IFA Badge',
                class: 'card card-1 padding single-column',
                field_class: '',
                fields: [
                    {
                        type: 'select_group',
                        name: 'badge_type',
                        select_groups: this.select_boxes['badge_type_select'],
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'number',
                        name: 'badge_rank',
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'text',
                        name: 'badge_name',
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'textarea',
                        name: 'badge_description',
                    },
                    {
                        type: 'template',
                        template: this.templates['upload_tpl'],
                        name: 'image',
                        disabled: true,
                        no_input: false
                    }
                ]
            }
        ];


        super.setEditFields();
    }

    /**
     * override / load all stores we need
     */
    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {

            this.stores['ifa_bade'] = this.storeService.createNoneGlobalStore('ifa_bade',
                'ifa/badges', 'badge_id');

            this.main_store = this.stores['ifa_bade'];

            const stores = [];
            // if (!this.stores['sc_version'].isLoaded()) {
            stores.push(this.stores['ifa_bade'].loadStore(this.filter));
            // }

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
                badge_id: null,
                badge_branch: 'any',
                badge_rank: 0,
                badge_name: '',
                badge_description: '',
                files: 0,
                badge_is_medal: 0
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        // call super
        this.parseBadgeTypeSelectBox();
        super.setSelectboxes();
    }


    public parseBadgeTypeSelectBox(): any[] {
        this.select_boxes['badge_type_select'] = [
            {
                label: 'APOLLON', items: [
                    {value: 'exploration', label: 'exploration'},
                    {value: 'science', label: 'science'},
                    {value: 'medical', label: 'medical'}
                ]},
            {
                label: 'ASPIS', items: [
                    {value: 'defence_fps', label: 'defence fps'},
                    {value: 'defence_flight', label: 'defence flight'}
                ]},
            {
                label: 'EMPOROS', items: [
                    {value: 'industries', label: 'industries'},
                    {value: 'logistics', label: 'logistics'}
                ]},
            {
                label: 'Medals', items: [
                    {value: 'medal', label: 'medal'}
                ]
            }
        ];
        return this.select_boxes['badge_type_select'];
    }
}
