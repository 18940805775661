<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <mat-tab-group mat-align-tabs="start" color="accent" LastActiveTabDirective="edit-ifa-member">
        <mat-tab label="User Data">
            <div class="padding">
                <app-form-inputs *ngIf="fields && fields.length"
                                 #tpl_form
                                 [field_groups]="fields"
                                 [edit_allowed]="false"
                                 [loading]="false"
                                 (on_submit)="saveStore($event)"
                                 [button_class]="'button-save'">
                </app-form-inputs>
            </div>
        </mat-tab>
        <mat-tab label="Badges" *ngIf="id && mode === 'edit' && record && record.data">
            <app-ifa-member-badges [user_id]="record.data.user_id"></app-ifa-member-badges>
        </mat-tab>
    </mat-tab-group>
</ng-template>
