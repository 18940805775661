import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {StoreService} from '../../../../services/store.service';
import {AuthService} from '../../../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ScbMembersPointsMining} from '../../../../forms/scb-members-points-mining';
import {ScbMembersPointsSalvage} from '../../../../forms/scb-members-points-salvage';
import {ScbMembersPointsTrading} from '../../../../forms/scb-members-points-trading';
import {AppSettings} from '../../../../config/AppSettings';

@Component({
  selector: 'app-members-points-details',
  templateUrl: './members-points-details.component.html',
  styleUrls: ['./members-points-details.component.css']
})
export class MembersPointsDetailsComponent implements OnInit, OnDestroy, AfterViewInit {


  @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
    this._select_caption_tpl = value;
    // this.setColumns();
    this.cdr.markForCheck();
  }


  public ScbMembersPointsMining: ScbMembersPointsMining;
  public storeExtObjMining: {};
  public ScbMembersPointsSalvage: ScbMembersPointsSalvage;
  public storeExtObjSalvage: {};
  public ScbMembersPointsTrading: ScbMembersPointsTrading;
  public storeExtObjTrading: {};
  public editAllowed = false;

  private subs: Array<Subscription> = [];

  public loading_text = '';
  public is_loading = false;
  public rows: any[];
  public require_filter = {index: 0, filter: []};

  private _select_caption_tpl: ElementRef;

  public column_definition: any[];
  public is_admin: boolean;
  public user_id = 0;

  constructor(private storeService: StoreService,
              private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private cdr: ChangeDetectorRef,
              public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.is_admin = this.authService.isAdmin();
    this.editAllowed = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin)
        || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_event_admin);
    this.subs.push(this.route.params.subscribe((params: any) => {
      this.user_id = params.user_id;

      this.require_filter.filter = [];
      this.require_filter.filter.push('filter[user_id][0][mode]=equals');
      this.require_filter.filter.push('filter[user_id][0][operator]=and');
      this.require_filter.filter.push('filter[user_id][0][value]=' + String(this.user_id));
      this.require_filter.index = 1;


      this.storeExtObjMining = {user_id: params.user_id};
      this.ScbMembersPointsMining = new ScbMembersPointsMining(this.storeService, this.authService, 'edit');
      this.storeExtObjSalvage = {user_id: params.user_id};
      this.ScbMembersPointsSalvage = new ScbMembersPointsSalvage(this.storeService, this.authService, 'edit');
      this.storeExtObjTrading = {user_id: params.user_id};
      this.ScbMembersPointsTrading = new ScbMembersPointsTrading(this.storeService, this.authService, 'edit');
      this.cdr.detectChanges();
    }));
  }

  public rowSelected($event: any, type: string) {
    if (this.is_admin) {
      this.router.navigateByUrl('/members-points/' + this.user_id + '/' + type + '/' + $event.row.id);
    }
  }

  public addRow(type: string) {
    if (this.editAllowed) {
      this.router.navigateByUrl('/members-points/' + this.user_id + '/' + type + '/add');
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
