import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {RightSidenavService} from '../../services/right-sidenav.service';
import {Subscription} from 'rxjs';
import {forEach, template} from 'lodash';

@Component({
    selector: 'app-content-container',
    templateUrl: './content-container.component.html',
    styleUrls: ['./content-container.component.css']
})
export class ContentContainerComponent implements OnInit, OnDestroy {

    private default_loading_text = 'Es wird geladen';
    private _loading_text: string;


    @Input() custom_class: string;
    @Input() is_loading: boolean;
    @Input() content_template: TemplateRef<any>;
    public side_template: TemplateRef<any>;

    private subs: Array<Subscription> = [];

    @Input() set loading_text(value: string) {
        if (value.length > 0) {
            this._loading_text = value;
        } else {
            this.loading_text = this.default_loading_text;
        }
    }

    get loading_text(): string {
        return this._loading_text;
    }

    private _sidenav: any;
    @ViewChild('sidenav', {static: false}) set sidenav(value: any) {
        this._sidenav = value;
        this.rightSidenavService.setSidenav(this._sidenav);
    }
    get sidenav() {
        return this._sidenav;
    }

    constructor(private rightSidenavService: RightSidenavService,
                private cdr: ChangeDetectorRef) { }

    ngOnInit() {
        this.subs.push(this.rightSidenavService.getSideTemplate()
            .subscribe((tmpRef: TemplateRef<any>) => {
                if (tmpRef) {
                    this.side_template = tmpRef;
                    // this.cdr.detectChanges();
                }
            }
        ));
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }

    rightSideNavStateChanged(open: boolean) {
        this.rightSidenavService.setOpenState(open);
    }
}
