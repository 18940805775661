import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {StoreService} from '../../../services/store.service';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AppSettings} from '../../../config/AppSettings';
import {MediaShort} from '../../../forms/media-short';
import {DialogData} from '../confirm/confirm.component';
import {WikiLink} from '../../../forms/wiki-link';
import {ScCommoditySetForm} from '../../../forms/scCommoditySetForm';

@Component({
  selector: 'app-table-item-picker',
  templateUrl: './table-item-picker.component.html',
  styleUrls: ['./table-item-picker.component.css']
})
export class TableItemPickerComponent implements OnInit, OnDestroy, AfterViewInit {


  @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
    this._select_caption_tpl = value;
    // this.setColumns();
    this.cdr.markForCheck();
  }


  public storeObject: MediaShort;
  private subs: Array<Subscription> = [];

  public loading_text = '';
  public is_loading = false;
  public rows: any[];


  private _select_caption_tpl: ElementRef;

  public column_definition: any[];
  public is_admin: boolean;
  public is_ifa = false;


  constructor(private storeService: StoreService,
              private authService: AuthService,
              private cdr: ChangeDetectorRef,
              public dialogRef: MatDialogRef<TableItemPickerComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.storeObject = data.storeObj;
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin)
        || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_event_admin);
    this.is_ifa = this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_ifa);
    switch (this.data.type) {
      default:
        this.storeObject = new MediaShort(this.storeService, this.authService, 'edit');
        break;
      case 'wiki_link':
        this.storeObject = new WikiLink(this.storeService, this.authService, 'edit');
        break;
      case 'commodity_sets':
        this.storeObject = new ScCommoditySetForm(this.storeService, this.authService, 'edit');
        break;
    }
    this.cdr.detectChanges();
  }

  public addClicked() {
    // this.router.navigateByUrl('/media/add');
    // open media in new tab
    window.open(this.data.add_url, '_blank');
  }

  public rowSelected($event: any) {
    // close dialog
    this.dialogRef.close({result: true, entry: $event.row});
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
