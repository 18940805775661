import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ScOres} from '../../../../../forms/sc-ores';
import {Subscription} from 'rxjs';
import {StoreService} from '../../../../../services/store.service';
import {AuthService} from '../../../../../services/auth.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {NotificationService} from '../../../../../services/notification.service';
import {FbFormObject} from '../../../../../class/fb_form_object';
import {ScCargo} from '../../../../../forms/sc-cargo';
import {AppSettings} from '../../../../../config/AppSettings';

@Component({
  selector: 'app-sc-commodity',
  templateUrl: './sc-commodity.component.html',
  styleUrls: ['./sc-commodity.component.css']
})
export class ScCommodityComponent implements OnInit, OnDestroy, AfterViewInit {


  @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
    this._select_caption_tpl = value;
    // this.setColumns();
    this.storeTemplates = {select_caption_tpl: value};
    this.cdr.markForCheck();
  }

  @Input() public version_id: string|number = 0;
  @Input() public com_type: string;

  public Store: FbFormObject;
  private subs: Array<Subscription> = [];

  public loading_text = '';
  public is_loading = true;
  public rows: any[];
  public storeExtObj: {};
  public storeTemplates = {};

  private _select_caption_tpl: ElementRef;

  public is_admin: boolean;
  public require_filter = {index: 0, filter: []};

  constructor(private storeService: StoreService,
              private authService: AuthService,
              private router: Router,
              private cdr: ChangeDetectorRef,
              private notificationService: NotificationService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin);
    this.storeExtObj = {version_id: this.version_id};

    this.require_filter.filter = [];
    this.require_filter.filter.push('filter[version_id][0][mode]=equals');
    this.require_filter.filter.push('filter[version_id][0][operator]=and');
    this.require_filter.filter.push('filter[version_id][0][value]=' + this.version_id);
    this.require_filter.filter.push('first=0');
    this.require_filter.filter.push('rows=1');
    this.require_filter.index = 2;

    this.Store = new ScOres(this.storeService, this.authService, 'edit');
    switch (this.com_type) {
      case 'ore':
        this.Store = new ScOres(this.storeService, this.authService, 'edit');
        break;
      case 'cargo':
        this.Store = new ScCargo(this.storeService, this.authService, 'edit');
        break;
    }

    this.is_loading = false;
    this.cdr.detectChanges();
  }

  public rowSelected($event: any) {
    const route = 'admin/games/starcitizen/sc-version/' + this.version_id + '/commodity/' +
        this.com_type + '/' + $event.row.com_id;
    if ($event.in_new_tab) {
      const url = this.router.createUrlTree([route]);
      const serializedUrl = this.router.serializeUrl(url);

      // Insert `#` between the domain and route
      const modifiedUrl = '#/' + serializedUrl;
      window.open(modifiedUrl, '_blank');
    } else {
      this.router.navigateByUrl(route);
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  public addClicked() {
    this.router.navigateByUrl('admin/games/starcitizen/sc-version/' + this.version_id + '/commodity/' +
        this.com_type + '/add');
  }
}
