import {Component, Inject, OnInit} from '@angular/core';
import {AsyncPipe, CommonModule, NgForOf, NgIf} from '@angular/common';
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from '@angular/material/autocomplete';
import {MatButton} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle} from '@angular/material/dialog';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {Observable} from 'rxjs';
import {Store} from '../../../class/store';
import {HttpClient} from '@angular/common/http';
import {StoreService} from '../../../services/store.service';
import {AppModule} from '../../../app.module';
import {map, startWith} from 'rxjs/operators';
import {AppSettings} from '../../../config/AppSettings';

@Component({
  selector: 'app-inventory-booking',
  standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormField,
        MatInput,
        MatAutocompleteTrigger,
        MatAutocomplete,
        MatOption,
        MatDialogActions,
        MatButton,
        MatLabel,
        CommonModule,
        MatSelect
    ],
  templateUrl: './inventory-booking.component.html',
  styleUrl: './inventory-booking.component.css'
})
export class InventoryBookingComponent implements OnInit {
    public form: FormGroup;
    public inputSenderControl = new FormControl();
    public inputReceiverControl = new FormControl();
    public filteredSenderOptions: Observable<any[]>;
    public filteredReceiverOptions: Observable<any[]>;
    public isLoading = true;
    public store: Store;

    constructor(
        private fb: FormBuilder,
        private http: HttpClient,
        public dialogRef: MatDialogRef<InventoryBookingComponent>,
        private storeService: StoreService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.form = this.fb.group({
            sender: this.inputSenderControl,
            receiver: this.inputReceiverControl,
            action: 'out',
            value: '',
            note: ''
        });
    }

    ngOnInit() {

        this.filteredSenderOptions = this.inputSenderControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value))
        );
        this.filteredReceiverOptions = this.inputReceiverControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value))
        );

        this.store = this.storeService.getStore('ifa_member');
        this.store.loadStore('').then((records: any[]) => {
            this.isLoading = false;


        });
    }

    private _filter(value: any): any[] {
        let filterValue = '';
        if (typeof value === 'string') {
            filterValue = value.toLowerCase();
        } else if (value && value.data) {
            filterValue = value.data.sc_username.toLowerCase();
        }
        return this.store.getRecords().filter(option => option.data.sc_username.toLowerCase().includes(filterValue));
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
        this.dialogRef.close(this.form.value);
    }

    public displayFn(option?: any): string | undefined {
        return option ? option.data.sc_username : undefined;
    }
}
