<div style="margin:0px;padding:0px;overflow:hidden">

    <div class="loader-container">
        <div>
            <div class="loader">
            </div>
        </div>
    </div>
    <iframe allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"
            id="iframe1"
            [src]="external_src | safe: 'ResourceURL'"
            [frameBorder]="0"
            style="overflow:hidden; width:100%"
            [height]="height"
            [width]="width"></iframe>
</div>

