import {AfterViewInit, Directive, Input, OnInit} from '@angular/core';
import {MatTabGroup} from '@angular/material/tabs';

@Directive({
    selector: '[LastActiveTabDirective]',
    standalone: true
})
export class LastActiveTabDirective implements OnInit, AfterViewInit {
    @Input('LastActiveTabDirective') tabGroupId: string;

    constructor(private tabGroup: MatTabGroup) {
    }

    ngOnInit(): void {
        this.tabGroup.selectedIndexChange.subscribe(index => {
            localStorage.setItem(`lastActiveTab-${this.tabGroupId}`, index.toString());
        });
    }

    ngAfterViewInit(): void {
        this.openLastActiveTab();
    }

    private openLastActiveTab(): void {
        const lastActiveTabIndex = localStorage.getItem(`lastActiveTab-${this.tabGroupId}`);
        if (lastActiveTabIndex !== null) {
            setTimeout(() => {
                this.tabGroup.selectedIndex = +lastActiveTabIndex;
            }, 10);
        }
    }

}
