import {FbFormObject} from '../class/fb_form_object';

export class MiningGadgetLocation extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'gadget', label: 'gadget', visible: true, noclick: false, disable_ripple: true},
            {id: 'location', label: 'location', visible: true, noclick: false, disable_ripple: true},
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Gadget',
                class: 'card card-1 padding single-column',
                field_class: '',
                fields: [
                    {
                        type: 'text',
                        name: 'gadget',
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'autocomplete',
                        name: 'location',
                        options: this.select_boxes['location_select'],
                        rules: [
                            {rule: 'required'}
                        ]
                    }
                ]
            }
        ];
        super.setEditFields();
    }

    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.stores['mining_gadget_location'] = this.storeService.createNoneGlobalStore('mining',
                '/games/starcitizen/mining_gadgets/' + String(this.custom_obj.parent_row.gadget) + '/location-assign',
                'assign_id');

            this.stores['store_locations'] = this.storeService.getStore('store_locations');

            const selectFilter = [];
            selectFilter.push('first=0');
            selectFilter.push('rows=1000');

            this.main_store = this.stores['mining_gadget_location'];

            const stores = [];
            stores.push(this.stores['mining_gadget_location'].loadStore(this.filter));
            stores.push(this.stores['store_locations'].loadStore('?' + selectFilter.join('&')));

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
                gadget: this.custom_obj.parent_row.gadget,
                location: ''
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        this.parseStoreLocationSelectbox();
        // call super
        super.setSelectboxes();
    }

    public parseStoreLocationSelectbox() {
        const options = [];
        const records = this.stores['store_locations'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.location),
                name: records[i].data.location
            });
        }
        this.select_boxes['location_select'] = options;
        return this.select_boxes['location_select'];
    }
}
