import {FbFormObject} from '../class/fb_form_object';

export class ScbEventOreDeliveryBv2 extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'user_id', label: 'SC Username', visible: true, noclick: false, disable_ripple: true},
            {id: 'com_id', label: 'Ore', visible: true, noclick: false, disable_ripple: true},
            {id: 'amount_yield', label: 'yield', visible: true, noclick: false, disable_ripple: true},
            {id: 'price', label: 'price', visible: true, noclick: false, disable_ripple: true},
            {id: 'sales', label: 'sales', visible: true, noclick: false, disable_ripple: true}
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Ore delivery',
                class: 'card card-1 padding single-column',
                field_class: '',
                fields: [
                    {
                        type: 'autocomplete',
                        name: 'user_id',
                        options: this.select_boxes['sc_user_select'],
                        disabled: false
                    },
                    {
                        type: 'select',
                        name: 'com_id',
                        options: this.select_boxes['ores_select'],
                        disabled: false
                    },
                    {
                        type: 'number',
                        name: 'amount_yield',
                        disabled: false
                    },
                    {
                        type: 'number',
                        name: 'sales',
                        disabled: true,
                        no_input: true,
                        number_formatted: true
                    },
                    {
                        type: 'number',
                        name: 'sales',
                        disabled: true,
                        no_input: true,
                        number_formatted: true
                    }
                ]
            }
        ];
        super.setEditFields();
    }

    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.stores['event_ore_delivery'] = this.storeService.createNoneGlobalStore('event-ore-delivery',
                '/games/starcitizen/events/' + String(ext_obj.event_id) + '/commodities/ore',
                'id');
            this.stores['possible_ores'] = this.storeService.createNoneGlobalStore('possible-ores',
                '/games/starcitizen/events/' + String(ext_obj.event_id) + '/possible-commodities/ore',
                'com_id');
            this.stores['event_member'] = this.storeService.createNoneGlobalStore('event-member',
                '/games/starcitizen/events/' + String(ext_obj.event_id) + '/member',
                'user_id');
            this.main_store = this.stores['event_ore_delivery'];

            const tagFilter = [];
            tagFilter.push('first=0');
            tagFilter.push('rows=1000');

            const stores = [];
            if (!this.stores['event_ore_delivery'].isLoaded()) {
                stores.push(this.stores['event_ore_delivery'].loadStore(this.filter));
            }
            if (!this.stores['possible_ores'].isLoaded()) {
                stores.push(this.stores['possible_ores'].loadStore('?' + tagFilter.join('&')));
            }
            if (!this.stores['event_member'].isLoaded()) {
                stores.push(this.stores['event_member'].loadStore('?' + tagFilter.join('&')));
            }

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
                event_id: this.custom_obj.event_id,
                amount_delivered: 0,
                ore_id: 0,
                user_id: 0
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        this.setOreSelectBox();
        this.parseScUserSelectbox();
        // call super
        super.setSelectboxes();
    }

    public setOreSelectBox() {
        const options = [];
        const records = this.stores['possible_ores'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.com_id),
                name: records[i].data.name
            });
        }
        this.select_boxes['ores_select'] = options;
        return this.select_boxes['ores_select'];
    }

    public parseScUserSelectbox() {
        const options = [];
        const records = this.stores['event_member'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.user_id),
                name: records[i].data.sc_username
            });
        }
        this.select_boxes['sc_user_select'] = options;
        return this.select_boxes['sc_user_select'];
    }
}
