<div>
    <app-content-container [content_template]="page_content"
                           [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div class="padding">
        @if (mining_ships) {
            <div class="card card-1 padding">
                <div style="display: inline-block; width: 100%;">
                    <div class="f-left">
                        <mat-chip-listbox class="mat-mdc-chip" aria-label="Mining Ship" [value]="selected_ship.id"
                            (change)="shipSelected($event)">
                            @for (ship of mining_ships; track ship.slug) {
                                <mat-chip-option [value]=" ship.slug">{{ship.name}}</mat-chip-option>
                            }
                        </mat-chip-listbox>
                    </div>
                    <div class="f-right">
                        <button mat-mini-fab
                                matTooltip="share" (click)="shareConfiguration()">
                            <mat-icon aria-label="share">share</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="['2025miner', 'arrastra', 'orion'].includes(configService.selectedShip.id)">
                just kidding
            </div>
            <div *ngIf="!['2025miner', 'arrastra', 'orion'].includes(configService.selectedShip.id)">
                @if (configService && configService.selectedShip && configService.selectedShip.id) {
                    <main class="tiles">
                    @for (laser of configService.lasers; track $index) {
                        <div>
                            <h3 class="text-left">{{laser.position_name}}</h3>
                            <div class="card card-1 padding full-width" style="display: inline-block;">
                                <div class="full-width f-left">
                                    <mat-form-field class="f-left">
                                        <mat-label>Laser {{laser.position_name}}</mat-label>
                                        <mat-select [(value)]="laser.laser"
                                                    (selectionChange)="laserChanged($event, laser)"
                                                    (closed)="resetHoverLaser(laser)"
                                                    [panelWidth]="'1550px'"
                                        >
                                            <mat-option [value]="''">empty</mat-option>
                                            @for (availableLaserGroups of mining_laser_grouped | keyvalue; track availableLaserGroups) {
                                                @if (availableLaserGroups.key <= laser.size) {
                                                    <mat-optgroup [label]="'Size ' + availableLaserGroups.key">
                                                        @for (availableLaser of availableLaserGroups.value; track availableLaser) {
                                                            <mat-option [value]="availableLaser.laser"
                                                                        (mouseover)="onLaserOptionMouseOver(availableLaser, laser)"
                                                            >
                                                                <app-stat-row [name_key]="'laser'"
                                                                              [stats]="availableLaser"
                                                                              [stat_translation]="stat_translation"
                                                                ></app-stat-row>
                                                            </mat-option>
                                                        }
                                                    </mat-optgroup>
                                                }
                                            }
                                        </mat-select>
                                        @if (laser.laser) {
                                            <mat-hint>{{mining_laser_configs[laser.laser].price}} aUEC</mat-hint>
                                        }
                                    </mat-form-field>
                                    <section class="toggle-section f-left">
                                        <mat-slide-toggle [(ngModel)]="laser.is_active"
                                                          (change)="laserActiveChanged($event, laser)"></mat-slide-toggle>
                                    </section>

                                    <div class="f-clear" style="display: grid;">
                                    @for (m_id of laser.moduleIds; let outerIndex = $index; track $index) {
                                        <div>
                                            <mat-form-field  class="f-left">
                                                <mat-label>Module</mat-label>
                                                <mat-select [value]="m_id"
                                                    (selectionChange)="laserModuleChanged($event, laser.position, m_id, outerIndex)"
                                                    (closed)="resetHoverModule(laser, outerIndex)"
                                                    [panelWidth]="'700px'"
                                                >
                                                    <mat-option [value]="''">empty</mat-option>

                                                    <mat-optgroup [label]="'active'">
                                                        @for (module of mining_modules_grouped.active; track $index) {
                                                            <mat-option [value]="module.module"
                                                                        (mouseover)="onModuleOptionMouseOver(module.module, laser, outerIndex)">
                                                                <app-stat-row [name_key]="'module'"
                                                                              [stats]="module"
                                                                              [stat_translation]="stat_translation"
                                                                ></app-stat-row>
                                                            </mat-option>
                                                        }
                                                    </mat-optgroup>

                                                    <mat-optgroup [label]="'passive'">
                                                        @for (module of mining_modules_grouped.passive; track $index) {
                                                            <mat-option [value]="module.module"
                                                                        (mouseover)="onModuleOptionMouseOver(module.module, laser, outerIndex)">
                                                                <app-stat-row [name_key]="'module'"
                                                                              [stats]="module"
                                                                              [stat_translation]="stat_translation"
                                                                ></app-stat-row>
                                                            </mat-option>
                                                        }
                                                    </mat-optgroup>

                                                </mat-select>
                                                @if (mining_module_with_key[m_id]) {
                                                    <mat-hint>{{mining_module_with_key[m_id].price}} aUEC</mat-hint>
                                                }
                                            </mat-form-field>
                                            @if (m_id && mining_module_with_key && mining_module_with_key[m_id].type === 'active') {
                                                <section class="toggle-section" style="float: left;">
                                                    <mat-slide-toggle [(ngModel)]="laser.moduleStates[$index]"
                                                                      (change)="laserModuleActiveChanged($event, laser.position, m_id, $index)"></mat-slide-toggle>
                                                </section>
                                            }
                                        </div>
                                    }
                                    </div>
                                </div>
                                <div class="padding f-left full-width" *ngIf="laser.stats">
                                    <mat-grid-list cols="2" rowHeight="56px">
                                        <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                            <div class="full-width">
                                                <app-display-stat
                                                        [caption]="'opt range'"
                                                        [tooltip]="'optimum range'"
                                                        [value]="laser.stats.optimum_range"
                                                        [display_value]="laser.stats.optimum_range"
                                                        [negative_class]="''"
                                                        [positive_class]="''"
                                                        [unit]="''">
                                                </app-display-stat>
                                            </div>
                                        </mat-grid-tile>
                                        <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                            <div class="full-width">
                                                <app-display-stat
                                                        [caption]="'max range'"
                                                        [tooltip]="'max range'"
                                                        [value]="laser.stats.max_range"
                                                        [display_value]="laser.stats.max_range"
                                                        [negative_class]="''"
                                                        [positive_class]="''"
                                                        [unit]="''">
                                                </app-display-stat>
                                            </div>
                                        </mat-grid-tile>
                                        <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                            <div class="full-width">
                                                <app-display-stat
                                                        [caption]="'min power'"
                                                        [tooltip]="'min power'"
                                                        [value]="laser.bonus_stats.min_power"
                                                        [display_value]="laser.stats.min_power"
                                                        [negative_class]="'bad'"
                                                        [positive_class]="'good'"
                                                        [unit]="''">
                                                </app-display-stat>
                                            </div>
                                        </mat-grid-tile>
                                        <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                            <div class="full-width">
                                                <app-display-stat
                                                        [caption]="'max power'"
                                                        [tooltip]="'max power'"
                                                        [value]="laser.bonus_stats.max_power"
                                                        [display_value]="laser.stats.max_power"
                                                        [negative_class]="'bad'"
                                                        [positive_class]="'good'"
                                                        [unit]="''">
                                                </app-display-stat>
                                            </div>
                                        </mat-grid-tile>
                                        <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                            <div class="full-width">
                                                <app-display-stat
                                                        [caption]="'ext power'"
                                                        [tooltip]="'ext power'"
                                                        [value]="laser.stats.extract_power"
                                                        [display_value]="laser.stats.extract_power"
                                                        [negative_class]="'bad'"
                                                        [positive_class]="'good'"
                                                        [unit]="''">
                                                </app-display-stat>
                                            </div>
                                        </mat-grid-tile>
                                        <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                            <div class="full-width">
                                                <app-display-stat
                                                        [caption]="'resistance'"
                                                        [tooltip]="'resistance'"
                                                        [value]="laser.stats.resistance"
                                                        [display_value]="laser.stats.resistance_display"
                                                        [negative_class]="'good'"
                                                        [positive_class]="'bad-positive-percent'"
                                                        [unit]="'%'">
                                                </app-display-stat>
                                            </div>
                                        </mat-grid-tile>
                                        <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                            <div class="full-width">
                                                <app-display-stat
                                                        [caption]="'instability'"
                                                        [tooltip]="'instability'"
                                                        [value]="laser.stats.instability"
                                                        [display_value]="laser.stats.instability_display"
                                                        [negative_class]="'good'"
                                                        [positive_class]="'bad-positive-percent'"
                                                        [unit]="'%'">
                                                </app-display-stat>
                                            </div>
                                        </mat-grid-tile>
                                        <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                            <div class="full-width">
                                                <app-display-stat
                                                        [caption]="'opt chrg rt'"
                                                        [tooltip]="'optimal charge rate'"
                                                        [value]="laser.stats.optimal_charge_rate"
                                                        [display_value]="laser.stats.optimal_charge_rate_display"
                                                        [negative_class]="'bad'"
                                                        [positive_class]="'good-positive-percent'"
                                                        [unit]="'%'">
                                                </app-display-stat>
                                            </div>
                                        </mat-grid-tile>
                                        <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                            <div class="full-width">
                                                <app-display-stat
                                                        [caption]="'opt chrg wnd'"
                                                        [tooltip]="'optimal charge window'"
                                                        [value]="laser.stats.optimal_charge_window"
                                                        [display_value]="laser.stats.optimal_charge_window_display"
                                                        [negative_class]="'bad'"
                                                        [positive_class]="'good-positive-percent'"
                                                        [unit]="'%'">
                                                </app-display-stat>
                                            </div>
                                        </mat-grid-tile>
                                        <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                            <div class="full-width">
                                                <app-display-stat
                                                        [caption]="'overcharge'"
                                                        [tooltip]="'overcharge rate'"
                                                        [value]="laser.stats.overcharge_rate"
                                                        [display_value]="laser.stats.overcharge_rate_display"
                                                        [negative_class]="'good'"
                                                        [positive_class]="'bad-positive-percent'"
                                                        [unit]="'%'">
                                                </app-display-stat>
                                            </div>
                                        </mat-grid-tile>
                                        <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                            <div class="full-width">
                                                <app-display-stat
                                                        [caption]="'inert'"
                                                        [tooltip]="'inert materials'"
                                                        [value]="laser.stats.inert_materials"
                                                        [display_value]="laser.stats.inert_materials_display"
                                                        [negative_class]="'good'"
                                                        [positive_class]="'bad-positive-percent'"
                                                        [unit]="'%'">
                                                </app-display-stat>
                                            </div>
                                        </mat-grid-tile>
                                        <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                            <div class="full-width">
                                                <app-display-stat
                                                        [caption]="'shatter'"
                                                        [tooltip]="'shatter damage'"
                                                        [value]="laser.stats.shatter_damage"
                                                        [display_value]="laser.stats.shatter_damage_display"
                                                        [negative_class]="'good'"
                                                        [positive_class]="'bad-positive-percent'"
                                                        [unit]="'%'">
                                                </app-display-stat>
                                            </div>
                                        </mat-grid-tile>
                                    </mat-grid-list>
                                </div>
                            </div>
                        </div>
                    }
                        <div>
                            <h3 class="text-left">Gadget</h3>
                            <div class="card card-1" style="display: grid;">
                                <div class="padding" style="display: inline-block;">
                                    <mat-form-field class="f-left">
                                        <mat-label>Gadget</mat-label>
                                        <mat-select [(value)]="configService.gadget.gadget"
                                                    (selectionChange)="gadgetChanged($event)"
                                                    (closed)="resetHoverGadget()"
                                        >
                                            <mat-option [value]="''">empty</mat-option>
                                            @for (gadget of mining_gadgets; track $index) {
                                                <mat-option [value]="gadget.gadget"
                                                            (mouseover)="onGadgetOptionMouseOver(gadget)"
                                                >{{gadget.gadget}}</mat-option>
                                            }
                                        </mat-select>
                                        @if (configService.gadget) {
                                            <mat-hint>{{configService.gadget.price}} aUEC</mat-hint>
                                        }
                                    </mat-form-field>
                                    <section class="toggle-section f-left">
                                        <mat-slide-toggle [(ngModel)]="configService.gadget_enabled"
                                                          (change)="gadgetActiveChanged($event)"></mat-slide-toggle>
                                    </section>
                                </div>
                                <div class="padding f-left full-width" style="display: inline-block;">
                                    <mat-grid-list cols="2" rowHeight="56px">
                                        <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                            <div class="full-width">
                                                <app-display-stat
                                                        [caption]="'resistance'"
                                                        [tooltip]="'resistance'"
                                                        [value]="gadget_bonus.resistance"
                                                        [display_value]="gadget_bonus.resistance_display"
                                                        [negative_class]="'good'"
                                                        [positive_class]="'bad-positive-percent'"
                                                        [unit]="'%'">
                                                </app-display-stat>
                                            </div>
                                        </mat-grid-tile>
                                        <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                            <div class="full-width">
                                                <app-display-stat
                                                        [caption]="'instability'"
                                                        [tooltip]="'instability'"
                                                        [value]="gadget_bonus.instability"
                                                        [display_value]="gadget_bonus.instability_display"
                                                        [negative_class]="'good'"
                                                        [positive_class]="'bad-positive-percent'"
                                                        [unit]="'%'">
                                                </app-display-stat>
                                            </div>
                                        </mat-grid-tile>
                                        <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                            <div class="full-width">
                                                <app-display-stat
                                                        [caption]="'opt chrg rt'"
                                                        [tooltip]="'optimal charge rate'"
                                                        [value]="gadget_bonus.optimal_charge_rate"
                                                        [display_value]="gadget_bonus.optimal_charge_rate_display"
                                                        [negative_class]="'bad'"
                                                        [positive_class]="'good-positive-percent'"
                                                        [unit]="'%'">
                                                </app-display-stat>
                                            </div>
                                        </mat-grid-tile>
                                        <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                            <div class="full-width">
                                                <app-display-stat
                                                        [caption]="'opt chrg wnd'"
                                                        [tooltip]="'optimal charge window'"
                                                        [value]="gadget_bonus.optimal_charge_window"
                                                        [display_value]="gadget_bonus.optimal_charge_window_display"
                                                        [negative_class]="'bad'"
                                                        [positive_class]="'good-positive-percent'"
                                                        [unit]="'%'">
                                                </app-display-stat>
                                            </div>
                                        </mat-grid-tile>
                                        <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                            <div class="full-width">
                                                <app-display-stat
                                                        [caption]="'overcharge'"
                                                        [tooltip]="'overcharge rate'"
                                                        [value]="gadget_bonus.overcharge_rate"
                                                        [display_value]="gadget_bonus.overcharge_rate_display"
                                                        [negative_class]="'good'"
                                                        [positive_class]="'bad-positive-percent'"
                                                        [unit]="'%'">
                                                </app-display-stat>
                                            </div>
                                        </mat-grid-tile>
                                        <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                            <div class="full-width">
                                                <app-display-stat
                                                        [caption]="'shatter'"
                                                        [tooltip]="'shatter damage'"
                                                        [value]="gadget_bonus.shatter_damage"
                                                        [display_value]="gadget_bonus.shatter_damage_display"
                                                        [negative_class]="'good'"
                                                        [positive_class]="'bad-positive-percent'"
                                                        [unit]="'%'">
                                                </app-display-stat>
                                            </div>
                                        </mat-grid-tile>
                                        <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                            <div class="full-width">
                                                <app-display-stat
                                                        [caption]="'clustering'"
                                                        [tooltip]="'clustering'"
                                                        [value]="gadget_bonus.clustering"
                                                        [display_value]="gadget_bonus.clustering_display"
                                                        [negative_class]="'bad'"
                                                        [positive_class]="'good-positive-percent'"
                                                        [unit]="'%'">
                                                </app-display-stat>
                                            </div>
                                        </mat-grid-tile>
                                    </mat-grid-list>
                                </div>
                            </div>
                        </div>
                    </main>
                    <div class="two-tiles">
                        <div>
                            <h3 class="text-left">Summary</h3>
                            <div class="card card-1 padding">
                                <mat-grid-list cols="5" rowHeight="56px">
                                    <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                        <div class="full-width">
                                            <app-display-stat
                                                    [caption]="'min power'"
                                                    [tooltip]="'min power'"
                                                    [value]="all_bonus_stats.min_power / all_stats.active_laser_count"
                                                    [display_value]="all_stats.min_power"
                                                    [negative_class]="'bad'"
                                                    [positive_class]="'good'"
                                                    [unit]="''">
                                            </app-display-stat>
                                        </div>
                                    </mat-grid-tile>
                                    <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                        <div class="full-width">
                                            <app-display-stat
                                                    [caption]="'max power'"
                                                    [tooltip]="'max power'"
                                                    [value]="all_bonus_stats.max_power / all_stats.active_laser_count"
                                                    [display_value]="all_stats.max_power"
                                                    [negative_class]="'bad'"
                                                    [positive_class]="'good'"
                                                    [unit]="''">
                                            </app-display-stat>
                                        </div>
                                    </mat-grid-tile>
                                    <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                        <div class="full-width">
                                            <app-display-stat
                                                    [caption]="'resistance'"
                                                    [tooltip]="'resistance'"
                                                    [value]="all_stats.resistance"
                                                    [display_value]="all_stats.resistance_display"
                                                    [negative_class]="'good'"
                                                    [positive_class]="'bad-positive-percent'"
                                                    [unit]="'%'">
                                            </app-display-stat>
                                        </div>
                                    </mat-grid-tile>
                                    <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                        <div class="full-width">
                                            <app-display-stat
                                                    [caption]="'instability'"
                                                    [tooltip]="'instability'"
                                                    [value]="all_stats.instability"
                                                    [display_value]="all_stats.instability_display"
                                                    [negative_class]="'good'"
                                                    [positive_class]="'bad-positive-percent'"
                                                    [unit]="'%'">
                                            </app-display-stat>
                                        </div>
                                    </mat-grid-tile>
                                    <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                        <div class="full-width">
                                            <app-display-stat
                                                    [caption]="'opt chrg rt'"
                                                    [tooltip]="'optimal charge rate'"
                                                    [value]="all_stats.optimal_charge_rate"
                                                    [display_value]="all_stats.optimal_charge_rate_display"
                                                    [negative_class]="'bad'"
                                                    [positive_class]="'good-positive-percent'"
                                                    [unit]="'%'">
                                            </app-display-stat>
                                        </div>
                                    </mat-grid-tile>
                                    <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                        <div class="full-width">
                                            <app-display-stat
                                                    [caption]="'opt chrg wnd'"
                                                    [tooltip]="'optimal charge window'"
                                                    [value]="all_stats.optimal_charge_window"
                                                    [display_value]="all_stats.optimal_charge_window_display"
                                                    [negative_class]="'bad'"
                                                    [positive_class]="'good-positive-percent'"
                                                    [unit]="'%'">
                                            </app-display-stat>
                                        </div>
                                    </mat-grid-tile>
                                    <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                        <div class="full-width">
                                            <app-display-stat
                                                    [caption]="'overcharge'"
                                                    [tooltip]="'overcharge rate'"
                                                    [value]="all_stats.overcharge_rate"
                                                    [display_value]="all_stats.overcharge_rate_display"
                                                    [negative_class]="'good'"
                                                    [positive_class]="'bad-positive-percent'"
                                                    [unit]="'%'">
                                            </app-display-stat>
                                        </div>
                                    </mat-grid-tile>
                                    <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                        <div class="full-width">
                                            <app-display-stat
                                                    [caption]="'shatter'"
                                                    [tooltip]="'shatter damage'"
                                                    [value]="all_stats.shatter_damage"
                                                    [display_value]="all_stats.shatter_damage_display"
                                                    [negative_class]="'good'"
                                                    [positive_class]="'bad-positive-percent'"
                                                    [unit]="'%'">
                                            </app-display-stat>
                                        </div>
                                    </mat-grid-tile>
                                    <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                        <div class="full-width">
                                            <app-display-stat
                                                    [caption]="'clustering'"
                                                    [tooltip]="'clustering'"
                                                    [value]="all_stats.clustering"
                                                    [display_value]="all_stats.clustering_display"
                                                    [negative_class]="'bad'"
                                                    [positive_class]="'good-positive-percent'"
                                                    [unit]="'%'">
                                            </app-display-stat>
                                        </div>
                                    </mat-grid-tile>
                                    <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                        <div class="full-width">
                                            <app-display-stat
                                                    [caption]="'cost'"
                                                    [tooltip]="'load out cost'"
                                                    [value]="all_stats.price"
                                                    [display_value]="all_stats.price"
                                                    [negative_class]="''"
                                                    [positive_class]="''"
                                                    [unit]="' aUEC'">
                                            </app-display-stat>
                                        </div>
                                    </mat-grid-tile>
                                </mat-grid-list>
                            </div>
                        </div>
                        <div>
                            <h3 class="text-left">Shops</h3>
                            <div class="card card-1 padding two-tiles">
                                @for (shop of shop_list | keyvalue; track shop.key) {
                                    <div>
                                        <div class="full-width">
                                            <h3>
                                            {{shop.key}} ({{getObjectLength(shop.value)}})
                                            </h3>
                                        </div>
                                        <div>
                                            <ul>
                                                @for (entry of shop.value | keyvalue; track $index) {
                                                    <li>
    <!--                                                @if ($index % 2 !== 0) {                                                    <li>-->
                                                        {{entry.value.wanted}} x

                                                        @if (entry.value.type === 'laser') {
                                                            <span class="item-name">
                                                            {{entry.value.item.laser}}
                                                            </span> for {{entry.value.item.price}} aUEC
                                                        }
                                                        @if (entry.value.type === 'module') {
                                                            <span class="item-name">
                                                            {{entry.value.item.module}}
                                                            </span> for {{entry.value.item.price}} aUEC
                                                        }
                                                        @if (entry.value.type === 'gadget') {
                                                            <span class="item-name">
                                                            {{entry.value.item.gadget}}
                                                            </span> for {{entry.value.item.price}} aUEC
                                                        }
                                                    </li>
    <!--                                                }-->
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        }
    </div>
</ng-template>
