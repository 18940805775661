import {Component} from '@angular/core';
import {CookieService} from '../../services/cookie.service';

@Component({
    selector: 'app-page-footer',
    templateUrl: './page-footer.component.html',
    styleUrls: ['./page-footer.component.css']
})
export class PageFooterComponent {
    constructor(private cookieService: CookieService) {
    }

    openCookieConsent() {
        this.cookieService.openConsent();
    }
}
