import {Validators} from './validators';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn} from '@angular/forms';
import {FormInputsComponent} from "../form-inputs/form-inputs.component";


export class FbValidationManager {

    private controls = {};

    formGroup: UntypedFormGroup;
    errors = {};
    submitted = false;
    children = {};


    private _fb: UntypedFormBuilder;

    private change_subs: any[] = [];

    constructor(formValidations: String | Object | Array<FbValidationManager> | FbValidationManager,
                private displayError: Array<String> = ['invalid', 'dirty', 'submitted'],
                private fieldNameLookUp: any,
                private translation: any) {
        this.formGroup = new UntypedFormGroup({});
        this._fb = new UntypedFormBuilder();
        this.setRules(formValidations);
        this.addRuleChanges();
    }

    setRules(formValidations: String | Object | Array<FbValidationManager> | FbValidationManager) {
        // tslint:disable-next-line:forin
        for (const key in formValidations) {
            // @ts-ignore
            if (typeof formValidations[key] === 'string') {
                // @ts-ignore
            } else if (formValidations[key] instanceof FbValidationManager) {
                // @ts-ignore
                this.children[key] = formValidations[key];
                // @ts-ignore
                this.controls[key] = {control: formValidations[key].getForm(), messages: {}};
            // @ts-ignore
            } else if (formValidations[key] instanceof Array) {
                // @ts-ignore
                this.children[key] = [];
                const formArray = <UntypedFormArray>this._fb.array([]);
                // @ts-ignore
                for (const group of formValidations[key]) {
                    if (group instanceof FbValidationManager) {
                        formArray.push(group.getForm());
                        // @ts-ignore
                        this.children[key].push(group);
                    } else {
                        formArray.push(new UntypedFormControl(group));
                    }

                }
                // @ts-ignore
                this.controls[key] = {control: formArray, messages: {}};
                // @ts-ignore
            } else if (typeof formValidations[key] === 'object') {
                // @ts-ignore
                if (!formValidations[key].value) {
                    // @ts-ignore
                    formValidations[key].value = '';
                }
                // @ts-ignore
                this.controls[key] = this.buildControl(key, formValidations[key].rules, formValidations[key].value);
            }
            // @ts-ignore
            this.controls[key] = this.buildControl(key, formValidations[key]);
            // @ts-ignore
            this.formGroup.addControl(key, this.controls[key].control);
            // @ts-ignore
            this.errors[key] = '';
        }
    }

    removeRules() {
        this.detachRuleChanges();
        this.formGroup.clearAsyncValidators();
    }

    detachRuleChanges() {
        this.change_subs.forEach((sub) => {
            // @ts-ignore
            sub.unsubscribe();
        });
        this.change_subs = [];
    }

    addRuleChanges() {
        // @ts-ignore
        this.change_subs.push(this.formGroup.valueChanges.subscribe(data => this.onValueChanged()));
    }

    revalidate() {
        this.formGroup.updateValueAndValidity();
    }

    updateRule(key: string, field_form_obj: any) {
        const form = this.formGroup;

        // form.get(key).updateValueAndValidity();
        if (field_form_obj === '') {
            // form.get(key).clearValidators();
            // this.controls[key].control.setValue(value);
            // @ts-ignore
            this.controls[key].control.clearValidators();
        } else {
            // form.get(key).clearValidators();
            // this.controls[key].control.clearValidators();
            // @ts-ignore
            this.controls[key].control.clearValidators();
            // this.controls[key].control.setValidators(field_form_obj);
            // @ts-ignore
            this.controls[key] = this.buildControl(key, field_form_obj);
            // @ts-ignore
            this.formGroup.setControl(key, this.controls[key].control);
        }
        // @ts-ignore
        this.controls[key].control.setErrors(null);
        // @ts-ignore
        this.controls[key].control.updateValueAndValidity();
    }

    updateRuleOld(key: string, field_form_obj: any) {


        // this.formGroup.get(key).setErrors(null);
        this.detachRuleChanges();
        const form = this.formGroup;
        // @ts-ignore
        this.errors[key] = '';
        const control_old = form.get(key);
        // @ts-ignore
        form.get(key).setValidators(null);
        // @ts-ignore
        form.get(key).setErrors(null);

        // @ts-ignore
        form.get(key).clearValidators();
        // @ts-ignore
        form.get(key).updateValueAndValidity();

        // @ts-ignore
        this.errors[key] = '';

        // @ts-ignore
        this.controls[key] = this.buildControl(key, field_form_obj);

        // @ts-ignore
        this.formGroup.setControl(key, this.controls[key].control);
        const control = form.get(key);

        // @ts-ignore
        this.errors[key] = '';
        this.addRuleChanges();
        // @ts-ignore
        this.errors[key] = '';

        // @ts-ignore
        control.updateValueAndValidity();
    }

    getForm() {
        return this.formGroup;
    }

    getChildGroup(field: string, index: number | null) {
        if (index !== null) {
            // @ts-ignore
            return this.children[field][index];
        }
        // @ts-ignore
        return this.children[field];
    }

    getChildren(field: string) {
        // @ts-ignore
        return this.children[field];
    }

    addChildGroup(field: string, mgr: FbValidationManager | any) {
        if (this.formGroup.controls[field] && this.formGroup.controls[field] instanceof UntypedFormArray) {
            const control = <UntypedFormArray>this.formGroup.controls[field];
            if (mgr instanceof FbValidationManager) {
                control.push(mgr.getForm());
                // @ts-ignore
                this.children[field].push(mgr);
            } else {
                control.push(new UntypedFormControl(mgr));
            }

            return control.length - 1;
        } else {
            // @ts-ignore
            this.children[field] = mgr;
            this.formGroup.addControl(field, mgr.getForm());
            return -1;
        }
    }

    removeChildGroup(field: string, index: number | null) {
        if (!this.formGroup.controls[field]) {
            return;
        }

        if (index !== null) {
            const control = <UntypedFormArray>this.formGroup.controls[field];
            control.removeAt(index);
            // @ts-ignore
            this.children[field].splice(index, 1);
        } else {
            this.formGroup.removeControl(field);
            // @ts-ignore
            delete this.children[field];
        }
    }

    isValid() {
        this.submitted = true;
        this.__setOnChild('submitted', true);
        this.onValueChanged();
        return !this.formGroup.invalid;
    }

    hasError(field: string) {
        // @ts-ignore
        return this.errors[field] ? true : false;
    }

    getError(field: string) {
        // @ts-ignore
        return this.errors[field];
    }

    getErrors() {
        for (const child in this.children) {
            // @ts-ignore
            if (this.children[child] instanceof Array) {
                // @ts-ignore
                this.errors[child] = {};
                // tslint:disable-next-line:forin
                // @ts-ignore
                for (const subChild in this.children[child]) {
                    // @ts-ignore
                    this.errors[child][subChild] = this.children[child][subChild].errors;
                }
            } else {
                // @ts-ignore
                this.errors[child] = this.children[child].errors;
            }
        }
        return this.errors;
    }

    reset() {
        this.submitted = false;
        this.formGroup.reset();
        this.__setOnChild('submitted', false);
        // tslint:disable-next-line:forin
        for (const fld in this.children) {
            // @ts-ignore
            for (const child of this.children[fld]) {
                child.formGroup.reset();
            }
        }
    }

    // @ts-ignore
    onValueChanged(displayError = null) {
        if (!this.formGroup) {
            return;
        }

        const form = this.formGroup;
        for (const field in this.errors) {
            const control = form.get(field);
            // @ts-ignore
            this.errors[field] = '';

            if (displayError == null) {
                // @ts-ignore
                displayError = this.displayError;
            }

            // console.warn('onValueChanged control.errors', control.errors);
            // @ts-ignore
            if (control && displayError.length && (displayError.every(element => {
                // @ts-ignore
                return (element === 'submitted') ? true : control[element];
            }) || this.submitted)) {
                // tslint:disable-next-line:forin
                for (const rule in control.errors) {
                    if (rule === 'matDatepickerParse' && control.errors['matDatepickerParse'].text === '') {
                        return null;
                    }
                    // @ts-ignore
                    this.errors[field] = this.getErrorMessage(field, rule);
                }
            }
        }

        this.__callOnChild('onValueChanged');
    }

    setValue(values: Object | String, value: any = null) {
        if (typeof values === 'string') {
            const control = this.formGroup.get(values);
            if (!control || control instanceof UntypedFormArray) {
                return;
            }

            if (value !== null) {
                // @ts-ignore
                this.formGroup.get(values).setValue(value.toString());
                // @ts-ignore
                this.formGroup.get(values).markAsTouched();
                // @ts-ignore
                this.formGroup.get(values).markAsDirty();
            }
        }

        if (typeof values === 'object') {
            for (const key in values) {
                if (this.formGroup.get(key)) {
                    // @ts-ignore
                    this.setValue(key, values[key]);
                }
            }
        }
    }

    getValue(controlKey: string) {
        return this.formGroup.value[controlKey];
    }

    getData() {
        return this.formGroup.value;
    }

    getControl(controlName: string): any {
        if (!this.formGroup.controls[controlName]) {
            return;
        }
        return this.formGroup.controls[controlName];
    }

    buildControl(name: string, rules: string, value: string | Object | null) {
        const controlRules: ValidatorFn[] = [];
        const messages: any = {};

        rules = rules.replace(/pattern:(\/.+\/)(\|?)/, function (a, b, c) {
            return 'pattern:' + btoa(b) + c;
        });

        rules.split('|').forEach(rule => {
            if (rule) {
                const rule_spilted = rule.split(':');
                const rule_name = rule_spilted[0];


                let rule_vars: any[] = [];
                if (rule_spilted[1]) {
                    rule_vars = rule_spilted[1].split(',');
                }
                // @ts-ignore
                if (!Validators[rule_name]) {
                    throw new TypeError('Validation rule [' + rule_name + '] does not exists.');
                }

                if (rule_vars.length > 1) {
                    // @ts-ignore
                    controlRules.push(Validators[rule_name](rule_vars));
                } else if (rule_vars.length === 1) {

                    if (rule_name === 'pattern' && isBase64(rule_vars[0])) {
                        rule_vars[0] = atob(rule_vars[0]).slice(1, -1);
                    }
                    // @ts-ignore
                    controlRules.push(Validators[rule_name](rule_vars[0]));
                } else {
                    // @ts-ignore
                    controlRules.push(Validators[rule_name]);
                }
                // @ts-ignore
                messages[rule_name.toLowerCase()] = this.buildMessage(name, rule_name, rule_vars);
            }

            messages['mask'] = 'test';

        });

        const formControl = new UntypedFormControl(value, controlRules);
        return {control: formControl, messages: messages};
    }

    private getErrorMessage(field: string, rule: string) {
        // @ts-ignore
        if (!this.controls[field].messages[rule.toLowerCase()]) {
            // @ts-ignore
            console.log(this.controls[field].messages);
            // @ts-ignore
            throw Error('Message not found inside the control:' + field + ' message:' + rule.toLowerCase());
        }
        // @ts-ignore
        return this.controls[field].messages[rule.toLowerCase()];
    }

    private buildMessage(name: string, rule: string, arg: any = []) {
        if (!this.getMessage(rule)) {
            throw Error('Validation message is missing for: ' + rule);
        }

        let message = this.getMessage(rule);
        // message = message.replace(/%n/g, ucFirst(name)).replace(/_/g, ' ');
        message = message.replace(/%n/g, this.fieldNameLookUp[name]);

        if (arg.length) {
            // tslint:disable-next-line:no-shadowed-variable
            if (rule === 'equalTo') {
                arg.forEach((arg: any, key: string) => {
                    message = message.replace('%' + key, this.fieldNameLookUp[arg]);
                });
            } else {
                arg.forEach((arg: any, key: string) => {
                    message = message.replace('%' + key, arg);
                });
            }
        }

        return message;
    }

    private getMessage(rule: string) {
        // @ts-ignore
        return this.translation[rule];
    }

    private __callOnChild(funct: any) {
        for (const fld in this.children) {
            // @ts-ignore
            if (this.children[fld] instanceof Array) {
                // @ts-ignore
                for (const child of this.children[fld]) {
                    child[funct].apply(child, Array.prototype.slice.call(arguments, 1));
                }
            } else {
                // @ts-ignore
                this.children[fld][funct].apply(this.children[fld], Array.prototype.slice.call(arguments, 1));
            }

        }
    }

    private __setOnChild(field: string, value: any) {
        for (const fld in this.children) {
            // @ts-ignore
            if (this.children[fld] instanceof Array) {
                // @ts-ignore
                for (const child of this.children[fld]) {
                    child[field] = value;
                }
            } else {
                // @ts-ignore
                this.children[fld][field] = value;
            }
        }
    }
}

function ucFirst(str: string) {
    const firstLetter = str.substring(0, 1);
    return firstLetter.toUpperCase() + str.substring(1);
}

function isBase64(str: string) {
    try {
        return btoa(atob(str)) === str;
    } catch (err) {
        return false;
    }
}
