<main class="big-tiles padding">
    <article class="card card-1 full-width" *ngIf="(record && summaryRows && summaryRows.length > 0); else noData">
        <div class="padding">
            <div class="">
                <div class="">
                    <table style="width: 100%; border-spacing: 0;">
                        <tr>
                            <th class="th-spacing">User</th>
                            <th class="th-spacing align-right">Time</th>
                            <th class="th-spacing align-right">%</th>
                            <th class="th-spacing align-right">to pay</th>
                            <th class="th-spacing align-right">to get</th>
<!--                            <th class="th-spacing align-right">Payment combined</th>-->
                        </tr>
                        <tr *ngFor="let row of summaryRows" [style.color]="getRowColor(row)">
                            <td class="td-spacing">{{row.sc_username}}</td>
                            <td class="td-spacing">
                                {{row.start_ts | date:'shortTime':'': 'de'}} - {{row.end_ts | date:'shortTime':'': 'de'}}
                            </td>
                            <td class="td-spacing align-right">{{row.percent}}</td>
                            <td class="td-spacing align-right"><div [appCopyValue]="row.profit">{{row.profit_formatted}}</div></td>
                            <td class="td-spacing align-right"><div [appCopyValue]="row.payout_value">{{row.payout_value_formatted}}</div></td>
<!--                            <td class="td-spacing align-right">{{row.payment}}</td>-->
                        </tr>
                        <tr *ngIf="record.data.bonus && record.data.bonus > 0">
                            <td style="color: rgb(0, 200, 83)"
                                class="bottom-row td-spacing" colspan="2">Bonus</td>
                            <td style="color: rgb(0, 200, 83)" class="bottom-row td-spacing align-right"><div [appCopyValue]="record.data.bonus">{{record.data.bonus_formatted}}</div></td>
                            <td style="color: rgb(0, 200, 83)"
                                class="bottom-row td-spacing align-right">-</td>
                            <td style="color: rgb(0, 200, 83)"
                                class="bottom-row td-spacing align-right">
                                <div [appCopyValue]="record.data.bonus">{{record.data.bonus_formatted}}</div>
                            </td>
                        </tr>
                        <tr *ngIf="record.data.bonus_tax_free && record.data.bonus_tax_free > 0">
                            <td style="color: rgb(0, 200, 83)"
                                class="bottom-row td-spacing" colspan="2">Bonus tax free</td>
                            <td style="color: rgb(0, 200, 83)" class="bottom-row td-spacing align-right"><div [appCopyValue]="record.data.bonus_tax_free">{{record.data.bonus_tax_free_formatted}}</div></td>
                            <td style="color: rgb(0, 200, 83)"
                                class="bottom-row td-spacing align-right">-</td>
                            <td style="color: rgb(0, 200, 83)"
                                class="bottom-row td-spacing align-right">
                                <div [appCopyValue]="record.data.bonus_tax_free">{{record.data.bonus_tax_free_formatted}}</div>
                            </td>
                        </tr>
                        <tr *ngIf="record.data.orga_tax && record.data.orga_tax > 0">
                            <td style="color: #ffd600"
                                class="bottom-row td-spacing" colspan="2">Tax</td>
                            <td style="color: #ffd600" class="bottom-row td-spacing align-right"><div [appCopyValue]="record.data.orga_tax">-{{record.data.orga_tax_formatted}}</div></td>
                            <td style="color: #ffd600"
                                class="bottom-row td-spacing align-right">-</td>
                            <td style="color: #ffd600"
                                class="bottom-row td-spacing align-right">
                                <div [appCopyValue]="record.data.orga_tax_value">-{{record.data.orga_tax_value_formatted}}</div>
                            </td>
                        </tr>
                        <tr>
                            <td style="color: #00c853">total</td>
                            <td class="td-spacing">
                                {{record.data.start_ts | date:'shortTime':'': 'de'}} - {{record.data.end_ts | date:'shortTime':'': 'de'}}
                            </td>
                            <td class="td-spacing"></td>
                            <td style="color: #00c853"
                                class="td-spacing align-right"><div [appCopyValue]="record.data.sum_profit">{{record.data.sum_profit_formatted}}</div></td>
                            <td style="color: #00c853"
                                class="td-spacing align-right">-</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </article>
</main>

<ng-template #noData>
    <article class="card card-1 full-width" matRipple>
        <div class="padding">
            <div class="">
                <div class="">
                    No Data yet
                </div>
            </div>
        </div>
    </article>
</ng-template>
