import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {StoreService} from '../../services/store.service';
import {AuthService} from '../../services/auth.service';
import {NotificationService} from '../../services/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {AppSettings} from '../../config/AppSettings';
import {HttpClient} from '@angular/common/http';
import {AppModule} from '../../app.module';

@Component({
    selector: 'app-vehicle-details',
    standalone: false,
    templateUrl: './vehicle-details.component.html',
    styleUrl: './vehicle-details.component.css'
})
export class VehicleDetailsComponent implements OnInit, OnDestroy, AfterViewInit {

    public model: string;
    public loading_text = '';
    public is_loading = true;
    public vehicle: any;
    public hardPoints: any;
    public paints: any[];
    public variants: any[];
    public hardPointTypes: any = {};
    private loadCount = 0;

    private subs: Array<Subscription> = [];


    constructor(private storeService: StoreService,
                private authService: AuthService,
                private notificationService: NotificationService,
                private route: ActivatedRoute,
                private router: Router,
                private cdr: ChangeDetectorRef,
                public dialog: MatDialog,
                private http: HttpClient) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.subs.push(this.route.params.subscribe((params: any) => {
            this.model = params.model;
            this.cdr.detectChanges();

            this.loadCount = 0;
            this.hardPointTypes = {};
            this.vehicle = undefined;
            this.variants = [];
            this.paints = [];

            this.http.get<any[]>(AppSettings.getRealAPILink() + 'fleetyards/models/' + this.model, {withCredentials: true})
                .subscribe((result: any) => {
                    this.cdr.detectChanges();
                    let records = [];
                    if (result.success) {
                        records = result.rows;
                    } else {
                        // old api
                        records = result;
                    }

                    this.vehicle = records[0];
                    console.log('vehicle', this.vehicle);
                    this.loadCount++;
                    this.is_loading = this.loadCount !== 4;
                    this.cdr.detectChanges();
                });

            this.http.get<any[]>(AppSettings.getRealAPILink() + 'fleetyards/models/' + this.model + '/hardpoints', {withCredentials: true})
                .subscribe((result: any) => {
                    this.cdr.detectChanges();
                    let records = [];
                    if (result.success) {
                        records = result.rows;
                    } else {
                        // old api
                        records = result;
                    }

                    this.hardPoints = records[0];
                    for (let i = 0; i < this.hardPoints.length; i++) {
                        const rec = this.hardPoints[i];
                        if (!this.hardPointTypes[rec.group]) {
                            this.hardPointTypes[rec.group] = {};
                        }
                        if (!this.hardPointTypes[rec.group][rec.type]) {
                            this.hardPointTypes[rec.group][rec.type] = [];
                        }
                        if (!this.hardPointTypes[rec.group][rec.type][rec.key]) {
                            this.hardPointTypes[rec.group][rec.type][rec.key] = [];
                        }
                        if (!this.hardPointTypes[rec.group][rec.type][rec.key][rec.size]) {
                            this.hardPointTypes[rec.group][rec.type][rec.key][rec.size] = [];
                        }
                        this.hardPointTypes[rec.group][rec.type][rec.key][rec.size].push(rec);
                    }
                    console.log('hardPointTypes', this.hardPointTypes);
                    this.loadCount++;
                    this.is_loading = this.loadCount !== 4;
                    this.cdr.detectChanges();
                });

            this.http.get<any[]>(AppSettings.getRealAPILink() + 'fleetyards/models/' + this.model + '/paints', {withCredentials: true})
                .subscribe((result: any) => {
                    this.cdr.detectChanges();
                    let records = [];
                    if (result.success) {
                        records = result.rows;
                    } else {
                        // old api
                        records = result;
                    }

                    this.paints = records;
                    this.loadCount++;
                    this.is_loading = this.loadCount !== 4;
                    this.cdr.detectChanges();
                });

            this.http.get<any[]>(AppSettings.getRealAPILink() + 'fleetyards/models/' + this.model + '/variants', {withCredentials: true})
                .subscribe((result: any) => {
                    this.cdr.detectChanges();
                    let records = [];
                    if (result.success) {
                        records = result.rows;
                    } else {
                        // old api
                        records = result;
                    }

                    this.variants = records;
                    this.loadCount++;
                    this.is_loading = this.loadCount !== 4;
                    this.cdr.detectChanges();
                });
        }));
    }

    public convertToTitleCase(input: string): string {
        return input
            .split('_')  // Split by underscores
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize first letter of each word
            .join(' ');  // Join words with a space
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }
}
