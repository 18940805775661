import {AfterViewInit, Component, Input} from '@angular/core';
import {FormInputsComponent} from '../form-inputs/form-inputs.component';


@Component({
  selector: 'app-form-inputs-row',
  templateUrl: './form-inputs-row.component.html',
  styleUrls: ['./form-inputs-row.component.css']
})
export class FormInputsRowComponent extends FormInputsComponent implements AfterViewInit {

  get fields(): any {
    return this._fields;
  }

  @Input()
  public storeObjClone: any;

  @Input()
  public column: any;

  @Input()
  public row: any;

  private _fields: any;

  private clone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
  }

  ngAfterViewInit(): void {
    const value = this.storeObjClone.getForm(this.row.data);
    // const formFields = _.cloneDeep(value);
    this.field_groups = value;
    this.storeObjClone.setEditFields();
    const fields = [];
    for (let i = 0; i < value.length; i++) {
      for (let f = 0; f < value[i].fields.length; f++) {
        const field = {...value[i].fields[f]};
        fields.push(field);
      }
    }
    this._fields = fields;
  }

}
