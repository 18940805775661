@if (init_done) {
    <div>
        <div class="option-stat">
            <div class="option-stat-name">{{stats[name_key]}}</div>
            <div class="option-stat-price">{{stats.price}} aUEC</div>
        </div>
        @for (stat of stat_translation | keyvalue; track stat) {
            @if (stat.value && stats[stat.key] && stat.key !== 'price')
            {
                <div class="option-stat"
                     [ngClass]="stats[stat.key + '_class']"
                >
                    <div>{{stat_translation[stat.key].caption}}</div>
                    <div>{{stats[stat.key]}}{{stat.value.unit}}</div>
                </div>
            }
        }
    </div>
}