<div>
    <app-content-container [content_template]="page_content"
                           [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>


<ng-template #page_content>
    <div class="padding">
        <div class="card card-1 padding table-wrapper" *ngIf="storeObj">
            <app-fb-forms-table [storeObj]="storeObj"
                                [edit_allowed]="true"
                                (row_click)="rowSelected($event)"
                                [stateKey]="'calculator-data-' + type"
                                [show_add_in_view]="true"
                                [show_delete]="true"
                                [title]="title"
                                [expand_tpl]="expand_tpl"
                                [expandAble]="is_expandable"
            >
            </app-fb-forms-table>
        </div>
    </div>
</ng-template>

<ng-template #expand_tpl let-row>
    <div class="padding">{{type}}</div>
    <app-item-location [type]="type" [parent_row]="row"></app-item-location>
</ng-template>
