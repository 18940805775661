<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div class="padding">
        <div *ngIf="badges">
            <h3>Badges</h3>
            <div *ngFor="let branch of badges" style="text-align: left;">
                <h4>{{branches[branch.branch]}}</h4>
                <div class="card padding">
                    <div *ngIf="branch.ranks && branch.ranks.length > 0">
                        <table [cellSpacing]="8" style="width: 100%">
                            <tr>
                                <th style="width: 32px">
                                </th>
                                <th style="width: 150px">
                                </th>
                                <th style="width: 200px">
                                </th>
                                <th>
                                </th>
                            </tr>
                            <tr *ngFor="let rank of branch.ranks">
                                <td>
                                    <app-badge-icon *ngIf="rank.file_id > 0"
                                                [file_id]="rank.file_id"></app-badge-icon>
                                </td>
                                <td>
                                    {{rank.badge_name}}
                                </td>
                                <td>
                                    awarded on {{rank.assign_date | date:'mediumDate':'': 'de'}}
                                </td>
                                <td>
                                    {{rank.public_note}}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div *ngIf="branch.medals && branch.medals.length > 0">
                        <h5>Medal</h5>
                        <div *ngFor="let medal of branch.medals">
                            {{medal.badge_name}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
