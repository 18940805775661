import {AfterViewInit, Component, Input} from '@angular/core';
import {KeyValuePipe, NgForOf, NgIf} from '@angular/common';
import {MatGridList, MatGridTile} from '@angular/material/grid-list';
import {MatDivider} from '@angular/material/divider';

@Component({
    selector: 'app-vehicle-component-details',
    standalone: true,
    imports: [
        NgIf,
        MatGridList,
        MatGridTile,
        KeyValuePipe,
        NgForOf,
        MatDivider
    ],
    templateUrl: './vehicle-component-details.component.html',
    styleUrl: './vehicle-component-details.component.css'
})
export class VehicleComponentDetailsComponent implements AfterViewInit {

    @Input()
    public component_group: any;

    ngAfterViewInit(): void {
        console.log('component_group', this.component_group);
    }

    public convertToTitleCase(input: string): string {
        return input
            .split('_')  // Split by underscores
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize first letter of each word
            .join(' ');  // Join words with a space
    }


}
