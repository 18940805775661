<div class="padding">
    <div class="card card-1 padding table-wrapper" *ngIf="!is_loading">
        <app-fb-forms-table [storeObj]="ScbEventMember"
                            [edit_allowed]="editAllowed"
                            [require_filter]="require_filter"
                            (row_click)="rowSelected($event)"
                            [storeExtObj]="ext_obj"
                            [stateKey]="'bot-event-member'"
                            [show_add_in_view]="editAllowed"
                            [show_delete]="editAllowed"
                            (dataLoaded)="dataLoadedDone($event)"
                            [title]="'Event Members'"
        >
        </app-fb-forms-table>
    </div>
</div>
