import { HttpClient, HttpHeaders } from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import * as jsonBig from 'json-bigint';

export class Store {

    public object$: BehaviorSubject<any[]>;
    private index_field = 'id';
    private store_key = '';
    private index_array = [];
    private is_loading = false;
    private records = [];
    private patch_url = '';
    private put_url = '';
    private delete_url = '';
    private post_url = '';
    private is_loaded = false;
    private callbacks = [];
    private api_table = '';
    private last_checked_for_update = null;
    private last_transaction_id = 0;
    private lookup_cache = {};
    public totalRecords = 0;

    constructor(private http: HttpClient, private url: string, store_key: string, index_field: string) {
        this.store_key = store_key;
        this.index_field = index_field;
        this.object$ = new BehaviorSubject([]);
    }

    public setApiTable(table: string) {
        this.api_table = table;
    }

    public getApiTable() {
        return this.api_table;
    }

    public setLastCheckForUpdate(last_changed: any) {
        this.last_checked_for_update = last_changed;
    }

    public getLastCheckForUpdate() {
        return this.last_checked_for_update;
    }

    public getUrl(): string {
        return this.url;
    }

    public setLastTransactionId(last_transaction_id: number) {
        this.last_transaction_id = last_transaction_id;
    }

    public getLastTransactionId() {
        return this.last_transaction_id;
    }

    public invalidateStore() {
        this.is_loaded = false;
        this.is_loading = false;
    }

    public unloadStore() {
        delete this.records;
        this.records = [];
        this.is_loaded = false;
        this.is_loading = false;
    }

    public getKey() {
        return this.store_key;
    }

    public setStoreKeyArray(index_array: any[]) {
        this.index_array = index_array;
    }

    /**
     * sets the url for patch like '/mis/objects/{object_i}'
     */
    public setPatchUrl(url: string) {
        this.patch_url = url;
    }
    public getPatchUrl() {
        return this.patch_url;
    }
    /**
     * sets the url for put like '/mis/objects/{object_i}'
     */
    public setPutUrl(url: string) {
        this.put_url = url;
    }
    /**
     * sets the url for delete like '/mis/objects/{object_i}'
     */
    public setDeleteUrl(url: string) {
        this.delete_url = url;
    }
    /**
     * sets the url for post like '/mis/objects/{object_i}'
     */
    public setPostUrl(url: string) {
        this.post_url = url;
    }

    private getParsedUrl(url: string, data: any) {
        let tmp_url = url;
        if (data && Object.keys(data).length > 0) {
            Object.keys(data).forEach(function (field) {
                tmp_url = tmp_url.replace('{' + field + '}', data[field]);
            });
        }
        return tmp_url;
    }

    private getAllRecords(): Observable<any[]> {
        return this.http.get<any[]>(this.url);
    }

    public loadAllRecords(query = '') {
        if (!this.is_loading) {
            this.is_loading = true;
            this.http.get<any[]>(this.url + query, {withCredentials: true})
                .subscribe((result: any) => {
                    this.is_loading = false;
                    let records = [];
                    if (result.success) {
                        records = result.rows;
                    } else {
                        // old api
                        records = result;
                    }
                    if (result.totalRecords) {
                        this.totalRecords = result.totalRecords;
                    }
                    if (records && records.length > 0) {
                        this.records = [];
                        for (let i = 0; i < records.length; i++) {
                            this.addRecordToStore(records[i]);
                        }
                    }
                    this.object$.next(this.getRecords());
                    this.is_loaded = true;
                });
        }
    }

    public reloadStore(query = ''): Promise<any> {
        this.unloadStore();
        return this.loadStore(query);
    }

    public loadStore(query = ''): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (!this.is_loading) {
                this.is_loading = true;

                if (this.url.includes('?')) {
                    query = query.replace('?', '&');
                }

                this.http.get<any[]>(this.url + query, {withCredentials: true})
                    // .pipe(map(resp => JSONbig.parse()))
                    .subscribe((result: any) => {
                        let records = [];
                        if (result.success) {
                            records = result.rows;
                            this.afterApiRequestDone(result);
                        } else {
                            // old api
                            records = result;
                        }
                        if (result.totalRecords) {
                            this.totalRecords = result.totalRecords;
                        }
                        this.is_loading = false;
                        if (records && records.length > 0) {
                            this.records = [];
                            for (let i = 0; i < records.length; i++) {
                                // const record = JsonBigInt.parse(records[i]);
                                // JSON.stringify(records[i]);
                                // const record = JSONbig.parse(JSON.stringify(records[i]));
                                const record = records[i];
                                // console.log('record', JsonBigInt.parse(record));
                                this.addRecordToStore(record);
                            }
                            this.is_loaded = true;
                            this.callAllSubscriber(this.getRecords());
                            resolve(this.getRecords());
                        }
                        if (records && records.length === 0) {
                            this.records = [];
                            this.is_loaded = true;
                            this.callAllSubscriber(this.getRecords());
                            resolve(this.getRecords());
                        }
                    }
                );
            } else {
                this.callbacks.push(() => {
                    resolve(this.getRecords());
                });
            }
        });
    }

    private callAllSubscriber(records: any) {
        if (this.callbacks && this.callbacks.length > 0) {
            for (let i = 0; i < this.callbacks.length; i++) {
                this.callbacks[i](records);
            }
        }
        this.callbacks = [];
    }

    public addRecordToStoreDemo(data: any) {
        this.records.push({
            data: Object.assign({}, data),
            org_data: Object.assign({}, data)
        });
    }

    private addRecordToStore(data: any) {
        this.records.push({
            data: Object.assign({}, data),
            org_data: Object.assign({}, data)
        });
    }

    public getRecords(): any[] {
        return this.records;
    }

    public getStore(): BehaviorSubject<any[]> {
        return this.object$;
    }

    public getRecordByData(data: any) {
        if (this.index_field && this.index_field.length > 0) {
            return this.getRecordById(data[this.index_field]);
        }
        if (this.index_array && this.index_array.length > 0) {
            const index_array = [];
            for (let i = 0; i  < this.index_array.length; i++) {
                const filter = {key: this.index_array[i], value: data[this.index_array[i]]};
                index_array.push(filter);
            }
            return this.getRecordByKeyArray(index_array);
        }
    }

    /**
     * get a record by id
     */
    public getRecordById(id: any): any {
        const me = this;
        if (this.records && this.records.length > 0) {
            /*
            if (this.lookup_cache[id]) {
                return this.lookup_cache[id];
            }
             */
            const result = this.records.find((record: any) => {
                return String(record.data[me.index_field]) === String(id) || Number(record.data[me.index_field]) === Number(id);
            });
            if (result) {
                // this.lookup_cache[id] = result;
                return result;
            }
        }
        return null;
    }

    /**
     * get a record by property value
     */
    public getRecordsByPropertyValue(property: string, value: string): any {
        if (this.records && this.records.length > 0) {
            const results = this.records.filter((record: any) => {
                return record.data[property] === value;
            });
            return results;
        }
        return null;
    }


    /** get record by filter array
     * @description [
     {key: 'job_type_id', value: '4200'},
     {key: 'job_status', value: '2', operator="not"},
     {key: 'job_exec_type', value: '4200 Lauf 1 8. AT'}
     ]
     */
    public getRecordByKeyArray(key_values: any) {
        if (this.records && this.records.length > 0) {
            const results = this.records.filter((record: any) => {
                let found = false;
                for (let k = 0; k < key_values.length; k++) {
                    const compare_obj = key_values[k];
                    if (!compare_obj.operator) {
                        if (String(compare_obj.value) === String(record.org_data[compare_obj.key]) ||
                            Number(compare_obj.value) === Number(record.org_data[compare_obj.key])) {
                            found = true;
                        } else {
                            return false;
                        }
                    } else {
                        switch (compare_obj.operator) {
                            case 'not':
                                if (String(compare_obj.value) !== String(record.org_data[compare_obj.key]) &&
                                    Number(compare_obj.value) !== Number(record.org_data[compare_obj.key])) {
                                    found = true;
                                } else {
                                    return false;
                                }
                                break;
                        }
                    }
                }
                return found;
            });
            if (results && results.length > 0) {
                return results[0];
            }
        }
        return null;
    }

    /** get records by filter array
     * @description [
     {key: 'job_type_id', value: '4200'},
     {key: 'job_status', value: '2'},
     {key: 'job_exec_type', value: '4200 Lauf 1 8. AT'}
     ]
     */
    public getRecordsByKeyArray(key_values: any) {
        if (this.records && this.records.length > 0) {
            const results = this.records.filter((record: any) => {
                let found = false;
                for (let k = 0; k < key_values.length; k++) {
                    const compare_obj = key_values[k];
                    if (!compare_obj.operator) {
                        if (compare_obj.value === record.org_data[compare_obj.key] ||
                            Number(compare_obj.value) === Number(record.org_data[compare_obj.key])) {
                            found = true;
                        } else {
                            return false;
                        }
                    } else {
                        switch (compare_obj.operator) {
                            case 'not':
                                if (compare_obj.value !== record.org_data[compare_obj.key] &&
                                    Number(compare_obj.value) !== Number(record.org_data[compare_obj.key])) {
                                    found = true;
                                } else {
                                    return false;
                                }
                                break;
                        }
                    }
                }
                return found;
            });
            if (results && results.length > 0) {
                return results;
            }
        }
        return [];
    }

    /**
     * update record data not org_data for id
     */
    public updateRecordDataById(id: any, data: any): boolean {
        const rec = this.getRecordById(id);
        if (rec) {
            Object.keys(rec).forEach(function(key, index) {
                if (rec.data.hasOwnProperty(key)) {
                    rec.data[key] = data[key];
                }
            });
            return true;
        }
        return false;
    }

    /**
     * update record data not org_data for record
     */
    public updateRecordDataByRecord(record: any, data: any): boolean {
        if (record) {
            Object.keys(record.data).forEach(function(key, index) {
                if (record.data.hasOwnProperty(key)) {
                    record.data[key] = data[key];
                    record.org_data[key] = data[key];
                }
            });
            return true;
        }
        return false;
    }

    /**
     * get changed record data for patch/put
     */
    public getChangedData(record: any): any {
        let changed_data = {};
        if (record && record.org_data) {
            Object.keys(record.data).forEach(function(key, index) {
                if (record.data.hasOwnProperty(key) && !Array.isArray(record.data[key])) {
                    if (record.org_data[key] !== record.data[key] || !Object.is(Number(record.org_data[key]), Number(record.data[key]))) {
                        changed_data[key] = record.data[key];
                    }
                    if (typeof record.org_data[key] === 'object' || typeof record.data[key] === 'object') {
                        if (JSON.stringify(record.org_data[key]) !== JSON.stringify(record.data[key])) {
                            changed_data[key] = record.data[key];
                        }
                    }
                }
            });
        } else {
            changed_data = record.data;
        }
        return changed_data;
    }

    /**
     * update record fully after patch/put
     */
    private updateRecord(record: any, new_data: any): boolean {
        if (record) {
            Object.keys(new_data).forEach(function(key, index) {
                if (record.data.hasOwnProperty(key)) {
                    record.data[key] = new_data[key];
                    if (typeof new_data[key] === 'object') {
                        if (new_data[key] === null) {
                            record.org_data[key] = new_data[key];
                        } else {
                            if (Array.isArray(new_data[key])) {
                                record.org_data[key] = Object.assign([], new_data[key]);
                            } else {
                                record.org_data[key] = Object.assign({}, new_data[key]);
                            }
                        }
                    } else {
                        record.org_data[key] = new_data[key];
                    }
                }
            });
            return true;
        }
        return false;
    }

    /**
     * send post to api and add it to forms after
     */
    public addRecord(data: any, comment?: string, change_user?: string): Promise<any> {
        this.showSaveScreen();
        const httpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json');
        const options = {
            headers: httpHeaders,
            withCredentials: true
        };
        let url = this.url;
        if (this.post_url.length > 0) {
            url = this.getParsedUrl(this.post_url, data);
        }
        return new Promise<any>((resolve, reject) =>  {
            // append change_comment
            if (comment) {
                data['change_comment'] = comment;
            }
            if (change_user) {
                data['change_user'] = change_user;
            }
            const postObs = this.http.post<any>(url, data, options);
            postObs.subscribe(
                response => {
                    let records = [];
                    if (response.success) {
                        records = response.rows;
                        this.afterApiRequestDone(response);
                    } else {
                        // old api
                        records = response;
                    }
                    const rec = records[0];

                    // put record to forms if not exits
                    this.addRecordToStore(rec);
                    // this.object$.next(this.getRecords());
                    this.hideSaveScreen();
                    resolve({success: true, record: this.getRecordById(rec[this.index_field])});
                },
                err => {
                    console.log(err);
                    this.hideSaveScreen();
                    resolve(false);
                }
            );
        });
    }

    /**
     * send patch to api and update after
     * @comment record
     */
    public patchRecord(record: any, comment?: string, change_user?: string): Promise<any> {
        this.showSaveScreen();
        // ToDo: get record and data (records id may changed)
        const httpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json');
        const options = {
            headers: httpHeaders,
            withCredentials: true
        };
        let url = this.url + '/' + record.org_data[this.index_field];
        if (this.patch_url.length > 0) {
            url = this.getParsedUrl(this.patch_url, record.org_data);
        }
        return new Promise<any>((resolve, reject) => {
            const changed_data = this.getChangedData(record);
            if (Object.keys(changed_data).length === 0) {
                // no changes
                this.hideSaveScreen();
                resolve({success: true, record: record});
            } else {
                // append change_comment
                if (comment) {
                    changed_data['change_comment'] = comment;
                }
                if (change_user) {
                    changed_data['change_user'] = change_user;
                }
                this.http.patch<any>(url, changed_data, options).subscribe(
                    {
                        next:
                            ((response) => {
                                let records = [];
                                if (response.success) {
                                    records = response.rows;
                                    this.afterApiRequestDone(response);
                                } else {
                                    // old api
                                    records = response;
                                }
                                const rec = records[0];
                                this.updateRecord(record, rec);
                                // this.object$.next(this.getRecords());
                                this.hideSaveScreen();
                                resolve({success: true, record: this.getRecordById(rec[this.index_field])});
                            }),
                        error: ((err) => {
                            console.log(err);
                            this.hideSaveScreen();
                            resolve(false);
                        })
                    }
                );
            }
        });
    }

    /**
     * send put to api and update/add after
     */
    public putRecord(record: any, comment?: string, change_user?: string): Promise<any> {
        // ToDo: get record and data (records id may changed)
        const httpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json');
        const options = {
            headers: httpHeaders,
            withCredentials: true
        };
        let is_new = true;
        return new Promise<any>((resolve, reject) =>  {
            this.showSaveScreen();
            const changed_data = this.getChangedData(record);
            if (Object.keys(changed_data).length === 0) {
                // no changes
                this.hideSaveScreen();
                resolve({success: true, record: record});
            } else {
                let url = this.url + '/' + record.data[this.index_field];
                if (record.hasOwnProperty('org_data')) {
                    // existing record
                    url = this.url + '/' + record.org_data[this.index_field];
                    if (this.put_url.length > 0) {
                        url = this.getParsedUrl(this.put_url, record.org_data);
                    }
                    is_new = false;
                } else {
                    // new record
                    url = this.url + '/' + record.data[this.index_field];
                    if (this.put_url.length > 0) {
                        url = this.getParsedUrl(this.put_url, record.data);
                    }
                }
                // append change_comment
                if (comment) {
                    changed_data['change_comment'] = comment;
                }
                if (change_user) {
                    changed_data['change_user'] = change_user;
                }
                const putObs = this.http.put<any>(url, changed_data, options);
                putObs.subscribe(
                    (response) => {
                        let records = [];
                        if (response.success) {
                            records = response.rows;
                            this.afterApiRequestDone(response);
                        } else {
                            // old api
                            records = response;
                        }
                        const rec_data = records[0];
                        // try to update record
                        if (is_new) {
                            // put record to forms if not exits
                            this.addRecordToStore(rec_data);
                        } else {
                            this.updateRecord(record, rec_data);
                        }
                        // this.object$.next(this.getRecords());
                        this.hideSaveScreen();
                        resolve({success: true, record: this.getRecordById(record.data[this.index_field])});
                    },
                    err => {
                        console.log(err);
                        this.hideSaveScreen();
                        resolve(false);
                    }
                );
            }
        });
    }

    /**
     * send delete to api and remove record after
     */
    public deleteRecord(record: any): Promise<any> {
        this.showSaveScreen();
        // ToDo: get record and data (records id may changed)
        const httpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json');
        const options = {
            headers: httpHeaders,
            withCredentials: true
        };

        let url = this.url + '/' + record.org_data[this.index_field];
        if (this.delete_url.length > 0) {
            url = this.getParsedUrl(this.delete_url, record.org_data);
        }
        return new Promise<any>((resolve, reject) =>  {
            const old_rec = Object.assign({}, record);
            const deleteObs = this.http.delete<any>(url, options);
            deleteObs.subscribe(
                response => {
                    // remove record from forms
                    if (this.index_array.length === 0) {
                        this.removeRecordByID(record.data[this.index_field]);
                    } else {
                        const search_array = [];
                        for (let i = 0; i < this.index_array.length; i++) {
                            const key = this.index_array[i];
                            search_array.push( {key: key, value: record.data[key]});
                        }
                        this.removeRecordByKeyArray(search_array);
                    }

                    // this.object$.next(this.getRecords());
                    this.hideSaveScreen();
                    resolve({success: true, record: old_rec});
                },
                err => {
                    this.hideSaveScreen();
                    resolve(false);
                }
            );
        });
    }

    /**
     * remove record from forms by Id
     */
    private removeRecordByID(id: any): any {
        const me = this;
        const records = this.records.filter(function( rec ) {
            return rec.data[me.index_field] !== id;
        });
        this.records = records;
        this.object$.next(this.getRecords());
    }

    /**
     * removes a record from forms by search key array
     */
    private removeRecordByKeyArray(key_values: any) {
        if (this.records && this.records.length > 0) {
            const records = this.records.filter((record: any) => {
                const ar_not_found = [];
                for (let k = 0; k < key_values.length; k++) {
                    const compare_obj = key_values[k];
                    // console.log(compare_obj.value, record.org_data[compare_obj.key]);
                    if (compare_obj.value !== record.org_data[compare_obj.key] &&
                        Number(compare_obj.value) !== Number(record.org_data[compare_obj.key])) {
                        ar_not_found.push(true);
                    } else {
                        ar_not_found.push(false);
                    }
                }
                return ar_not_found.some((value) => {
                    return value === true;
                });
            });
            this.records = records;
            this.object$.next(this.getRecords());
        }
    }

    public revertRecord(record: any) {
        if (record && record.data && record.org_data) {
            record.data = Object.assign({}, record.org_data);
        }
    }

    public isLoaded() {
        return this.is_loaded;
    }

    public getIndexField() {
        return this.index_field;
    }

    private afterApiRequestDone(response: any) {
        if (response && response.change_infos) {
            const records = response.change_infos;
            for (let i = 0; i < records.length; i++) {
                const rec = records[i];
                if (rec.sync_table === this.getApiTable()) {
                    if (this.getLastTransactionId()) {
                        // forms was loaded
                        const last_transaction_id = this.getLastTransactionId();
                        const diff = rec.transaction_id - last_transaction_id;
                        if (diff > 1) {
                            // more then one entry must be changed on db
                            this.setLastTransactionId(rec.transaction_id);
                            this.setLastCheckForUpdate(rec.last_changed);
                            this.invalidateStore();
                        } else {
                            // only this one was changed or it was a data load
                            this.setLastTransactionId(rec.transaction_id);
                            this.setLastCheckForUpdate(rec.last_changed);
                        }
                    } else {
                        // it was a data load (forms was not loaded before)
                        this.setLastTransactionId(rec.transaction_id);
                        this.setLastCheckForUpdate(rec.last_changed);
                    }

                }
            }
        }
    }

    public showSaveScreen() {
        const screen = document.getElementById('overlay-saving');
        if (screen) {
            screen.style.display = 'flex';
        }
    }

    public hideSaveScreen() {
        const screen = document.getElementById('overlay-saving');
        if (screen) {
            screen.style.display = 'none';
        }
    }
}
