<div class="padding">
    <div class="card card-1 padding table-wrapper" *ngIf="storeObject">
        <app-fb-forms-table [storeObj]="storeObject"
                            [edit_allowed]="is_admin"
                            [storeTemplates]="{upload_tpl: upload_tpl}"
                            [storeExtObj]="{commodity: commodity, type: type}"
                            (row_click)="rowSelected($event)"
                            [stateKey]="'sc-commodity-inventory-log'"
                            [show_add_in_view]="is_admin"
                            [show_delete]="is_admin"
                            [title]="'Inventory Log'"
        >
        </app-fb-forms-table>
    </div>
</div>

<ng-template #upload_tpl let-element="element" let-column="column" let-edit="edit">
    <div *ngIf="element.data && element.data.file_id > 0">
        <app-badge-icon [file_id]="element.data.file_id"></app-badge-icon>
        <button mat-mini-fab class="button-delete"
                (click)="deleteFile(element.data.file_id, element.data)"
                style="margin-left: 16px;"
                matTooltip="löschen"
                type="button">
            <mat-icon aria-label="löschen">delete</mat-icon>
        </button>
    </div>
    <div *ngIf="element.model && element.model.obj.file_id > 0">
        <app-badge-icon [file_id]="element.model.obj.file_id"></app-badge-icon>
        <button mat-mini-fab class="button-delete"
                (click)="deleteFile(element.model.obj.file_id, element.model.obj)"
                style="margin-left: 16px;"
                matTooltip="löschen"
                type="button">
            <mat-icon aria-label="löschen">delete</mat-icon>
        </button>
    </div>
    <ng-container *ngIf="element.model && element.model.obj.log_id > 0 &&
                         (element.model.obj.file_id === 0 || element.model.obj.file_id === null)
                         && edit">
        <app-file-upload2 [file_type]="'sc_commodity_log'"
                          (on_success)="onFileUpload($event, 'sc_commodity_log', element)"
                          [url]="url_file_store"
                          [show_save_button]="true"
                          [parent_object_id]="element.model.obj.log_id"
                          [max_count]="1"
                          [accept]="'.jpeg,.jpg,.png,image/*'"
                          (init)="onFileuploadInit($event)"
        ></app-file-upload2>
    </ng-container>
</ng-template>
