<mat-sidenav-container class="example-container">
    <mat-sidenav mode="side" closed position="end" class="table-filter"
                 #sidenav>
        <div>
            <div *ngIf="default_model && filter_row && filter_row.fb_field_obj">
                <h3 class="group-caption">Filter</h3>
                <button mat-icon-button matTooltip="Filter aufheben" (click)="removeFilter(true)" class="remove-filter">
                    <mat-icon>remove_circle_outline</mat-icon>
                </button>
                <div *ngIf="filter_row.fb_field_obj">
                    <app-form-inputs
                            [field_groups]="filter_row.fb_field_obj"
                            [edit_allowed]="true"
                            [loading]="false"
                            [show_filter_button]="true"
                            [show_save_button]="false"
                            (on_change)="filterFieldChanged($event)">
                    </app-form-inputs>
                </div>
            </div>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <div [ngClass]="{'small-table-container': is_small_device, 'in-tab-container': in_tab}">
            <div class="full-width">
                <div style="display: inline-block; width: 80px;">
                    <mat-form-field>
                        <mat-label>Filter</mat-label>
                        <input matInput #searchRef [(ngModel)]="filter_value">
                    </mat-form-field>
                </div>

                <div style="display: inline-block; margin-left: 110px;">
                    <mat-button-toggle-group name="active_filter" aria-label="aktiv Filter"
                                             *ngIf="show_active"
                                             [(ngModel)]="active_filter_value"
                                             (change)="activeFilterChanged($event)">
                        <mat-button-toggle value="both">alle</mat-button-toggle>
                        <mat-button-toggle value="active">aktiv</mat-button-toggle>
                        <mat-button-toggle value="deactivated">inaktiv</mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <!--
                <mat-checkbox *ngIf="show_active"
                              [(ngModel)]="filter_active_value"
                              (change)="activeFilterChanged($event)"
                              style="padding-left: 100px;">
                    nur aktive Einträge
                </mat-checkbox>
                -->


                <span class="table-top-buttons">
                    <button mat-icon-button color="accent" matTooltip="Filter" (click)="toggleFilter()" *ngIf="default_model && custom_filter_active">
                        <mat-icon matBadge="!" matBadgeColor="warn">filter_list</mat-icon>
                    </button>
                    <button mat-icon-button color="accent" matTooltip="Filter" (click)="toggleFilter()" *ngIf="default_model && !custom_filter_active">
                        <mat-icon>filter_list</mat-icon>
                    </button>
                    <button mat-icon-button color="accent" matTooltip="Export" (click)="exportRows()" *ngIf="default_model">
                        <i class="card-icon-small fas fa-file-csv"></i>
                    </button>
                    <button mat-icon-button color="accent" [matMenuTriggerFor]="menu"
                            matTooltip="Tabellen-Einstellungen">
                        <mat-icon>settings</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <!--<mat-checkbox class="example-margin" [(ngModel)]="checked">Checked</mat-checkbox>-->
                        <div>
                            <section *ngFor="let cell of _column_definition" class="menu-section">
                                <mat-checkbox [(ngModel)]="cell['visible']"
                                              (click)="updateCellDefinitionClicked($event)"
                                              *ngIf="cell.id !== '#fb_column_spacer'">{{cell.label}}</mat-checkbox>
                            </section>
                        </div>
                    </mat-menu>
            </span>
            </div>
            <div *ngIf="is_loading" class="full-width">
                <div class="centered-spinner" style="max-width: 300px; min-width: 300px;">
                    <div>
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </div>
                </div>
            </div>
            <div class="full-width" *ngIf="table_set && dataSource && _column_definition" [ngClass]="{'table-hidden': is_loading}">
                <div class="table-scroll-container">
                    <table mat-table [dataSource]="dataSource" multiTemplateDataRows
                           width="100%"
                           matSort
                           (matSortChange)="sortChanged($event)"
                           [matSortActive]="_default_sort_active"
                           [matSortDirection]="_default_sort_direction"
                           matSortDisableClear
                           (resize)="onResizeTableContent($event)">

                        <!-- all record columns -->
                        <ng-container *ngFor="let column of _column_definition">
                            <ng-container matColumnDef="{{column.id}}">
                                <!-- ToDo: fix sticky on empty rows -->
                                <!--                                  [sticky]="!edit && column.sticky && !in_tab">-->
                                <!--<th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="column.type === 'template'"-->
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    [style.width.px]="column.max_width"
                                    [disabled]="group_by.length > 0 || column.disable_sort">
                                    <ng-container *ngIf="column.type !== 'template'">
                                        {{column.label}}
                                    </ng-container>
                                    <ng-container *ngIf="column.type === 'template'">
                                        <ng-container *ngIf="column.template_header">
                                            <ng-container
                                                    *ngTemplateOutlet="column.template_header;context:{column:column}">
                                            </ng-container>
                                        </ng-container>

                                        <ng-container *ngIf="!column.template_header">
                                            {{column.label}}
                                        </ng-container>
                                    </ng-container>
                                </th>
                                <td mat-cell *matCellDef="let element" [attr.data-label]="column.label"
                                    (click)="rowSelected($event, element.data, column)"
                                    [ngClass]="{'hidden-mobile': column.id == '#fb_column_spacer'}"
                                    matRipple [matRippleDisabled]="column.noclick || column.disable_ripple">
                                    <ng-container *ngIf="!edit && element.data && element.expanded">
                                        <div [ngSwitch]="column.type">
                                            <ng-container *ngSwitchDefault>
                                                <ng-container [ngSwitch]="column.field_type">
                                                    <ng-container *ngSwitchDefault>
                                                        <span *ngIf="element.tmp.caption[column.id]" [innerHTML]="element.tmp.caption[column.id]"></span>
                                                        <span *ngIf="!element.tmp.caption[column.id]" [innerHTML]="element.data[column.id]"></span>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'checkbox'">
                                                        <span *ngIf="element.data[column.id] && element.data[column.id] !== '0'">Ja</span>
                                                        <span *ngIf="!element.data[column.id] || (element.data[column.id] && element.data[column.id] === '0')">Nein</span>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'date'">
                                                        {{element.data[column.id] | date:'mediumDate':'': 'de'}}
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'template'">
                                                <ng-container
                                                        *ngTemplateOutlet="column.template_row;context:{element: element, column:column}">
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'date'">
                                                <ng-container
                                                        *ngTemplateOutlet="date_tpl;context:{element: element, column:column}">
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'datetime'">
                                                <ng-container
                                                        *ngTemplateOutlet="datetime_tpl;context:{element: element, column:column}">
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'time'">
                                                <ng-container>
                                                    {{element.data[column.id].substring(0,5)}}
                                                </ng-container>
                                            </ng-container>

                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="edit && element.fb_field_obj && element.expanded">
                                        <ng-container *ngIf="element.fb_field_obj">
                                            <app-form-inputs
                                                    [field_groups]="element.fb_field_obj[column.id]"
                                                    [edit_allowed]="true"
                                                    [loading]="false"
                                                    [(ref_holder)]="element['ref_holder'][column.id]"
                                                    [show_save_button]="false"
                                                    (on_change)="fieldChanged(element, column.id)">
                                            </app-form-inputs>
                                        </ng-container>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="_column_definition.length">
                                <div class="fb-element-detail"
                                     [@detailExpand]="element == _expandedElement ? 'expanded' : 'collapsed'">
                                    <ng-container
                                            *ngTemplateOutlet="detail_row_tpl;context:{element: element}">
                                    </ng-container>
                                </div>
                            </td>
                        </ng-container>

                        <!-- Group header -->
                        <ng-container matColumnDef="groupHeader">
                            <td mat-cell colspan="999" *matCellDef="let group">
                                <button mat-icon-button>
                                    <mat-icon *ngIf="group.expanded">expand_less</mat-icon>
                                    <mat-icon *ngIf="!group.expanded">expand_more</mat-icon>
                                </button>
                                <strong>{{group.fb_group_caption}}</strong>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" (click)="groupHeaderClick(row)"> </tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                            [@detailExpand]="element.expanded ? 'expanded' : 'collapsed'"
                            [class.fb-expanded-row]="_expandedElement === element"
                            [class.hidden-row]="!element.expanded"
                            [ngClass]="element.tmp.row_cls"
                        >
                        </tr>
                        <tr mat-row *matRowDef="let element; columns: ['expandedDetail']; when: isDetailRow" class="fb-detail-row"
                            [class.fb-expanded-detail-row]="_expandedElement === element">isDetailRow</tr>
                        <!--<tr mat-row [@rowsAnimation]="" *matRowDef="let row; columns: displayedColumns;"></tr>-->



                    </table>
                </div>
                <mat-toolbar>
                    <mat-toolbar-row class="paging-toolbar-row">
                        <div class="table-buttons-bottom">
                            <ng-container *ngIf="!edit && show_add">
                                <button mat-mini-fab class="button-add"
                                        matTooltip="neu" (click)="addClicked()">
                                    <mat-icon aria-label="neu">add</mat-icon>
                                </button>
                                <span class="button-spacer"></span>
                            </ng-container>
                            <ng-container *ngIf="!edit">
                                <button mat-mini-fab class="button-refresh" (click)="reloadStore()"
                                        matTooltip="neuladen">
                                    <mat-icon aria-label="neuladen">refresh</mat-icon>
                                </button>
                                <span class="button-spacer"></span>
                            </ng-container>
                            <ng-container *ngIf="!edit && _edit_fields_table.length && edit_allowed && !is_small_device">
                                <button mat-mini-fab class="button-edit" (click)="showEdit()"
                                        matTooltip="bearbeiten">
                                    <mat-icon aria-label="bearbeiten">edit</mat-icon>
                                </button>
                                <span class="button-spacer"></span>
                            </ng-container>
                            <ng-container *ngIf="edit && edit_allowed">
                                <button mat-mini-fab class="button-cancel" (click)="cancelRowInput()"
                                        matTooltip="verwerfen" [@fadeInOut]>
                                    <mat-icon aria-label="verwerfen">cancel</mat-icon>
                                </button>
                                <span class="button-spacer"></span>
                            </ng-container>
                            <ng-container *ngIf="edit && changed_rows.length > 0 && edit_allowed && !is_small_device">
                                <button mat-mini-fab class="button-add" (click)="saveRows()"
                                        matTooltip="speichern" [@fadeInOut]>
                                    <mat-icon aria-label="speichern">save</mat-icon>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="selection.selected.length > 0 && !edit && !is_small_device && show_delete">
                                <button mat-mini-fab class="button-delete" (click)="deleteSelection()"
                                        matTooltip="löschen" [@fadeInOut]>
                                    <mat-icon aria-label="löschen">delete</mat-icon>
                                </button>
                                <span class="button-spacer"></span>
                            </ng-container>
                            <ng-container *ngIf="selection.selected.length > 0 && !edit && !is_small_device && show_active">
                                <ng-container *ngIf="filter_active_value">
                                    <button mat-mini-fab class="button-delete" (click)="toggleRowsActiveState()"
                                            matTooltip="deaktivieren" [@fadeInOut]>
                                        <mat-icon aria-label="" *ngIf="filter_active_value">check_box_outline_blank</mat-icon>
                                    </button>
                                </ng-container>
                                <ng-container *ngIf="!filter_active_value">
                                    <button mat-mini-fab class="button-delete" (click)="toggleRowsActiveState()"
                                            matTooltip="aktivieren" [@fadeInOut]>
                                        <mat-icon aria-label="" *ngIf="!filter_active_value">check_box</mat-icon>
                                    </button>
                                </ng-container>
                                <span class="button-spacer"></span>
                            </ng-container>
                        </div>
                        <span class="paging-spacer"></span>
                        <div [ngClass]="{'small-paging-container': is_small_device}">
                            <mat-paginator [pageSizeOptions]="[5, 10, 15, 20, 25, 50, 100, 200]" [pageSize]="page_size"
                                           (page)="pagingChanged($event)"></mat-paginator>
                        </div>
                    </mat-toolbar-row>
                </mat-toolbar>
            </div>
        </div>

        <ng-template #select_column_header let-column="column">
            <ng-container *ngIf="!edit && (show_delete || show_active)">
                <div (click)="cancelRowSelect($event, null)">
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                                  [checked]="selection.hasValue() && isAllSelected()"
                                  [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </div>
            </ng-container>
        </ng-template>
        <ng-template #select_column_row let-element="element" let-column="column">
            <ng-container *ngIf="!edit && (show_delete || show_active)">
                <div (click)="cancelRowSelect($event, element)">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                  (change)="toggleRowSelection($event, element)"
                                  [checked]="selection.isSelected(element)">
                    </mat-checkbox>
                </div>
            </ng-container>
        </ng-template>
        <ng-template #expand_column_header let-column="column"></ng-template>
        <ng-template #expand_column_row let-element="element" let-column="column">
            <ng-container>
                <div>
                    <button mat-icon-button aria-label=""
                            (click)="expandedElement = _expandedElement === element ? null : element">
                        <mat-icon *ngIf="_expandedElement === element">expand_less</mat-icon>
                        <mat-icon *ngIf="_expandedElement !== element">expand_more</mat-icon>
                    </button>
                </div>
            </ng-container>
        </ng-template>
        <ng-template #spacer_column_header let-column="column"></ng-template>
        <ng-template #spacer_column_row let-element="element" let-column="column"></ng-template>
        <ng-template #date_tpl let-element="element" let-column="column">
            {{element.data[column.id] | date:'mediumDate':'': 'de'}}
        </ng-template>
        <ng-template #datetime_tpl let-element="element" let-column="column">
            {{element.data[column.id] | date:'medium':'': 'de'}}
        </ng-template>
        <ng-template #time_tpl let-element="element" let-column="column">
            {{element.data[column.id] | date:'HH:mm'}}
        </ng-template>
    </mat-sidenav-content>
</mat-sidenav-container>

