<article class="tile-card-user card card-1" matRipple>
    <div class="menuCardContainer">
        <div class="inner-card">
            <div *ngIf="file_id" style="background: url('{{api_url}}files/{{file_id}}') 50% 50% no-repeat;"
            class="card-img">
            </div>
            <div class="cardContainer">
                <h4 class="truncate-header">{{user_id}} - {{firstname}} {{lastname}}</h4>
                <h5 class="cardFunktion">{{job}}</h5>
                <p class="cardBeschreibung" [innerHTML]="_job_description">
                </p>
            </div>
        </div>
    </div>
</article>
