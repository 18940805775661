import { Injectable } from '@angular/core';
import {InMemoryDbService} from 'angular-in-memory-web-api';

@Injectable({
  providedIn: 'root'
})
export class InMemoryDataService implements InMemoryDbService {

  constructor() { }

  public createDb() {
    const features = [
      {id: 1, name: 'Side menu', description: '', status: '90%'},
      {id: 2, name: 'External Content', description: '', status: '100%'},
      {id: 3, name: 'AuthService', description: '', status: '100%'},
      {id: 4, name: 'Notification', description: '', status: '100%'},
      {id: 5, name: 'Loading screen', description: '', status: '100%'},
      {id: 6, name: 'Form builder with validation', description: '', status: '100%'},
      {id: 7, name: 'Store', description: '', status: 'planed'},
      {id: 8, name: 'Translation', description: '', status: '100%'},
      {id: 9, name: 'Search (and Service)', description: '', status: '100%'},
      {id: 10, name: 'Filter', description: '', status: 'planed'},
      {id: 11, name: 'Usermenu', description: 'must be moved to own component', status: '80%'},
      {id: 12, name: 'Breadcrumb', description: '', status: '100%'},
      {id: 13, name: 'Dialogs', description: '', status: 'planed'},
      {id: 14, name: 'Theme switching', description: '', status: '100%'},
      {id: 15, name: 'HTTP Example (InMemoryCache)', description: 'https://www.npmjs.com/package/angular-in-memory-web-api',
        status: '80%'},
      {id: 16, name: 'Tests', description: '', status: 'planed'},
      {id: 17, name: 'other', description: '', status: 'tbd'},
      {id: 18, name: 'PWA', description: 'https://itnext.io/turning-an-angular-6-app-into-a-progressive-web-app-9e6fc6361ba6',
        status: 'planed'},
      {id: 19, name: 'Splash Screen', description: '', status: '90%'},
      {id: 20, name: 'fav icons', description: '', status: 'planed'}
    ];
    return {features};
  }
}
