<div class="sidemenu sidenav">

    <!--
    <mat-form-field appearance="legacy" class="full-width">
        <mat-label>Suche</mat-label>
        <input matInput placeholder="Bericht/Eintrag" class="search">
        <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    -->

    <ng-container *ngFor="let group of menu">
        <div *ngIf="group.is_link">
            <a href="{{group.link}}" target="_blank" *ngIf="group.open_new">
                <button class="no-padding" matRipple >{{group.name}}</button>
            </a>

            <a routerLink="{{group.link}}" routerLinkActive="active" *ngIf="!group.open_new">
                <button class="no-padding" matRipple>{{group.name}}</button>
            </a>

        </div>
        <div *ngIf="!group.is_link">
            {{group.name}}
            <div class="group-menu">
                <!-- sub header with links (dashboards) -->
                <ng-container *ngFor="let sub_one of group.entries">
                    <a routerLink="{{sub_one.route}}" routerLinkActive="active" (click)="closeMenuAfterClick($event)">
                        <button mat-button>
                            <i class="fas {{sub_one.icon}}"></i>
                            {{sub_one.name}}
                        </button>
                        <!--                            <i class="card-icon-small" [ngClass]="sub_one.icon"></i>-->
                        <!--                            {{sub_one.name}}-->
                        <!--    -->
                        <!--                            <span class="open-right" matTooltip="Gruppe öffnen" matRipple>-->
                        <!--                                <a routerLink="/reports/{{group.key}}/{{sub_one.key}}" routerLinkActive="active">-->
                        <!--                                    <i class="card-icon-small fas fa-chevron-circle-right"></i>-->
                        <!--                                </a>-->
                        <!--                            </span>-->
                    </a>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <!--    <div *ngIf="is_loading">-->
    <!--        <mat-progress-bar mode="indeterminate"></mat-progress-bar>-->
    <!--    </div>-->
</div>


