import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable, of, tap} from 'rxjs';
import {CacheService} from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class ScWikiTooltipService {

  public requestFilter = '\n' +
      '&action=query&format=json&prop=info%7Cextracts%7Cpageimages%7Crevisions%7Cinfo' +
      '&formatversion=2&redirects=true&exintro=true&exchars=525&explaintext=true&exsectionformat=plain' +
      '&piprop=thumbnail&pithumbsize=480&pilicense=any&rvprop=timestamp&inprop=url&smaxage=300&maxage=300&uselang=content';

  private apiUrl = 'https://starcitizen.tools/api.php';

  constructor(private http: HttpClient, private cache: CacheService) {}

  getTooltipData(searchValue: string): Observable<any> {
    const url = `${this.apiUrl}?titles=${searchValue}` + this.requestFilter + '&origin=*';
    const cachedData = this.cache.get(searchValue);
    if (cachedData) {
      // Return cached data as an Observable
      return of(cachedData);
    } else {
      return this.http.get(url).pipe(
          tap(data => {
            this.cache.set(searchValue, data);
          })
      );
    }
  }
}
