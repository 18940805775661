<h1 mat-dialog-title>Enter Details</h1>
<div mat-dialog-content *ngIf="!isLoading">
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>action</mat-label>
            <mat-select formControlName="action">
                <mat-option [value]="'in'">in</mat-option>
                <mat-option [value]="'out'">out</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Sender</mat-label>
            <input type="text" matInput [formControl]="inputSenderControl" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredSenderOptions | async" [value]="option">
                    {{option.data.sc_username}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Receiver</mat-label>
            <input type="text" matInput [formControl]="inputReceiverControl" [matAutocomplete]="autoReceiver">
            <mat-autocomplete #autoReceiver="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredReceiverOptions | async" [value]="option">
                    {{option.data.sc_username}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Number</mat-label>
            <input matInput type="number" formControlName="value">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Note</mat-label>
            <textarea matInput formControlName="note"></textarea>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-button (click)="onSubmit()" [disabled]="form.invalid">Submit</button>
</div>
