import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {FbFormObject} from '../../../../../../class/fb_form_object';
import {Subscription} from 'rxjs';
import {AppSettings} from '../../../../../../config/AppSettings';
import {FileUpload2Component} from '../../../../../../shared/file-upload2/file-upload2.component';
import {StoreService} from '../../../../../../services/store.service';
import {AuthService} from '../../../../../../services/auth.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../../../../../services/notification.service';
import {MatDialog} from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import {ScCommoditySetForm} from '../../../../../../forms/scCommoditySetForm';

@Component({
    selector: 'app-sc-commodity-sets',
    templateUrl: './sc-commodity-sets.component.html',
    styleUrls: ['./sc-commodity-sets.component.css']
})
export class ScCommoditySetsComponent implements OnInit, OnDestroy, AfterViewInit {

    public storeObj: FbFormObject;
    // public storeAssignedObj: FbFormObject;
    private subs: Array<Subscription> = [];

    public loading_text = '';
    public is_loading = true;
    public rows: any[];

    public files = [];
    public max_file_count = 1;
    public url_file_store = AppSettings.getRealAPILink() + 'media';
    private file_upload: FileUpload2Component;

    private _select_caption_tpl: ElementRef;

    public is_admin: boolean;

    constructor(private storeService: StoreService,
                private authService: AuthService,
                private router: Router,
                private cdr: ChangeDetectorRef,
                private notificationService: NotificationService,
                public dialog: MatDialog,
                private http: HttpClient) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin)
            || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_contract_admin);

        this.storeObj = new ScCommoditySetForm(this.storeService, this.authService, 'edit');

        this.is_loading = false;
        this.cdr.detectChanges();
    }

    public rowSelected($event: any) {
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }
}
