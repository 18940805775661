<div class="cookie-consent-modal">
    <div>

        <div>
            <a routerLink="/impressum">Impressum</a> |
            <a routerLink="/privacy-police">Datenschutzrichtlinie</a>
        </div>

        <h2>Cookies und externer Inhalt:</h2>

        <h3>Unsere Cookies</h3>
        <p>Wir verwenden nur technisch notwendige Cookies, um Ihre Erfahrung zu verbessern.</p>


        <h3>Weitere  Content Anbieter die ggf. auch Cookies setzen:</h3>
        <p>Bitte wählen Sie Ihre Präferenzen:</p>
        <div class="card card-1 padding">
            <span class="provider">
                <mat-slide-toggle [(ngModel)]="consent_sc_wiki">{{getProviderName('sc_wiki')}}</mat-slide-toggle>
            </span>
            <span style="padding-left: 32px;">
                <a href="https://starcitizen.tools/Star_Citizen:Privacy_policy" target="_blank">Datenschutz des Anbieters</a> (öffnet im neuen Fenster des Anbieters)
            </span>
            <div>
                Wird benötigt um euch Tooltips aus der Starcitizen Tools Wiki zeigen zu können.<br>
                Da wir keinen Einfluss auf den Betreiber haben, können wir euch den Tooltipservice nur anbeiten,
                wenn ihr Cookies des Anbieters erlaubt.
            </div>
        </div>
        <div class="card card-1 padding">
            <span class="provider">
                <mat-slide-toggle [(ngModel)]="consent_discord">{{getProviderName('discord')}}</mat-slide-toggle>
            </span>
            <span style="padding-left: 32px;">
                <a href="https://support.discord.com/hc/en-us/community/posts/360061593771-Privacy-for-CDN-attachements" target="_blank">Datenschutz des Anbieters</a> (öffnet im neuen Fenster des Anbieters)
            </span>
            <div>
                Wird benötigt um euch Bilder aus Discord zeigen zu können.<br>
                Da wir keinen Einfluss auf den Betreiber haben, können wir euch diese nur anbeiten,
                wenn ihr Cookies des Anbieters erlaubt.
            </div>
        </div>
        <div class="card card-1 padding">
            <span class="provider">
                <mat-slide-toggle [(ngModel)]="consent_youtube">{{getProviderName('youtube')}}</mat-slide-toggle>
            </span>
            <span style="padding-left: 32px;">
                <a href="https://policies.google.com/privacy?hl=de" target="_blank">Datenschutz des Anbieters</a> (öffnet im neuen Fenster des Anbieters)
            </span>
            <div>
                Wird benötigt um euch Youtube Inhalte aus Discord oder unseren Guides zeigen zu können.<br>
                Da wir keinen Einfluss auf den Betreiber haben, können wir euch diese nur anbeiten,
                wenn ihr Cookies des Anbieters erlaubt.
            </div>
        </div>

        <div class="card card-1 padding">
            <span class="provider">
                <mat-slide-toggle [(ngModel)]="consent_reddit">{{getProviderName('reddit')}}</mat-slide-toggle>
            </span>
            <span style="padding-left: 32px;">
                <a href="https://www.reddit.com/policies/privacy-policy" target="_blank">Datenschutz des Anbieters</a> (öffnet im neuen Fenster des Anbieters)
            </span>
            <div>
                Wird benötigt um euch Reddit Inhalte aus Discord zeigen zu können.<br>
                Da wir keinen Einfluss auf den Betreiber haben, können wir euch diese nur anbeiten,
                wenn ihr Cookies des Anbieters erlaubt.
            </div>
        </div>

        <div class="card card-1 padding">
            <span class="provider">
                <mat-slide-toggle [(ngModel)]="consent_rsi">{{getProviderName('rsi')}}</mat-slide-toggle>
            </span>
            <span style="padding-left: 32px;">
                <a href="https://robertsspaceindustries.com/privacy" target="_blank">Datenschutz des Anbieters</a> (öffnet im neuen Fenster des Anbieters)
            </span>
            <div>
                Wird benötigt um euch RSI Inhalte aus Discord zeigen zu können.<br>
                Da wir keinen Einfluss auf den Betreiber haben, können wir euch diese nur anbeiten,
                wenn ihr Cookies des Anbieters erlaubt.
            </div>
        </div>

        <div class="card card-1 padding">
            <span class="provider">
                <mat-slide-toggle [(ngModel)]="consent_fleetyards">{{getProviderName('fleetyards')}}</mat-slide-toggle>
            </span>
            <span style="padding-left: 32px;">
                <a href="https://fleetyards.net/privacy-policy" target="_blank">Datenschutz des Anbieters</a> (öffnet im neuen Fenster des Anbieters)
            </span>
            <div>
                Wird benötigt um euch fleetyards zeigen zu können.<br>
                Da wir keinen Einfluss auf den Betreiber haben, können wir euch diese nur anbeiten,
                wenn ihr Cookies des Anbieters erlaubt.
            </div>
        </div>

        <div class="padding">
            <span class="padding"><button mat-raised-button class="button-delete" (click)="refuseAll()">Alle Ablehnen</button></span>
            <span class="padding"><button mat-raised-button class="button-save" (click)="confirm()">Auswahl bestätigen</button></span>
        </div>
    </div>
</div>