import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FbFormObject} from '../../../../../../class/fb_form_object';
import {Subscription} from 'rxjs';
import {StoreService} from '../../../../../../services/store.service';
import {AuthService} from '../../../../../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../../../../../services/notification.service';
import {MatDialog} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import {MiningGadgetLocation} from '../../../../../../forms/mining-gadget-location';
import {MiningLaserLocation} from '../../../../../../forms/mining-laser-location';
import {MiningModuleLocation} from '../../../../../../forms/mining-module-location';

@Component({
    selector: 'app-item-location',
    templateUrl: './item-location.component.html',
    styleUrl: './item-location.component.css'
})
export class ItemLocationComponent implements OnInit, OnDestroy, AfterViewInit {

    public storeObj: FbFormObject;
    private subs: Array<Subscription> = [];

    public loading_text = '';
    public is_loading = true;
    public rows: any[];

    public is_admin: boolean;

    public title = 'Locations';
    @Input()
    public type = 'mining_laser_location';

    @Input()
    public parent_row: any;

    constructor(private storeService: StoreService,
                private authService: AuthService,
                private router: Router,
                private cdr: ChangeDetectorRef,
                private notificationService: NotificationService,
                public dialog: MatDialog,
                private http: HttpClient,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {

        this.subs.push(this.route.params.subscribe((params: any) => {
            this.type = params.type;
            switch (this.type) {
                default:
                    this.storeObj = new MiningGadgetLocation(this.storeService, this.authService, 'edit');
                    break;
                case 'mining_laser':
                    this.storeObj = new MiningLaserLocation(this.storeService, this.authService, 'edit');
                    break;
                case 'mining_module':
                    this.storeObj = new MiningModuleLocation(this.storeService, this.authService, 'edit');
                    break;
                case 'mining_gadgets':
                    this.storeObj = new MiningGadgetLocation(this.storeService, this.authService, 'edit');
                    break;
            }
        }));


        // this.storeAssignedObj = new IfaMemberBadgeOverview(this.storeService, this.authService, 'edit');

        this.is_loading = false;
        this.cdr.detectChanges();
    }

    public rowSelected($event: any) {
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }
}
