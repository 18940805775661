<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading" [loading_text]="loading_text"
                           [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div class="padding">
        <div class="card card-1">
            <mat-grid-list cols="5" rowHeight="32px" *ngIf="quickStats && quickStats.metrics">
                <mat-grid-tile [colspan]="1" [rowspan]="1">
                    Total: <span class="total-value">{{quickStats.total}}</span> ships
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1" [rowspan]="1">
                    Money value: <span class="total-value">${{quickStats.metrics.totalMoney}}</span>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1" [rowspan]="1">
                    Min. Crew: <span class="total-value">{{quickStats.metrics.totalMinCrew}}</span> persons
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1" [rowspan]="1">
                    Max. Crew: <span class="total-value">{{quickStats.metrics.totalMaxCrew}}</span> persons
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1" [rowspan]="1">
                    Total Cargo: <span class="total-value">{{quickStats.metrics.totalCargo}}</span> SCU
                </mat-grid-tile>
            </mat-grid-list>
        </div>
        <div class="card padding">
            <div style="padding-right: 16px;">
                <ng-container>
                    <div>
                        <div style="float: left;">
                            <mat-chip-listbox aria-label="vehicle type selection" [multiple]="false" *ngIf="quickStats">
                                <ng-container *ngFor="let classification of quickStats.classifications">
                                    <mat-chip-option [selected]="quickStats.classifications.length === 1"
                                                     (selectionChange)="classificationChanged($event, classification)">
                                        {{classification.label}}: {{classification.count}}
                                    </mat-chip-option>
                                </ng-container>
                            </mat-chip-listbox>
                        </div>
                        <div style="float: right;">
                            <mat-slide-toggle [(ngModel)]="showDetails">show details</mat-slide-toggle>
                        </div>
                        <div style="float: right;">
                            <mat-form-field style="float: right; padding-right: 16px;">
                                <mat-label>Ship</mat-label>
                                <input matInput
                                       placeholder="e.g. Orion"
                                       [(ngModel)]="searchText"
                                       (input)="onSearch()"
                                       class="search"/>
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>
<!--                            <mat-form-field style="margin-right: 16px; float: right;">-->
<!--                                <mat-select [formControl]="tags"-->
<!--                                            multiple (selectionChange)="onTagChanged($event)"-->
<!--                                            placeholder="tags">-->
<!--                                    <mat-select-trigger>-->
<!--                                        {{tags.value?.[0] || ''}}-->
<!--                                        <span *ngIf="(tags.value?.length || 0) > 1" class="example-additional-selection">-->
<!--                                        (+{{(tags.value?.length || 0) - 1}} {{tags.value?.length === 2 ? 'other' : 'others'}})-->
<!--                                    </span>-->
<!--                                    </mat-select-trigger>-->
<!--                                    <mat-option *ngFor="let tag of tagsList" [value]="tag">{{tag}}</mat-option>-->
<!--                                </mat-select>-->
<!--                            </mat-form-field>-->
                        </div>
                        <div style="clear: both"></div>
                    </div>
                </ng-container>
            </div>
        </div>
        <ng-container *ngIf="cookieService.getConsent('fleetyards')">
            <main class="bigger-tiles padding" *ngIf="vehicles">
                <mat-card *ngFor="let vehicle of vehicles">
                    <div class="vehicle-name-header">
                        {{vehicle.manufacturer.code}} {{vehicle.name}} x {{modelCount[vehicle.slug]}}
                    </div>
                    <div class="fy-vehicle-tile"
                         style="background-image: url({{vehicle.storeImageSmall}}); height: 128px;">
                        <div class="btn-container">
                            <button mat-icon-button aria-label="Captain" (click)="getCaptains($event, vehicle.slug)">
                                <i class="fas fa-user-astronaut"></i>
                            </button>
                            <a routerLink="../vehicle/{{vehicle.rsiSlug}}" routerLinkActive="active"
                               class="card-link">
                                <button mat-icon-button aria-label="Show Vehicle">
                                    <i class="fas fa-space-shuttle"></i>
                                </button>
                            </a>
                        </div>
                    </div>
                    <div *ngIf="showDetails" class="vehicle-details">
                        <div>{{vehicle.productionStatus}}</div>
                        <mat-divider></mat-divider>
                        <mat-grid-list cols="3" rowHeight="96px">
                            <mat-grid-tile [colspan]="1" [rowspan]="1">
                                <div>
                                    <b class="top-header">Focus</b>
                                    <div class="detail-content total-value">{{vehicle.focus}}</div>
                                </div>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="1" [rowspan]="1">
                                <div>
                                    <b class="top-header">Crew</b>
                                    <div class="detail-content total-value">{{vehicle.crew.min}} - {{vehicle.crew.max}}</div>
                                </div>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="1" [rowspan]="1">
                                <div>
                                    <b class="top-header">Speed</b>
                                    <div class="detail-content">SCM: <span class="total-value">{{vehicle.speeds.scmSpeed}}</span> m/s</div>
                                    <div class="detail-content">MAX: <span class="total-value">{{vehicle.speeds.maxSpeed}}</span> m/s</div>
                                </div>
                            </mat-grid-tile>
                        </mat-grid-list>
                        <mat-divider></mat-divider>
                        <mat-grid-list cols="2" rowHeight="48px">
                            <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                <div>
                                    <b class="sub-header">Length</b>
                                    <div class="total-value">{{vehicle.metrics.lengthLabel}}</div>
                                </div>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                <div>
                                    <b class="sub-header">Height</b>
                                    <div class="total-value">{{vehicle.metrics.heightLabel}}</div>
                                </div>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                <div>
                                    <b class="sub-header">Beam</b>
                                    <div class="total-value">{{vehicle.metrics.beamLabel}}</div>
                                </div>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                <div>
                                    <b class="sub-header">Mass</b>
                                    <div class="total-value">{{vehicle.metrics.massLabel}}</div>
                                </div>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                <div>
                                    <b class="sub-header">Cargo</b>
                                    <div class="total-value">{{vehicle.metrics.cargoLabel}}</div>
                                </div>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                <div>
                                    <b class="sub-header">Price</b>
                                    <div class="total-value">{{vehicle.priceLabel}}</div>
                                </div>
                            </mat-grid-tile>
                        </mat-grid-list>
                    </div>
                </mat-card>
            </main>
        </ng-container>
    </div>




    <ng-container *ngIf="!cookieService.getConsent('fleetyards')">
        <span class="allow-external-cookie">
        Erlaube Cookies für {{cookieService.getProviderName('fleetyards')}}, um mehr zu sehen.
        </span>
    </ng-container>
    <div>
        powerd by <a href="https://fleetyards.net" target="_blank">Fleetyards.net</a>
    </div>

</ng-template>
