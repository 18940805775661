import {FbFormObject} from '../class/fb_form_object';

export class ScCommodityInventoryForm extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'commodity', label: 'commodity', visible: true, noclick: false, disable_ripple: true},
            {id: 'set_id', label: 'Set', visible: true, noclick: false, disable_ripple: true},
            {id: 'in_stock', label: 'in stock', visible: true, noclick: false, disable_ripple: true},
            {id: 'min_stock', label: 'min stock', visible: true, noclick: false, disable_ripple: true},
            {id: 'max_stock', label: 'max stock', visible: true, noclick: false, disable_ripple: true},
            {id: 'comment', label: 'comment', visible: true, noclick: false, disable_ripple: true},
            {id: 'in_stock_value', label: 'in stock aUEC value', visible: true, noclick: false, disable_ripple: true},
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Commodity',
                class: 'card card-1 padding single-column',
                field_class: '',
                fields: [
                    {
                        type: 'autocomplete',
                        name: 'commodity',
                        options: this.select_boxes['commodity_select'],
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'autocomplete',
                        name: 'set_id',
                        options: this.select_boxes['set_select'],
                        disabled: true
                    },
                    {
                        type: 'number',
                        name: 'in_stock',
                        disabled: true,
                        number_formatted: true
                    },
                    {
                        type: 'number',
                        name: 'min_stock',
                        number_formatted: true
                    },
                    {
                        type: 'number',
                        name: 'max_stock',
                        number_formatted: true
                    },
                    {
                        type: 'number',
                        name: 'in_stock_value',
                        number_formatted: true
                    },
                    {
                        type: 'textarea',
                        name: 'comment'
                    }
                ]
            }
        ];
        super.setEditFields();
    }

    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.stores['sc_commodity_inventory'] = this.storeService.createNoneGlobalStore('sc_commodity_inventory',
                'sc-commodity-inventory/type/' + this.custom_obj.type, 'commodity');
            this.stores['sc_available_commodities'] = this.storeService.getStore('sc_available_commodities');
            this.stores['commodity_sets'] = this.storeService.getStore('commodity_sets');

            this.main_store = this.stores['sc_commodity_inventory'];

            const selectFilter = [];
            selectFilter.push('first=0');
            selectFilter.push('rows=1000');

            const stores = [];
            stores.push(this.stores['sc_commodity_inventory'].loadStore(this.filter));
            stores.push(this.stores['sc_available_commodities'].loadStore('?' + selectFilter.join('&')));
            stores.push(this.stores['commodity_sets'].loadStore('?' + selectFilter.join('&')));

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
                commodity: null,
                min_stock: 0,
                max_stock: 0,
                comment: ''
            }
        };
    }

    /**
     * custom reload
     */
    public setSelectboxes() {
        this.parseCommoditySelectBox(this.custom_obj.type);
        this.parseSetSelectBox();
        // call super
        super.setSelectboxes();
    }

    public parseCommoditySelectBox(type) {
        const options = [];
        const records = this.stores['sc_available_commodities'].getRecords();
        for (let i = 0; i < records.length; i++) {
            if (type === records[i].data.type) {
                options.push({
                    value: String(records[i].data.c_id),
                    name: records[i].data.c_name
                });
            }
        }
        this.select_boxes['commodity_select'] = options;
        return this.select_boxes['commodity_select'];
    }

    public parseSetSelectBox() {
        const options = [];
        const records = this.stores['commodity_sets'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.set_id),
                name: records[i].data.set_name
            });
        }
        this.select_boxes['set_select'] = options;
        return this.select_boxes['set_select'];
    }

}
