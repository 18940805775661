import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {Store} from '../../../../class/store';
import {StoreService} from '../../../../services/store.service';
import {AuthService} from '../../../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../../../services/notification.service';
import {MatDialog} from '@angular/material/dialog';
import {IMqttMessage, MqttService} from 'ngx-mqtt';
import {FbFormObject} from '../../../../class/fb_form_object';
import {LapTimeOverview} from '../../../../forms/lap-time-overview';
import {AppSettings} from '../../../../config/AppSettings';
import {LapTimeSeriesOverview} from '../../../../forms/lap-time-series-overview';

@Component({
    selector: 'app-lap-times',
    templateUrl: './lap-times.component.html',
    styleUrls: ['./lap-times.component.css']
})
export class LapTimesComponent implements OnInit, OnDestroy, AfterViewInit {


    @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
        this._select_caption_tpl = value;
        // this.setColumns();
        this.cdr.markForCheck();
    }


    public LapTimes: FbFormObject;
    private subs: Array<Subscription> = [];

    public loading_text = '';
    public is_loading = true;
    public rows: any[];


    private _select_caption_tpl: ElementRef;

    public column_definition: any[];
    public is_admin: boolean;
    public custom_obj: any;
    public track_id = 0;
    public type_id = 0;
    public series_id = 0;
    public assign_id = 0;
    public can_add = false;

    private channelStore: Store;

    constructor(private storeService: StoreService,
                private authService: AuthService,
                private router: Router,
                private cdr: ChangeDetectorRef,
                private notificationService: NotificationService,
                public dialog: MatDialog,
                private route: ActivatedRoute,
                private _mqttService: MqttService) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin)
            || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_rally_admin);

        this.can_add = this.authService.getLoginState() === 2;
        this.cdr.detectChanges();

        this.subs.push(this.route.params.subscribe((params: any) => {

            this.track_id = params.track_id;
            this.type_id = params.type_id;

            if (params.series_id && params.series_id > 0) {
                this.series_id = params.series_id;
                this.assign_id = params.assign_id;

                this.custom_obj =  {
                    track_id: params.track_id,
                    race_type_id: params.type_id,
                    series_id: params.series_id,
                    assign_id: params.assign_id
                };
                this.LapTimes = new LapTimeSeriesOverview(this.storeService, this.authService, 'edit');
            } else {
                this.LapTimes = new LapTimeOverview(this.storeService, this.authService, 'edit');
                this.custom_obj =  {
                    track_id: params.track_id,
                    race_type_id: params.type_id
                };
            }

            this.is_loading = false;
            this.cdr.detectChanges();
        }));
        this.cdr.detectChanges();
    }


    public rowSelected($event: any) {
        let url = '';
        if (this.series_id && this.series_id > 0) {
            url = 'rally/lap-times/' +
                $event.row.track_id + '/' + $event.row.race_type_id + '/' + $event.row.rt_id +
                    '/' + $event.row.series_id + '/' + $event.row.series_track_assign_id;
        } else {
            url = 'rally/lap-times/' +
                $event.row.track_id + '/' + $event.row.race_type_id + '/' + $event.row.rt_id;
        }

        const route = url;
        if ($event.in_new_tab) {
            const url = this.router.createUrlTree([route]);
            const serializedUrl = this.router.serializeUrl(url);

            // Insert `#` between the domain and route
            const modifiedUrl = '#/' + serializedUrl;
            window.open(modifiedUrl, '_blank');
        } else {
            this.router.navigateByUrl(route);
        }
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }

    public addClicked() {
        if (this.series_id && this.series_id > 0) {
            this.router.navigateByUrl('/rally/lap-times/' + this.track_id + '/' + this.type_id + '/add/' +
                this.series_id + '/' + this.assign_id
            );
        } else {
            this.router.navigateByUrl('/rally/lap-times/' + this.track_id + '/' + this.type_id + '/add');
        }
    }
}
