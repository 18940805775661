import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter, HostListener,
    Input, OnChanges, OnDestroy,
    OnInit,
    Output, SimpleChanges, TemplateRef,
    ViewChild, ViewContainerRef, ViewEncapsulation, ViewRef
} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {Busline} from '../../model/busline';
import {ConfirmComponent} from '../dialoge/confirm/confirm.component';
import {Observable, Subject, fromEvent} from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {fadeInOut} from '../fade-animation';
import {animate, state, style, transition, trigger} from '@angular/animations';
import * as _ from 'underscore';
import {ExportToCsv} from 'export-to-csv';
import {MatSort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import $ from 'jquery';

export class Group {
    level = 0;
    parent: Group;
    expanded = true;
    totalCounts = 0;

    get visible(): boolean {
        return !this.parent || (this.parent.visible && this.parent.expanded);
    }
}

@Component({
    selector: 'app-fb-table',
    templateUrl: './fb-table.component.html',
    styleUrls: ['./fb-table.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    animations: [fadeInOut,
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ])]
})
export class FbTableComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit, AfterViewChecked {

    set expandedElement(value: any | null) {
        this._expandedElement = value;
    }

    @ViewChild('select_column_header', { static: true }) public select_column_header_tpl: ElementRef;
    @ViewChild('select_column_row', { static: true }) public select_column_row_tpl: ElementRef;
    @ViewChild('expand_column_header', { static: true }) public expand_column_header_tpl: ElementRef;
    @ViewChild('expand_column_row', { static: true }) public expand_column_row_tpl: ElementRef;
    @ViewChild('spacer_column_header', { static: true }) public spacer_column_header_tpl: ElementRef;
    @ViewChild('spacer_column_row', { static: true }) public spacer_column_row_tpl: ElementRef;

    @ViewChild('sidenav') private _sidenav;

    @Input() set edit_fields_table(value: any[]) {
        this._edit_fields_table = value;
        if (value) {
            this._edit_fields_table_set = true;
            this.buildFieldLookup();
            if (this._tmp_rows && this._tmp_rows.length > 0 && this._columns_set) {
                this.setRows(this._tmp_rows, false);
            }
            // this.initTable();
        }
    }

    @Input() set default_sort_direction(value: string) {
        this._default_sort_direction = value;
        this._sort_direction_set = true;
    }
    @Input() set default_sort_active(value: string) {
        this._default_sort_active = value;
        this._sort_active_set = true;
    }

    @Input() set rows(value: any[]) {
        this._tmp_rows = value;
        if (value !== undefined && value.length >= 0) {
            if (this._columns_set && this._edit_fields_table_set) {
                this.setRows(value, false);
            }
        }
    }

    @Input() set table_key(value: string) {
        this._table_key = value;
        this._key_set = true;
        // this.initTable();
    }

    @Input() set column_definition(value: any[]) {
        this._column_definition = value;
        this.buildColumnLookup();
        if (value) {
            this._columns_set = true;
            if (this._tmp_rows && this._tmp_rows.length >= 0 && this._edit_fields_table_set) {
                this.setRows(this._tmp_rows, false);
            }
            // this.initTable();
        }
    }


    @Input() public edit_allowed = false;
    @Input() public in_tab = false;
    @Input() public show_add = false;
    @Input() public show_delete = false;
    @Input() public show_active = false;
    @Input() public use_confirm_on_changes = true;
    @Input() public save_filter = true;
    @Input() set detail_row_tpl(value: ElementRef) {
        this._detail_row_tpl = value;
        // console.log('set _detail_row_tpl', this._detail_row_tpl);
        this.setRows(this._tmp_rows, true);
      this.doCdr(this.cdr);
    }
    get detail_row_tpl(): ElementRef {
        return this._detail_row_tpl;
    }

    // tslint:disable-next-line:no-output-on-prefix
    @Output() public on_add_clicked: EventEmitter<any> = new EventEmitter();
    // tslint:disable-next-line:no-output-on-prefix
    @Output() public on_row_select: EventEmitter<any> = new EventEmitter();
    // tslint:disable-next-line:no-output-on-prefix
    @Output() public on_reload_clicked: EventEmitter<any> = new EventEmitter();
    // tslint:disable-next-line:no-output-on-prefix
    @Output() public on_delete_clicked: EventEmitter<any> = new EventEmitter();
    // tslint:disable-next-line:no-output-on-prefix
    @Output() public on_rows_saved: EventEmitter<any> = new EventEmitter();
    // tslint:disable-next-line:no-output-on-prefix
    @Output() public on_active_changed: EventEmitter<any> = new EventEmitter();
    // @Output() public on_cancel_click: EventEmitter<any> = new EventEmitter();

    @Output() public filter_changed: EventEmitter<any> = new EventEmitter();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    @ViewChild('searchRef', {static: true}) searchRef: ElementRef;

    @Input() public row_key_field = 'fb_row_id';
    @Input() public active_field_key = 'active';
    @Input() public show_expand_row = false;
    @Input() public default_model: any;
    @Input() public filter_fields: any[];
    private real_filter_fields: any[];

    @Input() group_by = '';
    @Input() public fixed_height = true;

    private debounceClick = new EventEmitter();
    private clicks = new Subject();
    private _detail_row_tpl: ElementRef;
    private filter_func_set = false;

    public search: string;   // text to search (email)
    public _expandedElement: any | null;
    public filter_value = '';
    public filter_active_value = true;
    public displayedColumns = [];
    public _column_definition: any = [];
    public dataSource = new MatTableDataSource([]);
    public page_size = 15;
    public selection = new SelectionModel<any>(true, []);
    public table_set = false;
    public edit = false;
    public is_loading = false;
    public _edit_fields_table = [];
    public changed_rows = [];
    public innerWidth = 0;
    public is_small_device = false;
    public disable_ripple = false;
    public id = 0;
    public show_detailed_filter = false;
    public filter_row: any;
    public custom_filter_active = false;
    public active_filter_value = 'both';
    public column_lookup = {};
    public field_lookup = {};

    private _edit_fields_table_set = false;
    private _rows_set = false;
    private _columns_set = false;
    private _key_set = false;
    private _sort_active_set = false;
    private _sort_direction_set = false;
    private _default_sort_active = '';
    private _default_sort_direction = 'asc';
    private _table_key = '';
    private _rows = [];
    private _tmp_rows = [];
    private _small_device_limit = 1200;

    private _current_sort_field = '';
    private _current_sort_direction = 'asc';

    private user_changed_filter = false;
    private initialFilterDone = false;
    private time_out_subs = [];
    private last_search_time_out = null;

    @HostListener('window:resize', ['$event'])
    onResize($event) {
        this.innerWidth = window.innerWidth;
        this.checkForSmallDevice();
        this.setHeight();
    }

    onResizeTableContent($event) {
        this.innerWidth = window.innerWidth;
        this.checkForSmallDevice();
        this.setHeight();
    }


    constructor(private cdr: ChangeDetectorRef,
                public dialog: MatDialog) {
        this.is_small_device = false;
        this.innerWidth = window.innerWidth;
        this.checkForSmallDevice();
        this.searchObserve = _.debounce(this.searchObserve, 1000);
    }

    ngAfterViewChecked(): void {
        this.setHeight();
    }

    ngOnInit() {
        fromEvent(this.searchRef.nativeElement, 'keyup')
            // get value
            .pipe(map((evt: any) => evt.target.value))
            // text length must be > 2 chars
            // .filter(res => res.length > 2)
            // emit after 1s of silence
            .pipe(debounceTime(1000))
            // emit only if data changes since the last emit
            .pipe(distinctUntilChanged())
            // subscription
            .subscribe((text: string) => {
                this.user_changed_filter = false;
                this.detach(this.cdr);
                this.is_loading = true;
                this.doCdr(this.cdr);
                this.time_out_subs.push(setTimeout(() => {
                    this.applyFilter(text, 'text search changed');
                    this.time_out_subs.push(setTimeout(() => {
                        this.is_loading = false;
                        this.doCdr(this.cdr);
                        this.reattach(this.cdr);
                    }, 500));
                }, 10));
            });
        this.clicks
            .pipe(debounceTime(1000))
            .subscribe((e) => {
                this.updateCellDefinition(e);
            });
    }

    ngAfterViewInit() {
        this.initTable();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this._rows_set && this._columns_set && this._key_set &&
            this._sort_active_set && this._sort_direction_set && this._edit_fields_table_set) {
            this.setRows(this._tmp_rows, false);
        }
    }

    private initTable() {

        this.user_changed_filter = false;
        if (this._rows_set && this._columns_set && this._key_set &&
            this._sort_active_set && this._sort_direction_set && this._edit_fields_table_set) {

            const sort_active = localStorage.getItem('table.sort.active.' + this._table_key);
            const sort_direction = localStorage.getItem('table.sort.direction.' + this._table_key);

            if (sort_active) {
                this._default_sort_active = sort_active;
            }
            if (sort_direction) {
                this._default_sort_direction = sort_direction;
            }

            this.addSelfColumns();

            // get columns from local storage
            const displayedColumns = localStorage.getItem('columns.' + this._table_key);
            if (displayedColumns) {
                const columns = JSON.parse(displayedColumns);
                const new_columns = [];

                // filter unknown columns for compatibility
                for (let c = 0; c < this._column_definition.length; c++) {
                    this._column_definition[c].visible = false;
                    for (let i = 0; i < columns.length; i++) {
                        if (columns[i] === this._column_definition[c].id) {
                            new_columns.push(columns[i]);
                            // set visibility correct
                            this._column_definition[c].visible = true;
                        }
                    }
                }
                // new_columns.push('#fb_column_spacer');
                this.displayedColumns = new_columns;
            } else {
                this.parseColumns();
            }

            // this.applyFilterStored();
            this.initialFilterDone = false;
            this.rebuildFilter();

            if (this.save_filter) {
                this.applyFilterByFilterRow('init');
            } else {
                this.filter_value = '';
                localStorage.setItem('filter.' + this._table_key, '');
                this.applyFilter('', 'init');
            }

            this.table_set = true;
            this.doCdr(this.cdr);
        }
    }


    private rebuildFilter() {
        let saved_filter = {};
        if (this.save_filter) {
            saved_filter = this.getSavedFilter();
        }

        if (this.default_model && this.initialFilterDone === false) {
            let tmp_row = this.default_model;

            // restore last filter
            if (saved_filter && saved_filter !== '' && Object.keys(saved_filter).length) {
                // if (tmp_row.fb_field_obj[0].fields.length) {
                // tmp_row.fb_field_obj[0].fields[0].model = saved_filter;
                tmp_row = saved_filter;
                this.filter_row = this.createFilterRowFieldObject(tmp_row);
                this.applyFilterByFilterRow('rebuildFilter');
                this.custom_filter_active = true;
                // }
            } else {
                this.filter_row = this.createFilterRowFieldObject(tmp_row);
                this.removeFilter(false);
            }
            this.initialFilterDone = true;
        } else {
            // this.removeFilter(false);
        }
    }

    private applyFilterStored() {
        this.filter_value = localStorage.getItem('filter.' + this._table_key);
        if (!this.filter_value || this.filter_value === '{"data":{}}' || this.save_filter === false) {
            this.filter_value = '';
            this.doCdr(this.cdr);
        }

        if (this.custom_filter_active) {
            this.applyFilterByFilterRow('applyFilterStored');
        } else {
            this.applyFilter(this.filter_value, 'activeFilterChanged');
        }
    }

    private addSelfColumns() {
        // remove template reference to clear view correct
        const filtered = this._column_definition.filter(function(element, index, arr) {
            return element.id !== '#fb_column_expand' && element.id !== '#fb_column_select'  && element.id !== '#fb_column_spacer' ;
        });
        this._column_definition = filtered;
        this.buildColumnLookup();

        if (this.show_expand_row && !this.isFBbColumnSet('#fb_column_expand')) {
            // new_columns.push('#fb_column_expand');
            this._column_definition.unshift({
                id: '#fb_column_expand',
                label: 'Details',
                visible: true,
                disable_ripple: true,
                noclick: true,
                disable_sort: true,
                max_width: 64,
                sticky: true,
                type: 'template',
                template_header: this.expand_column_header_tpl,
                template_row: this.expand_column_row_tpl
            });
        }
        if ((this.show_delete || this.show_active) && !this.isFBbColumnSet('#fb_column_select')) {
            // new_columns.push('#fb_column_select');
            this._column_definition.unshift({
                id: '#fb_column_select',
                label: 'select',
                visible: true,
                disable_ripple: true,
                noclick: true,
                disable_sort: true,
                max_width: 64,
                sticky: true,
                type: 'template',
                template_header: this.select_column_header_tpl,
                template_row: this.select_column_row_tpl
            });
        }

        if (!this.isFBbColumnSet('#fb_column_spacer')) {
            this._column_definition.push({
                id: '#fb_column_spacer',
                label: 'Spacer',
                visible: true,
                disable_ripple: true,
                noclick: true,
                disable_sort: true,
                type: 'template',
                template_header: this.spacer_column_header_tpl,
                template_row: this.spacer_column_row_tpl
            });
        }
    }

    public toggleRowSelection($event: any, row: any) {
        if ($event) {
            this.selection.toggle(row);
        }
        this.doCdr(this.cdr);
    }

    private isFBbColumnSet(id: string) {
        const res = this._column_definition.filter((column) => {
            return column.id === id;
        });
        return res.length ? true : false;
    }

    private parseColumns() {
        const visible_columns = [];
        /*
        if (this.show_delete || this.show_active) {
            visible_columns.push('#fb_column_select');
        }
        if (this.show_expand_row) {
            visible_columns.push('#fb_column_expand');
        }
        visible_columns.push('#fb_column_spacer');
        */
        for (let i = 0; i < this._column_definition.length; i++) {
            const column = this._column_definition[i];
            if (column.visible) {
                visible_columns.push(this._column_definition[i].id);
            }
        }

        this.displayedColumns = visible_columns;
        localStorage.setItem('columns.' + this._table_key, JSON.stringify(this.displayedColumns));
        this.doCdr(this.cdr);
    }

    private buildColumnLookup() {
        this.column_lookup = {};
        for (let i = 0; i < this._column_definition.length; i++) {
            const col = this._column_definition[i];
            this.column_lookup[col.id] = col;
        }
    }

    private buildFieldLookup() {
        this.field_lookup = {};
        for (let i = 0; i < this._edit_fields_table.length; i++) {
            const feld = this._edit_fields_table[i];
            this.field_lookup[feld.name] = feld;
        }
    }

    public setRows(value, force: boolean) {
        // tslint:disable-next-line:no-console
        // console.time('setRows.' + value.length);
        /*
        const filter_active_value = localStorage.getItem('table.filter_active_value.' + this._table_key);
        if (filter_active_value && Number(filter_active_value) === 0) {
            this.filter_active_value = false;
        }
        if (filter_active_value && Number(filter_active_value) === 1) {
            this.filter_active_value = true;
        }
        */

        if (this.isArrayEqual(this._rows, value) && this._rows_set && force === false) {
            // do not set same rows twice
            return false;
        }

        const rows = [];
        const page_size_saved = localStorage.getItem('page_size.' + this._table_key);
        if (page_size_saved) {
            this.page_size = Number(page_size_saved);
        }

        /*
        for (let i = 0; i < this._rows.length; i++) {
            this._rows[i] = null;
        }
        */
        this._rows = [];
        this.cdr.markForCheck();
        this.is_loading = true;
        this.cdr.markForCheck();

        let id_count = 0;
        let last_group_by_value = '';
        let parent_row = null;
        if (value && value.length > 0 || this.show_active) {
            for (let i = 0; i < value.length; i++) {

                const row = value[i];
                const new_row = Object.assign({}, row);

                if (!new_row.hasOwnProperty('tmp')) {
                    new_row['tmp'] = {};
                }
                if (!new_row.tmp.hasOwnProperty('caption')) {
                    new_row.tmp['caption'] = {};
                }

                // new_row['fb_data'] = Object.assign({}, row);
                // new_row['fb_org_data'] = Object.assign({}, row);
                // const new_row = row;
                // new_row['fb_data'] = row;
                // new_row['fb_org_data'] = row;
                new_row['fb_row_id'] = id_count;
                new_row['fb_field_obj'] = {};
                new_row['expanded'] = true;

                let caption = '';
                if (this.group_by && this.group_by !== '') {
                    if (new_row.tmp && new_row.tmp.caption && new_row.tmp.caption.hasOwnProperty(this.group_by)) {
                        caption = new_row.tmp.caption[this.group_by] ? new_row.tmp.caption[this.group_by] : 'no caption found';
                    } else {
                        caption = new_row.data[this.group_by] ? new_row.data[this.group_by] : 'no caption found';
                    }

                    if (caption && caption !== last_group_by_value) {
                        parent_row = new_row;
                        new_row['fb_is_group'] = true;
                        new_row['fb_group_caption'] = caption;
                        new_row['fb_group_by'] = caption;
                        new_row['expand_childs'] = [new_row];
                    } else {
                        new_row['fb_group_by'] = last_group_by_value;
                        parent_row['expand_childs'].push(new_row);
                    }
                }

                for (let c = 0; c < this._column_definition.length; c++) {
                    const col = this._column_definition[c];
                    // set captions
                    if (!col.display_value) {
                        switch (col.field_type) {
                            default:
                                break;
                            case 'select':
                            case 'select_group':
                            case 'autocomplete':
                                const lookup = this.column_lookup[col.id].look_up;
                                if (lookup && col.option_key) {
                                    if (col.option_multiple) {
                                        // multiple-select
                                        new_row.tmp.caption[col.id] = this.ArrayToLookUpStringForLookUp(lookup,
                                            new_row.data[col.option_key]);
                                    } else {
                                        const val = this.toLookUpString(new_row.data, col.option_key);
                                        // select, autocomplete
                                        new_row.tmp.caption[col.id] = lookup[val];
                                    }
                                }
                                break;
                            case 'chip_autocomplete':
                                const lookup_chip_auto = this.column_lookup[col.id].look_up;
                                new_row.tmp.caption[col.id] = this.ChipAutocompletValuesToLookUpString(lookup_chip_auto,
                                    new_row.data[col.option_key]);
                                break;
                        }
                    }
                }

                if (this._detail_row_tpl) {
                    new_row['is_detail_row'] = true;
                }

                rows.push(new_row);

                // set edit fields if set for row
                this.createRowFieldObject(new_row);
                id_count++;

                last_group_by_value = caption;
            }
        }

        // tslint:disable-next-line:no-console
        // console.timeEnd('setRows.' + value.length);

        this._rows = this._rows.concat(rows);
        this._rows_set = true;
        this.rebuildFilter();
        this.applyFilterStored();
        this.checkMatRipple();
        this.is_loading = false;
        this.cdr.markForCheck();
    }

    private checkMatRipple() {
        if (this.on_row_select && this.on_row_select.observers.length > 0 && !this.edit) {
            this.disable_ripple = false;
        } else {
            this.disable_ripple = true;
        }
    }

    private createRowFieldObject(row: any) {
        // console.log('createRowFieldObject', row);
        // get edit fields if set for row
        if (this._edit_fields_table.length > 0) {
            row['fb_all_fields_helper'] = [];
            row['ref_holder'] = {};
            for (let f = 0; f < this._edit_fields_table.length; f++) {
                const field = Object.assign({}, this._edit_fields_table[f]);
                const model = {obj: row.data, key: field.name};
                field['model'] = model;
                row['fb_field_obj'][field.name] = [
                    {
                        class: 'input-cell',
                        field_class: 'input-cell',
                        fields: [
                            // linien_id
                            field
                        ]
                    }
                ];
                row['ref_holder'][field.name] = null;
                row['fb_all_fields_helper'].push(field);
            }
        }
    }

    private createFilterRowFieldObject(original_row: any) {
        // get edit fields if set for row
        const row = Object.assign({}, original_row);
        row['fb_field_obj'] = {};
        const fields = [];
        if (this._edit_fields_table.length > 0) {
            this.real_filter_fields = [];
            for (let i = 0; i < this.filter_fields.length; i++) {
                const group = this.filter_fields[i];
                for (let f = 0; f < group.fields.length; f++) {
                    const field = Object.assign({}, group.fields[f]);
                    field['is_filter'] = true;
                    if (field.hasOwnProperty('on_other_field_changed') && field.table_filter_remove_callbacks) {
                        delete field['on_other_field_changed'];
                    }
                    if (field.hasOwnProperty('on_init') && field.table_filter_remove_callbacks) {
                        delete field['on_init'];
                    }
                    for (let c = 0; c < this._column_definition.length; c++) {
                        const column = this._column_definition[c];
                        if (column.visible) {
                            const field_name = field.name;
                            if (column.id === field.name) {
                                if (field.type === 'date') {
                                    const model = {obj: row.data, key: 'from_' + field_name};
                                    const model2 = {obj: row.data, key: 'until_' + field_name};
                                    /*
                                    if (row.data['from_' + field_name] !== '' && row.data['from_' + field_name] !== null
                                        && row.data['from_' + field_name] !== undefined) {
                                        row.data['from_' + field_name] = new Date(row.data['from_' + field_name]);
                                    }
                                    */
                                    // row.data['from_' + field_name] = null;
                                    // row.data['until_' + field_name] = null;
                                    // create from until fields for filter
                                    field['placeholder'] = column.label + ' >=';
                                    field['disabled'] = false;
                                    field['model'] = model;
                                    field['rules'] = [];
                                    field['ref_name'] = field_name;
                                    field['filter_type'] = '>=';
                                    field['reset_allowed'] = true;
                                    field['important'] = false;
                                    field.name = 'from_' + field_name;
                                    const field2 = Object.assign({}, group.fields[f]);
                                    field2.name = 'until_' + field_name;
                                    field2['placeholder'] = column.label + ' <=';
                                    field2['ref_name'] = field_name;
                                    field2['disabled'] = false;
                                    field2['filter_type'] = '<=';
                                    field2['model'] = model2;
                                    field2['rules'] = [];
                                    field2.name = 'until_' + field_name;
                                    field2['reset_allowed'] = true;
                                    field2['important'] = false;
                                    field2['is_filter'] = true;
                                    fields.push(field);
                                    fields.push(field2);
                                } else {
                                    const model = {obj: row.data, key: field_name};
                                    field['placeholder'] = column.label;
                                    field['disabled'] = false;
                                    field['model'] = model;
                                    field['rules'] = [];
                                    field['reset_allowed'] = true;
                                    field['ref_name'] = field_name;
                                    field['important'] = false;
                                    // for test only did not work without changes
                                    if (field.type === 'select') {
                                        field['multiple'] = true;
                                    }
                                    fields.push(field);
                                }
                            }
                        }
                    }
                    this.real_filter_fields.push(field);
                }
                // console.log(i, group);
            }

            row['fb_field_obj'] = [
                {
                    class: 'input-cell',
                    field_class: 'input-cell',
                    fields: fields
                }
            ];


            // console.log('row', row);
            // row['fb_field_obj'] = fields;
        }

        return row;
    }

    public rowSelected($event, row: Busline, column: any) {
        if (this.edit || column.noclick) {
            $event.stopPropagation();
        } else {
            if (this.on_row_select) {
                this.on_row_select.emit({row: row, column: column});
            }
        }
    }

    public applyFilter(filterValue: string, caller: string) {
        // console.log('applyFilter', caller, this.user_changed_filter, this.filter_changed, filterValue);
        if (this.user_changed_filter && this.filter_changed && this.filter_changed.observers.length > 0) {
            this.user_changed_filter = false;
            this.filter_changed.emit({
                filter: this.filter_row,
                filter_api: this.buildAPIFilterFromFilter()
            });
            return false;
        }

        // this function can not filter with empty values so use filterbyrow
        if (filterValue === '') {
            this.filter_value = filterValue;
            localStorage.setItem('filter.' + this._table_key, '');
            this.applyFilterByFilterRow('applyFilter');
            return false;
        }

        this.filter_value = filterValue;

        if (this.paginator && this.sort) {
            this.is_loading = true;
            this.selection.clear();

            this.dataSource = new MatTableDataSource(this._rows);

            this.setDataSort();

            /*
            this.dataSource.sortingDataAccessor = (item, property) => {
                if (!this.field_lookup[this._default_sort_active]) {
                    // field not knoww
                    return '';
                }
                const field = this.field_lookup[this.sort.active];

                switch (field.type) {
                    default:
                        if (item.tmp && item.tmp.caption && item.tmp.caption.hasOwnProperty(property)
                            && item.tmp.caption[property] !== null  && item.tmp.caption[property] !== undefined) {
                            return item.tmp.caption[property].toLowerCase();
                        }

                        if (!item.data[property]) {
                            return '';
                        }
                        if (item.data[property] && typeof item.data[property] === 'string') {
                            return item.data[property].toLowerCase();
                        } else {
                            if (item.data[property] && Array.isArray(item.data[property])) {
                                const tmp_sort_val = JSON.stringify(item.data[property]);
                                if (tmp_sort_val !== null) {
                                    return JSON.stringify(item.data[property]);
                                }
                            }
                            return '';
                        }
                        break;
                    case 'number':
                        return Number(item.data[property]);
                        break;
                    case 'date':
                        return item.data[property];
                        break;
                    case 'time':
                        return Date.parse('1970/01/01 ' + item.data[property]);
                        break;
                    case 'select':
                    case 'select_group':
                    case 'autocomplete':
                        if (field.sort_by_value) {
                            switch (field.sort_type) {
                                default:
                                    // sort property explicit set
                                    if (!item.data[property]) {
                                        return '';
                                    }
                                    if (item.data[property] && typeof item.data[property] === 'string') {
                                        return item.data[property].toLowerCase();
                                    } else {
                                        if (item.data[property] && Array.isArray(item.data[property])) {
                                            const tmp_sort_val = JSON.stringify(item.data[property]);
                                            if (tmp_sort_val !== null) {
                                                return JSON.stringify(item.data[property]);
                                            }
                                        }
                                        return '';
                                    }
                                    break;
                                case 'number':
                                    return Number(item.data[property]);
                                    break;
                            }
                        } else {
                            // use default sort based on type
                            if (item.tmp && item.tmp.caption && item.tmp.caption.hasOwnProperty(property)
                                && item.tmp.caption[property] !== null  && item.tmp.caption[property] !== undefined) {
                                return item.tmp.caption[property].toLowerCase();
                            }

                            if (!item.data[property]) {
                                return '';
                            }
                            if (item.data[property] && typeof item.data[property] === 'string') {
                                return item.data[property].toLowerCase();
                            } else {
                                if (item.data[property] && Array.isArray(item.data[property])) {
                                    const tmp_sort_val = JSON.stringify(item.data[property]);
                                    if (tmp_sort_val !== null) {
                                        return JSON.stringify(item.data[property]);
                                    }
                                }
                                return '';
                            }
                        }
                        break;
                }
            };
             */

            /* configure filter */
            this.dataSource.filterPredicate = (row: any, filter: string) => {

                switch (this.active_filter_value) {
                    case 'both':
                        // do nothing
                        break;
                    case 'active':
                        if (Number(row.data[this.active_field_key]) === 0) {
                            return false;
                        }
                        break;
                    case 'deactivated':
                        if (Number(row.data[this.active_field_key]) === 1) {
                            return false;
                        }
                        break;
                }

                const object_string = JSON.stringify(row.data);
                let tmp_string = '';
                if (row.tmp) {
                    tmp_string = JSON.stringify(row.tmp);
                }
                return object_string.toLowerCase().indexOf(filter) !== -1
                    || tmp_string.toLowerCase().indexOf(filter) !== -1
                    || row.fb_is_group;
            };

            this.dataSource.filter = filterValue.trim().toLowerCase();

            this.dataSource.paginator = this.paginator;
            // tslint:disable-next-line:no-console
            // console.time('sort ?');
            // this.dataSource.sort = this.sort;
            // tslint:disable-next-line:no-console
            // console.timeEnd('sort ?');

            if (this.dataSource.paginator) {
                this.dataSource.paginator.firstPage();
            }
            localStorage.setItem('filter.' + this._table_key, filterValue);
            if (filterValue === '') {
                localStorage.removeItem('filter.' + this._table_key);
            }
            this.filter_value = filterValue;
            this.is_loading = false;
            this.doCdr(this.cdr);
        } else {
            this.time_out_subs.push(setTimeout(() => {
                this.applyFilter(this.filter_value, 'applyFilter');
            }, 500));
        }
    }

    private setDataSort() {
        this.dataSource.sortingDataAccessor = (item, property) => {
            let field = this.field_lookup[this.sort.active];
            // console.log('### sortingDataAccessor', property);
            if (!field) {
                // field not known try column for fallback
                field =  this.column_lookup[this.sort.active];
                if (!field) {
                    return '';
                }
            }

            switch (field.type) {
                default:
                    if (item.tmp && item.tmp.caption && item.tmp.caption.hasOwnProperty(property)
                        && item.tmp.caption[property] !== null  && item.tmp.caption[property] !== undefined) {
                        return item.tmp.caption[property].toLowerCase();
                    }

                    if (!item.data[property]) {
                        return '';
                    }
                    if (item.data[property] && typeof item.data[property] === 'string') {
                        return item.data[property].toLowerCase();
                    } else {
                        if (item.data[property] && Array.isArray(item.data[property])) {
                            const tmp_sort_val = JSON.stringify(item.data[property]);
                            if (tmp_sort_val !== null) {
                                return JSON.stringify(item.data[property]);
                            }
                        }
                        return '';
                    }
                    break;
                case 'number':
                    return Number(item.data[property]);
                    break;
                case 'date':
                    return item.data[property];
                    break;
                case 'time':
                    return Date.parse('1970/01/01 ' + item.data[property]);
                    break;
                case 'datetime':
                    return new Date(item.data[property]);
                    break;
                case 'select':
                case 'select_group':
                case 'autocomplete':
                    if (field.sort_by_value) {
                        switch (field.sort_type) {
                            default:
                                // sort property explicit set
                                if (!item.data[property]) {
                                    return '';
                                }
                                if (item.data[property] && typeof item.data[property] === 'string') {
                                    return item.data[property].toLowerCase();
                                } else {
                                    if (item.data[property] && Array.isArray(item.data[property])) {
                                        const tmp_sort_val = JSON.stringify(item.data[property]);
                                        if (tmp_sort_val !== null) {
                                            return JSON.stringify(item.data[property]);
                                        }
                                    }
                                    return '';
                                }
                                break;
                            case 'number':
                                return Number(item.data[property]);
                                break;
                        }
                    } else {
                        // use default sort based on type
                        if (item.tmp && item.tmp.caption && item.tmp.caption.hasOwnProperty(property)
                            && item.tmp.caption[property] !== null && item.tmp.caption[property] !== undefined) {
                            return item.tmp.caption[property].toLowerCase();
                        }

                        if (!item.data[property]) {
                            return '';
                        }
                        if (item.data[property] && typeof item.data[property] === 'string') {
                            return item.data[property].toLowerCase();
                        } else {
                            if (item.data[property] && Array.isArray(item.data[property])) {
                                const tmp_sort_val = JSON.stringify(item.data[property]);
                                if (tmp_sort_val !== null) {
                                    return JSON.stringify(item.data[property]);
                                }
                            }
                            return '';
                        }
                    }
                    break;
            }
        };


        this.dataSource.sort = this.sort;
    }

    public applyFilterByFilterRow(caller: string) {
        // console.log('applyFilterByFilterRow', caller, this.user_changed_filter, this.filter_changed);

        if (this.user_changed_filter && this.filter_changed && this.filter_changed.observers.length > 0) {
            this.user_changed_filter = false;
            this.filter_changed.emit({
                filter: this.filter_row,
                filter_api: this.buildAPIFilterFromFilter()
            });
            if (this.filter_row && this.filter_row.fb_field_obj && this.filter_row.fb_field_obj[0]) {
                this.saveFilter(this.filter_row.fb_field_obj[0].fields[0].model.obj);
            }
            return false;
        }


        //
        // const fields_to_filter = this.getSavedFilter();
        const fields_to_filter = [];

        if (this.filter_row && this.filter_row.fb_field_obj && this.filter_row.fb_field_obj[0]) {
            for (let i = 0; i < this.filter_row.fb_field_obj[0].fields.length; i++) {
                const field = this.filter_row.fb_field_obj[0].fields[i];


                field['in_filter'] = true;
                const field_name = field.name;
                const field_type = field.type;
                const filter_type = field.filter_type;
                const ref_name = field.ref_name;
                const field_value = field.model.obj[field_name];
                if (field_value && this.isFieldVisible(ref_name)) {
                    fields_to_filter.push({
                        name: field_name,
                        value: field_value,
                        field_type: field_type,
                        filter_type: filter_type,
                        ref_name: ref_name
                    });
                }
            }
        }

        if (fields_to_filter.length > 0) {
            this.custom_filter_active = true;
        } else {
            this.custom_filter_active = false;
        }

        // for (const [key, value] of Object.entries(this.filter_row.fb_field_obj)) {
        //     console.log(key, value);
        //     const field_name = value['fields'][0].name;
        //     const field_value = value['fields'][0].model.obj[field_name];
        //     const field_type = value['fields'][0].type;
        //     const filter_type = value['fields'][0].filter_type;
        //
        //     if (field_value && this.isFieldVisible(field_name)) {
        //         // console.log(field_name, filter_type);
        //         fields_to_filter.push({
        //             name: field_name,
        //             value: field_value,
        //             field_type: field_type,
        //             filter_type: filter_type});
        //     }
        // }

        if (this.paginator && this.sort) {

            this.dataSource = new MatTableDataSource(this._rows);

            this.setDataSort();
                /* configure filter */
                this.dataSource.filterPredicate = (row: any, filter: string) => {
                    switch (this.active_filter_value) {
                        case 'both':
                            // do nothing
                            break;
                        case 'active':
                            if (Number(row.data[this.active_field_key]) === 0) {
                                return false;
                            }
                            break;
                        case 'deactivated':
                            if (Number(row.data[this.active_field_key]) === 1) {
                                return false;
                            }
                            break;
                    }

                    if (fields_to_filter.length === 0) {
                        if (this.filter_value !== '') {
                            const object_string = JSON.stringify(row.data);
                            let tmp_string = '';
                            if (row.tmp) {
                                tmp_string = JSON.stringify(row.tmp);
                            }

                            if (object_string.toLowerCase().indexOf(this.filter_value.toLowerCase()) !== -1
                                || tmp_string.toLowerCase().indexOf(this.filter_value.toLowerCase()) !== -1
                                || row.fb_is_group) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                        return true;
                    }

                    let found = true;
                    for (let i = 0; i < fields_to_filter.length; i++) {
                        const field = fields_to_filter[i];
                        // console.log(field.name, row.data[field.name]);
                        const field_ref_name = field.ref_name;
                        // console.log('field', field);
                        // console.log('field_ref_name', field_ref_name);
                        if (row.data[field_ref_name] && field.value !== '') {
                            // console.log(row.data[field_ref_name], field.field_type, field.filter_type, field.value);
                            switch (field.filter_type) {
                                default:
                                    switch (field.field_type) {
                                        default:
                                            if (String(row.data[field_ref_name]).toLowerCase()
                                                .indexOf(String(field.value).toLowerCase()) === -1) {
                                                found = false;
                                                return false;
                                            }
                                            break;
                                        case 'select_group':
                                        case 'select':
                                            // check for multiselect first
                                            if (Array.isArray(row.data[field_ref_name])) {
                                                found = this.isListInList(row.data[field_ref_name], field.value);
                                                if (!found) {
                                                    return found;
                                                }
                                            } else {
                                                found = this.isEntryInList(row.data[field_ref_name], field.value);
                                                if (!found) {
                                                    return found;
                                                }
                                            }

                                            /*
                                            if (row.data[field_ref_name].indexOf(String(field.value).toLowerCase()) === -1) {

                                            }
                                             */
                                            /*
                                            if (row.tmp && row.tmp.value && row.tmp.value[field_ref_name]) {
                                                if (row.data[field_ref_name] !== field.value
                                                    && row.tmp.value[field_ref_name] !== field.value) {
                                                    found = false;
                                                    return false;
                                                }
                                            } else {
                                                if (row.data[field_ref_name] !== field.value) {
                                                    found = false;
                                                    return false;
                                                }
                                            }
                                             */
                                            break;
                                        case 'chip_autocomplete':
                                            found = this.isListInList(row.data[field_ref_name].split(','), field.value.split(','));
                                            if (!found) {
                                                return found;
                                            }
                                            break;
                                        case 'checkbox':
                                            if (field.value) {
                                                if (String(row.data[field_ref_name]) !== 'true'
                                                    && String(row.data[field_ref_name]) !== '1') {
                                                    found = false;
                                                    return false;
                                                }
                                            } else {
                                                if (String(row.data[field_ref_name]) !== 'false'
                                                    && String(row.data[field_ref_name]) !== '0') {
                                                    found = false;
                                                    return false;
                                                }
                                            }
                                            break;
                                        case 'date':
                                            const date_a = new Date(row.data[field_ref_name]);
                                            let date_b = new Date(field.value);
                                            date_b = new Date(date_b.getFullYear() + '-' + (date_b.getMonth() + 1)
                                                + '-' + date_b.getDate());
                                            if (date_a.getTime() < date_b.getTime()) {
                                                found = false;
                                                return false;
                                            }
                                            break;
                                    }
                                    break;
                                case '<=':
                                    switch (field.field_type) {
                                        default:
                                            break;
                                        case 'date':
                                            const date_a = new Date(row.data[field_ref_name]);
                                            const date_b = new Date(field.value);
                                            if (date_a.getTime() > date_b.getTime()) {
                                                found = false;
                                                return false;
                                            }
                                            break;
                                    }
                                    break;
                                case '>=':
                                    switch (field.field_type) {
                                        default:
                                            break;
                                        case 'date':
                                            const date_a = new Date(row.data[field_ref_name]);
                                            const date_b = new Date(field.value);
                                            if (date_a.getTime() < date_b.getTime()) {
                                                found = false;
                                                return false;
                                            }
                                            break;
                                    }
                                    break;
                                case '<':
                                    switch (field.field_type) {
                                        default:
                                            break;
                                        case 'date':
                                            const date_a = new Date(row.data[field_ref_name]);
                                            const date_b = new Date(field.value);
                                            if (date_a.getTime() >= date_b.getTime()) {
                                                found = false;
                                                return false;
                                            }
                                            break;
                                    }
                                    break;
                                case '>':
                                    switch (field.field_type) {
                                        default:
                                            break;
                                        case 'date':
                                            const date_a = new Date(row.data[field_ref_name]);
                                            const date_b = new Date(field.value);
                                            if (date_a.getTime() <= date_b.getTime()) {
                                                found = false;
                                                return false;
                                            }
                                            break;
                                    }
                                    break;
                                case '=':
                                    switch (field.field_type) {
                                        default:
                                            break;
                                        case 'date':
                                            const date_a = new Date(row.data[field_ref_name]);
                                            const date_b = new Date(field.value);
                                            if (date_a.getTime() !== date_b.getTime()) {
                                                found = false;
                                                return false;
                                            }
                                            break;
                                    }
                                    break;
                            }
                            if (found && this.filter_value !== '') {
                                const object_string = JSON.stringify(row.data);
                                let tmp_string = '';
                                if (row.tmp) {
                                    tmp_string = JSON.stringify(row.tmp);
                                }

                                if (object_string.toLowerCase().indexOf(this.filter_value.toLowerCase()) !== -1
                                    || tmp_string.toLowerCase().indexOf(this.filter_value.toLowerCase()) !== -1
                                    || row.fb_is_group) {
                                    found = true;
                                } else {
                                    found = false;
                                }
                            }
                        } else {
                            // field not set in record
                            return false;
                        }
                    }
                    return found;
                };

                this.dataSource.filter = 'anyfilter to trigger filter function override it in filter';

                this.dataSource.paginator = this.paginator;
                // tslint:disable-next-line:no-console
                // console.time('sort b ?');

                // tslint:disable-next-line:no-console
                // console.timeEnd('sort b ?');

                if (this.dataSource.paginator) {
                    this.dataSource.paginator.firstPage();
                }

        } else {
            this.time_out_subs.push(setTimeout(() => {
                this.applyFilterByFilterRow('self after setTimeout');
            }, 500));
        }
        // localStorage.setItem('filter.' + this._table_key, filterValue);
        // this.filter_value = filterValue;
        this.is_loading = false;

        if (this.filter_row && this.filter_row.fb_field_obj && this.filter_row.fb_field_obj[0].fields.length === 0) {
            this.removeFilter(false);
        } else if (this.filter_row && this.filter_row.fb_field_obj && this.filter_row.fb_field_obj[0]) {
            this.saveFilter(this.filter_row.fb_field_obj[0].fields[0].model.obj);
        }
        this.doCdr(this.cdr);
    }

    public pagingChanged($event: PageEvent) {
        localStorage.setItem('page_size.' + this._table_key, $event.pageSize.toString());
        // setTimeout(() => {
        //     this.selection.clear();
        //     this.page_size = $event.pageSize;
        //     this.doCdr(this.cdr);
        // }, 100);

        this.detach(this.cdr);
        this.is_loading = true;
        this.doCdr(this.cdr);
        this.time_out_subs.push(setTimeout(() => {
            this.selection.clear();
            this.page_size = $event.pageSize;
            this.time_out_subs.push(setTimeout(() => {
                this.is_loading = false;
                this.doCdr(this.cdr);
                this.reattach(this.cdr);
            }, 500));
        }, 10));
    }

    public reloadStore() {
        if (this.selection.selected.length > 0) {
            this.selection.clear();
        }
        if (this.on_reload_clicked) {
            this.on_reload_clicked.emit();
        }
    }

    public deleteSelection() {

        let question = 'Ausgewählten Eintrag wirklich löschen?';
        if (this.selection.selected.length > 1) {
            question = 'Ausgewählte Einträge wirklich löschen?';
        }

        const dialogRef = this.dialog.open(ConfirmComponent, {
            width: '280px',
            height: '300px',
            data: {title: 'Löschen bestätigen', text: question}
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                if (this.on_delete_clicked) {
                    this.on_delete_clicked.emit(this.selection);
                }
            }
        });
    }

    public toggleRowsActiveState() {
        let active_text = 'aktivieren';
        if (!this.filter_active_value) {
            active_text = 'deaktivieren';
        }
        let question = 'Ausgewählten Eintrag wirklich ' + active_text + '?';
        if (this.selection.selected.length > 1) {
            question = 'Ausgewählte Einträge wirklich ' + active_text + '?';
        }

        const dialogRef = this.dialog.open(ConfirmComponent, {
            width: '280px',
            height: '300px',
            data: {title: 'Aktion bestätigen', text: question}
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                if (this.on_active_changed) {
                    this.on_active_changed.emit({
                        selection: this.selection,
                        new_active_state: !this.filter_active_value
                    });
                    this.selection.clear();
                }
            }
        });
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const page = this.dataSource.paginator.pageSize;
        return numSelected === page || numSelected === this._rows.length;
    }

    masterToggle() {
        this.isAllSelected() ? this.selection.clear() : this.selectRows();
        this.doCdr(this.cdr);
    }

    selectRows() {
        const data = this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort);
        for (let index = 0; index < this.dataSource.paginator.pageSize; index++) {
            if (data[index]) {
                this.selection.select(data[index]);
            }
            // this.selectionAmount = this.selection.selected.length;
        }
        // this.doCdr(this.cdr);
    }

    public cancelRowSelect($event, row: any) {
        $event.stopPropagation();
    }


    public updateCellDefinitionClicked($event) {
        this.user_changed_filter = true;
        this.clicks.next($event);
        $event.stopPropagation();
    }

    public updateCellDefinition($event) {
        this.detach(this.cdr);
        this.is_loading = true;
        this.doCdr(this.cdr);
        this.time_out_subs.push(setTimeout(() => {
            this.parseColumns();
            this.initialFilterDone = false;
            this.rebuildFilter();
            this.time_out_subs.push(setTimeout(() => {
                this.is_loading = false;
                this.doCdr(this.cdr);
                this.reattach(this.cdr);
            }, 500));
        }, 10));
    }

    public sortChanged($event) {
        this.detach(this.cdr);
        this.is_loading = true;
        this.doCdr(this.cdr);
        this.time_out_subs.push(setTimeout(() => {
            if (this.table_set) {
                localStorage.setItem('table.sort.active.' + this._table_key, $event.active);
                localStorage.setItem('table.sort.direction.' + this._table_key, $event.direction);
            }
            this.selection.clear();
            this.time_out_subs.push(setTimeout(() => {
                this.is_loading = false;
                this.doCdr(this.cdr);
                this.reattach(this.cdr);
            }, 500));
        }, 10));
    }

    private addChangedRow(changed_row: any) {
        const changed_rows = this.changed_rows.filter((row) => {
            return row.id === changed_row[this.row_key_field];
        });
        if (changed_rows.length === 0) {
            this.changed_rows.push({
                id: changed_row[this.row_key_field],
                row: changed_row,
            });
        }
    }

    public showEdit() {
        this.toggleEditState();
    }

    public toggleEditState() {
        this.is_loading = true;
        this.doCdr(this.cdr);
        this.time_out_subs.push(setTimeout(() => {
            this.is_loading = false;
            this.edit = !this.edit;
            this.checkMatRipple();
            this.doCdr(this.cdr);
        }, 500));
    }

    private forceRefresh() {
        this.is_loading = true;
        this.doCdr(this.cdr);
        this.time_out_subs.push(setTimeout(() => {
            this.is_loading = false;
            this.doCdr(this.cdr);
        }, 500));
    }

    public cancelRowInput() {
        this.is_loading = true;
        this.doCdr(this.cdr);
        this.time_out_subs.push(setTimeout(() => {
            if (this.changed_rows.length > 0) {
                for (let i = 0; i < this.changed_rows.length; i++) {
                    const row = this.changed_rows[i];
                    row.row.data = {...row.row.org_data};
                    // reset edit field models too
                    this.createRowFieldObject(row.row);
                }
            }
            this.changed_rows = [];
            this.edit = !this.edit;
            this.checkMatRipple();
            this.setRows(this._tmp_rows, false);
            this.is_loading = false;
            this.doCdr(this.cdr);
        }, 500));
    }

    public saveRows() {
        if (this.use_confirm_on_changes) {
            const question = 'Alle Änderungen übernehmen?';
            const dialogRef = this.dialog.open(ConfirmComponent, {
                width: '280px',
                height: '300px',
                data: {title: 'Berarbeitung bestätigen', text: question}
            });

            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    if (this.on_rows_saved) {
                        this.on_rows_saved.emit({rows: this.changed_rows});
                    }
                }
            });
        } else {
            if (this.on_rows_saved) {
                this.on_rows_saved.emit({rows: this.changed_rows});
            }
        }
    }

    private checkForSmallDevice() {
        let is_small_device = false;
        if (this.innerWidth <= this._small_device_limit) {
            is_small_device = true;
            this.edit = false;
        } else {
            is_small_device = false;
        }

        if (is_small_device !== this.is_small_device) {
            this.is_small_device = is_small_device;
        }
    }

    private setHeight() {
        if (this.fixed_height) {
            const htmlTable =  $('.table-scroll-container');
            // const tob_bar_height =  $('.top-bar').top();
            // $('.content-container-wrapper').height(window.innerHeight - tob_bar_height);
            if (htmlTable && htmlTable.length > 0) {
                // console.log('window.innerHeight', window.innerHeight);
                // console.log('htmlTable.offset().top', htmlTable.offset().top);
                let height = window.innerHeight - htmlTable.offset().top - 94;
                if (this.in_tab) {
                    height = height - 24;
                }
                $('.table-scroll-container').height(height);
            }
        }
    }

    ngOnDestroy(): void {
        this._rows = [];
        this._tmp_rows = [];
        this.dataSource = new MatTableDataSource(null);
        if (this.time_out_subs.length > 0) {
            for (let i = 0; i < this.time_out_subs.length; i++) {
                clearTimeout(this.time_out_subs[i]);
            }
        }
    }

    addClicked() {
        if (this.on_add_clicked) {
            this.on_add_clicked.emit();
        }
    }

    public activeFilterChanged($event) {
        /*
        if ($event.checked) {
            localStorage.setItem('table.filter_active_value.' + this._table_key, '1');
        } else {
            localStorage.setItem('table.filter_active_value.' + this._table_key, '0');
        }
         */
        if (this.custom_filter_active) {
            this.applyFilterByFilterRow('activeFilterChanged');
        } else {
            this.applyFilter(this.filter_value, 'activeFilterChanged');
        }
        // custom_filter_active
        /*
        this.detach(this.cdr);
        this.is_loading = true;
        this.doCdr(this.cdr);
        setTimeout(() => {
            this.setRows(this._tmp_rows, false);
            this.selection.clear();
            setTimeout(() => {
                this.is_loading = false;
                this.doCdr(this.cdr);
                this.reattach(this.cdr);
            }, 500);
        }, 200);
        */
    }

    public fieldChanged(row, column_id) {
        this.time_out_subs.push(setTimeout(() => {
            this.doCdr(this.cdr);
        }, 100));
        // dirty workaround to make table work with depending fields and more
        for (let i = 0; i < row.fb_all_fields_helper.length; i++) {
            const field = row.fb_all_fields_helper[i];
            if (field.hasOwnProperty('on_other_field_changed')) {
                if (field.name !== column_id) {
                    const form_trigger_class = row['ref_holder'][column_id];
                    const tmp_field_groups = form_trigger_class._field_groups;
                    const changed_field = tmp_field_groups[0].fields[0];
                    const form_target_class = row['ref_holder'][field.name];
                    // erstmal nur bekannte felder informieren
                    if (form_target_class) {
                        const tmp_target_groups = form_target_class._field_groups;
                        const target_field = tmp_target_groups[0].fields[0];

                        if (field.hasOwnProperty('on_other_field_changed')) {
                            field.on_other_field_changed(target_field, changed_field,
                                form_target_class.setFieldValue.bind(form_target_class),
                                form_target_class.refreshFormField.bind(form_target_class),
                                form_target_class);
                            form_target_class.cdr.detectChanges();
                        }
                    }
                }
            }
        }

        // add row to changed rows
        this.addChangedRow(row);
    }

    public searchObserve(evt) {
        // const searchText = evt.target.value;
        // console.log('searchObserve', this._tmp_rows);
        if (this.last_search_time_out) {
            clearTimeout(this.last_search_time_out);
        }

        this.last_search_time_out = setTimeout(() => {
            if (this.user_changed_filter || this._tmp_rows && this._tmp_rows.length > 0) {
                // console.log($event);
                this.applyFilterByFilterRow('searchObserve');
                // this.doCdr(this.cdr);
            }
        }, 10);
        this.time_out_subs.push(this.last_search_time_out);
    }

    public filterFieldChanged($event) {
        this.user_changed_filter = true;
        this.custom_filter_active = true;
        this.searchObserve($event);
        /*
        setTimeout(() => {
            // console.log($event);
            this.applyFilterByFilterRow();
            this.doCdr(this.cdr);
        }, 100);
        */
    }

    isGroup(index, item): boolean {
        return item.fb_is_group;
    }

    isDetailRow(index, item): boolean {
        // console.log('item.is_detail_row', item.is_detail_row);
        return item.is_detail_row;
    }

    groupHeaderClick(row) {
        for (let i = 0; i < row.expand_childs.length; i++) {
            const child = row.expand_childs[i];
            child.expanded = !child.expanded;
        }
        // this.dataSource.filter = performance.now().toString();  // bug here need to fix
    }


    public toggleFilter() {
        this._sidenav.toggle();
        this.show_detailed_filter = !this.show_detailed_filter;
        /*
        if (this.show_detailed_filter) {
            this.filter_value = '';
        }
         */
    }

    public isFieldVisible(field_name: string) {
        for (let i = 0; i < this._column_definition.length; i++) {
            const field = this._column_definition[i];
            if (field.id === field_name) {
                return field.visible;
            }
        }
        return false;
    }

    public removeFilter(user_changed: boolean) {
        this.user_changed_filter = user_changed;
        if (this.filter_row && this.filter_row.fb_field_obj && this.filter_row.fb_field_obj[0]) {
            for (let i = 0; i < this.filter_row.fb_field_obj[0].fields.length; i++) {
                const field = this.filter_row.fb_field_obj[0].fields[i];
                field.model.obj[field.name] = null;
            }
        }
        const row = this.filter_row;
        this.filter_row = {};
        // this.filter_row = row;
        this.time_out_subs.push(setTimeout(() => {
            this.filter_row = row;
            this.doCdr(this.cdr);
            this.applyFilterByFilterRow('removeFilter');
            this.saveFilter({});
            this.custom_filter_active = false;
        }, 100));
    }

    public doCdr(cdr: ChangeDetectorRef) {
        if (cdr && !(cdr as ViewRef).destroyed) {
            cdr.detectChanges();
        }
    }

    public detach(cdr: ChangeDetectorRef) {
        if (cdr && !(cdr as ViewRef).destroyed) {
            cdr.detach();
        }
    }

    public reattach(cdr: ChangeDetectorRef) {
        if (cdr && !(cdr as ViewRef).destroyed) {
            cdr.reattach();
        }
    }

    public buildAPIFilterFromFilter(): string {
        let filter = '';
        const obj_filter = {};
        if (this.filter_row && this.filter_row.data) {
            for (const [key, value] of Object.entries(this.filter_row.data)) {
                if (value && value !== '') {
                    const field = this.getFieldForFilterEntry(key);
                    if (field) {
                        switch (field.type) {
                            default:
                                obj_filter[key] = {
                                    type: field.type,
                                    value: value
                                };
                                break;
                            case 'checkbox':
                                obj_filter[key] = {
                                    type: field.type,
                                    value: value === true ? '1' : '0'
                                };
                                break;
                            case 'date':
                                if (!obj_filter.hasOwnProperty(field.ref_name)) {
                                    obj_filter[field.ref_name] = {
                                        type: field.type,
                                        value: {}
                                    };
                                }
                                const dt = new Date(value.toString());
                                obj_filter[field.ref_name].value[field.filter_type] = dt.getFullYear() +
                                    '-' + this.pad(dt.getMonth() + 1, 2) +
                                    '-' + this.pad(dt.getDate(), 2);
                                break;
                        }
                    }
                }
            }
            for (const [key, field] of Object.entries(obj_filter)) {
                let new_value = '';
                switch (field['type']) {
                    default:
                        new_value = field['value'];
                        if (filter === '') {
                            filter += '?' + key + '=' + new_value;
                        } else {
                            filter += '&' + key + '=' + new_value;
                        }
                        break;
                    case 'date':
                        new_value = field['value'];
                        if (field['value']['>=']) {
                            new_value = field['value']['>='];
                            if (filter === '') {
                                filter += '?' + key + '[start]=' + new_value;
                            } else {
                                filter += '&' + key + '[start]=' + new_value;
                            }
                        }
                        if (field['value']['<=']) {
                            new_value = field['value']['<='];
                            if (filter === '') {
                                filter += '?' + key + '[end]=' + new_value;
                            } else {
                                filter += '&' + key + '[end]=' + new_value;
                            }
                        }
                        break;
                }

            }
        }

        localStorage.setItem('api_query.' + this._table_key, filter);
        return filter;
    }

    private getFieldForFilterEntry(key) {
        // fb_field_obj[] fields[]
        for (let g = 0; g < this.filter_row.fb_field_obj.length; g++) {
            const fb_fields = this.filter_row.fb_field_obj[g].fields;
            for (let f = 0; f < fb_fields.length; f++) {
                const field = fb_fields[f];
                if (field.name === key) {
                    return field;
                }
            }
        }
    }

    private pad(num, size) {
        let s = num + '';
        while (s.length < size) {
            s = '0' + s;
        }
        return s;
    }


    public exportRows() {
        const data = [];
        const headers = [];

        const div = document.createElement('div');

        // let csvContent = '';
        // const header = [];
        for (let c = 0; c < this._column_definition.length; c++) {
            const column = this._column_definition[c];
            if (column.id !== '#fb_column_spacer' && column.id !== '#fb_column_select') {
                headers.push(column.label);
                switch (column.field_type) {
                    default:
                        break;
                    case 'select':
                    case 'select_group':
                    case 'chip_autocomplete':
                    case 'autocomplete':
                    case 'checkbox':
                        if (!column.do_not_export_klartext) {
                            headers.push('_Klartext_' + column.label);
                        }
                        break;
                }
            }
        }

        const options = {
            fieldSeparator: ';',
            quoteStrings: '"',
            decimalSeparator: ',',
            showLabels: true,
            showTitle: false,
            title: '',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: headers,
            filename: 'export'
        };

        // const row = header.join(';');
        // csvContent += row + '\r\n';

        const that = this;
        this.dataSource.filteredData.forEach(function (rowArray) {
            const body = [];
            const row = {};
            for (let c = 0; c < that._column_definition.length; c++) {
                const column = that._column_definition[c];
                if (column.id !== '#fb_column_spacer' && column.id !== '#fb_column_select') {

                    let val = rowArray.data[column.id]; // .replace('\r', '').replace('\n', '')
                    if (String(val) !== undefined) {
                        val = String(val).replace('\r', '').replace('\n', '');
                    }

                    div.innerHTML = val;
                    val = div.textContent || div.innerText || '';
                    // body.push(val);
                    row[column.id] = val;

                    if (!column.do_not_export_klartext) {
                        switch (column.field_type) {
                            default:
                                break;
                            case 'select':
                            case 'select_group':
                            case 'autocomplete':
                                const lookup = that.column_lookup[column.id].look_up;
                                if (lookup && column.option_key) {
                                    if (column.option_multiple) {
                                        // multiple-select
                                        row['_Klartext_' + column.id] = that.ArrayToLookUpStringForLookUp(lookup,
                                            rowArray.data[column.option_key]);
                                    } else {
                                        const lookup_val = that.toLookUpString(rowArray.data, column.option_key);
                                        // select, autocomplete
                                        row['_Klartext_' + column.id] = lookup[lookup_val];
                                    }
                                } else {
                                    row['_Klartext_' + column.id] = '';
                                }
                                break;
                            case 'chip_autocomplete':
                                const lookup_chip_auto = that.column_lookup[column.id].look_up;
                                row['_klartext_' + column.id] = that.ChipAutocompletValuesToLookUpString(lookup_chip_auto,
                                    rowArray.data[column.option_key]);
                                break;
                            case 'checkbox':
                                if (rowArray.data[column.id] && rowArray.data[column.id] !== '0') {
                                    row['_Klartext_' + column.id] = 'Ja';
                                } else {
                                    row['_Klartext_' + column.id] = 'Nein';
                                }
                                break;
                        }
                    }
                }
            }

            // const tmp_row = body.join(';');
            // csvContent += tmp_row + '\r\n';
            data.push(row);
        });

        // const encodedUri = encodeURI(csvContent);
        // window.open(encodedUri);
        //
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);

        /*
        const blob = new Blob([
                new Uint8Array([0xEF, 0xBB, 0xBF]), // UTF-8 BOM
                csvContent,
            ],
            { type: 'text/csv;charset=utf-8' });

        const pom = document.createElement('a');
        // var csvContent=csv; //here we load our csv data
        // let blob = new Blob([csvContent],{type: 'text/csv;charset=utf-8;'});
        const url = URL.createObjectURL(blob);
        pom.href = url;
        pom.setAttribute('download', 'export.csv');
        pom.click();
        */
    }

    private utf8_from_str(s) {
        const a = '';
        // tslint:disable-next-line:no-shadowed-variable
        for (let i = 0, enc = encodeURIComponent(s), a = []; i < enc.length;) {
            if (enc[i] === '%') {
                a.push(parseInt(enc.substr(i + 1, 2), 16));
                i += 3;
            } else {
                a.push(enc.charCodeAt(i++));
            }
        }
        return a;
    }

    public getGetFieldOptionsByFieldname(column_name: string) {
        for (let i = 0; i < this.real_filter_fields.length; i++) {
            const group = this.real_filter_fields[i];
            for (let f = 0; f < group.fields.length; f++) {
                const field = group.fields[f];
                if (field.name === column_name) {
                    return field;
                }
            }
            // console.log(i, group);
        }
        //
        // for (let i = 0; i < this.filter_row.fb_field_obj[0].fields.length; i++) {;
        //     const field = this.filter_row.fb_field_obj[0].fields[i];
        //     const field_name = field.name;
        //     const field_value = field.model.obj[field_name];
        //     const field_type = field.type;
        //     const filter_type = field.filter_type;
        //     console.log(field_name, column_name);
        //     if (field_name === column_name) {
        //         return field
        //     }
        // }
        return null;
    }

    private getSavedFilter(): any {
        const filter_str = localStorage.getItem('filter_detail_model.' + this._table_key);
        let filter: {};
        if (filter_str && filter_str !== '') {
            filter = JSON.parse(filter_str);
            if (filter &&
                (!filter.hasOwnProperty('data') || (filter.hasOwnProperty('data') && Object.keys(filter['data']).length === 0))) {
                filter = {};
            }
        }

        if (!filter) {
            filter = {};
        }
        return filter;
    }

    private saveFilter(filter: any) {
        const new_filter_obj = {};
        for (const [key, value] of Object.entries(filter)) {
            if (value && value !== '') {
                new_filter_obj[key] = value;
            }
        }
        if (this.filter_row && this.filter_row.fb_field_obj && this.filter_row.fb_field_obj[0]) {
            const data = {data: new_filter_obj, fb_field_obj: this.filter_row.fb_field_obj};
            if (this.default_model && this.filter_row.fb_field_obj && this.filter_row.fb_field_obj.length) {
                for (let i = 0; i < this.filter_row.fb_field_obj.length; i++) {
                    const fobj = this.filter_row.fb_field_obj[i];
                    for (let f = 0; f < fobj.fields.length; f++) {
                        const field = fobj.fields[f];
                        /*
                        if (field.options && field.options.length > 0) {
                            field.options = [];
                        }
                        if (field.select_options && field.select_options.length > 0) {
                            field.select_options = [];
                        }
                         */
                        if (field.template) {
                            delete field.template;
                        }
                    }
                }
            }
            localStorage.setItem('filter_detail_model.' + this._table_key, JSON.stringify(data, this.censor(data)));
        }
    }

    private censor(censor) {
        let i = 0;

        return function(key, value) {
            if (i !== 0 && typeof(censor) === 'object' && typeof(value) === 'object' && censor === value) {
                return '[Circular]';
            }

            if (i >= 29) {// seems to be a harded maximum of 30 serialized objects?
                return '[Unknown]';
            }

            ++i; // so we know we aren't using the original object anymore

            return value;
        };
    }


    private isArrayEqual(array_a: any[], array_b: any[]): boolean {

        if (array_a.length !== array_b.length) {
            return false;
        }
        const a_string = Array.isArray(array_a) && `${array_a}`;
        const b_string = Array.isArray(array_b) && `${array_b}`;

        return (a_string === b_string);
    }

    public toLookUpString(obj: any, key: any) {
        if (obj[key] && obj[key] !== '') {
            return String(obj[key]);
        }
        return null;
    }

    public ArrayToLookUpStringForLookUp(lookup: any, ar_values: any) {
        const res = [];
        if (Array.isArray(ar_values) && ar_values.length > 0) {
            for (let i = 0; i < ar_values.length; i++) {
                const looked_up = lookup[String(ar_values[i])];
                if (looked_up) {
                    res.push(looked_up);
                }
            }
        }
        return res.join(', ');
    }

    private ChipAutocompletValuesToLookUpString(lookup: any, comma_value: any) {
        if (comma_value) {
            const chip_value = comma_value.replaceAll(', ', ',');
            const ar_values = chip_value.split(',');
            return this.ArrayToLookUpStringForLookUp(lookup, ar_values);
        }
        return '';
    }

    private isEntryInList(value: string, list: any) {
        for (let i = 0; i < list.length; i++) {
            if (list[i] === value) {
                return true;
            }
        }
        return false;
    }

    private isListInList(list_values: any, list: any) {
        for (let l = 0; l < list_values.length; l++) {
            const value = list_values[l];
            for (let i = 0; i < list.length; i++) {
                if (list[i].trim() === value.trim()) {
                    return true;
                }
            }
        }
        return false;
    }
}
