<div class="content-container">

    <div style="display:flex;justify-content:center;align-items:center;">
        <div>
            <button mat-button class="button-login-discord" (click)="doLogin($event)">
                Discord
            </button>
        </div>
    </div>

    <!--
    <div class="padding">
        <button mat-button (click)="openRegister()">Konto erstellen</button>
    </div>
    -->

</div>
