import {FbFormObject} from '../class/fb_form_object';

export class RaceTypeVehicleClass extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'type_id', label: 'Race Type', visible: true, noclick: false, disable_ripple: true},
            {id: 'vehicle_class', label: 'Vehicle Class', visible: true, noclick: false, disable_ripple: true}
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Assign Vehicle Class',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'select',
                        name: 'type_id',
                        options: this.select_boxes['race_type_select'],
                        disabled: false,
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'select',
                        name: 'vehicle_class',
                        options: this.select_boxes['vehicle_class_select'],
                        disabled: false,
                        rules: [
                            {rule: 'required'}
                        ]
                    }
                ]
            }
        ];


        super.setEditFields();
    }

    /**
     * override / load all stores we need
     */
    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {

            this.stores['assigned_vehicle_class'] = this.storeService.createNoneGlobalStore('assigned_vehicle_class',
                'race/types/' + String(this.custom_obj.type_id) + '/vehicle-class-assigned', 'assign_id');

            this.stores['vehicle_class'] = this.storeService.getStore('vehicle_class');
            this.stores['race_types'] = this.storeService.getStore('race_types');

            this.main_store = this.stores['assigned_vehicle_class'];

            const selectFilter = [];
            selectFilter.push('first=0');
            selectFilter.push('rows=1000');


            const stores = [];
            // if (!this.stores['sc_version'].isLoaded()) {
            stores.push(this.stores['assigned_vehicle_class'].loadStore(this.filter));
            stores.push(this.stores['vehicle_class'].loadStore('?' + selectFilter.join('&')));
            stores.push(this.stores['race_types'].loadStore('?' + selectFilter.join('&')));
            // }

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        console.log('this.custom_obj', this.custom_obj);
        return {
            data: {
                type_id: this.custom_obj.type_id,
                vehicle_class: null
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        // call super
        this.parseVehicleClassSelectBox();
        this.parseTypeSelectBox();
        super.setSelectboxes();
    }

    public parseVehicleClassSelectBox(): any[] {
        const options = [];
        const records = this.stores['vehicle_class'].getRecords();
        for (let i = 0; i < records.length; i++) {
            const rec = records[i];
            options.push(
                {
                    name: rec.data.class_name,
                    value: rec.data.vehicle_class
                }
            );
        }
        this.select_boxes['vehicle_class_select'] = options;
        return this.select_boxes['vehicle_class_select'];
    }

    public parseTypeSelectBox(): any[] {
        const options = [];
        const records = this.stores['race_types'].getRecords();
        for (let i = 0; i < records.length; i++) {
            const rec = records[i];
            options.push(
                {
                    name: rec.data.type_name,
                    value: rec.data.type_id
                }
            );
        }
        this.select_boxes['race_type_select'] = options;
        return this.select_boxes['race_type_select'];
    }

}
