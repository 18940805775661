import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {StoreService} from '../../../../services/store.service';
import {AuthService} from '../../../../services/auth.service';
import {ActivatedRoute} from '@angular/router';
import {NotificationService} from '../../../../services/notification.service';
import {MatDialog} from '@angular/material/dialog';
import {ScbEvent} from 'src/app/forms/scb-event';
import {IMqttMessage, MqttService} from 'ngx-mqtt';
import {AppSettings} from '../../../../config/AppSettings';

@Component({
    selector: 'app-bot-event',
    templateUrl: './bot-event.component.html',
    styleUrls: ['./bot-event.component.css']
})
export class BotEventComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
        this._select_caption_tpl = value;
        this.cdr.markForCheck();
    }

    public loading_text = '';
    public is_loading = true;
    public fields: any[];
    public mode = 'edit';
    public edit = false;
    public rows = [];
    public count = 0;

    private subs: Array<Subscription> = [];
    public record: any;
    public id: string | number = '';
    private ScbEvent: ScbEvent;

    private _select_caption_tpl: ElementRef;

    public user_id = 0;
    public memberDataElement: any;
    public depositDataElement: any;
    public oreDeliveryDataElement: any;
    public oreRefineryFeeDataElement: any;
    public cargoDeliveryDataElement: any;
    public extraPaymentDataElement: any;
    public editAllowed = false;
    public isIfa = false;
    public show_save_button = false;

    constructor(private storeService: StoreService,
                private authService: AuthService,
                private notificationService: NotificationService,
                private route: ActivatedRoute,
                private cdr: ChangeDetectorRef,
                public dialog: MatDialog,
                private _mqttService: MqttService) {
    }

    ngOnInit() {
        this.user_id = this.authService.getUserId();
    }

    ngAfterViewInit(): void {

        this.subs.push(this.route.params.subscribe((params: any) => {
            this.edit = this.authService.isAdmin();

            if (params.id && params.id !== 'add') {
                this.mode = 'edit';
                this.id = params.id;
            } else {
                this.mode = 'add';
                // this.edit = true;
            }

            const filter = [];
            filter.push('filter[event_id][0][mode]=equals');
            filter.push('filter[event_id][0][operator]=and');
            filter.push('filter[event_id][0][value]=' + this.id);
            filter.push('first=0');
            filter.push('rows=1');

            this.ScbEvent = new ScbEvent(this.storeService, this.authService, this.mode);
            this.ScbEvent.init({select_caption_tpl: this._select_caption_tpl}, {},
                '?' + filter.join('&')).then((success) => {
                this.fields = this.ScbEvent.getEditFields();
                if (this.id && this.id !== '' && this.mode === 'edit') {
                    const rec = this.ScbEvent.getStore().getRecordById(this.id);
                    if (rec) {
                        this.record = rec;
                        this.loadRoleStore(this.record.data.guild_id);
                        if (AppSettings.ifa_guild_id === String(this.record.data.guild_id)) {
                            this.isIfa = true;
                        }
                    }
                    this.subs.push(this._mqttService.observe('bot/event').subscribe((message: IMqttMessage) => {
                        const info = JSON.parse(message.payload.toString());
                        if (Number(info.event_id) === Number(this.record.data.event_id) && !this.storeService.is_saving) {
                            this.is_loading = true;
                            this.ScbEvent.getStore().reloadStore().then(() => {
                                const updatedRec = this.ScbEvent.getStore().getRecordById(this.id);
                                if (updatedRec) {
                                    this.record = updatedRec;
                                    this.rebuildForm();
                                }
                                this.is_loading = false;
                            });
                        }
                    }));

                } else {
                    this.resetModel();
                }
                this.rebuildForm();
                this.is_loading = false;
            });

        }));
    }

    private loadRoleStore(guild_id: number) {
        const roleStore = this.storeService.createNoneGlobalStore('sc_ores',
            'me/bot/mod_role/server', 'role_id');

        this.editAllowed = false;
        this.show_save_button = false;

        roleStore.loadStore('').then((records: any) => {
            if (records && records.length > 0) {
                let foundRole = false;
                for (let r = 0; r < records.length; r++) {
                    if (String(records[r].data.guild_id) === String(this.record.data.guild_id)) {
                        foundRole = this.authService.hasRole(this.record.data.guild_id, records[r].data.role);
                        if (foundRole) {
                            this.editAllowed = foundRole;
                            this.show_save_button = this.editAllowed;
                            continue;
                        }
                    }
                }
            }
        });
    }

    public rebuildForm() {
        this.fields = [];
        this.cdr.detectChanges();
        this.fields = this.ScbEvent.getForm(this.record.data);
        this.cdr.detectChanges();
    }

    private resetModel() {
        this.record = this.ScbEvent.getDefaultModel();
    }

    public saveStore(event: any) {


        if (this.mode === 'edit') {
            this.ScbEvent.getStore().patchRecord(this.record, '').then((res) => {
                if (res && res.success) {
                    this.notificationService.success('Data',
                        'Speichern erfolgreich.', {timeOut: 5000});
                    this.rebuildForm();
                } else {
                    this.notificationService.error('Data',
                        'Speichern fehlgeschlagen.', {timeOut: 5000});
                }
            });
        } else {
            // Todo: read alle channels for all discord server on login and get channel list for servers
            this.ScbEvent.getStore().addRecord(this.record.data, '').then((res) => {
                if (res && res.success) {
                    this.notificationService.success('Data',
                        'Speichern erfolgreich.', {timeOut: 5000});
                    this.resetModel();
                    this.rebuildForm();
                } else {
                    this.notificationService.error('Data',
                        'Speichern fehlgeschlagen.', {timeOut: 5000});
                }
            });
        }
    }

    public memberDataLoadedDone(event) {
        this.memberDataElement = event;
    }

    public depositDataLoadedDone(event) {
        this.depositDataElement = event;
    }

    public oreDeliveryDataLoadedDone(event) {
        this.oreDeliveryDataElement = event;
    }

    public oreRefineryFeeDataLoadedDone(event) {
        this.oreRefineryFeeDataElement = event;
    }

    public cargoDeliveryDataLoadedDone(event) {
        this.cargoDeliveryDataElement = event;
    }

    public extraPaymentDataLoadedDone(event) {
        this.extraPaymentDataElement = event;
    }

    public onFormChanged($event: any) {
        this.show_save_button = false;
        this.loadRoleStore($event.model.guild_id);
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
        if (this.mode === 'edit') {
            this.ScbEvent.getStore().revertRecord(this.record);
        }
    }
}
