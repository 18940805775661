import {FbFormObject} from '../class/fb_form_object';

export class ScbEvent extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'guild_id', label: 'Guild', visible: true, noclick: false, disable_ripple: true, max_width: '150px'},
            {id: 'channel_id', label: 'Channel', visible: true, noclick: false, disable_ripple: true},
            {id: 'event_type', label: 'Type', visible: true, noclick: false, disable_ripple: true},
            {id: 'event_name', label: 'Name', visible: true, noclick: false, disable_ripple: true, max_width: '300px'},
            {id: 'event_description', label: 'Description', visible: true, noclick: false, disable_ripple: true, max_width: '300px'},
            {id: 'event_datetime', label: 'Date', visible: true, noclick: false, disable_ripple: true},
            {id: 'event_lead', label: 'Lead', visible: true, noclick: false, disable_ripple: true},
            {id: 'start_ts', label: 'Start', visible: true, noclick: false, disable_ripple: true},
            {id: 'end_ts', label: 'End', visible: true, noclick: false, disable_ripple: true},
            {id: 'orga_tax', label: 'Orga tax', visible: true, noclick: false, disable_ripple: true}
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Discord',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'select',
                        name: 'guild_id',
                        options: this.select_boxes['guild_select'],
                        disabled: this.mode === 'edit',
                        rules: [
                            {rule: 'required'}
                        ]
                        // on_change: (event) => {
                        //     console.log('event', event);
                        // },
                    },
                    {
                        type: 'select',
                        name: 'channel_id',
                        options: this.select_boxes['sc_guild_channel_select'],
                        on_other_field_changed: (self, changed_field, setFieldValueCallback, refreshFormField) => {
                            if (changed_field.name === 'guild_id') {
                                setFieldValueCallback(self, null);
                                self.options = this.filterScGuildChannelSelectBox(changed_field.model.obj.guild_id);
                                refreshFormField(self);
                            }
                        },
                        on_init: (field, inputCls) => {
                            field.options = this.filterScGuildChannelSelectBox(field.model.obj.guild_id);
                        },
                        disabled: this.mode === 'edit',
                        rules: [
                            {rule: 'required'}
                        ]
                        // on_change: (event) => {
                        //     console.log('event', event);
                        // },
                    },
                ]
            },
            {
                caption: 'Date',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'datetime',
                        name: 'event_datetime',
                        disabled: false,
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'datetime',
                        name: 'start_ts',
                        disabled: false
                    },
                    {
                        type: 'datetime',
                        name: 'end_ts',
                        disabled: false
                    },
                ]
            },
            {
                caption: 'Event',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'select',
                        name: 'event_type',
                        options: this.select_boxes['event_type_select'],
                        disabled: this.mode === 'edit',
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'text',
                        name: 'event_name',
                        disabled: false,
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'textarea',
                        name: 'event_description',
                        disabled: false
                    },
                    {
                        type: 'number',
                        name: 'orga_tax',
                        disabled: false
                    }
                ]
            },
            {
                caption: 'Leader',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'autocomplete',
                        name: 'event_lead',
                        options: this.select_boxes['sc_user_select'],
                        disabled: false,
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                ]
            },
        ];


        super.setEditFields();
    }

    /**
     * override / load all stores we need
     */
    public loadStores(ext_obj?: any): Promise<any> {
        console.log('ext_obj', ext_obj);
        return new Promise<any>((resolve, reject) => {
            this.stores['sc_events'] = this.storeService.getStore('sc_events');
            this.stores['guilds'] = this.storeService.getStore('guilds');
            this.stores['sc_user'] = this.storeService.getStore('sc_user');
            this.stores['dc_channel'] = this.storeService.getStore('dc_channel');
            this.main_store = this.stores['sc_events'];

            const stores = [];

            const selectFilter = [];
            selectFilter.push('first=0');
            selectFilter.push('rows=1000');

            // if (!this.stores['sc_events'].isLoaded()) {
            if (this.filter === '') {
                this.filter = '&sort[0][event_datetime]=-1';
            }
            stores.push(this.stores['sc_events'].loadStore(this.filter));
            // }
            if (!this.stores['guilds'].isLoaded()) {
                stores.push(this.stores['guilds'].loadStore('?' + selectFilter.join('&')));
            }
            if (!this.stores['sc_user'].isLoaded()) {
                stores.push(this.stores['sc_user'].loadStore('?' + selectFilter.join('&')));
            }
            if (!this.stores['dc_channel'].isLoaded()) {
                stores.push(this.stores['dc_channel'].loadStore('?' + selectFilter.join('&')));
            }

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        const dt = new Date();
        return {
            data: {
                event_id: 0,
                guild_id: 0,
                event_type: 'mining',
                event_name: '',
                event_description: '',
                event_datetime: dt,
                event_lead: 0,
                start_ts: null,
                end_ts: null,
                orga_tax: 0
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        this.parseGuildSelectbox();
        this.parseScUserSelectbox();
        this.parseScGuildChannelSelectBox();
        this.parseEventTypeSelectBox();
        // call super
        super.setSelectboxes();
    }

    public parseGuildSelectbox() {
        const options = [];
        const records = this.stores['guilds'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.guild_id),
                name: records[i].data.guild_name
            });
        }
        this.select_boxes['guild_select'] = options;
        return this.select_boxes['guild_select'];
    }

    public parseScUserSelectbox() {
        const options = [];
        const records = this.stores['sc_user'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.user_id),
                name: records[i].data.sc_username
            });
        }
        this.select_boxes['sc_user_select'] = options;
        return this.select_boxes['sc_user_select'];
    }

    // public loadAndParseScGuildChannelSelectBox(guild_id): Promise<any> {
    //     return new Promise<any>((resolve, reject) => {
    //         const channelStore = this.storeService.createNoneGlobalStore('guild_channels',
    //             '/guilds/' + String(guild_id) + '/channel', 'auto_id');
    //         channelStore.loadStore('').then((records) => {
    //             const options = this.parseScGuildChannelSelectBox();
    //             console.log(this.select_boxes['sc_guild_channel_select']);
    //             resolve(options);
    //         });
    //     });
    // }

    public parseScGuildChannelSelectBox(): any[] {
        const options = [];
        const records = this.stores['dc_channel'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.channel_id),
                name: records[i].data.channel_name,
                parent: records[i].data.guild_id,
            });
        }
        this.select_boxes['sc_guild_channel_select'] = options;
        return this.select_boxes['sc_guild_channel_select'];
    }

    public parseEventTypeSelectBox(): any[] {
        this.select_boxes['event_type_select'] = [
            {value: 'mining', name: 'Mining'},
            {value: 'salvage', name: 'Salvage'},
            {value: 'trading', name: 'Trading'},
            {value: 'industrial', name: 'Industrial (Mining + Salvage)'}
        ];
        return this.select_boxes['event_type_select'];
    }

    public filterScGuildChannelSelectBox(guild_id): any[] {
        const options = this.select_boxes['sc_guild_channel_select'].filter((row) => {
           return String(row.parent) === String(guild_id);
        });
        return options;
    }
}
