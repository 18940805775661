<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <mat-tab-group mat-align-tabs="start" color="accent" LastActiveTabDirective="edit-sc-version">
        <mat-tab label="Version Data">
            <div class="padding">
                <app-form-inputs *ngIf="fields && fields.length"
                                 #tpl_form
                                 [field_groups]="fields"
                                 [edit_allowed]="true"
                                 [loading]="false"
                                 (on_submit)="saveStore($event)"
                                 [button_class]="'button-save'">
                </app-form-inputs>
            </div>
        </mat-tab>
        <mat-tab label="Ores" *ngIf="id && mode === 'edit'">
            <app-sc-commodity [version_id]="id" [com_type]="'ore'"></app-sc-commodity>
        </mat-tab>
        <mat-tab label="Cargo" *ngIf="id && mode === 'edit'">
            <app-sc-commodity [version_id]="id" [com_type]="'cargo'"></app-sc-commodity>
        </mat-tab>
    </mat-tab-group>
</ng-template>
