import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from '../pages/page-not-found/page-not-found.component';
import {MainComponent} from '../pages/main/main.component';
import {LoginComponent} from '../pages/login/login.component';
import {AuthGuard} from '../guards/auth.guard';
import {CheckLoginComponent} from '../pages/check-login/check-login.component';
import {EditUserComponent} from '../pages/user/edit-user/edit-user.component';
import {AppSettings} from '../config/AppSettings';
import {RegisterComponent} from '../pages/register/register.component';
import {UserComponent} from '../pages/user/user.component';
import {LogoutComponent} from '../pages/logout/logout.component';
import {AdminComponent} from '../pages/admin/admin.component';
import {TokenLoginComponent} from '../pages/token-login/token-login.component';
import {DiscordAuthPointComponent} from '../pages/discord-auth-point/discord-auth-point.component';
import {StarcitizenComponent} from '../pages/admin/games/starcitizen/starcitizen.component';
import {GamesComponent} from '../pages/admin/games/games.component';
import {ScVersionComponent} from '../pages/admin/games/starcitizen/sc-version/sc-version.component';
import {EditScVersionComponent} from '../pages/admin/games/starcitizen/sc-version/edit-sc-version/edit-sc-version.component';
import {BotEventsComponent} from '../pages/bot/bot-events/bot-events.component';
import {BotEventComponent} from '../pages/bot/bot-events/bot-event/bot-event.component';
import {PrivacyPoliceComponent} from '../pages/privacy-police/privacy-police.component';
import {BotAboutComponent} from '../pages/bot/bot-about/bot-about.component';
import {ImpressumComponent} from '../pages/impressum/impressum.component';
import {MembersComponent} from '../pages/members/members.component';
import {MembersPointsComponent} from '../pages/members/members-points/members-points.component';
import {MembersPointsDetailsComponent} from '../pages/members/members-points/members-points-details/members-points-details.component';
import {
    MembersPointsEditComponent
} from '../pages/members/members-points/members-points-details/members-points-edit/members-points-edit.component';
import {ScCommodityEditComponent} from '../pages/admin/games/starcitizen/sc-commodity/sc-commodity-edit/sc-commodity-edit.component';
import {IfaBadgesComponent} from '../pages/admin/ifa/ifa-badges/ifa-badges.component';
import {IfaComponent} from '../pages/admin/ifa/ifa.component';
import {IfaMemberComponent} from '../pages/admin/ifa/ifa-member/ifa-member.component';
import {EditIfaMemberComponent} from '../pages/admin/ifa/ifa-member/edit-ifa-member/edit-ifa-member.component';
import {GuidesComponent} from '../pages/guides/guides.component';
import {GuideEditComponent} from '../pages/guides/guide-edit/guide-edit.component';
import {MediaComponent} from '../pages/media/media.component';
import {MediaEditComponent} from '../pages/media/media-edit/media-edit.component';
import {UpcomingEventsComponent} from '../pages/upcomming-events/upcoming-events.component';
import {BotResourcesComponent} from '../pages/bot/bot-resources/bot-resources.component';
import {BotComponent} from '../pages/bot/bot.component';
import {MemberProfileComponent} from '../pages/members/member-profile/member-profile.component';
import {RaceTracksComponent} from '../pages/rally/race-tracks/race-tracks.component';
import {RaceTrackDetailsComponent} from '../pages/rally/race-tracks/race-track-details/race-track-details.component';
import {RaceTypesComponent} from '../pages/admin/games/starcitizen/race-types/race-types.component';
import {RaceTracksAdminComponent} from '../pages/admin/games/starcitizen/race-tracks/race-tracks-admin.component';
import {ManufacturerComponent} from '../pages/admin/games/starcitizen/manufacturer/manufacturer.component';
import {VehicleTypesComponent} from '../pages/admin/games/starcitizen/vehicle-types/vehicle-types.component';
import {VehicleClassComponent} from '../pages/admin/games/starcitizen/vehicle-class/vehicle-class.component';
import {VehiclesComponent} from '../pages/admin/games/starcitizen/vehicles/vehicles.component';
import {LapTimesComponent} from '../pages/rally/race-tracks/lap-times/lap-times.component';
import {LapTimeEditComponent} from '../pages/rally/race-tracks/lap-times/lap-time-edit/lap-time-edit.component';
import {EditRaceTypeComponent} from '../pages/admin/games/starcitizen/race-types/edit-race-type/edit-race-type.component';
import {CareersComponent} from '../pages/admin/games/starcitizen/career/careers.component';
import {RallyComponent} from '../pages/rally/rally.component';
import {SeriesComponent} from '../pages/rally/series/series.component';
import {SeriesDetailsComponent} from '../pages/rally/series/series-details/series-details.component';
import {ProfileComponent} from '../pages/profile/profile.component';
import {PointsOfInterestComponent} from '../pages/points-of-interest/points-of-interest.component';
import {
    PointsOfInterestDetailsComponent
} from '../pages/points-of-interest/points-of-interest-details/points-of-interest-details.component';
import {NewsComponent} from '../pages/news/news.component';
import {FleetComponent} from '../pages/fleet/fleet.component';
import {
    ScAvailableCommoditiesComponent
} from '../pages/admin/games/starcitizen/sc-available-commodities/sc-available-commodities.component';
import {ScContractsComponent} from '../pages/sc-contracts/sc-contracts.component';
import {ScContractCommoditiesComponent} from '../pages/sc-contracts/sc-contract-commodities/sc-contract-commodities.component';
import {ScContractEditComponent} from '../pages/sc-contracts/sc-contract-edit/sc-contract-edit.component';
import {ScCommodityInventoryComponent} from '../pages/sc-commodity-inventory/sc-commodity-inventory.component';
import {
    ScCommodityInventoryEditComponent
} from '../pages/sc-commodity-inventory/sc-commodity-inventory-edit/sc-commodity-inventory-edit.component';
import {VehicleDetailsComponent} from '../shared/vehicle-details/vehicle-details.component';
import {MiningLoadoutCalcComponent} from '../pages/mining-loadout-calc/mining-loadout-calc.component';
import {CalculatorDataComponent} from '../pages/admin/games/starcitizen/calculator-data/calculator-data.component';

const appRoutes: Routes = [
    {
        path: 'checklogin',
        component: CheckLoginComponent,
        data: {breadcrumb: 'Login check', show_back: false}
    },
    {
        path: 'register',
        component: RegisterComponent,
        data: {breadcrumb: 'Register', show_back: false}
    },
    {
        path: 'login',
        component: LoginComponent,
        data: {breadcrumb: 'Login', show_back: false}
    },
    {
        path: 'login/:token',
        component: TokenLoginComponent,
        data: {breadcrumb: 'Login', show_back: false}
    },
    {
        path: 'login-dc/:state/:code',
        component: DiscordAuthPointComponent,
        data: {breadcrumb: 'Login', show_back: false}
    },
    {
        path: 'logout',
        component: LogoutComponent,
        data: {breadcrumb: 'Logout', show_back: false}
    },
    {
        path: 'main',
        component: MainComponent,
        data: {breadcrumb: 'Start', show_back: false}
    },
    {
        path: 'admin',
        component: AdminComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Admin', groups: [{guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_page_admin}],
            show_back: true
        }
    },
    {
        path: 'admin/games',
        component: GamesComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Games edit', groups: [{guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_page_admin}],
            show_back: true
        }
    },
    {
        path: 'admin/games/starcitizen',
        component: StarcitizenComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Starcitizen edit', groups: [{guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_page_admin}],
            show_back: true
        }
    },
    {
        path: 'admin/games/starcitizen/sc-version',
        component: ScVersionComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Starcitizen Version bearbeiten', groups: [{guild_id: AppSettings.ifa_guild_id,
                role: AppSettings.group_page_admin}],
            show_back: true
        }
    },
    {
        path: 'admin/games/starcitizen/race-types',
        component: RaceTypesComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Race Types bearbeiten', groups: [
                {guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_page_admin},
                {guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_rally_admin}
            ],
            show_back: true
        }
    },
    {
        path: 'admin/games/starcitizen/race-types/:id',
        component: EditRaceTypeComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Race Type bearbeiten', groups: [
                {guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_page_admin},
                {guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_rally_admin}
            ],
            show_back: true,
            has_tabs: true
        }
    },
    {
        path: 'admin/games/starcitizen/race-tracks',
        component: RaceTracksAdminComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Race Tracks bearbeiten', groups: [
                {guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_page_admin},
                {guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_rally_admin}
            ],
            show_back: true
        }
    },
    {
        path: 'admin/games/starcitizen/manufacturer',
        component: ManufacturerComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Manufacturer bearbeiten', groups: [
                {guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_page_admin},
                {guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_rally_admin}
            ],
            show_back: true
        }
    },
    {
        path: 'admin/games/starcitizen/vehicle-types',
        component: VehicleTypesComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Vehicle Types bearbeiten', groups: [
                {guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_page_admin},
                {guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_rally_admin}
            ],
            show_back: true
        }
    },
    {
        path: 'admin/games/starcitizen/vehicle-class',
        component: VehicleClassComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Vehicle Class bearbeiten', groups: [
                {guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_page_admin},
                {guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_rally_admin}
            ],
            show_back: true
        }
    },
    {
        path: 'admin/games/starcitizen/careers',
        component: CareersComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Careers bearbeiten', groups: [
                {guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_page_admin},
                {guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_rally_admin}
            ],
            show_back: true
        }
    },
    {
        path: 'admin/games/starcitizen/vehicle',
        component: VehiclesComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Vehicle bearbeiten', groups: [
                {guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_page_admin},
                {guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_rally_admin}
            ],
            show_back: true
        }
    },
    {
        path: 'admin/games/starcitizen/available-commodities',
        component: ScAvailableCommoditiesComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Available Commodities', groups: [
                {guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_page_admin},
                {guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_contract_admin}
            ],
            show_back: true,
            has_tabs: true
        }
    },
    {
        path: 'admin/games/starcitizen/sc-version/:id',
        component: EditScVersionComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Starcitizen Version edit', groups: [{guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_page_admin}],
            show_back: true,
            has_tabs: true
        }
    },
    {
        path: 'admin/games/starcitizen/sc-version/:version_id/commodity/:com_type/:id',
        component: ScCommodityEditComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Starcitizen Commodity edit', groups: [{guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_page_admin}],
            show_back: true
        }
    },
    {
        path: 'admin/ifa',
        component: IfaComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'IFA', groups: [{guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_page_admin}],
            show_back: true
        }
    },
    {
        path: 'admin/ifa/member',
        component: IfaMemberComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'IFA Member', groups: [{guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_page_admin}],
            show_back: true
        }
    },
    {
        path: 'admin/ifa/member/:id',
        component: EditIfaMemberComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'IFA Member Edit', groups: [{guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_page_admin}],
            show_back: true,
            has_tabs: true
        }
    },
    {
        path: 'admin/ifa/badges',
        component: IfaBadgesComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Badges', groups: [{guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_page_admin}],
            show_back: true,
            has_tabs: true
        }
    },
    {
        path: 'user',
        component: UserComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Benutzer', show_back: true}
    },
    // {
    //     path: 'user/add',
    //     component: EditUserComponent,
    //     canActivate: [AuthGuard],
    //     data: {
    //         breadcrumb: 'User add', groups: [AppSettings.group_page_admin], show_back: true
    //     }
    // },
    {
        path: 'user/:id',
        component: EditUserComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'User edit', show_back: true
        }
    },
    {
        path: 'bot',
        component: BotComponent,
        data: {
            breadcrumb: 'Bot', show_back: true
        }
    },
    {
        path: 'bot/about',
        component: BotAboutComponent,
        data: {
            breadcrumb: 'About Bot', show_back: true
        }
    },
    {
        path: 'bot/events',
        component: BotEventsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Bot Events', show_back: true
        }
    },
    {
        path: 'bot/resources',
        component: BotResourcesComponent,
        data: {
            breadcrumb: 'Bot Resources', show_back: true
        }
    },
    {
        path: 'bot/events/:id',
        component: BotEventComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Bot Event', show_back: true,
            has_tabs: true
        }
    },
    {
        path: '',
        redirectTo: 'main',
        pathMatch: 'full',
        data: { breadcrumb: 'External', show_back: true }
    },
    {
        path: 'news',
        component: NewsComponent,
        data: {
            breadcrumb: 'News', show_back: true,
            groups: [{guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_ifa}]
        }
    },
    {
        path: 'fleet',
        component: FleetComponent,
        data: {
            breadcrumb: 'Fleet', show_back: true,
            groups: [{guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_ifa}]
        }
    },
    {
        path: 'vehicle/:model',
        component: VehicleDetailsComponent,
        data: {
            breadcrumb: 'Vehicle Details', show_back: true,
            groups: [{guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_ifa}]
        }
    },
    {
        path: 'sc-contracts',
        component: ScContractsComponent,
        data: {
            breadcrumb: 'Contracts', show_back: true,
            groups: [{guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_member}]
        }
    },
    {
        path: 'sc-contracts/:id',
        component: ScContractEditComponent,
        data: {
            breadcrumb: 'Contracts', show_back: true,
            groups: [{guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_member}],
            has_tabs: true
        }
    },
    {
        path: 'sc-commodity-inventory',
        component: ScCommodityInventoryComponent,
        data: {
            breadcrumb: 'Inventory', show_back: true,
            groups: [{guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_contract_admin}],
            has_tabs: true
        }
    },
    {
        path: 'sc-commodity-inventory/type/:type/:id',
        component: ScCommodityInventoryEditComponent,
        data: {
            breadcrumb: 'Inventory', show_back: true,
            groups: [{guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_contract_admin}],
            has_tabs: true
        }
    },
    {
        path: 'members',
        component: MembersComponent,
        data: {
            breadcrumb: 'Members', show_back: false,
            groups: [{guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_ifa}]
        }
    },
    {
        path: 'members/profile/:user_id',
        component: MemberProfileComponent,
        data: {
            breadcrumb: 'Profile', show_back: false
        }
    },
    {
        path: 'members-points',
        component: MembersPointsComponent,
        data: {
            breadcrumb: 'Points', show_back: false, groups: [{guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_ifa}]
        }
    },
    {
        path: 'members-points/:user_id',
        component: MembersPointsDetailsComponent,
        data: {
            breadcrumb: 'Points User', show_back: true, groups: [{guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_ifa}]
        }
    },
    {
        path: 'members-points/:user_id/:type/:id',
        component: MembersPointsEditComponent,
        data: {
            breadcrumb: 'Points User', show_back: true, groups: [{guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_ifa}]
        }
    },
    {
        path: 'guides',
        component: GuidesComponent,
        data: {
            breadcrumb: 'Guides', show_back: true
        }
    },
    {
        path: 'media',
        component: MediaComponent,
        data: {
            breadcrumb: 'Media', show_back: true, groups: [{guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_ifa}]
        }
    },
    {
        path: 'media/:id',
        component: MediaEditComponent,
        data: {
            breadcrumb: 'Media add', show_back: true, groups: [{guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_ifa}]
        }
    },
    {
        path: 'guides/:id',
        component: GuideEditComponent,
        data: {
            breadcrumb: 'Guide', show_back: true
        }
    },
    {
        path: 'impressum',
        component: ImpressumComponent,
        data: {
            breadcrumb: 'Impressum', show_back: false
        }
    },
    {
        path: 'privacy-police',
        component: PrivacyPoliceComponent,
        data: {
            breadcrumb: 'Privacy Police', show_back: false
        }
    },
    {
        path: 'upcoming-events',
        component: UpcomingEventsComponent,
        data: {
            breadcrumb: 'Upcoming Events', show_back: true
        }
    },
    {
        path: 'rally',
        component: RallyComponent,
        data: {
            breadcrumb: 'Rally', show_back: true
        }
    },
    {
        path: 'rally/series',
        component: SeriesComponent,
        data: {
            breadcrumb: 'Series', show_back: true
        }
    },
    {
        path: 'rally/series/:id',
        component: SeriesDetailsComponent,
        data: {
            breadcrumb: 'Series', show_back: true
        }
    },
    {
        path: 'rally/race-tracks',
        component: RaceTracksComponent,
        data: {
            breadcrumb: 'Race Track', show_back: true
        }
    },
    {
        path: 'rally/race-tracks/:id',
        component: RaceTrackDetailsComponent,
        data: {
            breadcrumb: 'Race Track', show_back: true
        }
    },
    {
        path: 'rally/lap-times/:track_id/:type_id',
        component: LapTimesComponent,
        data: {
            breadcrumb: 'Lap Times', show_back: true
        }
    },
    {
        path: 'rally/lap-times/:track_id/:type_id/:series_id/:assign_id',
        component: LapTimesComponent,
        data: {
            breadcrumb: 'Lap Times', show_back: true
        }
    },
    {
        path: 'rally/lap-times/:track_id/:type_id/:id',
        component: LapTimeEditComponent,
        data: {
            breadcrumb: 'Lap Time Series', show_back: true
        }
    },
    {
        path: 'rally/lap-times/:track_id/:type_id/:id/:series_id/:assign_id',
        component: LapTimeEditComponent,
        data: {
            breadcrumb: 'Lap Time Series', show_back: true
        }
    },
    {
        path: 'profile',
        component: ProfileComponent,
        data: {
            breadcrumb: 'Profile', show_back: true
        }
    },
    {
        path: 'points-of-interest',
        component: PointsOfInterestComponent,
        data: {
            breadcrumb: 'Points of Interest', show_back: true, groups: [{guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_ifa}]
        }
    },
    {
        path: 'points-of-interest/:id',
        component: PointsOfInterestDetailsComponent,
        data: {
            breadcrumb: 'Points of Interest', show_back: true, groups: [{guild_id: AppSettings.ifa_guild_id, role: AppSettings.group_ifa}]
        }
    },
    {
        path: 'admin/games/starcitizen/calculator-data/:type',
        component: CalculatorDataComponent,
        data: {
            breadcrumb: 'Calculator Data', groups: [{guild_id: AppSettings.ifa_guild_id,
                role: AppSettings.group_page_admin}],
            show_back: true
        }
    },
    {
        path: 'mining-loadouts-calc',
        component: MiningLoadoutCalcComponent,
        data: {
            breadcrumb: 'Mining Calculator', show_back: true
        }
    },
    {path: '**', component: PageNotFoundComponent, data: {breadcrumb: 'Page not found', show_back: true}}
];


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forRoot(
            appRoutes,
            { enableTracing: false,
                useHash: true,
                anchorScrolling: 'enabled',
                onSameUrlNavigation: 'reload',
                scrollPositionRestoration: 'enabled'
            } // <-- debugging purposes only
 // <-- debugging purposes only
 // <-- debugging purposes only
        )
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
