<div class="padding">
    <div class="card card-1 padding table-wrapper" *ngIf="!is_loading && storeObject">
        <app-fb-forms-table [storeObj]="storeObject"
                            [edit_allowed]="is_owner"
                            [storeExtObj]="{c_id: c_id, contract_type: contract_type}"
                            (row_click)="rowSelected($event)"
                            (after_save)="afterSave()"
                            [stateKey]="'sc-contract-commodities'"
                            [show_add_in_view]="is_owner"
                            [show_delete]="is_owner"
                            [title]="'Commodities'"
                            [footer_tpl]="footer_tpl"
        >
        </app-fb-forms-table>
    </div>
</div>


<ng-template #footer_tpl>
    <ng-container *ngIf="is_owner">
        <button mat-raised-button class="button-add"
                style="margin-top: -14px;"
                (click)="openAddSetDialog()"
                matTooltip="add set"
                type="button">
            add set
        </button>
    </ng-container>
</ng-template>