import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import {forkJoin, Observable, Subject} from 'rxjs';

@Component({
    selector: 'app-file-upload2',
    templateUrl: './file-upload2.component.html',
    styleUrls: ['./file-upload2.component.css']
})
export class FileUpload2Component implements OnInit {

    @Input() file_type = 'misc';
    @Input() url = '';
    @Input() append_data = [];
    @Input() show_save_button = true;
    @Input() max_count = 1;
    @Input() accept = '.*';
    @Input() reduce = false;
    @Input() delete_old = 0;

    @Input() set parent_object_id(value: string) {
        this._parent_object_id = value;
    }
    get parent_object_id(): string {
        return this._parent_object_id;
    }

    @Output() on_success: EventEmitter<any> = new EventEmitter();
    @Output() init: EventEmitter<any> = new EventEmitter();
    @ViewChild('file', {static: true}) file;
    public files: Set<File> = new Set();
    private _parent_object_id = '0';
    progress: any;
    showCancelButton = true;
    uploading = false;
    uploadSuccessful = false;

    constructor(private http: HttpClient) {
    }

    ngOnInit() {
        if (this.init) {
            this.init.emit(this);
        }
    }

    addFiles(event: MouseEvent) {
        this.file.nativeElement.click();
        event.preventDefault();
    }

    onFilesAdded() {
        const files: { [key: string]: File } = this.file.nativeElement.files;
        let file_count = 0;
        for (const key in files) {
            if (!isNaN(Number(key)) && file_count < this.max_count) {
                file_count++;
                this.files.add(files[key]);
            }
        }
    }

    public updateParentObjectId(value: string) {
        this._parent_object_id = value;
    }

    public startUpload() {
        // set the component state to "uploading"
        this.uploading = true;
        this.showSaveScreen();

        // start the upload and save the progress map
        this.progress = this.upload(this.files);

        // convert the progress map into an array
        const allProgressObservables = [];
        // tslint:disable-next-line:forin
        for (const key in this.progress) {
            allProgressObservables.push(this.progress[key].progress);
        }


        // Hide the cancel-button
        this.showCancelButton = false;

        // When all progress-observables are completed...
        forkJoin(allProgressObservables).subscribe(end => {

            // ... the upload was successful...
            this.uploadSuccessful = true;

            // ... and the component is no longer uploading
            this.uploading = false;
        });
    }

    public upload(files: Set<File>): { [key: string]: { progress: Observable<number> } } {

        // this will be our resulting map
        const status: { [key: string]: { progress: Observable<number> } } = {};

        files.forEach(file => {
            // create a new multipart-form for every file
            const formData: FormData = new FormData();
            formData.append('file', file, file.name);
            formData.append('file_type', this.file_type);
            formData.append('delete_old', String(this.delete_old));
            formData.append('parent_object_id', this.parent_object_id);

            if (this.reduce) {
                formData.append('reduce', '1');
            }


            if (this.append_data && this.append_data.length > 0) {
                for (let i = 0; i < this.append_data.length; i++) {
                    const info = this.append_data[i];
                    formData.append(info.key, info.value);
                }
            }

            // create a http-post request and pass the form
            // tell it to report the upload progress
            const req = new HttpRequest('POST', this.url, formData, {
                reportProgress: true,
                withCredentials: true
            });

            // create a new progress-subject for every file
            const progress = new Subject<number>();

            // send the http-request and subscribe for progress-updates
            this.http.request(req).subscribe(event => {
                if (event.type === HttpEventType.UploadProgress) {

                    // calculate the progress percentage
                    const percentDone = Math.round(100 * event.loaded / event.total);

                    // pass the percentage into the progress-stream
                    progress.next(percentDone);
                } else if (event instanceof HttpResponse) {

                    // Close the progress-stream if we get an answer form the API
                    // The upload is complete
                    progress.complete();
                    this.files = new Set();

                    this.hideSaveScreen();
                    if (this.on_success) {
                        this.on_success.emit(event);
                    }
                }
            });

            // Save every progress-observable in a map of all observables
            status[file.name] = {
                progress: progress.asObservable()
            };
        });

        // return the map of progress.observables
        return status;
    }


    private showSaveScreen() {
        const screen = document.getElementById('overlay-saving');
        if (screen) {
            screen.style.display = 'flex';
        }
    }

    private hideSaveScreen() {
        const screen = document.getElementById('overlay-saving');
        if (screen) {
            screen.style.display = 'none';
        }
    }
}
