import {FbFormObject} from '../class/fb_form_object';

export class IfaMemberBadge extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'badge_id', label: 'Badge', visible: true, noclick: false, disable_ripple: true},
            {id: 'public_note', label: 'pulic note', visible: true, noclick: false, disable_ripple: true},
            {id: 'badge_type', label: 'type', visible: true, noclick: false, disable_ripple: true},
            {id: 'assigned_by_user', label: 'assigned by', visible: true, noclick: false, disable_ripple: true},
            {id: 'note', label: 'private note', visible: true, noclick: false, disable_ripple: true},
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'IFA Badge',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'select_group',
                        name: 'badge_id',
                        select_groups: this.select_boxes['badge_select'],
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'textarea',
                        name: 'public_note'
                    },
                    {
                        type: 'select_group',
                        name: 'badge_type',
                        select_groups: this.select_boxes['badge_type_select'],
                        disabled: true
                    },
                    {
                        type: 'text',
                        name: 'assigned_by_user',
                        disabled: true
                    },
                    {
                        type: 'textarea',
                        name: 'note'
                    }
                ]
            }
        ];


        super.setEditFields();
    }

    /**
     * override / load all stores we need
     */
    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {

            this.stores['ifa_member_badge'] = this.storeService.createNoneGlobalStore('ifa_member_badge',
                '/ifa/members/' + String(this.custom_obj.user_id) + '/badges-assigned', 'assign_id');

            this.stores['ifa_badges'] = this.storeService.getStore('ifa_badges');

            this.main_store = this.stores['ifa_member_badge'];

            const tagFilter = [];
            tagFilter.push('first=0');
            tagFilter.push('rows=1000');


            const stores = [];
            // if (!this.stores['sc_version'].isLoaded()) {
            stores.push(this.stores['ifa_member_badge'].loadStore(this.filter));
            stores.push(this.stores['ifa_badges'].loadStore('?' + tagFilter.join('&')));
            // }

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
                badge_id: null
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        // call super
        this.parseBadgeSelectBox();
        this.parseBadgeTypeSelectBox();
        super.setSelectboxes();
    }

    public parseBadgeSelectBox(): any[] {
        const tmpOptionObj = {};
        const tmpOptions = [];
        const records = this.stores['ifa_badges'].getRecords();
        for (let i = 0; i < records.length; i++) {
            const rec = records[i];
            if (!tmpOptionObj[rec.data.badge_type]) {
                tmpOptionObj[rec.data.badge_type] = {
                    items: [],
                    label: rec.data.badge_type
                };
            }
            tmpOptions.push({
                label: rec.data.badge_name + ' (Rank ' + records[i].data.badge_rank + ')',
                value: rec.data.badge_id,
                badge_type: rec.data.badge_type
            });
        }
        for (let i = 0; i < tmpOptions.length; i++) {
            tmpOptionObj[tmpOptions[i].badge_type].items.push({
                label: tmpOptions[i].label,
                value: tmpOptions[i].value
            });
        }

        const options = [];

        for (const [key, value] of Object.entries(tmpOptionObj)) {
            options.push(value);
        }
        this.select_boxes['badge_select'] = options;
        return this.select_boxes['badge_select'];
    }

    public parseBadgeTypeSelectBox(): any[] {
        this.select_boxes['badge_type_select'] = [
            {
                label: 'APOLLON', items: [
                    {value: 'exploration', label: 'exploration'},
                    {value: 'science', label: 'science'},
                    {value: 'medical', label: 'medical'}
                ]},
            {
                label: 'ASPIS', items: [
                    {value: 'defence_fps', label: 'defence fps'},
                    {value: 'defence_flight', label: 'defence flight'}
                ]},
            {
                label: 'EMPOROS', items: [
                    {value: 'industries', label: 'industries'},
                    {value: 'logistics', label: 'logistics'}
                ]},
            {
                label: 'Medals', items: [
                    {value: 'medal', label: 'medal'}
                ]
            }
        ];
        return this.select_boxes['badge_type_select'];
    }
}
