import {Component, Input, OnInit} from '@angular/core';
import {KeyValuePipe, NgClass} from '@angular/common';

@Component({
    selector: 'app-stat-row',
    standalone: true,
    imports: [
        KeyValuePipe,
        NgClass
    ],
    templateUrl: './stat-row.component.html',
    styleUrl: './stat-row.component.css'
})
export class StatRowComponent implements OnInit {

    @Input()
    public name_key = '';

    @Input()
    public stats: any;

    @Input()
    public stat_translation: any;

    @Input()
    public positive_class = 'green';

    @Input()
    public negative_class = 'red';

    public init_done: boolean;

    ngOnInit(): void {
        for (const key in this.stats) {
            if (this.stats.hasOwnProperty(key)) {
                if (this.stats[key]) {
                    let cls = '';
                    if (this.stat_translation[key]) {
                        if (this.stats[key] < 0) {
                            cls = this.stat_translation[key].negative_class;
                        } else if (this.stats[key] > 0) {
                            cls = this.stat_translation[key].positive_class;
                        }
                        this.stats[key + '_class'] = cls;
                    }
                }
            }
        }
        this.init_done = true;
    }
}
