import {Component, Input} from '@angular/core';
import {AppSettings} from '../../config/AppSettings';
import {MatDialog} from '@angular/material/dialog';
import {ImageViewerComponent} from '../dialoge/image-viewer/image-viewer.component';

@Component({
    selector: 'app-image-preview',
    templateUrl: './image-preview.component.html',
    styleUrls: ['./image-preview.component.css']
})
export class ImagePreviewComponent {
    @Input() file_id: string;
    public path: string;

    constructor(public dialog: MatDialog) {
        this.path = AppSettings.getRealAPILink();
    }

    openDialog(): void {
        this.dialog.open(ImageViewerComponent, {
            data: {imageUrl: this.path + 'media/' + this.file_id + '/show'}
        });
    }
}
