<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <mat-tab-group mat-align-tabs="start" color="accent" LastActiveTabDirective="sc-contract-edit">
        <mat-tab label="Contract">
            <div class="padding">
                <app-form-inputs *ngIf="fields && fields.length"
                                 #tpl_form
                                 [field_groups]="fields"
                                 [edit_allowed]="is_owner"
                                 [loading]="false"
                                 (on_submit)="saveStore($event)"
                                 [button_class]="'button-save'">
                </app-form-inputs>
            </div>
        </mat-tab>
        <mat-tab label="Commodities" *ngIf="id && mode === 'edit'
            && record && record.data && record.data.c_id > 0
            && !['escort'].includes(record.data.type)">
            <app-sc-contract-commodities
                    [c_id]="record.data.c_id"
                    [is_owner]="is_owner"
                    [contract_type]="record.data.type"
                    [add_to_inventory]="record.data.add_to_inventory"
                    (after_save)="afterSaved()">
            </app-sc-contract-commodities>
        </mat-tab>
        <mat-tab label="Rewards" *ngIf="id && mode === 'edit' && record && record.data && record.data.c_id > 0">
            <div class="inner-card" *ngIf="reward_loading">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <ng-container *ngIf="!reward_loading">
                <app-sc-contract-rewards
                        [contract_type]="record.data.type"
                        [c_id]="record.data.c_id"
                        [is_owner]="is_owner"
                ></app-sc-contract-rewards>
            </ng-container>
        </mat-tab>
    </mat-tab-group>
</ng-template>
