import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    Renderer2,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {ScWikiTooltipService} from '../../services/sc-wiki-tooltip.service';
import {DOCUMENT} from '@angular/common';
import {CookieService} from '../../services/cookie.service';

@Component({
    selector: 'app-sc-wiki-link',
    templateUrl: './sc-wiki-link.component.html',
    styleUrls: ['./sc-wiki-link.component.css']
})
export class ScWikiLinkComponent implements AfterViewInit {

    get content(): string {
        return this._content;
    }

    @Input()
    set linkObj(linkObj: any) {
        if (linkObj.link) {
            this._content = '<a href="' + linkObj?.link + '" target="_blank">' + linkObj?.name + '</a>';
        } else {
            this._content = linkObj?.name;
        }

        console.log('linkObj', linkObj);
    }

    @ViewChild('dynamicContainer', {read: ViewContainerRef}) container: ViewContainerRef;

    private _content = '';
    private tooltipElement = null;
    private tooltipTextElement = null;
    private tooltipImageElement = null;
    private toolTipIsVisible = false;
    private oldNode = null;
    public sc_wiki_consent = false;

    constructor(private scWikiTooltipService: ScWikiTooltipService,
                private el: ElementRef,
                private renderer: Renderer2,
                private cdr: ChangeDetectorRef,
                @Inject(DOCUMENT) private document: Document,
                private cookieService: CookieService) {
        this.tooltipElement = this.renderer.createElement('span');
        this.renderer.setStyle(this.tooltipElement, 'position', 'absolute');
        this.renderer.setStyle(this.tooltipElement, 'background', '#333');
        this.renderer.setStyle(this.tooltipElement, 'color', '#fff');
        this.renderer.setStyle(this.tooltipElement, 'padding', '0');
        this.renderer.setStyle(this.tooltipElement, 'borderRadius', '4px');
        this.renderer.setStyle(this.tooltipElement, 'fontSize', '12px');
        // this.renderer.setStyle(this.tooltipElement, 'transform', 'translateY(10%) translateY(-5px)');
        this.renderer.setStyle(this.tooltipElement, 'width', '320px');
        this.renderer.setStyle(this.tooltipElement, 'display', 'none');
        this.renderer.setStyle(this.tooltipElement, 'z-index', '100');
        this.renderer.appendChild(this.document.body, this.tooltipElement);

        this.tooltipImageElement = this.renderer.createElement('img');
        this.renderer.setAttribute(this.tooltipImageElement, 'src', '');
        this.renderer.setAttribute(this.tooltipImageElement, 'alt', '');
        this.renderer.setStyle(this.tooltipImageElement, 'width', '100%');
        this.renderer.setStyle(this.tooltipImageElement, 'display', 'none');
        // this.renderer.setStyle(this.tooltipImageElement, 'height', '200px');
        this.renderer.appendChild(this.tooltipElement, this.tooltipImageElement);

        this.tooltipTextElement = this.renderer.createElement('div');
        this.renderer.setStyle(this.tooltipTextElement, 'width', '100%');
        this.renderer.setStyle(this.tooltipTextElement, 'padding', '16px');
        this.renderer.setStyle(this.tooltipTextElement, 'display', 'block');
        this.renderer.appendChild(this.tooltipElement, this.tooltipTextElement);
    }

    ngAfterViewInit() {
        this.sc_wiki_consent = this.cookieService.getConsent('sc_wiki');

        const anchors = this.el.nativeElement.querySelectorAll('a');
        anchors.forEach(anchor => {
            if (anchor.host === 'starcitizen.tools') {
                anchor.setAttribute('class', 'ql-sc-tool-tip');
                anchor.removeAttribute('title');
                this.renderer.setAttribute(anchor, 'appScWikiToolTip', 'test');
                this.renderer.listen(anchor, 'mouseover', (event) => {
                    this.showToolTip(anchor);
                });
                this.renderer.listen(anchor, 'mouseout', (event) => {
                    this.hideToolTip();
                });
                this.renderer.listen(this.tooltipElement, 'mouseout', (event) => {
                    this.hideToolTip();
                });
            }
        });
    }

    public showToolTip(anchor) {
        // this.renderer.setStyle(this.tooltipElement, 'whiteSpace', 'nowrap');
        this.toolTipIsVisible = true;
        if (!this.cookieService.getConsent('sc_wiki')) {
            // do not show tooltips without consent
            if (this.oldNode !== null) {
                this.renderer.removeChild(this.tooltipTextElement, this.oldNode);
            }

            const text = this.renderer.createText('Erlaube Cookies für starcitizen.tools um den Tooltip zu laden.');
            this.renderer.appendChild(this.tooltipTextElement, text);
            this.oldNode = text;
            this.renderer.setStyle(this.tooltipElement, 'display', 'block');
            this.renderer.setStyle(this.tooltipElement, 'color', 'orangered');

            const hostPos = anchor.getBoundingClientRect();
            const tooltipPos = this.tooltipElement.getBoundingClientRect();
            const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

            let top = hostPos.bottom + 10;
            if (top + tooltipPos.height > window.innerHeight) {
                top = hostPos.bottom - tooltipPos.height - 30;
            }
            let left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;

            // Check if tooltip goes out of the right boundary of the screen
            if (left + tooltipPos.width > window.innerWidth) {
                left = window.innerWidth - tooltipPos.width - 10; // 10 is for some margin
            }

            // Check if tooltip goes out of the left boundary of the screen
            if (left < 0) {
                left = 10; // 10 is for some margin
            }

            // Update the position
            this.renderer.setStyle(this.tooltipElement, 'top', `${top + scrollPos}px`);
            this.renderer.setStyle(this.tooltipElement, 'left', `${left}px`);

            return;
        }
        const url_parts = anchor.getAttribute('href').split('/');
        let last_url_part = '';
        if (url_parts && url_parts.length > 0) {
            last_url_part = url_parts[url_parts.length - 1];
        }

        this.scWikiTooltipService.getTooltipData(last_url_part).subscribe((result: any) => {
            if (this.toolTipIsVisible === true) {
                const text = this.renderer.createText(result.query.pages[0].extract);
                this.renderer.setStyle(this.tooltipElement, 'display', 'block');
                if (this.oldNode !== null) {
                    this.renderer.removeChild(this.tooltipTextElement, this.oldNode);
                }

                this.renderer.setStyle(this.tooltipElement, 'color', 'inherit');

                // add image if found
                if (result.query.pages[0].thumbnail) {
                    const thumbnail = result.query.pages[0].thumbnail;
                    this.renderer.setAttribute(this.tooltipImageElement, 'src', thumbnail.source);
                    this.renderer.setAttribute(this.tooltipImageElement, 'alt', '');
                    this.renderer.setStyle(this.tooltipImageElement, 'width', '320px');
                    this.renderer.setStyle(this.tooltipImageElement, 'display', 'block');
                }

                // add text
                this.renderer.appendChild(this.tooltipTextElement, text);
                this.oldNode = text;

                const hostPos = anchor.getBoundingClientRect();
                const tooltipPos = this.tooltipElement.getBoundingClientRect();
                const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

                let top = hostPos.bottom + 10;
                if (top + tooltipPos.height > window.innerHeight) {
                    top = hostPos.bottom - tooltipPos.height - 30;
                }
                let left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;

                // Check if tooltip goes out of the right boundary of the screen
                if (left + tooltipPos.width > window.innerWidth) {
                    left = window.innerWidth - tooltipPos.width - 10; // 10 is for some margin
                }

                // Check if tooltip goes out of the left boundary of the screen
                if (left < 0) {
                    left = 10; // 10 is for some margin
                }

                // Update the position
                this.renderer.setStyle(this.tooltipElement, 'top', `${top + scrollPos}px`);
                this.renderer.setStyle(this.tooltipElement, 'left', `${left}px`);
                this.cdr.detectChanges();
            }
        });
    }

    public hideToolTip() {
        this.toolTipIsVisible = false;
        this.renderer.setStyle(this.tooltipElement, 'display', 'none');
        this.renderer.setStyle(this.tooltipImageElement, 'display', 'none');
        this.cdr.detectChanges();
    }
}
