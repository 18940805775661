import {ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {DialogData} from '../dialoge/confirm/confirm.component';
import {FormInputsComponent} from '../form-inputs/form-inputs.component';
import {NotificationService} from '../../services/notification.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-add-form-dialog',
    templateUrl: './add-form-dialog.component.html',
    styleUrls: ['./add-form-dialog.component.css']
})
export class AddFormDialogComponent implements OnInit {

    @ViewChild('tpl_form')
    public set tpl_form(value: FormInputsComponent) {
        this._tpl_form = value;
        this.form_set = true;
        this.cdr.detectChanges();
    }

    public record = {};
    public form_set = false;
    public _tpl_form: FormInputsComponent;
    public fields: any;

    constructor(
        public dialogRef: MatDialogRef<AddFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private cdr: ChangeDetectorRef,
        private notificationService: NotificationService) {
        this.record = this.data.storeClass.getDefaultModel();
        this.fields = this.data.storeClass.getForm(this.record['data']);
    }

    public saveRecord() {
        this.data.storeClass.getStore().addRecord(this.record['data']).then((result) => {
            if (result && result.success) {
                this.notificationService.success('Eintrag',
                    'Speichern erfolgreich.', {timeOut: 5000});
                this.dialogRef.close({result: true});
            } else {
                this.notificationService.error('Eintrag',
                    'Speichern fehlgeschlagen.', {timeOut: 5000});
            }
        });
    }

    ngOnInit() {
    }

}
