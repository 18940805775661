<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading" [loading_text]="loading_text"
                           [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div>
        <div *ngIf="is_loading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <main class="tiles padding" *ngIf="records">
            <ng-container *ngFor="let series of records">
                <a routerLink="/rally/series/{{series.data.series_id}}" class="link-clear">
                    <app-rally-series-info-tile title="{{series.data.series_name}}" icon="fas fa-calendar"
                                              [style]="{'background-image': 'url(' + media_path +  'media/' + series.data.file_id + '/show)'}"
                                              [info_data]="series.data"
                                              [customClass]="'bg-card'"></app-rally-series-info-tile>
                </a>
            </ng-container>
        </main>

        <ng-container *ngIf="is_admin">
            <button mat-mini-fab class="button-add" (click)="openAddSeries()"
                    style="position: absolute; left: 8px; top: 8px;"
                    matTooltip="add">
                <mat-icon aria-label="add">add</mat-icon>
            </button>
        </ng-container>
    </div>
</ng-template>
