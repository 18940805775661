import {Component, Input, OnInit} from '@angular/core';
import {AppSettings} from '../../config/AppSettings';

@Component({
    selector: 'app-team-tile',
    templateUrl: './team-tile.component.html',
    styleUrls: ['./team-tile.component.css']
})
export class TeamTileComponent implements OnInit {

    @Input()
    set job_description(value: string) {
        this._job_description = value.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }

    public api_url = '';
    @Input() public user_id = '';
    @Input() public firstname = '';
    @Input() public lastname = '';
    @Input() public job = '';
    public _job_description = '';
    @Input() public file_id = '';

    constructor() {
        this.api_url = AppSettings.getRealAPILink();
    }

    ngOnInit() {
    }

}
