import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {StoreService} from '../../services/store.service';
import {MenuService} from '../../services/menu.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private authService: AuthService,
              private router: Router,
              private storeService: StoreService,
              private menuService: MenuService,
              private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
      this.logout();
  }

  private logout() {
      this.authService.logout().then(() => {
          this.storeService.unloadStores();
          this.router.navigateByUrl('/main');
      });
  }

}
