<div>
    <app-content-container [content_template]="page_content" [is_loading]="false" [loading_text]="''"
                           [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div class="padding">
        <p>
            Die Gesuchte Seite befindet sich leider gerade in Quarantäne.
        </p>
        <p>
            Versuch es doch in 14 Tagen noch einmal.
        </p>
    </div>
</ng-template>
