<div class="info-footer">
    <table style="width: 100%">
        <tr>
            <th rowspan="4" style="padding: 8px"><img src="assets/img/mbtc.png" height="96"/></th>
            <th colspan="2">IFA</th>
            <th></th>
            <th colspan="2">Privacy</th>
            <th></th>
        </tr>
        <tr>
            <td class="link-clear">©2024 IFA Team</td>
            <td></td>
            <td></td>
            <td></td>
            <td><a routerLink="/privacy-police" class="link-clear">Datenschutz</a></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><a routerLink="/impressum" class="link-clear">Impressum</a></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><span class="link-clear" style="cursor: pointer" (click)="openCookieConsent()">Privatsphäre</span></td>
            <td></td>
        </tr>

    </table>
</div>
<div class="info-footer-bottom">
    This is an unofficial Star Citizen fansite, not affiliated with the Cloud Imperium group of companies. All content on this site not authored by its host or users are property of their respective owners.
</div>
