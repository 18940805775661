import {Component, OnDestroy, OnInit} from '@angular/core';
import {StoreService} from '../../services/store.service';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {UserStore} from '../../forms/user';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit, OnDestroy {

    public records = [];
    public is_admin = false;
    public UserStore: UserStore;
    public loading_text = '';
    public is_loading = true;

    constructor(private storeService: StoreService,
                private router: Router,
                private authService: AuthService) {

        this.is_admin = this.authService.isAdmin();

        this.UserStore = new UserStore(this.storeService, this.authService, 'edit');
        this.is_loading = false;
    }

    ngOnInit() {
    }

    addClicked() {
        this.router.navigateByUrl('/user/add');
    }

    public rowSelected($event: any) {
        const route = 'user/' + $event.row.user_id;
        if ($event.in_new_tab) {
            const url = this.router.createUrlTree([route]);
            const serializedUrl = this.router.serializeUrl(url);

            // Insert `#` between the domain and route
            const modifiedUrl = '#/' + serializedUrl;
            window.open(modifiedUrl, '_blank');
        } else {
            this.router.navigateByUrl(route);
        }
    }

    ngOnDestroy(): void {
    }
}
