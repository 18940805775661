import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FbFormObject} from '../../../../../class/fb_form_object';
import {Subscription} from 'rxjs';
import {StoreService} from '../../../../../services/store.service';
import {AuthService} from '../../../../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../../../../services/notification.service';
import {MatDialog} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import {MiningLaser} from '../../../../../forms/mining-laser';
import {MiningModule} from '../../../../../forms/mining-module';
import {MiningGadget} from '../../../../../forms/mining-gadget';
import {StoreLocation} from '../../../../../forms/store-location';
import {ScSystem} from '../../../../../forms/sc-system';

@Component({
    selector: 'app-calculator-data',
    templateUrl: './calculator-data.component.html',
    styleUrl: './calculator-data.component.css'
})
export class CalculatorDataComponent implements OnInit, OnDestroy, AfterViewInit {

    public storeObj: FbFormObject;
    private subs: Array<Subscription> = [];

    public loading_text = '';
    public is_loading = true;
    public rows: any[];

    public is_admin: boolean;

    public title = 'Mining Laser';
    public type = 'mining_laser';
    public is_expandable = true;

    constructor(private storeService: StoreService,
                private authService: AuthService,
                private router: Router,
                private cdr: ChangeDetectorRef,
                private notificationService: NotificationService,
                public dialog: MatDialog,
                private http: HttpClient,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {

        this.subs.push(this.route.params.subscribe((params: any) => {
            this.type = params.type;
            switch (this.type) {
                default:
                    this.storeObj = new MiningLaser(this.storeService, this.authService, 'edit');
                    break;
                case 'mining_laser':
                    this.title = 'Mining Laser';
                    this.storeObj = new MiningLaser(this.storeService, this.authService, 'edit');
                    break;
                case 'mining_module':
                    this.title = 'Mining Module';
                    this.storeObj = new MiningModule(this.storeService, this.authService, 'edit');
                    break;
                case 'mining_gadgets':
                    this.title = 'Mining Gadgets';
                    this.storeObj = new MiningGadget(this.storeService, this.authService, 'edit');
                    break;
                case 'store_locations':
                    this.title = 'Store Locations';
                    this.is_expandable = false;
                    this.storeObj = new StoreLocation(this.storeService, this.authService, 'edit');
                    break;
                case 'sc_systems':
                    this.title = 'SC Systems';
                    this.is_expandable = false;
                    this.storeObj = new ScSystem(this.storeService, this.authService, 'edit');
                    break;
            }
        }));


        // this.storeAssignedObj = new IfaMemberBadgeOverview(this.storeService, this.authService, 'edit');

        this.is_loading = false;
        this.cdr.detectChanges();
    }

    public rowSelected($event: any) {
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }
}
