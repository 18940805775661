import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {StoreService} from '../../services/store.service';
import {AuthService} from '../../services/auth.service';
import {NotificationService} from '../../services/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ScUser} from '../../forms/sc-user';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
        this._select_caption_tpl = value;
        this.cdr.markForCheck();
    }

    public loading_text = '';
    public is_loading = true;
    public fields: any[];
    public mode = 'edit';
    public edit = false;
    public rows = [];
    public count = 0;

    private subs: Array<Subscription> = [];
    private record: any;
    private id: string | number = '';
    private ScUser: ScUser;

    private _select_caption_tpl: ElementRef;

    constructor(private storeService: StoreService,
                private authService: AuthService,
                private notificationService: NotificationService,
                private route: ActivatedRoute,
                private router: Router,
                private cdr: ChangeDetectorRef,
                public dialog: MatDialog) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.subs.push(this.route.params.subscribe((params: any) => {
            this.edit = this.authService.isAdmin();


            this.ScUser = new ScUser(this.storeService, this.authService, this.mode);
            this.ScUser.init({select_caption_tpl: this._select_caption_tpl}, {}, '').then((success) => {
                this.fields = this.ScUser.getEditFields();
                const rec = this.ScUser.getStore().getRecordById(this.authService.getUserId());
                if (rec) {
                    this.record = rec;
                    this.mode = 'edit';
                } else {
                    this.mode = 'add';
                    this.resetModel();
                }
                this.rebuildForm();
                this.is_loading = false;
            });
        }));
    }

    public rebuildForm() {
        this.fields = [];
        this.cdr.detectChanges();
        this.fields = this.ScUser.getForm(this.record.data);
        this.cdr.detectChanges();
    }

    private resetModel() {
        this.record = this.ScUser.getDefaultModel();
    }

    public saveStore(event: any) {
        if (this.mode === 'edit') {
            this.ScUser.getStore().patchRecord(this.record, '').then((res) => {
                if (res && res.success) {
                    this.notificationService.success('Data',
                        'Speichern erfolgreich.', {timeOut: 5000});
                    this.storeService.getStore('my_sc_user').unloadStore();
                    this.storeService.getStore('my_sc_user').reloadStore('?rows=1000').then((records: any) => {
                        this.rebuildForm();
                    });
                } else {
                    this.notificationService.error('Data',
                        'Speichern fehlgeschlagen.', {timeOut: 5000});
                }
            });
        } else {
            this.ScUser.getStore().addRecord(this.record.data, '').then((res) => {
                if (res && res.success) {
                    this.notificationService.success('Data',
                        'Speichern erfolgreich.', {timeOut: 5000});
                    this.storeService.getStore('my_sc_user').unloadStore();
                    this.storeService.getStore('my_sc_user').reloadStore('?rows=1000').then((records: any) => {
                        this.rebuildForm();
                    });
                } else {
                    this.notificationService.error('Data',
                        'Speichern fehlgeschlagen.', {timeOut: 5000});
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
        if (this.mode === 'edit') {
            this.ScUser.getStore().revertRecord(this.record);
        }
    }
}
