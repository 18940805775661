import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Subscription} from 'rxjs';
import {Store} from '../../../../../class/store';
import {StoreService} from '../../../../../services/store.service';
import {AuthService} from '../../../../../services/auth.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../../../../services/notification.service';
import {MatDialog} from '@angular/material/dialog';
import {IMqttMessage, MqttService} from 'ngx-mqtt';
import {ScbEventMember} from '../../../../../forms/scb-event-member';

@Component({
  selector: 'app-bot-event-member',
  templateUrl: './bot-event-member.component.html',
  styleUrls: ['./bot-event-member.component.css']
})
export class BotEventMemberComponent implements OnInit, OnDestroy, AfterViewInit {


  @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
    this._select_caption_tpl = value;
    // this.setColumns();
    this.cdr.markForCheck();
  }


  public ScbEventMember: ScbEventMember;
  private subs: Array<Subscription> = [];

  public loading_text = '';
  public is_loading = true;
  public rows: any[];

  @Input()
  public event_id = 0;
  @Input()
  public editAllowed = false;

  private _select_caption_tpl: ElementRef;

  public column_definition: any[];
  public is_admin: boolean;

  private channelStore: Store;
  public ext_obj = {};
  public require_filter = {index: 0, filter: []};

  @Output() public dataLoaded: EventEmitter<any> = new EventEmitter();

  constructor(private storeService: StoreService,
              private authService: AuthService,
              private router: Router,
              private cdr: ChangeDetectorRef,
              private notificationService: NotificationService,
              public dialog: MatDialog,
              private _mqttService: MqttService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.is_admin = this.authService.isAdmin();

    this.require_filter.filter = [];
    this.require_filter.filter.push('filter[event_id][0][mode]=equals');
    this.require_filter.filter.push('filter[event_id][0][operator]=and');
    this.require_filter.filter.push('filter[event_id][0][value]=' + this.event_id);
    this.require_filter.filter.push('first=0');
    this.require_filter.filter.push('rows=1');
    this.require_filter.index = 1;

    this.ScbEventMember = new ScbEventMember(this.storeService, this.authService, 'edit');
    this.ext_obj = {event_id: this.event_id};
    this.is_loading = false;
    this.cdr.detectChanges();
    this.channelStore = this.storeService.getStore('dc_channel');
    this.channelStore.loadStore('').then((records) => {
      this.subs.push(this._mqttService.observe('bot/event').subscribe((message: IMqttMessage) => {
        // refresh table on notifications for user guilds events
        const info = JSON.parse(message.payload.toString());
        const rec = this.channelStore.getRecordById(info.channel_id);
        if (this.ScbEventMember.getStore() && rec && Number(info.event_id) === Number(this.event_id)) {
          this.is_loading = true;
          this.ScbEventMember.getStore().reloadStore().then(() => {
            this.is_loading = false;
          });
        }
      }));
    });
  }

  public rowSelected($event: any) {
    const route = 'bot/events/' + $event.row.event_id;
    if ($event.in_new_tab) {
      const url = this.router.createUrlTree([route]);
      const serializedUrl = this.router.serializeUrl(url);

      // Insert `#` between the domain and route
      const modifiedUrl = '#/' + serializedUrl;
      window.open(modifiedUrl, '_blank');
    } else {
      this.router.navigateByUrl(route);
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  public dataLoadedDone(event) {
    this.dataLoaded.emit(event);
  }
}
