import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DecimalPipe, NgClass} from '@angular/common';
import {MatTooltip} from '@angular/material/tooltip';

@Component({
    selector: 'app-display-stat',
    standalone: true,
    imports: [
        NgClass,
        MatTooltip,
        DecimalPipe
    ],
    templateUrl: './display-stat.component.html',
    styleUrl: './display-stat.component.css'
})
export class DisplayStatComponent implements OnInit, OnChanges {

    @Input()
    public caption = '';

    @Input()
    public tooltip = '';

    @Input()
    public value: number;

    @Input()
    public display_value: number;

    @Input()
    public positive_class = 'green';

    @Input()
    public negative_class = 'red';

    @Input()
    public unit = '%';

    public class = '';

    constructor() {

    }

    ngOnChanges(changes: SimpleChanges): void {
        this.checkClass();
    }

    ngOnInit(): void {
        this.checkClass();
    }

    private checkClass() {
        this.class = '';
        if (this.value > 1) {
            this.class = this.positive_class;
        }
        if (this.value < 1) {
            this.class = this.negative_class;
        }
    }
}
