<div>
    <app-content-container [content_template]="page_content" [is_loading]="false" [loading_text]="''"
                           [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div class="padding">
        <markdown class="variable-binding" [data]="markdown"></markdown>
        <div *ngIf="records" style="text-align: left">
            <ng-container *ngFor="let message of records">
                <app-discord-message [message]="message.data"></app-discord-message>
            </ng-container>
            <div class="message-embed card padding">mehr auf unserem Discord</div>
        </div>
    </div>
</ng-template>
