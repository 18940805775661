import {ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {DialogData} from '../confirm/confirm.component';
import {FormInputsComponent} from '../../form-inputs/form-inputs.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.css']
})
export class CommentComponent implements OnInit {
    @ViewChild('tpl_form') public set tpl_form(value: FormInputsComponent) {
        this._tpl_form = value;
        this.form_set = true;
        this.cdr.detectChanges();
    }

    public _tpl_form: FormInputsComponent;

    public model = {
        comment: ''
    };

    public fields: any[];
    public form_set = false;
    constructor(
        public dialogRef: MatDialogRef<CommentComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.buildForm();
    }

    private buildForm() {
        this.fields = [
            {
                class: 'full-width',
                fields: [
                    {
                        model: {obj: this.model, key: 'comment'},
                        placeholder: 'Kommentar',
                        type: 'textarea',
                        name: 'comment',
                        rows: 5,
                        cols: 80,
                        rules: [
                            {rule: 'required'}
                        ]
                    }
                ]
            }
        ];
    }
}

