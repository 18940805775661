import {ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {DialogData} from '../confirm/confirm.component';
import {NotificationService} from '../../../services/notification.service';
import {MatSelectionList} from '@angular/material/list';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-add-select-list',
    templateUrl: './add-select-list.component.html',
    styleUrls: ['./add-select-list.component.css']
})
export class AddSelectListComponent implements OnInit {

    public selected = [];
    public key = '';
    list_entries = [];

    @ViewChild('entries', {static: true}) entries: MatSelectionList;

    constructor(
        public dialogRef: MatDialogRef<AddSelectListComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private cdr: ChangeDetectorRef,
        private notificationService: NotificationService) {
        // this.record = this.data.storeClass.getDefaultModel();
        this.key = data.obj.key;
        this.list_entries = data.obj.records;
    }

    public saveRecord() {
        const posts = [];
        for (let i = 0; i < this.entries.selectedOptions.selected.length; i++) {
            const new_rec = {};
            new_rec[this.data.obj.table_a_id] = this.data.obj.master_id;
            new_rec[this.data.obj.table_b_id] = this.entries.selectedOptions.selected[i].value;

            posts.push(this.data.storeClass.getStore().addRecord(new_rec));
        }

        Promise.all(posts).then((result) => {

        }).then((result) => {
            // all stores loaded
            this.notificationService.success('Gespeichert',
                'Speichern erfolgreich.', {timeOut: 5000});
            this.dialogRef.close({result: true});
        })
            .catch(function (err) {
                this.notificationService.error('Fehler',
                    'Speichern fehlgeschlagen.', {timeOut: 5000});
            });
    }

    ngOnInit(): void {
    }

}
