import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {Subscription} from 'rxjs';
import {Store} from '../../../../../../class/store';
import {StoreService} from '../../../../../../services/store.service';
import {AuthService} from '../../../../../../services/auth.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../../../../../services/notification.service';
import {MatDialog} from '@angular/material/dialog';
import {RaceTypeVehicleType} from '../../../../../../forms/race-type-vehicle-type';
import {AppSettings} from '../../../../../../config/AppSettings';

@Component({
    selector: 'app-race-type-vehicle-type',
    templateUrl: './race-type-vehicle-type.component.html',
    styleUrls: ['./race-type-vehicle-type.component.css']
})
export class RaceTypeVehicleTypeComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
        this._select_caption_tpl = value;
        // this.setColumns();
        this.cdr.markForCheck();
    }

    public RaceTypeVehicleType: RaceTypeVehicleType;
    private subs: Array<Subscription> = [];

    public loading_text = '';
    public is_loading = true;
    public rows: any[];

    @Input()
    public type_id: number | string = 0;

    private _select_caption_tpl: ElementRef;

    public column_definition: any[];
    public is_admin: boolean;

    private channelStore: Store;
    public ext_obj = {};
    public require_filter = {index: 0, filter: []};

    @Output() public dataLoaded: EventEmitter<any> = new EventEmitter();

    constructor(private storeService: StoreService,
                private authService: AuthService,
                private router: Router,
                private cdr: ChangeDetectorRef,
                private notificationService: NotificationService,
                public dialog: MatDialog) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin)
            || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_rally_admin);

        this.RaceTypeVehicleType = new RaceTypeVehicleType(this.storeService, this.authService, 'edit');
        this.ext_obj = {type_id: Number(this.type_id)};
        this.is_loading = false;
        this.cdr.detectChanges();
    }

    public rowSelected($event: any) {
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }

    public dataLoadedDone(event) {
        this.dataLoaded.emit(event);
    }
}
