<h1 mat-dialog-title>Enter Details</h1>
<div mat-dialog-content *ngIf="!isLoading">
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>Set</mat-label>
            <input type="text" matInput [formControl]="inputSetControl" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{option.data.set_name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Number</mat-label>
            <input matInput type="number" formControlName="selected_count">
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-button (click)="onSubmit()" [disabled]="form.invalid">Submit</button>
</div>
