import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {StoreService} from '../../../../../services/store.service';
import {AuthService} from '../../../../../services/auth.service';
import {NotificationService} from '../../../../../services/notification.service';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ScbMembersPointsMining} from '../../../../../forms/scb-members-points-mining';
import {ScbMembersPointsTrading} from '../../../../../forms/scb-members-points-trading';
import {ScbMembersPointsSalvage} from '../../../../../forms/scb-members-points-salvage';
import {FbFormObject} from '../../../../../class/fb_form_object';
import {AppSettings} from '../../../../../config/AppSettings';

@Component({
  selector: 'app-members-points-edit',
  templateUrl: './members-points-edit.component.html',
  styleUrls: ['./members-points-edit.component.css']
})
export class MembersPointsEditComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
    this._select_caption_tpl = value;
    this.cdr.markForCheck();
  }

  public loading_text = '';
  public is_loading = true;
  public fields: any[];
  public mode = 'edit';
  public rows = [];
  public count = 0;
  public isAdmin = false;

  private subs: Array<Subscription> = [];
  private record: any;
  private id: string|number = '';
  private Store: FbFormObject;

  private _select_caption_tpl: ElementRef;

  constructor(private storeService: StoreService,
              private authService: AuthService,
              private notificationService: NotificationService,
              private route: ActivatedRoute,
              private cdr: ChangeDetectorRef,
              public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {

    this.isAdmin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_event_admin);

    this.subs.push(this.route.params.subscribe((params: any) => {
      if (params.id && params.id !== 'add') {
        this.mode = 'edit';
        this.id = params.id;
      } else {
        this.mode = 'add';
        // this.edit = true;
      }
      switch (params.type) {
        case 'mining':
          this.Store = new ScbMembersPointsMining(this.storeService, this.authService, this.mode);
          break;
        case 'salvage':
          this.Store = new ScbMembersPointsSalvage(this.storeService, this.authService, this.mode);
          break;
        case 'misc':
          this.Store = new ScbMembersPointsTrading(this.storeService, this.authService, this.mode);
          break;
      }

      const filter = [];
      filter.push('filter[id][0][mode]=equals');
      filter.push('filter[id][0][operator]=and');
      filter.push('filter[id][0][value]=' + this.id);
      filter.push('first=0');
      filter.push('rows=1');

      this.Store.init({select_caption_tpl: this._select_caption_tpl},
          {user_id: params.user_id},
          '?' + filter.join('&')).then((success) => {
        this.fields = this.Store.getEditFields();
        if (this.id && this.id !== '' && this.mode === 'edit') {
          const rec = this.Store.getStore().getRecordById(this.id);
          if (rec) {
            this.record = rec;
          }
        } else {
          this.resetModel();
          this.record.data.type = params.type;
          this.record.data.user_id = params.user_id;
        }
        this.rebuildForm();
        this.is_loading = false;
      });

    }));
  }

  public rebuildForm() {
    this.fields = [];
    this.cdr.detectChanges();
    this.fields = this.Store.getForm(this.record.data);
    this.cdr.detectChanges();
  }

  private resetModel() {
    this.record = this.Store.getDefaultModel();
  }

  public saveStore(event: any) {


    if (this.mode === 'edit') {
      this.Store.getStore().patchRecord(this.record, '').then((res) => {
        if (res && res.success) {
          this.notificationService.success('Data',
              'Speichern erfolgreich.', {timeOut: 5000});
          this.rebuildForm();
        } else {
          this.notificationService.error('Data',
              'Speichern fehlgeschlagen.', {timeOut: 5000});
        }
      });
    } else {
      this.Store.getStore().addRecord(this.record.data, '').then((res) => {
        if (res && res.success) {
          this.notificationService.success('Data',
              'Speichern erfolgreich.', {timeOut: 5000});
          this.resetModel();
          this.rebuildForm();
        } else {
          this.notificationService.error('Data',
              'Speichern fehlgeschlagen.', {timeOut: 5000});
        }
      });
    }
  }


  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
    if (this.mode === 'edit') {
      this.Store.getStore().revertRecord(this.record);
    }
  }
}
