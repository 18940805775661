<div class="" *ngIf="storeObj && !refresh">
    <p-table
        #dt
        [value]="filteredData"
        [columns]="selectedColumns"
        [rowHover]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="rowsPerPageOptions"
        [loading]="loading"
        [paginator]="true"
        [paginatorDropdownAppendTo]="'body'"
        currentPageReportTemplate="{first} bis {last} von {totalRecords} Einträgen"
        [filterDelay]="0"
        [breakpoint]="'624px'"
        responsiveLayout="stack"
        sortMode="multiple"
        (onFilter)="filterChanged($event)"
        [lazy]="true"
        (onLazyLoad)="lazyLoadData($event)"
        [totalRecords]="totalRecords ? totalRecords : 0"
        stateStorage="local"
        [stateKey]="stateKey"
        selectionMode="multiple"
        [(selection)]="selectedRows"
        (selectionChange)="rowSelectionChanged($event)"
        [globalFilterFields]="globalFilterFields"
        [resizableColumns]="resizableColumns"
        [reorderableColumns]="reorderableColumns && !edit"
        (onColReorder)="onColumnReorder($event)"
        columnResizeMode="expand"
        (onStateSave)="onStateSave($event)"
        [expandedRowKeys]="expandedRows"
        [dataKey]="datakey"
    >
        <ng-template pTemplate="caption">
            <div class="table-header">
                <div>
                    {{title}}
                </div>
                <ng-container *ngIf="header_tpl">
                    <ng-container
                        *ngTemplateOutlet="header_tpl">
                    </ng-container>
                </ng-container>
                <span class="p-input-icon-left">
<!--                    <i class="pi pi-search"></i>-->
<!--                    <mat-form-field>-->
<!--                        <input matInput type="text" [value]="globalFilterStr"-->
<!--                               (input)="dt.filterGlobal($event?.target['value'], 'contains')"-->
<!--                               placeholder="Suche" />-->
<!--                    </mat-form-field>-->
                    @if (cols && cols.length > 0) {
                        <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns"
                                       selectedItemsLabel="{0} Spalten"
                                       optionLabel="label"
                                       [disabled]="edit"
                                       [style]="{'min-width': '200px'}"
                                       placeholder="Choose Columns"></p-multiSelect>
                    }
                    <div style="float: right;" class="button-flex-container">

                        <button mat-fab color="accent" matTooltip="Filter l&ouml;schen"
                                (click)="clearFilter(dt)" *ngIf="default_model"
                                class="flex-button">
                            <mat-icon aria-hidden="false" aria-label="clear filter"
                                      fontIcon="filter_list_off"></mat-icon>
                        </button>

                        <button mat-fab color="warning" matTooltip="Tabelleneinstellungen l&ouml;schen"
                            (click)="clearTableSettings()" *ngIf="default_model"
                            class="flex-button">
                            <mat-icon aria-hidden="false" aria-label="clear table settings"
                                      fontIcon="view_column"></mat-icon>
                        </button>

                        <button mat-fab color="primary" matTooltip="Export"
                                (click)="exportRows()" *ngIf="!show_export && default_model"
                                class="flex-button">
                            <mat-icon aria-hidden="false" aria-label="export csv" fontIcon="cloud_download"></mat-icon>
                        </button>
                    </div>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngIf="expandAble" class="expand-column"
                    style="width: 64px !important; max-width: 64px !important;"></th>
                <th style="width: 4rem" *ngIf="!edit && (show_select || show_delete)">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <ng-container *ngFor="let column of columns">
                    <th [pSortableColumn]="'data.' + column.id"
                        class="no-wrap"
                        *ngIf="!column.noTable"
                        pResizableColumn
                        pReorderableColumn
                    >
                        {{column.label}}
                        <p-sortIcon [field]="'data.' + column.id" ></p-sortIcon>
                        <ng-container [ngSwitch]="column.field_type">
                            <ng-container *ngSwitchDefault>
                                <p-columnFilter [field]="'data.' + column.id" matchMode="in" display="menu"
                                                [showMatchModes]="true" [showOperator]="false"
                                                [matchMode]="'contains'"
                                                [showAddButton]="true" class="ml-auto">
                                </p-columnFilter>
<!--                                <p-columnFilter [field]="'data.' + column.id" display="menu" [showMatchModes]="true" [showOperator]="true" [showAddButton]="true" class="ml-auto">-->
<!--                                    <ng-template pTemplate="header">-->
<!--                                        <div class="px-3 pt-3 pb-0">-->
<!--                                            <span class="font-bold">{{column.label}}</span>-->
<!--                                        </div>-->
<!--                                    </ng-template>-->
<!--                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">-->
<!--                                        <p-calendar [ngModel]="value" [timeOnly]="true" inputId="timeonly" (onClose)="customFilterCallback(filter, value, column.field_type)"></p-calendar>-->
<!--                                    </ng-template>-->
<!--                                </p-columnFilter>-->
                            </ng-container>
                            <ng-container *ngSwitchCase="'number'">
                                <p-columnFilter [field]="'data.' + column.id" type="numeric" display="menu" [showOperator]="false">
                                </p-columnFilter>
                            </ng-container>

                            <ng-container *ngSwitchCase="'select'">
                                <p-columnFilter [field]="'data.' + column.id" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" class="ml-auto">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [options]="column.select_options"
                                                       placeholder="{{ 'primeng.matchAny' | translate }}"
                                                       (onChange)="customFilterCallback(filter, $event.value, column.field_type)"
                                                       optionLabel="name"
                                                       display="chip">
                                            <ng-template let-option pTemplate="item">
                                                <div class="inline-block vertical-align-middle">
                                                    <span class="ml-1 mt-1">{{ option.name }}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </ng-container>
                            <ng-container *ngSwitchCase="'select_group'">
                                <p-columnFilter [field]="'data.' + column.id" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" class="ml-auto">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [options]="column.select_groups" [group]="true"
                                                       placeholder="{{ 'primeng.matchAny' | translate }}"
                                                       (onChange)="customFilterCallback(filter, $event.value, column.field_type)"
                                                       optionLabel="label"
                                                       display="chip">
                                            <ng-template let-group pTemplate="group">
                                                <div class="flex align-items-center">
                                                    <span class="ml-1 mt-1">{{ group.label }}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </ng-container>
                            <ng-container *ngSwitchCase="'autocomplete'">
                                <p-columnFilter [field]="column.id" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" class="ml-auto">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [options]="column.select_options" placeholder="{{ 'primeng.matchAny' | translate }}" (onChange)="customFilterCallback(filter, $event.value, column.field_type)" optionLabel="name">
                                            <ng-template let-option pTemplate="item">
                                                <div class="inline-block vertical-align-middle">
                                                    <span class="ml-1 mt-1">{{ option.name }}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </ng-container>
                            <ng-container *ngSwitchCase="'chip_autocomplete'">
                                <p-columnFilter [field]="column.id" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" class="ml-auto">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [options]="column.select_options"
                                                       placeholder="{{ 'primeng.matchAny' | translate }}"
                                                       (onChange)="customFilterCallback(filter, $event.value, column.field_type)"
                                                       optionLabel="name"
                                                       display="chip">
                                            <ng-template let-option pTemplate="item">
                                                <div class="inline-block vertical-align-middle">
                                                    <span class="ml-1 mt-1">{{ option.name }}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </ng-container>
                            <ng-container *ngSwitchCase="'radio'">
                                <p-columnFilter [field]="'data.' + column.id" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" class="ml-auto">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [options]="column.select_options"
                                                       placeholder="{{ 'primeng.matchAny' | translate }}"
                                                       (onChange)="customFilterCallback(filter, $event.value, column.field_type)"
                                                       optionLabel="name"
                                                       display="chip">
                                            <ng-template let-option pTemplate="item">
                                                <div class="inline-block vertical-align-middle">
                                                    <span class="ml-1 mt-1">{{ option.name }}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </ng-container>
                            <ng-container *ngSwitchCase="'checkbox'">
                                <p-columnFilter [field]="'data.' + column.id" matchMode="boolEquals" display="menu"
                                                class="ml-auto" type="boolean">
                                </p-columnFilter>
                            </ng-container>
                            <ng-container *ngSwitchCase="'slide_toggle'">
                                <p-columnFilter [field]="'data.' + column.id" matchMode="boolEquals" display="menu"
                                                class="ml-auto" type="boolean">
                                </p-columnFilter>
                            </ng-container>
                            <ng-container *ngSwitchCase="'template'">
                            </ng-container>
                            <ng-container *ngSwitchCase="'date'">
                                <p-columnFilter type="date" [field]="'data.' + column.id"
                                                display="menu" class="ml-auto">
                                </p-columnFilter>
                            </ng-container>
                            <ng-container *ngSwitchCase="'datetime'">
                                <p-columnFilter [type]="'date'" [field]="'data.' + column.id" display="menu" [showMatchModes]="true" [showOperator]="false" [showAddButton]="true">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-calendar #calendar [ngModel]="value"
                                                    [showTime]="true"
                                                    (onSelect)="customFilterCallback(filter, calendar.value, column.field_type)" (onInput)="customFilterCallback(filter, calendar.value, column.field_type)"></p-calendar>
                                    </ng-template>
                                </p-columnFilter>
                            </ng-container>
                            <ng-container *ngSwitchCase="'time'">
                                <p-columnFilter [field]="'data.' + column.id" matchMode="in" display="menu"
                                                [showMatchModes]="true" [showOperator]="false"
                                                [showAddButton]="true" class="ml-auto">
                                </p-columnFilter>
                                <!--
                                <p-columnFilter [field]="'data.' + column.id" display="menu" [showMatchModes]="true" [showOperator]="true" [showAddButton]="true">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-calendar #calendar [ngModel]="value"
                                                    [timeOnly]="true"
                                                    (onSelect)="customFilterCallback(filter, calendar.value, column.field_type)" (onInput)="customFilterCallback(filter, calendar.value, column.field_type)" dateFormat="hh:ii"></p-calendar>
                                    </ng-template>
                                </p-columnFilter>
                                -->
                            </ng-container>
                        </ng-container>
                    </th>
                </ng-container>
            </tr>
        </ng-template>


        <ng-template pTemplate="body" let-row let-rowData let-columns="columns" let-expanded="expanded">
            <tr *ngIf="edit">
                <app-form-inputs-row style="display: contents;"
                                     #tpl_form
                                     [storeObjClone]="storeObj"
                                     [edit_allowed]="edit"
                                     [row]="row"
                                     [loading]="false"
                                     (on_change)="addChangedRow(row)"
                                     (on_DirtyChanged)="dirtyChanged($event, row)"
                >
                </app-form-inputs-row>
            </tr>
            <tr class="p-selectable-row" *ngIf="!edit" (click)="onRowClick($event, row)">
                <td *ngIf="expandAble" class="expand-column"
                    style="width: 64px !important; max-width: 64px !important;">
                    <p-button type="button" pRipple
                              [pRowToggler]="row"
                              [text]="true"
                              [rounded]="false"
                              [plain]="false"
                              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" />
                </td>
                <td (click)="$event.stopPropagation()" *ngIf="show_select || show_delete">
                    <p-tableCheckbox [value]="row"></p-tableCheckbox>
                </td>
                <ng-container *ngFor="let column of columns">
                    <td *ngIf="!column.noTable" [ngStyle]="{ 'max-width': column.max_width || 'auto', 'width': column.max_width || 'auto' }">
                        <span class="p-column-title">{{column.label}}</span>
                        <div [ngSwitch]="column.field_type" [ngClass]="{'no-wrap-left': (column.field_type !== 'textarea'
                            || column.field_type !== 'editor'),
                            'no-wrap-with-limit': (column.field_type === 'chip'
                            || column.field_type === 'chip_autocomplete'
                            || column.field_type === 'select'
                            )}">
                            <ng-container *ngSwitchDefault>
                                <ng-container [ngSwitch]="column.field_type">
                                    <ng-container *ngSwitchDefault>
                                        <ng-container *ngIf="!column.is_wikiLink">
                                            <span *ngIf="rowData.tmp?.caption[column.id]"
                                                  [innerHTML]="rowData.tmp?.caption[column.id]"
                                                  matTooltip="{{rowData.tmp?.caption[column.id]}}"
                                            ></span>
                                            <span *ngIf="!rowData.tmp?.caption[column.id]"
                                                  [innerHTML]="rowData.data[column.id]"
                                                  matTooltip="{{rowData.data[column.id]}}"
                                            ></span>
                                        </ng-container>
                                        <ng-container *ngIf="column.is_wikiLink">
                                            <app-sc-wiki-link [linkObj]="{link: rowData.data[column.id], name: rowData.data[column.wiki_link_name]}"></app-sc-wiki-link>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'number'">
                                        <span *ngIf="column.number_formatted">{{rowData.data[column.id] | number: column.number_format : 'de-DE'}}</span>
                                        <span *ngIf="!column.number_formatted">{{rowData.data[column.id]}}</span>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'checkbox'">
                                        <span *ngIf="rowData.data[column.id] && rowData.data[column.id] !== '0'">Ja</span>
                                        <span *ngIf="rowData.data[column.id] && rowData.data[column.id] === '0'">Nein</span>
                                        <span *ngIf="!rowData.data[column.id]">-</span>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'slide_toggle'">
                                        <span *ngIf="rowData.data[column.id] && rowData.data[column.id] !== '0'">Ja</span>
                                        <span *ngIf="rowData.data[column.id] && rowData.data[column.id] === '0'">Nein</span>
                                        <span *ngIf="!rowData.data[column.id]">-</span>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'date'">
                                        {{rowData.data[column.id] | date:'mediumDate':'': 'de'}}
                                    </ng-container>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngSwitchCase="'select'">
                                <ng-container *ngIf="column.look_up">
                                    <div matTooltip="{{column.look_up[rowData.data[column.id]]}}">
                                        {{column.look_up[rowData.data[column.id]]}}
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="'chip_autocomplete'">
                                <mat-chip *ngFor="let value of splitValue(rowData.data[column.id], ',')">
                                    {{column.look_up[value]}}
                                </mat-chip>
                            </ng-container>
                            <ng-container *ngSwitchCase="'chip'">
                                <mat-chip *ngFor="let value of splitValue(rowData.data[column.id], ',')">
                                    {{column.look_up[value]}}
                                </mat-chip>
                            </ng-container>

                            <ng-container *ngSwitchCase="'select_group'">
                                <ng-container *ngIf="column.look_up">
                                    <div matTooltip="{{column.look_up[rowData.data[column.id]]}}">
                                        {{column.look_up[rowData.data[column.id]]}}
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="'autocomplete'">
                                <ng-container *ngIf="column.look_up">
                                    <div matTooltip="{{column.look_up[rowData.data[column.id]]}}">
                                        {{column.look_up[rowData.data[column.id]]}}
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="'template'">
                                <ng-container
                                        *ngTemplateOutlet="column.template_row;context:{element: rowData, column:column, edit:edit}">
                                </ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="'date'">
                                <ng-container
                                        *ngTemplateOutlet="date_tpl;context:{element: rowData, column:column}">
                                </ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="'datetime'">
                                <ng-container
                                        *ngTemplateOutlet="datetime_tpl;context:{element: rowData, column:column}">
                                </ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="'time'">
                                <ng-container>
                                    {{rowData.data[column.id]}}
                                </ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="'editor'">
                                <ng-container>
                                    {{rowData.data[column.id] | noHtml}}
                                </ng-container>
                            </ng-container>
                        </div>
                    </td>
                </ng-container>

<!--                <td>-->
<!--                    <span class="p-column-title">Name</span>-->
<!--                    {{ customer.name }}-->
<!--                </td>-->
<!--                <td>-->
<!--                    <span class="p-column-title">Country</span>-->
<!--                    <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + customer.country.code" style="width: 20px" />-->
<!--                    <span class="ml-1 vertical-align-middle">{{ customer.country.name }}</span>-->
<!--                </td>-->
<!--                <td>-->
<!--                    <span class="p-column-title">Representative</span>-->
<!--                    <img [alt]="customer.representative.name" src="https://primefaces.org/cdn/primeng/images/demo/avatar/{{ customer.representative.image }}" width="32" style="vertical-align: middle" />-->
<!--                    <span class="ml-1 vertical-align-middle">{{ customer.representative.name }}</span>-->
<!--                </td>-->
<!--                <td>-->
<!--                    <span class="p-column-title">Date</span>-->
<!--                    {{customer.date | date:'mediumDate':'': 'de'}}-->
<!--                </td>-->
<!--                <td>-->
<!--                    <span class="p-column-title">Balance</span>-->
<!--                    {{ customer.balance | currency: 'USD':'symbol' }}-->
<!--                </td>-->
<!--                <td>-->
<!--                    <span class="p-column-title">Status</span>-->
<!--                    <p-tag [value]="customer.status" [severity]="getSeverity(customer.status)"></p-tag>-->
<!--                </td>-->
<!--                <td>-->
<!--                    <span class="p-column-title">Activity</span>-->
<!--                    <p-progressBar [value]="customer.activity" [showValue]="false"></p-progressBar>-->
<!--                </td>-->
<!--                <td style="text-align: center">-->
<!--                    <button pButton type="button" class="p-button-secondary" icon="pi pi-cog"></button>-->
<!--                </td>-->
            </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-element let-row>
<!--            {{row | json}}-->
            <tr>
                <td [attr.colspan]="column_definition.length + 2">
            <ng-container
                    *ngTemplateOutlet="expand_tpl; context:{$implicit: row}">
            </ng-container>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr *ngIf="column_definition">
                <td [attr.colspan]="column_definition.length + 1">No data found.</td>
            </tr>
        </ng-template>

        <ng-template pTemplate="paginatorleft">
            <div class="table-buttons-bottom">
                <ng-container *ngIf="!edit && show_add">
                    <button mat-mini-fab class="button-add"
                            matTooltip="neu" (click)="addClicked()">
                        <mat-icon aria-label="neu">add</mat-icon>
                    </button>
                    <span class="button-spacer"></span>
                </ng-container>
                <ng-container *ngIf="show_add_in_view">
                    <button mat-mini-fab class="button-add"
                            matTooltip="neu" (click)="addInTableClicked()">
                        <mat-icon aria-label="neu">add</mat-icon>
                    </button>
                    <span class="button-spacer"></span>
                </ng-container>
<!--                <ng-container *ngIf="!edit">-->
<!--                    <button mat-mini-fab class="button-refresh" (click)="reloadStore()"-->
<!--                            matTooltip="neuladen">-->
<!--                        <mat-icon aria-label="neuladen">refresh</mat-icon>-->
<!--                    </button>-->
<!--                    <span class="button-spacer"></span>-->
<!--                </ng-container>-->
                <ng-container *ngIf="!edit && filteredData && filteredData.length && edit_allowed">
                    <button mat-mini-fab class="button-edit" (click)="showEdit()"
                            matTooltip="bearbeiten">
                        <mat-icon aria-label="bearbeiten">edit</mat-icon>
                    </button>
                    <span class="button-spacer"></span>
                </ng-container>
                <ng-container *ngIf="edit && edit_allowed">
                    <button mat-mini-fab class="button-cancel" (click)="cancelRowInput()"
                            matTooltip="verwerfen">
                        <mat-icon aria-label="verwerfen">cancel</mat-icon>
                    </button>
                    <span class="button-spacer"></span>
                </ng-container>
                <ng-container *ngIf="edit && edit_allowed && changed_rows && changed_rows.length > 0 && !isDirty">
                    <button mat-mini-fab class="button-add" (click)="saveRows()"
                            matTooltip="speichern">
                        <mat-icon aria-label="speichern">save</mat-icon>
                    </button>
                </ng-container>
                <ng-container *ngIf="selectedRows && selectedRows.length > 0 && !edit && show_delete">
                    <button mat-mini-fab class="button-delete" (click)="deleteSelection()"
                            matTooltip="löschen">
                        <mat-icon aria-label="löschen">delete</mat-icon>
                    </button>
                    <span class="button-spacer"></span>
                </ng-container>
                <ng-container *ngIf="footer_tpl">
                    <ng-container
                            *ngTemplateOutlet="footer_tpl">
                    </ng-container>
                </ng-container>
<!--                <ng-container *ngIf="selection.selected.length > 0 && !edit && !is_small_device && show_active">-->
<!--                    <ng-container *ngIf="filter_active_value">-->
<!--                        <button mat-mini-fab class="button-delete" (click)="toggleRowsActiveState()"-->
<!--                                matTooltip="deaktivieren" [@fadeInOut]>-->
<!--                            <mat-icon aria-label="" *ngIf="filter_active_value">check_box_outline_blank</mat-icon>-->
<!--                        </button>-->
<!--                    </ng-container>-->
<!--                    <ng-container *ngIf="!filter_active_value">-->
<!--                        <button mat-mini-fab class="button-delete" (click)="toggleRowsActiveState()"-->
<!--                                matTooltip="aktivieren" [@fadeInOut]>-->
<!--                            <mat-icon aria-label="" *ngIf="!filter_active_value">check_box</mat-icon>-->
<!--                        </button>-->
<!--                    </ng-container>-->
<!--                    <span class="button-spacer"></span>-->
<!--                </ng-container>-->
            </div>
        </ng-template>
    </p-table>
</div>


<ng-template #select_column_header let-column="column">
<!--    <ng-container *ngIf="!edit && (show_delete || show_active)">-->
<!--        <div (click)="cancelRowSelect($event, null)">-->
<!--            <mat-checkbox (change)="$event ? masterToggle() : null"-->
<!--                          [checked]="selection.hasValue() && isAllSelected()"-->
<!--                          [indeterminate]="selection.hasValue() && !isAllSelected()">-->
<!--            </mat-checkbox>-->
<!--        </div>-->
<!--    </ng-container>-->
</ng-template>
<ng-template #select_column_row let-element="element" let-column="column">
<!--    <ng-container *ngIf="!edit && (show_delete || show_active)">-->
<!--        <div (click)="cancelRowSelect($event, element)">-->
<!--            <mat-checkbox (click)="$event.stopPropagation()"-->
<!--                          (change)="toggleRowSelection($event, element)"-->
<!--                          [checked]="selection.isSelected(element)">-->
<!--            </mat-checkbox>-->
<!--        </div>-->
<!--    </ng-container>-->
</ng-template>
<ng-template #expand_column_header let-column="column"></ng-template>
<ng-template #expand_column_row let-element="element" let-column="column">
    <ng-container>
<!--        <div>-->
<!--            <button mat-icon-button aria-label=""-->
<!--                    (click)="expandedElement = _expandedElement === element ? null : element">-->
<!--                <mat-icon *ngIf="_expandedElement === element">expand_less</mat-icon>-->
<!--                <mat-icon *ngIf="_expandedElement !== element">expand_more</mat-icon>-->
<!--            </button>-->
<!--        </div>-->
    </ng-container>
</ng-template>
<ng-template #spacer_column_header let-column="column"></ng-template>
<ng-template #spacer_column_row let-element="element" let-column="column"></ng-template>
<ng-template #date_tpl let-element="element" let-column="column">
    {{element.data[column.id] | date:'mediumDate':'': 'de'}}
</ng-template>
<ng-template #datetime_tpl let-element="element" let-column="column">
    <div *ngIf="element.data[column.id] !== '0000-00-00 00:00:00' && element.data[column.id] != 'NaN-NaN-NaN'">
        {{element.data[column.id] | date:'medium':'': 'de'}}
    </div>
</ng-template>
<ng-template #time_tpl let-element="element" let-column="column">
    {{element.data[column.id] | date:'HH:mm'}}
</ng-template>
<ng-template #select_caption_tpl let-element="element" let-column="column">
    {{element.tmp.caption[column.id]}}
</ng-template>
<ng-template #add_form_tpl let-element="element" let-column="column">
    <div class="padding" *ngIf="!saving">
        <app-form-inputs *ngIf="addFields && addFields.length && !saving"
                         #tpl_form
                         [field_groups]="addFields"
                         [edit_allowed]="true"
                         [loading]="false"
                         (on_submit)="saveStore($event)"
                         [button_class]="'button-save'">
        </app-form-inputs>
    </div>
    <div *ngIf="saving">
        <div class="centered-spinner">
            <div style="max-width: 400px; min-width: 400px;">
                Saving
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #clear_form_tpl let-element="element" let-column="column">
</ng-template>
