import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {ScContractCommodityForm} from '../../../forms/scContractCommodityForm';
import {StoreService} from '../../../services/store.service';
import {AuthService} from '../../../services/auth.service';
import {TableItemPickerComponent} from '../../../shared/dialoge/table-item-picker/table-item-picker.component';
import {MediaShort} from '../../../forms/media-short';
import {AppSettings} from '../../../config/AppSettings';
import {ScCommoditySetForm} from '../../../forms/scCommoditySetForm';
import {HttpClient} from '@angular/common/http';
import {FbUtils} from '../../../class/fb-utils';
import {map} from 'rxjs/operators';
import {ContractSetPickerComponent} from '../../../shared/dialoge/contract-set-picker/contract-set-picker.component';

@Component({
    selector: 'app-sc-contract-commodities',
    templateUrl: './sc-contract-commodities.component.html',
    styleUrls: ['./sc-contract-commodities.component.css']
})
export class ScContractCommoditiesComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input()
    public c_id = 0;

    @Input()
    public is_owner: boolean;

    @Input()
    public add_to_inventory;

    @Input()
    public contract_type: string;

    @Output()
    public after_save: EventEmitter<any> = new EventEmitter();

    public storeObject: ScContractCommodityForm;
    private subs: Array<Subscription> = [];

    public loading_text = '';
    public is_loading = false;
    public rows: any[];

    public column_definition: any[];


    constructor(private storeService: StoreService,
                private authService: AuthService,
                private cdr: ChangeDetectorRef,
                public dialog: MatDialog,
                public http: HttpClient) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.storeObject = new ScContractCommodityForm(this.storeService, this.authService, 'edit');
        this.cdr.detectChanges();
    }

    public rowSelected($event: any) {
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }

    afterSave() {
        if (this.after_save) {
            this.after_save.emit();
        }
    }

    openAddSetDialog() {
        const dialogRef = this.dialog.open(ContractSetPickerComponent, {
            width: '250px',
            data: { /* any data you want to pass to the dialog */ }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.selected_set.data.set_id > 0 && result.selected_count > 0) {
                this.is_loading = true;
                return this.http.post(FbUtils.formatUrlForApi(AppSettings.getRealAPILink() + 'sc-contracts/' + this.c_id + '/add-set'),
                    {
                        c_id: this.c_id,
                        set_id: result.selected_set.data.set_id,
                        count: result.selected_count
                    }
                    ).subscribe(data => {
                    this.is_loading = false;
                });
            }
        });
    }
}
