import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  public breadcrumb = '';
  public breadcrumb_changed: BehaviorSubject<any>;

  constructor() {

    this.breadcrumb_changed = new BehaviorSubject(this.breadcrumb);
  }

  public setNewBreadCrumb(breadcrumb: string) {
    this.breadcrumb = breadcrumb;
    this.breadcrumb_changed.next(this.breadcrumb);
  }

}
