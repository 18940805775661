<div>
    <app-content-container [content_template]="page_content"
                           [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div class="padding">
        <div class="card flex gap-3 align-items-center justify-content-end">
            <div style="padding-right: 16px;">
                <ng-container>
                    <div class="top-search-container">
                        <mat-form-field style="float: right;">
                            <mat-label>Search</mat-label>
                            <input matInput
                                   placeholder="search"
                                   [(ngModel)]="searchText"
                                   (input)="onSearch()"
                                   class="search"/>
                            <mat-icon matSuffix>search</mat-icon>
                        </mat-form-field>
                        <mat-form-field style="margin-right: 16px; float: right;">
                            <mat-select [formControl]="tags"
                                        multiple (selectionChange)="onTagChanged($event)"
                                        placeholder="tags">
                                <mat-select-trigger>
                                    {{tags.value?.[0] || ''}}
                                    <span *ngIf="(tags.value?.length || 0) > 1" class="example-additional-selection">
                                    (+{{(tags.value?.length || 0) - 1}} {{tags.value?.length === 2 ? 'other' : 'others'}})
                                </span>
                                </mat-select-trigger>
                                <mat-option *ngFor="let tag of tagsList" [value]="tag">{{tag}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="" *ngIf="store">
            <div style="padding-bottom: 32px; margin-top: 24px;">
                <div *ngIf="is_loading">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
                <main class="tiles" *ngIf="rows">
                    <ng-container *ngFor="let poi of rows">
                        <a routerLink="/points-of-interest/{{poi.data.poi_id}}" class="link-clear">
                            <app-poi-tile title="{{poi.data.title}}" icon="fas fa-calendar"
                                                      [style]="{'background-image': 'url(' + media_path +  'media/' + poi.data.file_id + '/show)'}"
                                                      [info_data]="poi.data"
                                                      [customClass]="'bg-card'"></app-poi-tile>
                        </a>
                    </ng-container>
                </main>
            </div>
        </div>
        <div class="card flex gap-3 align-items-center justify-content-space-between">
            <div class="" style="padding-left: 16px;">
                <ng-container *ngIf="is_ifa">
                    <button mat-mini-fab class="button-add"
                            matTooltip="neu" (click)="addClicked()">
                        <mat-icon aria-label="neu">add</mat-icon>
                    </button>
                    <span class="button-spacer"></span>
                </ng-container>
            </div>
            <p-paginator [first]="first"
                         [rows]="pageSize"
                         [totalRecords]="totalRecords"
                         [rowsPerPageOptions]="[10, 25, 50]"
                         [showCurrentPageReport]="false"
                         currentPageReportTemplate="{first} - {last} of {totalRecords}"
                         (onPageChange)="onPageChange($event)"
                         [showJumpToPageDropdown]="true"
                         [showPageLinks]="false">
            </p-paginator>
        </div>
    </div>
</ng-template>
<ng-template #select_caption_tpl let-element="element" let-column="column">
    {{element.tmp.caption[column.id]}}
</ng-template>
