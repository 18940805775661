import { Component } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {StoreService} from '../../services/store.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-upcoming-events',
  templateUrl: './upcoming-events.component.html',
  styleUrls: ['./upcoming-events.component.css']
})
export class UpcomingEventsComponent {
  public markdown = '## Upcoming Events';
  public records: any;

  constructor(private authService: AuthService, private storeService: StoreService, public router: Router) {
    const NewsStore = this.storeService.getStore('upcoming_events');
    NewsStore.loadStore('').then((records) => {
      this.records = records.sort((a, b) => {
        if ( a.data.scheduled_start_time < b.data.scheduled_start_time ) {
          return -1;
        }
        if ( a.data.scheduled_start_time > b.data.scheduled_start_time ) {
          return 1;
        }
        return 0;
      });
    });
  }
}
