<div class="confirm-container dialog-comment">
    <mat-toolbar class="shadow-bottom">
        <h1 mat-dialog-title>{{data.title}}</h1>
    </mat-toolbar>
    <div class="confirm-content padding">
        <mat-selection-list #entries>
            <mat-list-option *ngFor="let entry of list_entries" [value]="entry.data[key]">
                {{entry.data.Name}}
            </mat-list-option>
        </mat-selection-list>

        <p>
            Options selected: {{entries.selectedOptions.selected.length}}
        </p>
        <div mat-dialog-actions class="dialog-footer-bar">
            <button mat-raised-button [mat-dialog-close]="{result: false}"
                    cdkFocusInitial class="button-cancel" style="float: left; margin-left: 16px;">abbrechen
            </button>
            <button mat-raised-button (click)="saveRecord()"
                    class="button-confirm" style="float: right; margin-right: 16px;"
                    [disabled]="entries.selectedOptions.selected.length === 0">speichern
            </button>
        </div>
    </div>
</div>
