import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-discord-auth-point',
  templateUrl: './discord-auth-point.component.html',
  styleUrls: ['./discord-auth-point.component.css']
})
export class DiscordAuthPointComponent implements OnInit {

  private subs: Array<Subscription> = [];

  constructor(private authService: AuthService, private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.subs.push(this.route.params.subscribe((params: any) => {
      if (params.state && params.code) {
        this.authService.startOneTimeTokenLogin(params.state, params.code);
      }
    }));
  }

}
