<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading" [loading_text]="loading_text"
                           [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div>
        <h3 class="group-caption">Live</h3>
        <main class="tiles padding" *ngIf="records">
            <ng-container *ngFor="let track of records">
                <a routerLink="/rally/race-tracks/{{track.data.track_id}}" class="link-clear" *ngIf="!track.data.is_arena_commander">
                    <app-race-track-info-tile title="{{track.data.track_name}}" icon="fas fa-calendar"
                                                       [style]="{'background-image': 'url(' + media_path +  'media/' + track.data.file_id + '/show)'}"
                                                       [info_data]="track.data"
                                                       [customClass]="'bg-card'"></app-race-track-info-tile>
                </a>
            </ng-container>
        </main>
        <h3 class="group-caption">Arena Commander</h3>
        <main class="tiles padding" *ngIf="records">
            <ng-container *ngFor="let track of records">
                <a routerLink="/rally/race-tracks/{{track.data.track_id}}" class="link-clear" *ngIf="track.data.is_arena_commander">
                    <app-race-track-info-tile title="{{track.data.track_name}}" icon="fas fa-calendar"
                                              [style]="{'background-image': 'url(' + media_path +  'media/' + track.data.file_id + '/show)'}"
                                              [info_data]="track.data"
                                              [customClass]="'bg-card'"></app-race-track-info-tile>
                </a>
            </ng-container>
        </main>
    </div>
</ng-template>
