<div class="padding">
    <div class="card card-1 padding table-wrapper" *ngIf="Store">
        <app-fb-forms-table [storeObj]="Store"
                            [edit_allowed]="true"
                            [require_filter]="require_filter"
                            [storeExtObj]="storeExtObj"
                            [storeTemplates]="storeTemplates"
                            (row_click)="rowSelected($event)"
                            (add_click)="addClicked()"
                            [stateKey]="'sc-' + com_type"
                            [show_add]="true"
                            [show_delete]="true"
                            [title]="com_type"
        >
        </app-fb-forms-table>
    </div>
</div>
<ng-template #select_caption_tpl let-element="element" let-column="column">
    {{element.tmp.caption[column.id]}}
</ng-template>
