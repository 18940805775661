import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {StoreService} from '../../../../services/store.service';
import {AuthService} from '../../../../services/auth.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../../../services/notification.service';
import {MatDialog} from '@angular/material/dialog';
import {AppSettings} from '../../../../config/AppSettings';
import {FbFormObject} from '../../../../class/fb_form_object';
import {IfaBadge} from '../../../../forms/ifa-badge';
import {FileUpload2Component} from '../../../../shared/file-upload2/file-upload2.component';
import {IfaMemberBadgeOverview} from '../../../../forms/ifa-member-badge-overview';
import {FbUtils} from '../../../../class/fb-utils';
import {map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-ifa-badges',
  templateUrl: './ifa-badges.component.html',
  styleUrls: ['./ifa-badges.component.css']
})
export class IfaBadgesComponent implements OnInit, OnDestroy, AfterViewInit {


  @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
    this._select_caption_tpl = value;
    // this.setColumns();
    this.cdr.markForCheck();
  }

  @ViewChild('upload_tpl', {static: true})  public upload_tpl: ElementRef;

  public storeObj: FbFormObject;
  public storeAssignedObj: FbFormObject;
  private subs: Array<Subscription> = [];

  public loading_text = '';
  public is_loading = true;
  public rows: any[];

  public files = [];
  public max_file_count = 5;
  public url_file_store = AppSettings.getRealAPILink() + 'media';
  private file_upload: FileUpload2Component;

  private _select_caption_tpl: ElementRef;

  public is_admin: boolean;

  constructor(private storeService: StoreService,
              private authService: AuthService,
              private router: Router,
              private cdr: ChangeDetectorRef,
              private notificationService: NotificationService,
              public dialog: MatDialog,
              private http: HttpClient) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin);

    this.storeObj = new IfaBadge(this.storeService, this.authService, 'edit');
    this.storeObj.setTemplates({upload_tpl: this.upload_tpl});

    this.storeAssignedObj = new IfaMemberBadgeOverview(this.storeService, this.authService, 'edit');

    this.is_loading = false;
    this.cdr.detectChanges();
  }

  public rowSelected($event: any) {
  }

  public onFileuploadInit(file_upload: FileUpload2Component) {
    this.file_upload = file_upload;
  }

  public onFileUpload($event, type, element) {
    if ($event.ok) {
      // this.FileStore.reloadStore('').then(records => {
      this.notificationService.success('Daten',
          'Speichern erfolgreich.', {timeOut: 5000});
      this.router.navigateByUrl('admin/ifa/badges');
      const rec = this.storeObj.getStore().getRecordById(element.model.obj.log_id);
      rec.data.file_id = $event.body.rows[0].file_id;
      // });
    } else {
      this.notificationService.error('Daten',
          'Speichern fehlgeschlagen.', {timeOut: 5000});
      this.cdr.detectChanges();
    }
  }

  deleteFile(file_id: number, data: any) {
    return this.http.delete(FbUtils.formatUrlForApi(AppSettings.getRealAPILink() + 'media/' + file_id)).pipe(
        map((user: any) => {
        })
    ).subscribe((result) => {
      data.file_id = 0;
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
