import { Component } from '@angular/core';

@Component({
  selector: 'app-simple-edit',
  templateUrl: './simple-edit.component.html',
  styleUrls: ['./simple-edit.component.css']
})
export class SimpleEditComponent {

}
