import { Injectable } from '@angular/core';
import {Gadget, Laser, Ship} from '../interfaces/mining-loadout';

@Injectable({
    providedIn: 'root'
})
export class MiningLoadoutService {

    selectedShip: Ship;
    lasers: Laser[] = [];
    gadget: Gadget;
    gadget_enabled = false;

    constructor() { }

    generateShareableLink(): string {
        console.log('laser', this.lasers);
        const config = {
            shipId: this.selectedShip.id,
            lasers: this.lasers.map(laser => ({
                laser: laser.laser,
                is_active: laser.is_active,
                moduleIds: laser.moduleIds,
                moduleStates: laser.moduleStates,
                position: laser.position,
                module_slot_count: laser.slots,
                size: laser.size
            })),
            gadgetId: this.gadget?.gadget,
            gadget_enabled: this.gadget_enabled
        };
        console.log('config', config);
        const configString = JSON.stringify(config);
        const encodedConfig = btoa(configString); // Base64 encoding
        return `${window.location.origin}/#/mining-loadouts-calc?config=${encodedConfig}`;
    }
}
