import {Component} from '@angular/core';
import {CookieService} from '../../services/cookie.service';

@Component({
    selector: 'app-cookie-consent',
    templateUrl: './cookie-consent.component.html',
    styleUrls: ['./cookie-consent.component.css']
})
export class CookieConsentComponent {

    consent_sc_wiki = false;
    consent_discord = false;
    consent_youtube = false;
    consent_reddit = false;
    consent_rsi = false;
    consent_fleetyards = false;

    constructor(private cookieService: CookieService) {
        this.consent_sc_wiki = this.cookieService.getConsent('sc_wiki');
        this.consent_discord = this.cookieService.getConsent('discord');
        this.consent_youtube = this.cookieService.getConsent('youtube');
        this.consent_reddit = this.cookieService.getConsent('reddit');
        this.consent_rsi = this.cookieService.getConsent('rsi');
        this.consent_fleetyards = this.cookieService.getConsent('fleetyards');
    }

    public getProviderName(provider_key: string) {
        return this.cookieService.getProviderName(provider_key);
    }

    confirm() {
        this.cookieService.setConsent('sc_wiki', this.consent_sc_wiki);
        this.cookieService.setConsent('discord', this.consent_discord);
        this.cookieService.setConsent('youtube', this.consent_youtube);
        this.cookieService.setConsent('reddit', this.consent_reddit);
        this.cookieService.setConsent('rsi', this.consent_rsi);
        this.cookieService.setConsent('fleetyards', this.consent_fleetyards);
        this.cookieService.closeConsent();
    }

    refuseAll() {
        this.consent_sc_wiki = false;
        this.consent_discord = false;
        this.consent_youtube = false;
        this.consent_reddit = false;
        this.consent_rsi = false;
        this.consent_fleetyards = false;
        this.confirm();
    }

}
