import {Injectable, TemplateRef} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RightSidenavService {

    private sidenav: any;
    side_template: TemplateRef<any>;
    private side_template_changed: BehaviorSubject<any>;
    private is_open_changed: BehaviorSubject<boolean>;

    constructor() {
        this.side_template_changed = new BehaviorSubject(null);
        this.is_open_changed = new BehaviorSubject(false);
    }

    public setSidenav(sidenav: any) {
        this.sidenav = sidenav;
    }

    public getSidenav(): any {
        return this.sidenav;
    }

    public setSideTemplate(template: TemplateRef<any>) {
        this.side_template_changed.next(template);
    }

    public getSideTemplate(): BehaviorSubject<any> {
        return this.side_template_changed;
    }

    public OpenChanged() {
        return this.is_open_changed;
    }

    public setOpenState(value: boolean) {
        this.is_open_changed.next(value);
    }
}
