<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <mat-tab-group mat-align-tabs="start" color="accent" LastActiveTabDirective="bot-event">
        <mat-tab label="Event Data">
            <div class="padding">
                <app-form-inputs *ngIf="fields && fields.length"
                                 #tpl_form
                                 [field_groups]="fields"
                                 [edit_allowed]="mode === 'add' || editAllowed"
                                 [loading]="false"
                                 (on_submit)="saveStore($event)"
                                 [button_class]="'button-save'"
                                 (on_change)="onFormChanged($event)"
                                 [show_save_button]="show_save_button"
                >
                </app-form-inputs>
            </div>
        </mat-tab>
        <mat-tab label="Summary"
                 *ngIf="id && mode === 'edit' && record && record.data.event_id > 0">
            <app-bot-event-summary [event_id]="record.data.event_id">
            </app-bot-event-summary>
        </mat-tab>
        <mat-tab label="Member {{memberDataElement ? '(' + memberDataElement.totalRecords + ')' : ''}}"
                 *ngIf="id && mode === 'edit' && record && record.data.event_id > 0">
            <app-bot-event-member [event_id]="record.data.event_id"
                                  (dataLoaded)="memberDataLoadedDone($event)"
                                  [editAllowed]="editAllowed"
            ></app-bot-event-member>
        </mat-tab>
        <mat-tab label="Deposits {{depositDataElement ? '(' + depositDataElement.totalRecords + ')' : ''}}"
                 *ngIf="id && mode === 'edit' && record && (['mining', 'salvage', 'industrial'].includes(record.data.event_type)) && record.data.event_id > 0">
            <app-bot-event-deposit-tiles [event_id]="record.data.event_id"
                                         (dataLoaded)="depositDataLoadedDone($event)">
            </app-bot-event-deposit-tiles>
        </mat-tab>
        <mat-tab label="Ore Delivery {{oreDeliveryDataElement ? '(' + oreDeliveryDataElement.totalRecords + ')' : ''}}"
                 *ngIf="id && mode === 'edit' && record && (['mining', 'salvage', 'industrial'].includes(record.data.event_type)) && record.data.event_id > 0">
            <app-bot-event-commodity-delivery [event_id]="record.data.event_id"
                                        [editAllowed]="editAllowed"
                                        [com_type]="'ore'"
                                        [event_base_version]="record.data.event_base_version"
                                        (dataLoaded)="oreDeliveryDataLoadedDone($event)">
            </app-bot-event-commodity-delivery>
        </mat-tab>
        <mat-tab label="Refinery Fee {{oreRefineryFeeDataElement ? '(' + oreRefineryFeeDataElement.totalRecords + ')' : ''}}"
                 *ngIf="id && mode === 'edit' && record && (['mining', 'salvage', 'industrial'].includes(record.data.event_type)) && record.data.event_id > 0">
            <app-bot-event-commodity-delivery [event_id]="record.data.event_id"
                                              [editAllowed]="editAllowed"
                                              [com_type]="'refinery_fee'"
                                              [event_base_version]="record.data.event_base_version"
                                              (dataLoaded)="oreRefineryFeeDataLoadedDone($event)">
            </app-bot-event-commodity-delivery>
        </mat-tab>

        <mat-tab label="Cargo Delivery {{cargoDeliveryDataElement ? '(' + cargoDeliveryDataElement.totalRecords + ')' : ''}}"
                 *ngIf="id && mode === 'edit' && record && (['salvage', 'industrial', 'trading'].includes(record.data.event_type)) && record.data.event_id > 0">
            <app-bot-event-commodity-delivery [event_id]="record.data.event_id"
                                              [editAllowed]="editAllowed"
                                              [com_type]="'cargo'"
                                              (dataLoaded)="cargoDeliveryDataLoadedDone($event)">
            </app-bot-event-commodity-delivery>
        </mat-tab>
        <mat-tab label="Bonus {{extraPaymentDataElement ? '(' + extraPaymentDataElement.totalRecords + ')' : ''}}"
                 *ngIf="id && mode === 'edit' && record && record.data.event_id > 0">
            <app-bot-event-commodity-delivery [event_id]="record.data.event_id"
                                              [editAllowed]="editAllowed"
                                              [com_type]="'extra'"
                                              (dataLoaded)="extraPaymentDataLoadedDone($event)">
            </app-bot-event-commodity-delivery>
        </mat-tab>
<!--        <mat-tab label="Member Points"-->
<!--                 *ngIf="id && mode === 'edit' && record && record.data.event_id > 0 && isIfa">-->
<!--            <app-bot-event-points [event_id]="record.data.event_id"-->
<!--                                  [event_type]="record.data.event_type"-->
<!--                                  [editAllowed]="editAllowed">-->
<!--            </app-bot-event-points>-->
<!--        </mat-tab>-->
    </mat-tab-group>
</ng-template>
