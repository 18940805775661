<div class="confirm-container">
    <mat-toolbar class="shadow-bottom" color="warn">
        <h1 mat-dialog-title>{{data.title}}</h1>
    </mat-toolbar>
        <div class="confirm-content">
        {{data.text}}
        </div>
    <div mat-dialog-actions class="dialog-footer-bar">
        <button mat-raised-button [mat-dialog-close]="false" cdkFocusInitial class="button-cancel" style="float: left; margin-left: 16px;">abbrechen</button>
        <button mat-raised-button [mat-dialog-close]="true" class="button-confirm" style="float: right; margin-right: 16px;">bestätigen</button>
    </div>
</div>
