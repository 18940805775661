import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ScCommodityInventoryForm} from '../../../forms/scCommodityInventoryForm';
import {Subscription} from 'rxjs';
import {StoreService} from '../../../services/store.service';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {AppSettings} from '../../../config/AppSettings';
import {FbUtils} from '../../../class/fb-utils';
import {HttpClient} from '@angular/common/http';
import {InventoryBookingComponent} from '../../../shared/dialoge/invetory-booking/inventory-booking.component';
import {ContractSetPickerComponent} from '../../../shared/dialoge/contract-set-picker/contract-set-picker.component';

@Component({
    selector: 'app-sc-commodity-inventory-type',
    templateUrl: './sc-commodity-inventory-type.component.html',
    styleUrls: ['./sc-commodity-inventory-type.component.css']
})
export class ScCommodityInventoryTypeComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input()
    public type = 'cargo';


    public storeObject: ScCommodityInventoryForm;
    private subs: Array<Subscription> = [];

    public loading_text = '';
    public is_loading = false;
    public rows: any[];
    public selectedRows: any[] = [];


    private _select_caption_tpl: ElementRef;

    public column_definition: any[];
    public is_admin: boolean;
    public is_ifa = false;


    constructor(private storeService: StoreService,
                private authService: AuthService,
                private router: Router,
                private cdr: ChangeDetectorRef,
                public dialog: MatDialog,
                public http: HttpClient) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin)
            || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_contract_admin);
        this.is_ifa = this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_ifa);
        this.storeObject = new ScCommodityInventoryForm(this.storeService, this.authService, 'edit');
        this.cdr.detectChanges();
    }

    public rowSelected($event: any) {
        const route = 'sc-commodity-inventory/type/' + $event.row.c_type + '/' + $event.row.commodity;
        if ($event.in_new_tab) {
            const url = this.router.createUrlTree([route]);
            const serializedUrl = this.router.serializeUrl(url);

            // Insert `#` between the domain and route
            const modifiedUrl = '#/' + serializedUrl;
            window.open(modifiedUrl, '_blank');
        } else {
            this.router.navigateByUrl(route);
        }
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }

    openMultiLogDialog() {
        const dialogRef = this.dialog.open(InventoryBookingComponent, {
            width: '250px',
            data: { /* any data you want to pass to the dialog */}
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            console.log(result); // This will print the form values {autocomplete: '...', numberField: ...}
            if (result && result.action !== '' && result.value > 0) {
                this.is_loading = true;
                let send_done_count = 0;
                for (let i = 0; i < this.selectedRows.length; i++) {
                    const row = this.selectedRows[i].data;
                    this.http.post(FbUtils.formatUrlForApi(AppSettings.getRealAPILink() +
                            'sc-commodity-inventory/type/' + row.c_type + '/'  + row.commodity + '/log'),
                        {
                            commodity: row.commodity,
                            action: result.action,
                            c_id: 0,
                            value: result.value,
                            user_id: result.sender.data.user_id,
                            receiver: result.receiver.data.user_id,
                            note: result.note
                        }
                    ).subscribe(data => {
                        send_done_count++;
                        if (send_done_count === this.selectedRows.length) {
                            this.is_loading = false;
                        }
                    });
                }
            }
        });
    }

    rowSelectionChanged($event: any) {
        console.log('selectedRows', $event);
        this.selectedRows = $event;
    }
}
