import {TranslateLoader} from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';


export class TranslationLoader implements TranslateLoader {
    constructor(private http: HttpClient) {
    }

    getTranslation(lang: string): Observable<any> {
        const lang_json = './assets/i18n/' + lang + '.json';
        return this.http.get(lang_json);
    }
}
