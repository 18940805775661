<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading" [loading_text]="loading_text"
                           [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <mat-tab-group mat-align-tabs="start" color="accent" LastActiveTabDirective="vehicle-details" *ngIf="vehicle">
        <mat-tab label="Vehicle Details">
            <div class="padding">
                <div style="float: left; width: calc(100vw - 460px);">
                    <h2>{{vehicle.name}}</h2>
                    <div class="card card-1">
                        <div class="fy-vehicle-tile"
                             style="background-image: url({{vehicle.storeImageLarge}}); height: 662px;">
                        </div>
                        <div style="float: left;" class="highlighted padding" *ngIf="vehicle.productionStatus">
                            {{convertToTitleCase(vehicle.productionStatus)}}
                        </div>
                        <div style="float: right;" class="highlighted padding" *ngIf="vehicle.focus">
                            {{convertToTitleCase(vehicle.focus)}}
                        </div>
                        <div class="padding" style="clear: both;">
                            {{vehicle.description}}
                        </div>
                        <div class="padding">
                            <div style="float: left;">
                                <a href="{{vehicle.salesPageUrl}}"
                                   class="card-link" style="margin-right: 16px;">
                                    <button mat-flat-button aria-label="RSI Page" color="primary">
                                        Show on RSI Sales Page
                                    </button>
                                </a>
                                <a href="{{vehicle.storeUrl}}"
                                   class="card-link">
                                    <button mat-flat-button aria-label="RSI Page" color="primary">
                                        Show on RSI Store Page
                                    </button>
                                </a>
                            </div>
                            <div style="float: right;">
                                <a href="https://www.erkul.games/ship/{{vehicle.erkulIdentifier}}"
                                   class="card-link">
                                    <button mat-flat-button aria-label="Erkul's DPS Calculator" color="primary">
                                        Show in Erkul's DPS Calculator
                                    </button>
                                </a>
                            </div>
                            <div style="clear: both"></div>
                        </div>
                    </div>
                </div>
                <div style="float: right; width: 400px;">
                    <h2>{{vehicle.manufacturer.name}}<div class="fy-vehicle-tile manufacturer-logo"
                                                          style="background-image: url({{vehicle.manufacturer.logo}});"></div></h2>
                    <div class="vehicle-info-tile card card-1 padding">
                        <h3>Base</h3>
                        <div>
                            <mat-grid-list cols="3" rowHeight="54px">
                                <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                    <div>
                                        <b class="sub-header">Length</b>
                                        <div class="highlighted">{{vehicle.metrics.lengthLabel}}</div>
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                    <div>
                                        <b class="sub-header">Height</b>
                                        <div class="highlighted">{{vehicle.metrics.heightLabel}}</div>
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                    <div>
                                        <b class="sub-header">Cargo</b>
                                        <div class="highlighted">{{vehicle.metrics.cargoLabel}}</div>
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                    <div>
                                        <b class="sub-header">Beam</b>
                                        <div class="highlighted">{{vehicle.metrics.beamLabel}}</div>
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                    <div>
                                        <b class="sub-header">Mass</b>
                                        <div class="highlighted">{{vehicle.metrics.massLabel}}</div>
                                    </div>
                                </mat-grid-tile>
                            </mat-grid-list>
                            <mat-divider></mat-divider>
                            <mat-grid-list cols="2" rowHeight="54px">
                                <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                    <div>
                                        <b class="sub-header">Classification</b>
                                        <div class="highlighted">{{vehicle.classificationLabel}}</div>
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                    <div>
                                        <b class="sub-header">Size</b>
                                        <div class="highlighted">{{vehicle.metrics.sizeLabel}}</div>
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                    <div>
                                        <b class="sub-header">Price</b>
                                        <div class="highlighted">{{vehicle.priceLabel}}</div>
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                    <div>
                                        <b class="sub-header">Pledge Price</b>
                                        <div class="highlighted">{{vehicle.pledgePriceLabel}}</div>
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                    <div>
                                        <b class="sub-header">Fuel</b>
                                        <div class="highlighted">{{vehicle.hydrogenFuelTankSize}}</div>
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                    <div>
                                        <b class="sub-header">Quantum Fuel</b>
                                        <div class="highlighted">{{vehicle.quantumFuelTankSize}}</div>
                                    </div>
                                </mat-grid-tile>
                            </mat-grid-list>
                            <mat-divider></mat-divider>
                            <mat-grid-list cols="1" rowHeight="54px">
                                <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                    <div>
                                        <b class="sub-header">Last updated at</b>
                                        <div class="highlighted">{{vehicle.lastUpdatedAtLabel}}</div>
                                    </div>
                                </mat-grid-tile>
                            </mat-grid-list>
                        </div>
                    </div>
                    <div class="vehicle-info-tile card card-1 padding">
                        <h3>Sold and Rental</h3>
                        <mat-grid-list cols="1" rowHeight="64px">
                            <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left"
                                           *ngFor="let sold of vehicle.availability.soldAt">
                                <div>
                                    <b class="sub-header">Sold at</b>
                                    <div class="highlighted">
                                        <div >
                                            {{sold.shop.name}} {{sold.shop.locationLabel}}
                                        </div>
                                    </div>
                                </div>
                            </mat-grid-tile>
                        </mat-grid-list>
                        <mat-divider></mat-divider>
                        <mat-grid-list cols="1" rowHeight="64px">
                            <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left"
                                           *ngFor="let rental of vehicle.availability.rentalAt">
                                <div>
                                    <b class="sub-header">Rental at</b>
                                    <div class="highlighted">
                                        <div >
                                            {{rental.shop.name}} {{rental.shop.locationLabel}}
                                        </div>
                                    </div>
                                </div>
                            </mat-grid-tile>
                        </mat-grid-list>
                    </div>
                    <div class="vehicle-info-tile card card-1 padding">
                        <h3>Crew</h3>
                        <div>
                            <mat-grid-list cols="2" rowHeight="54px">
                                <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                    <div>
                                        <b class="sub-header">min. Crew</b>
                                        <div class="highlighted">{{vehicle.minCrewLabel}}</div>
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                    <div>
                                        <b class="sub-header">max. Crew</b>
                                        <div class="highlighted">{{vehicle.maxCrewLabel}}</div>
                                    </div>
                                </mat-grid-tile>
                            </mat-grid-list>
                        </div>
                    </div>
                    <div class="vehicle-info-tile card card-1 padding">
                        <h3>Speed</h3>
                        <div>
                            <mat-grid-list cols="2" rowHeight="54px">
                                <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                    <div>
                                        <b class="sub-header">SCM Speed</b>
                                        <div class="highlighted">{{vehicle.speeds.scmSpeed}}</div>
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                    <div>
                                        <b class="sub-header">Max Speed</b>
                                        <div class="highlighted">{{vehicle.speeds.maxSpeed}}</div>
                                    </div>
                                </mat-grid-tile>
                            </mat-grid-list>
                            <mat-divider></mat-divider>
                            <mat-grid-list cols="2" rowHeight="54px">
                                <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                    <div>
                                        <b class="sub-header">Zero to SCM</b>
                                        <div class="highlighted">{{vehicle.speeds.scmSpeedAcceleration}} s</div>
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                    <div>
                                        <b class="sub-header">SCM to Zero</b>
                                        <div class="highlighted">{{vehicle.speeds.scmSpeedDecceleration}} s</div>
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                    <div>
                                        <b class="sub-header">Zero to Max</b>
                                        <div class="highlighted">{{vehicle.speeds.maxSpeedAcceleration}} s</div>
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">
                                    <div>
                                        <b class="sub-header">Max to Zero</b>
                                        <div class="highlighted">{{vehicle.speeds.maxSpeedDecceleration}} s</div>
                                    </div>
                                </mat-grid-tile>
                            </mat-grid-list>
                            <!--                    <mat-grid-list cols="3" rowHeight="54px">-->
                            <!--                        <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">-->
                            <!--                            <div>-->
                            <!--                                <b class="sub-header">Pitch</b>-->
                            <!--                                <div class="highlighted">{{vehicle.speeds.scmSpeedAcceleration}} s</div>-->
                            <!--                            </div>-->
                            <!--                        </mat-grid-tile>-->
                            <!--                        <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">-->
                            <!--                            <div>-->
                            <!--                                <b class="sub-header">Yaw</b>-->
                            <!--                                <div class="highlighted">{{vehicle.speeds.scmSpeedDecceleration}} s</div>-->
                            <!--                            </div>-->
                            <!--                        </mat-grid-tile>-->
                            <!--                        <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-tile-left">-->
                            <!--                            <div>-->
                            <!--                                <b class="sub-header">Roll</b>-->
                            <!--                                <div class="highlighted">{{vehicle.speeds.maxSpeedAcceleration}} s</div>-->
                            <!--                            </div>-->
                            <!--                        </mat-grid-tile>-->
                            <!--                    </mat-grid-list>-->
                        </div>
                    </div>
                </div>
                <div style="clear: both"></div>
                <main class="bigger-tiles padding">
                    <ng-container *ngFor="let group of hardPointTypes | keyvalue">
                        <app-vehicle-component-details [component_group]="group"></app-vehicle-component-details>
                    </ng-container>
                </main>
                <div style="clear: both"></div>
            </div>
        </mat-tab>
        <mat-tab label="Paints" *ngIf="paints && paints.length > 0">
            <main class="bigger-tiles padding">
                <ng-container *ngFor="let paint of paints">
                    <div class="card card-1 padding" style="width: 100%;">
                        <h2>{{paint.name}}</h2>
                        <div class="fy-vehicle-tile" style="background-image: url({{paint.storeImage}}); height: 400px;">
                        </div>
                    </div>
                </ng-container>
            </main>
        </mat-tab>
        <mat-tab label="Variants" *ngIf="variants && variants.length > 0">
            <main class="bigger-tiles padding">
                <ng-container *ngFor="let variant of variants">
                    <div class="card card-1 padding" style="width: 100%;">
                        <h2>{{variant.name}}</h2>
                        <div class="fy-vehicle-tile" style="background-image: url({{variant.storeImage}}); height: 400px;">
                            <div class="btn-container">
                                <a routerLink="../{{variant.rsiSlug}}" routerLinkActive="active"
                                   class="card-link">
                                    <button mat-icon-button aria-label="Show Vehicle">
                                        <i class="fas fa-space-shuttle"></i>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </main>
        </mat-tab>
    </mat-tab-group>

</ng-template>
