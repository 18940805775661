import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-table-of-content-list-recursive',
  templateUrl: './table-of-content-list-recursive.component.html',
  styleUrls: ['./table-of-content-list-recursive.component.css']
})
export class TableOfContentListRecursiveComponent {
  @Input()
  public entryArray: any[];

  public scrollTo(target: string) {
    document.getElementById(target).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }
}
