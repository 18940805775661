<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div class="padding" *ngIf="owner && edit || mode === 'add'">
        <app-form-inputs *ngIf="fields && fields.length"
                         #tpl_form
                         [field_groups]="fields"
                         [edit_allowed]="true"
                         [loading]="false"
                         (on_submit)="saveStore($event)"
                         [button_class]="'button-save'">
        </app-form-inputs>
    </div>
    <div *ngIf="!edit && mode !== 'add'" class="padding">
        <div class="guide-side-menu">
            <div class="guide-toc card padding">
                <div>
                    By: <b [innerHTML]="authorsWithLinebreak(record.data.author_nicks)"></b>
                </div>
                <ol *ngIf="toc_summary && toc_content">
                    <li (click)="scrollTo('summary')">Summary</li>
                    <app-table-of-content [toc]="toc_summary"></app-table-of-content>
                    <li (click)="scrollTo('content')">Content</li>
                    <app-table-of-content [toc]="toc_content"></app-table-of-content>
                </ol>
                <ng-container *ngIf="!edit && owner">
                    <button mat-mini-fab class="button-edit" (click)="edit = !edit"
                            matTooltip="bearbeiten">
                        <mat-icon aria-label="bearbeiten">edit</mat-icon>
                    </button>
                </ng-container>
            </div>
            <div class="card guide-toc padding">
                Game Version: {{record.data.version_name}}
            </div>
        </div>
        <div class="guide-content-container">
            <h2>{{record.data.caption}}</h2>
            <section id="summary">
                <h3 class="group-caption">Summary</h3>
                <div class="card padding">
                    <app-sc-content [content]="record.data.summary"
                                    [id]="'guide-summary'"
                                    (tocChange)="tocCreated($event, 'toc_summary')"
                                    [checkboxContainerKey]="'checkboxes_guide_summary_' + record.data.guide_id">
                    </app-sc-content>
                </div>
            </section>
            <section id="content">
                <h3 class="group-caption">Content</h3>
                <div class="card padding">
                    <app-sc-content [content]="record.data.content"
                                    [id]="'guide-content'"
                                    (tocChange)="tocCreated($event, 'toc_content')"
                                    [checkboxContainerKey]="'checkboxes_guide_content_' + record.data.guide_id">
                    </app-sc-content>
                </div>
            </section>
        </div>
        <div style="clear: both;"></div>
    </div>
</ng-template>
